import { refreshToken } from '@/api/request';
import { LoginUserInfoParamsInterface } from '@/model/utils/cookie';
import { $dayjs } from '@/plugins/day';
import { useAppStore } from '@/stores/app';
import { CHANGE_COMPANY, SET_LOGIN_USER, SET_MEMBER_INFO, SET_TODO_LIST, SET_TOKEN } from '@/stores/app/actionTypes';
import { useIbClientReportStore } from '@/stores/ibClientReport';
import { RESET_USER_VIEW, SET_EXPORT_ALL_LOADING } from '@/stores/ibClientReport/actionTypes';
import { useIbReportStore } from '@/stores/ibReport';
import { RESET_ALL_REPORT } from '@/stores/ibReport/actionTypes';
import { useRegistrationStore } from '@/stores/registration';
import { SET_REGISTRATION_SEQUENCE } from '@/stores/registration/actionTypes';
import { useReportsStore } from '@/stores/reports';
import { RESET_VIEW } from '@/stores/reports/actionTypes';
import Cookie from 'js-cookie';
import { getLocalStorage, removeLocalStorage } from './localStorage';

const key = 'member';
const refreshTimeKey = `${key}_refresh_time`;
const continuedLoginKey = `${key}_continued_login`;
const tokenKey = `${key}_token`;
const refreshTokenKey = `${key}_refresh_token`;
const loginUserKey = `${key}_login_user`;

function getExpiresTime(second: number) {
    return $dayjs().add(second, 'second').$d;
}

export function setCookie(name: string, value: any, second?: number | string) {
    second = Number(second);
    const expires = { expires: second ? getExpiresTime(second) : new Date('2099-01-01') };
    if (!import.meta.env.DEV) {
        return Cookie.set(name, value, { secure: true, sameSite: 'Lax', ...expires });
    } else {
        return Cookie.set(name, value, expires);
    }
}

export function getLang() {
    return Cookie.get(`member_locale`);
}

export function setLang(value: string) {
    return setCookie(`member_locale`, value);
}

export function getCookie(name: string) {
    return Cookie.get(name);
}

export function removeCookie(name: string) {
    return Cookie.remove(name);
}

export function setLoginUserInfo(params: LoginUserInfoParamsInterface) {
    const appStore = useAppStore();
    const time = new Date().getTime();
    const res = params.res;
    removeLocalStorage(`notification_${getLocalStorage('login_id')}`);
    removeLocalStorage(`trading_tools_popup_read_${getLocalStorage('login_id')}`);
    setCookie(refreshTimeKey, time);
    setCookie(continuedLoginKey, params.continuedLogin || false);
    setCookie(loginUserKey, params.loginUser);
    setCookie(tokenKey, res.access_token, res.expires_in);
    setCookie(refreshTokenKey, res.refresh_token, res.expires_in);
    appStore[SET_TOKEN](res.access_token);
    appStore[SET_LOGIN_USER](params.loginUser);
}
function removeExportListTimeout() {
    const exportMember = window.exportMember;
    for (const key in exportMember) {
        if (Object.hasOwnProperty.call(exportMember, key)) {
            const element = exportMember[key];
            clearTimeout(element);
        }
    }
    window.exportMember = {};
    const ibClientReportStore = useIbClientReportStore();
    ibClientReportStore[SET_EXPORT_ALL_LOADING]();
}

export function removeLoginInfo() {
    const ibReportStore = useIbReportStore();
    const reportsStore = useReportsStore();
    const ibClientReportStore = useIbClientReportStore();
    const appStore = useAppStore();
    const registrationStore = useRegistrationStore();

    removeLocalStorage(`notification_${getLocalStorage('login_id')}`);
    removeLocalStorage(`trading_tools_popup_read_${getLocalStorage('login_id')}`);
    removeCookie(refreshTimeKey);
    removeCookie(continuedLoginKey);
    removeCookie(loginUserKey);
    removeCookie(tokenKey);
    removeCookie(refreshTokenKey);
    appStore[SET_TOKEN]('');
    appStore[SET_LOGIN_USER]('');
    appStore[SET_MEMBER_INFO]({});
    appStore[CHANGE_COMPANY]({});
    appStore[SET_TODO_LIST]([]);
    registrationStore[SET_REGISTRATION_SEQUENCE]([]);
    ibReportStore[RESET_ALL_REPORT]();
    reportsStore[RESET_VIEW]();
    ibClientReportStore[RESET_USER_VIEW]();
    removeExportListTimeout();
}

const refreshAndSaveToken = async () => {
    try {
        const refreshTokenByCookie = getCookie(refreshTokenKey);
        if (!refreshTokenByCookie) {
            return false;
        }
        const res = await refreshToken({ refresh_token: refreshTokenByCookie });
        if (res) {
            setLoginUserInfo({
                res,
                loginUser: getCookie(loginUserKey),
                continuedLogin: getCookie(continuedLoginKey),
            });
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const isTokenEnable = () => {
    const refreshTokenTime = Number(getCookie(refreshTimeKey));
    const continuedLogin = getCookie(continuedLoginKey) || false;
    const currentTime = new Date().getTime();
    if (continuedLogin && currentTime - refreshTokenTime > 1000 * 60 * 60 * 24) {
        return refreshAndSaveToken();
    } else {
        return getCookie(tokenKey);
    }
};
