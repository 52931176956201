/*
github repo: https://github.com/nefe/number-precision
*/

type numType = number | string;
/**
 * @desc 解决浮动运算问题，避免小数点后产生多位数和计算精度损失。
 * 问题示例：2.3 + 2.4 = 4.699999999999999，1.0 - 0.9 = 0.09999999999999998
 */

/**
 * 把错误的数据转正
 * strip(0.09999999999999998)=0.1
 */
function strip(num: numType, precision = 15): number {
    return +parseFloat(Number(num).toPrecision(precision));
}

/**
 * Return digits length of a number
 * @param {*number} num Input number
 */
function digitLength(num: numType): number {
    // Get digit length of e
    if (num === undefined || num === '' || num === null) return 0; //错误数值return 避免toString错误
    // 自定义加了代码，如果传入的时字符串，需要trim
    if (typeof num === 'string') {
        num = num.trim();
    }
    const eSplit = num.toString().split(/[eE]/);
    const len = (eSplit[0].split('.')[1] || '').length - +(eSplit[1] || 0);
    return len > 0 ? len : 0;
}

/**
 * 把数字后面多余的0，或者将科学计算的数字，转为正常的数字
 * 0.99000 => 0.99
 * 4.5235e-10  => 0.00000000045235
 * @param {*number} num 输入数
 */
function toNonExponential(num: numType): string {
    const target = Number(num);
    if (Number.isNaN(target)) {
        return '';
    }
    const m = target.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)!;
    //TODO: 需要驗證m[1], m[2]是什麼
    return target.toFixed(Math.max(0, (m[1] || '').length - +m[2]));
}

/**
 * 把小数转成整数，支持科学计数法。如果是小数则放大成整数
 * @param {*number} num 输入数
 */
function float2Fixed(num: numType): number {
    if (num === undefined || num === '' || num === null) return 0; //错误数值return 避免toString错误
    // 自定义加了代码，如果传入的时字符串，需要trim
    if (typeof num === 'string') {
        num = num.trim();
    }
    if (num.toString().indexOf('e') === -1) {
        return Number(num.toString().replace('.', ''));
    }
    const dLen = digitLength(num);
    return dLen > 0 ? strip(Number(num) * Math.pow(10, dLen)) : Number(num);
}

/**
 * 检测数字是否越界，如果越界给出提示
 * @param {*number} num 输入数
 */
function checkBoundary(num: number) {
    if (_boundaryCheckingState) {
        if (num > Number.MAX_SAFE_INTEGER || num < Number.MIN_SAFE_INTEGER) {
            console.warn(`${num} is beyond boundary when transfer to integer, the results may not be accurate`);
        }
    }
}

/**
 * 迭代操作
 */
function iteratorOperation(arr: numType[], operation: (...args: numType[]) => number): number {
    const [num1, num2, ...others] = arr;
    let res = operation(num1, num2);

    others.forEach((num) => {
        res = operation(res, num);
    });

    return res;
}

/**
 * 精确乘法
 */
function times(...nums: numType[]): any {
    if (nums.length > 2) {
        return iteratorOperation(nums, times);
    }

    const [num1, num2] = nums;
    const num1Changed = float2Fixed(num1);
    const num2Changed = float2Fixed(num2);
    const baseNum = digitLength(num1) + digitLength(num2);
    const leftValue = num1Changed * num2Changed;

    checkBoundary(leftValue);

    const res = leftValue / Math.pow(10, baseNum);
    return toNonExponential(res);
}

/**
 * 精确加法
 */
function plus(...nums: numType[]): any {
    if (nums.length > 2) {
        return iteratorOperation(nums, plus);
    }

    const [num1, num2] = nums;
    // 取最大的小数位
    const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)));
    // 把小数都转为整数然后再计算
    const res = (Number(times(num1, baseNum)) + Number(times(num2, baseNum))) / baseNum;
    return toNonExponential(res);
}

/**
 * 精确减法
 */
function minus(...nums: numType[]): any {
    if (nums.length > 2) {
        return iteratorOperation(nums, minus);
    }

    const [num1, num2] = nums;
    const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)));
    return toNonExponential((times(num1, baseNum) - times(num2, baseNum)) / baseNum);
}

/**
 * 精确除法
 */
function divide(...nums: numType[]): number {
    if (nums.length > 2) {
        return iteratorOperation(nums, divide);
    }

    const [num1, num2] = nums;
    const num1Changed = float2Fixed(num1);
    const num2Changed = float2Fixed(num2);
    checkBoundary(num1Changed);
    checkBoundary(num2Changed);
    // fix: 类似 10 ** -4 为 0.00009999999999999999，strip 修正
    return times(num1Changed / num2Changed, strip(Math.pow(10, digitLength(num2) - digitLength(num1))));
}

/**
 * 四舍五入
 */
function round(num: numType, ratio: number): number {
    const base = Math.pow(10, ratio);
    let result = divide(Math.round(Math.abs(times(num, base))), base);
    if (Number(num) < 0 && result !== 0) {
        result = times(result, -1);
    }
    return result;
}

/**
 * 四舍五入，输出string类型
 */
function c_round(number: numType, ratio = 0): string {
    return String(round(number, ratio));
}

/**
 * 有效数字，小数最大为15位
 */

function effective(num: numType, ratio: number): string {
    if (num === '' || num === undefined) {
        return '';
    }
    let base = toNonExponential(num);
    const decimal = base.split('.')[1];
    if (!decimal || decimal.length <= ratio) {
        return base;
    }
    base += '00000';
    const reg = base.match(new RegExp(`\\d+\\.0*[^0]\\d{${ratio - 1}}`, 'g'));
    return toNonExponential(reg ? reg[0] : base);
}

let _boundaryCheckingState = true;
/**
 * 是否进行边界检查，默认开启
 * @param flag 标记开关，true 为开启，false 为关闭，默认为 true
 */
function enableBoundaryChecking(flag = true) {
    _boundaryCheckingState = flag;
}
export { toNonExponential, c_round, effective, strip, plus, minus, times, divide, round, digitLength, float2Fixed, enableBoundaryChecking };
export default {
    toNonExponential,
    c_round,
    effective,
    strip,
    plus,
    minus,
    times,
    divide,
    round,
    digitLength,
    float2Fixed,
    enableBoundaryChecking,
};
