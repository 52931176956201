import { Objects } from '@/model/common';
import * as _ from 'lodash-es';
import { defineStore } from 'pinia';
import * as actionTypes from './actionTypes';
import { DataViewType, RebateReportRequest, RebateReportRequestInfo, RecentOrdersRequest, RecentOrdersRequestInfo, ReportViewType, State, TxnReportRequest, TxnReportRequestInfo, UserView } from './type';

export const useIbClientReportStore = defineStore('ibClientReport', {
    state: (): State => ({
        userViewList: [],
        activeUserViewID: undefined,
        reportViewTypes: {},
        dataViews: {},
        txnReportRequests: {},
        rebateReportRequests: {},
        recentOrdersRequests: {},
        exportAllLoading: {},
    }),

    getters: {
        activeUserView(state): UserView | undefined {
            return _.find(state.userViewList, {
                user_id: state.activeUserViewID,
            });
        },
        activeUserViewIsIB(state): number | undefined {
            if (!state.activeUserViewID && !this.activeUserView) {
                return undefined;
            }

            return this.activeUserView?.is_ib;
        },
        activeReportViewType(state): ReportViewType | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            return state.reportViewTypes[state.activeUserViewID];
        },
        activeDataViewType(state): DataViewType | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            const key = `${state.activeUserViewID}/${this.activeReportViewType}`;

            return state.dataViews[key]?.type || 'account-tree';
        },
        activeRebateReportRequest(state): RebateReportRequest | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            return state.rebateReportRequests[state.activeUserViewID];
        },
        activeRebateReportRequestInfo(state): RebateReportRequestInfo | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            const report = state.rebateReportRequests[state.activeUserViewID];

            if (!report) {
                return undefined;
            }

            return _.find(report.tabs, {
                key: report.active,
            });
        },
        activeTxnReportRequest(state): TxnReportRequest | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            return state.txnReportRequests[state.activeUserViewID];
        },
        activeTxnReportRequestInfo(state): TxnReportRequestInfo | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            const report = state.txnReportRequests[state.activeUserViewID];

            if (!report) {
                return undefined;
            }

            return _.find(report.tabs, {
                key: report.active,
            });
        },
        activeRecentOrdersRequest(state): RecentOrdersRequest | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            return state.recentOrdersRequests[state.activeUserViewID];
        },
        activeRecentOrdersRequestInfo(state): RecentOrdersRequestInfo | undefined {
            if (!state.activeUserViewID) {
                return undefined;
            }

            const report = state.recentOrdersRequests[state.activeUserViewID];

            if (!report) {
                return undefined;
            }

            return _.find(report.tabs, {
                key: report.active,
            });
        },
    },

    actions: {
        [actionTypes.ADD_USER_VIEW](userView: UserView): void {
            const exists = _.includes(_.map(this.userViewList, 'user_id'), userView.user_id);

            if (!exists) {
                this.userViewList.push(userView);
            }

            this.activeUserViewID = userView.user_id;
        },
        [actionTypes.TOGGLE_USER_VIEW](userViewID: number | undefined): void {
            this.activeUserViewID = userViewID;
        },
        [actionTypes.REMOVE_USER_VIEW](userViewID: number): void {
            const i = this.userViewList.findIndex((item) => item.user_id === userViewID);

            if (i === -1) {
                return;
            }

            this.activeUserViewID = undefined;

            this.userViewList.splice(i, 1);
            this.reportViewTypes = _.omit(this.reportViewTypes, [userViewID]);
            this.dataViews = _.omit(
                this.dataViews,
                _.filter(_.keys(this.dataViews), (item) => item.startsWith(`${userViewID}`)),
            );
            this.rebateReportRequests = _.omit(this.rebateReportRequests, [userViewID]);
            this.recentOrdersRequests = _.omit(this.recentOrdersRequests, [userViewID]);
        },
        [actionTypes.RESET_USER_VIEW](): void {
            this.activeUserViewID = undefined;
            this.userViewList = [];
            this.reportViewTypes = {};
            this.dataViews = {};
            this.txnReportRequests = {};
            this.rebateReportRequests = {};
            this.recentOrdersRequests = {};
        },
        [actionTypes.SET_REPORT_VIEW_TYPE](payload: any): void {
            this.reportViewTypes = _.assign({}, this.reportViewTypes, {
                [payload.userID]: payload.type,
            });
        },
        [actionTypes.SET_DATA_VIEW_TYPE](payload: any): void {
            const key = `${payload.userID}/${payload.reportViewType}`;
            const data = _.assign({}, this.dataViews[key], {
                type: payload.type,
            });

            if (payload.payload) {
                data.payload = payload.payload;
            }

            this.dataViews = _.assign({}, this.dataViews, {
                [key]: data,
            });
        },
        [actionTypes.SET_ACTIVE_REBATE_REPORT_REQUEST](payload: { userID: number; active: number | undefined }): void {
            const data: RebateReportRequest = _.assign({}, this.rebateReportRequests[payload.userID], {
                active: payload.active,
            });

            this.rebateReportRequests = _.assign({}, this.rebateReportRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.ADD_REBATE_REPORT_REQUEST_INFO](payload: any): void {
            const data: RebateReportRequest = _.assign({}, this.rebateReportRequests[payload.userID], {
                active: payload.info.key,
            });

            if (!data.tabs) {
                data.tabs = [];
            }

            if (data.tabs.length) {
                const i = _.findIndex(data.tabs, {
                    key: payload.info.key,
                });

                if (i === -1) {
                    data.tabs.push(payload.info);
                } else {
                    data.tabs[i] = payload.info;
                }
            } else {
                data.tabs.push(payload.info);
            }

            this.rebateReportRequests = _.assign({}, this.rebateReportRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.REMOVE_REBATE_REPORT_REQUEST_INFO](payload: { userID: number; key: number | undefined }): void {
            const request = this.rebateReportRequests[payload.userID];

            if (!request) {
                return;
            }

            const tabs = _.filter(request.tabs, (item) => item.key !== payload.key);

            const data: RebateReportRequest = _.assign({}, request, {
                tabs,
            });

            this.rebateReportRequests = _.assign({}, this.rebateReportRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.SET_ACTIVE_TXN_REPORT_REQUEST](payload: { userID: number; active: number | undefined }): void {
            const data: TxnReportRequest = _.assign({}, this.txnReportRequests[payload.userID], {
                active: payload.active,
            });

            this.txnReportRequests = _.assign({}, this.txnReportRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.ADD_TXN_REPORT_REQUEST_INFO](payload: any): void {
            const data: TxnReportRequest = _.assign({}, this.txnReportRequests[payload.userID], {
                active: payload.info.key,
            });

            if (!data.tabs) {
                data.tabs = [];
            }

            if (data.tabs.length) {
                const i = _.findIndex(data.tabs, {
                    key: payload.info.key,
                });

                if (i === -1) {
                    data.tabs.push(payload.info);
                } else {
                    data.tabs[i] = payload.info;
                }
            } else {
                data.tabs.push(payload.info);
            }

            this.txnReportRequests = _.assign({}, this.txnReportRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.REMOVE_TXN_REPORT_REQUEST_INFO](payload: { userID: number; key: number | undefined }): void {
            const request = this.txnReportRequests[payload.userID];

            if (!request) {
                return;
            }

            const active = request.tabs[0]?.key;
            const tabs = _.filter(request.tabs, (item) => item.key !== payload.key);

            const data: TxnReportRequest = _.assign({}, request, {
                active,
                tabs,
            });

            if (!tabs.length) {
                const dataViewsKey = `${payload.userID}/txn-report`;
                const dataViewsData = _.assign({}, this.dataViews[dataViewsKey], {
                    type: 'account-tree',
                    payload: {},
                });

                this.dataViews = _.assign({}, this.dataViews, {
                    [dataViewsKey]: dataViewsData,
                });
            }

            this.txnReportRequests = _.assign({}, this.txnReportRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.SET_ACTIVE_RECENT_ORDERS_REQUEST](payload: { userID: number; active: number | undefined }): void {
            const data: RecentOrdersRequest = _.assign({}, this.recentOrdersRequests[payload.userID], {
                active: payload.active,
            });

            this.recentOrdersRequests = _.assign({}, this.recentOrdersRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.ADD_RECENT_ORDERS_REQUEST_INFO](payload: any): void {
            const data: RecentOrdersRequest = _.assign({}, this.recentOrdersRequests[payload.userID], {
                active: payload.info.key,
            });

            if (!data.tabs) {
                data.tabs = [];
            }

            if (data.tabs.length) {
                const i = _.findIndex(data.tabs, {
                    key: payload.info.key,
                });

                if (i === -1) {
                    data.tabs.push(payload.info);
                } else {
                    data.tabs[i] = payload.info;
                }
            } else {
                data.tabs.push(payload.info);
            }

            this.recentOrdersRequests = _.assign({}, this.recentOrdersRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.REMOVE_RECENT_ORDERS_REQUEST_INFO](payload: { userID: number; key: number | undefined }): void {
            const request = this.recentOrdersRequests[payload.userID];

            if (!request) {
                return;
            }

            const active = request.tabs[0]?.key;
            const tabs = _.filter(request.tabs, (item) => item.key !== payload.key);

            const data: RecentOrdersRequest = _.assign({}, request, {
                active,
                tabs,
            });

            if (!tabs.length) {
                const dataViewsKey = `${payload.userID}/recent-orders`;
                const dataViewsData = _.assign({}, this.dataViews[dataViewsKey], {
                    type: 'account-tree',
                    payload: {},
                });

                this.dataViews = _.assign({}, this.dataViews, {
                    [dataViewsKey]: dataViewsData,
                });
            }

            this.recentOrdersRequests = _.assign({}, this.recentOrdersRequests, {
                [payload.userID]: data,
            });
        },
        [actionTypes.SET_EXPORT_ALL_LOADING](payload?: Objects) {
            if (!payload) {
                this.exportAllLoading = {};
            }
            this.exportAllLoading = Object.assign({}, this.exportAllLoading, payload);
        },
    },
});
