import { getLanguageInfo } from '@/api/request';
import { zhLocale } from '@/i18n/element';
import i18n from '@/i18n/i18n';
import { $message } from '@/plugins/element';
import { useAppStore } from '@/stores/app';
import { CHANGE_LANGUAGE } from '@/stores/app/actionTypes';
import { LANG_EN, LANG_ZH, LANG_ZH_TW } from '@/utils/constant';
import { getLang, setLang } from '@/utils/cookie';
import { parseCsvString } from '@/utils/csv';
import { $t, changeLocale, defaultLang, setLocaleMessage } from '@/utils/i18n';
import * as _ from 'lodash-es';
import { ref } from 'vue';

export default function langShareFunction() {
    const loading = ref(false);

    const appStore = useAppStore();

    const formatLangCSVData = (data: any): string | [] => {
        if (!data || !data.length) return [];

        let target = _.cloneDeep(data);
        const backUpHeader = target[0];
        const backUpHeaderKey = backUpHeader[0].trim();
        const backUpHeaderLang = backUpHeader[1].trim();
        const backUpHeaderLangOther = (backUpHeader[2] || '').trim();
        target.shift();
        if (backUpHeaderLangOther) {
            target = target.map((val: any) => {
                return {
                    [backUpHeaderKey]: val[0],
                    [backUpHeaderLang]: val[1],
                    [backUpHeaderLangOther]: val[2],
                };
            });
        } else {
            target = target.map((val: any) => {
                return {
                    [backUpHeaderKey]: val[0],
                    [backUpHeaderLang]: val[1],
                };
            });
        }
        return target;
    };

    const getLangFromRemote = async (lang: string): Promise<boolean> => {
        const temp = {};
        const res: any = await getLanguageInfo({ language: lang, terminal: 'pc', type: 'member_portal' });
        if (res && res.oss_path) {
            let csvData = await parseCsvString(res.oss_path);
            if (csvData) {
                csvData = formatLangCSVData(csvData.data);
                csvData.forEach((val: any) => {
                    if (val[lang]) _.set(temp, val.key, val[lang]);
                });
            }
        } else {
            $message.error($t('errorCode.lang_file_not_exist'));
            return false;
        }
        setLocaleMessage(lang, temp);
        return true;
    };

    const callGetLangFromRemote = async (lang: string) => {
        loading.value = true;
        const res = await getLangFromRemote(lang);
        loading.value = false;
        return res;
    };

    const chooseLang = async (lang: string): Promise<any> => {
        if (loading.value) return;
        if (!i18n.global.availableLocales.includes(lang)) {
            if (defaultLang.includes(lang)) {
                try {
                    loading.value = true;
                    const messages = await import(`@/i18n/zh.js`);
                    let currentMessages = messages.default;
                    if (lang === LANG_ZH) {
                        currentMessages = Object.assign(currentMessages, zhLocale);
                    }
                    setLocaleMessage(lang, currentMessages);
                } catch {
                    $message.error($t('Tips.load_lang_error'));
                } finally {
                    loading.value = false;
                }
            } else {
                const res = await callGetLangFromRemote(lang);
                if (!res) return $message.error($t('Tips.load_lang_error'));
            }
        }
        changeLang(lang);
        (document.querySelector('html') as Element).setAttribute('lang', lang);
    };

    const changeLang = (lang: string): void => {
        changeLocale(lang);
        setLang(lang);
        appStore[CHANGE_LANGUAGE](lang);
    };

    const getLocationLang = (): string => {
        let search: any = window.location.search;
        let lang = '';
        if (search) {
            search = search.split('?')[1].split('&');
            search.forEach((val: any) => {
                if (val.indexOf('language') !== -1) lang = val.split('=')[1];
            });
        }
        switch (lang) {
            case 'zh':
                lang = LANG_ZH;
                break;
            case 'zh-tw':
                lang = LANG_ZH_TW;
                break;
        }
        return lang;
    };

    const initLoadLang = async (): Promise<void> => {
        const allLang = appStore.allLanguages;
        let lang = getLocationLang() || getLang() || LANG_EN;
        const isExist = allLang.find((val: any) => val.status === 1 && val.language === lang);
        lang = isExist ? lang : LANG_EN;
        await chooseLang(lang);
    };

    return { loading, chooseLang, initLoadLang };
}
