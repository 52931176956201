import { $t } from '@/utils/i18n';
import { App } from 'vue';
import { Colgroup, Column, Icon, Table, Tooltip, VXETable } from 'vxe-table';
import 'xe-utils';

VXETable.config({
    i18n: (key, args) => $t(key, args),
    table: {
        loadingConfig: {
            icon: 'loader mini',
            text: undefined,
        },
    },
});

/**
 * @param app {App}
 */
export default function loadComponent(app: App) {
    app.use(Icon).use(Column).use(Tooltip).use(Colgroup).use(Table);
}
