export const ADD_USER_VIEW = 'ADD_USER_VIEW';
export const TOGGLE_USER_VIEW = 'TOGGLE_USER_VIEW';
export const REMOVE_USER_VIEW = 'REMOVE_USER_VIEW';
export const RESET_USER_VIEW = 'RESET_USER_VIEW';
export const SET_REPORT_VIEW_TYPE = 'SET_REPORT_VIEW_TYPE';
export const SET_DATA_VIEW_TYPE = 'SET_DATA_VIEW_TYPE';
export const SET_ACTIVE_TXN_REPORT_REQUEST = 'SET_ACTIVE_TXN_REPORT_REQUEST';
export const ADD_TXN_REPORT_REQUEST_INFO = 'ADD_TXN_REPORT_REQUEST_INFO';
export const REMOVE_TXN_REPORT_REQUEST_INFO = 'REMOVE_TXN_REPORT_REQUEST_INFO';
export const SET_ACTIVE_REBATE_REPORT_REQUEST = 'SET_ACTIVE_REBATE_REPORT_REQUEST';
export const ADD_REBATE_REPORT_REQUEST_INFO = 'ADD_REBATE_REPORT_REQUEST_INFO';
export const REMOVE_REBATE_REPORT_REQUEST_INFO = 'REMOVE_REBATE_REPORT_REQUEST_INFO';
export const SET_ACTIVE_RECENT_ORDERS_REQUEST = 'SET_ACTIVE_RECENT_ORDERS_REQUEST';
export const ADD_RECENT_ORDERS_REQUEST_INFO = 'ADD_RECENT_ORDERS_REQUEST_INFO';
export const REMOVE_RECENT_ORDERS_REQUEST_INFO = 'REMOVE_RECENT_ORDERS_REQUEST_INFO';
export const SET_EXPORT_ALL_LOADING = 'SET_EXPORT_ALL_LOADING';
