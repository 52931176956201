import { useAppStore } from '@/stores/app';
import * as _ from 'lodash-es';
import { $t } from './i18n';

export const headerMenu = [
    {
        key: 'IBDashboard',
        link: '/ib/dashboard',
        text: 'menu.dashboard',
        systemType: 'IB_Portal',
        hide: false,
    },
    {
        key: 'ClientsReport',
        link: '/ib/clients-report',
        text: 'menu.clients_report',
        systemType: 'IB_Portal',
        hide: false,
    },
    {
        key: 'IBFunding',
        link: '/ib/funding',
        text: 'menu.funding',
        systemType: 'IB_Portal',
        hide: false,
    },
    {
        key: 'RebateReportManagement',
        link: '/ib/rebate-report-management',
        text: 'menu.rebate_report_management',
        systemType: 'IB_Portal',
        hide: false,
    },
    {
        key: 'OrdersReportManagement',
        link: '/ib/orders-report-management',
        text: 'menu.orders_report_management',
        systemType: 'IB_Portal',
        hide: false,
    },
    {
        key: 'IBProfile',
        link: '/ib/ib-profile',
        text: 'menu.ib_profile',
        systemType: 'IB_Portal',
        hide: false,
    },
    {
        key: 'MemberDashboard',
        link: '/dashboard',
        text: 'menu.dashboard',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'MemberFunding',
        link: '/funding',
        text: 'menu.funding',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'TradingTools',
        link: '/trading-tools',
        text: 'menu.trading_tools',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'Downloads',
        link: '/downloads',
        text: 'menu.downloads',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'Reports',
        link: '/reports',
        text: 'menu.reports',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'Documents',
        link: '/documents',
        text: 'menu.documents',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'Promotions',
        link: '/promotions',
        text: 'menu.promotions',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'Rewards',
        link: '/rewards',
        text: 'menu.rewards',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'AccountSettings',
        link: '/user-account/account-settings',
        text: 'menu.account_settings',
        systemType: '',
        hide: true,
    },
    {
        key: 'MessageCenter',
        link: '/user-account/message-center',
        text: 'menu.message_center',
        systemType: '',
        hide: true,
    },
];

export const memberMenuList = headerMenu.filter((item) => !item.hide && item.systemType === 'Member_Portal');
export const ibMenuList = headerMenu.filter((item) => !item.hide && item.systemType === 'IB_Portal');

const titleMenu = [
    {
        key: 'login',
        link: '/login',
        text: 'menu.login',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'register',
        link: '/register',
        text: 'menu.register',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'retrieve-password',
        link: '/retrieve-password',
        text: 'menu.Retrieve_Password',
        systemType: 'Member_Portal',
        hide: false,
    },
    {
        key: 'email-verify',
        link: '/retrieve-password',
        text: 'menu.email_verify',
        systemType: '',
        hide: false,
    },
];

const getRouterTitle = (router: any) => {
    if (!router.meta) return console.error('需传入路由实例');
    const { category } = router.meta;
    if (!category) return '';
    const headerTitleMenu = headerMenu.concat(titleMenu);
    const activeRouter = headerTitleMenu.find((val) => val.key === category);
    return activeRouter?.text;
};

export const setDocumentTitle = (route: any) => {
    const appStore = useAppStore();
    const companyList = appStore.companyList;
    const routerTitle = getRouterTitle(route);
    if (!_.isEmpty(companyList)) {
        if (typeof companyList.web_title !== 'undefined') {
            document.title = routerTitle ? `${$t(routerTitle)} / ${companyList.web_title}`.replace('<br>', ' ') : companyList.web_title;
        }
    } else {
        document.title = routerTitle ? `${$t(routerTitle)}`.replace('<br>', ' ') : 'CRM';
    }
};

export const fundingMenu = [
    {
        path: '/funding/deposit',
        name: 'member-funding-deposit',
        text: 'deposit',
    },
    {
        path: '/funding/withdrawal',
        name: 'member-funding-withdrawal',
        text: 'withdrawal',
    },
    {
        path: '/funding/transfer',
        name: 'member-funding-transfer',
        text: 'transfer',
    },
];
