import { useAppStore } from '@/stores/app';
import NP from '@/utils/NP';

export const NumFormat = (value: string | number, type = 2) => {
    const resultNum = typeof value === 'string' ? Number(value) : value;
    if (!resultNum || isNaN(resultNum)) {
        return `0${type ? '.' : ''}${'0'.repeat(type)}`; // 根据所需的小数点位数去返回0的格式化
    }
    const tempNumberArr = NP.c_round(value, type).split('.'); // 获取最终结果
    const intPart = tempNumberArr[0];
    const decimal = tempNumberArr[1] || '0'.repeat(type);
    let intPartFormat = intPart.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
    return type ? (intPartFormat += `.${decimal}`) : intPartFormat; //type为0时，不显示小数，针对一些会员数量等数字的显示
};

export const PointFormat = (value: string): string => {
    const appStore = useAppStore();
    const name = appStore.getPointsCurrencyName;

    return value === 'POINTS' ? (name ? name : value) : value;
};

export const PriceFormat = (value: string) => {
    const resultNum = Number(value);
    if (!resultNum || isNaN(resultNum)) {
        return '0';
    }
    const tempNumberArr = String(value).split('.'); // 获取最终结果
    const intPart = tempNumberArr[0];
    let decimal = tempNumberArr[1];
    let intPartFormat = intPart.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
    if (decimal) {
        // 截取时需要四舍五入
        const tempNumberArr2 = NP.c_round(value, 5).split('.');
        // 四舍五入后，可能存在小数为0的情况
        decimal = tempNumberArr2[1] || '';
        if (decimal) {
            intPartFormat += `.${decimal}`;
        }
    }
    return intPartFormat;
};

const filters = {
    NumFormat,
    PointFormat,
    PriceFormat,
};
export default filters;
