import { LANG_EN } from '@/utils/constant';
import { getLang, setLang } from '@/utils/cookie';
import { createI18n } from 'vue-i18n';
import { enLocale } from './element';
import en from './en';

let locale = getLang();
if (!locale) {
    locale = LANG_EN;
    setLang(locale);
}

const i18n = createI18n({
    legacy: false,
    locale: locale,
    messages: {
        [LANG_EN]: Object.assign(en, enLocale),
    },
    // 当系统找不到key时，默认使用英文的翻译
    fallbackLocale: LANG_EN,
    // 禁用没有获取到语言时，使用默认en语言时发出的警告
    silentTranslationWarn: true,
});

export default i18n;
