export const DEPOSIT = 'Deposit';
export const WITHDRAWAL = 'Withdrawal';
export const INTERNAL_TF = 'Internal TF';
export const TRANSFER_TO_SUB = 'Transfer To Sub';
export const CREDIT = 'Credit';
export const POINTS = 'Points';
export const LANG_ZH = 'zh-Hans';
export const LANG_EN = 'en';
export const LANG_ZH_TW = 'zh-Hant';
export const MAX_IMG_DPI = 2048;
