import {
    Logout,
    getAllSymbolDisplayName,
    getCompanyData,
    getCountryList,
    getGlobalOption,
    getLanguageList,
    getMemberInfo,
    getMemberPermissions,
    getTradingServer,
    getTradingTools,
    getTransactionsType,
} from '@/api/request';
import { getTodoList, walletIndex } from '@/api/tradingAccount';
import type { AppStore, WalletInfo } from '@/model/app';
import { CompanyParamsInterface, ElementLinkInterface } from '@/model/vuex';
import { CREDIT, DEPOSIT, INTERNAL_TF, LANG_EN, POINTS, WITHDRAWAL } from '@/utils/constant';
import { getCookie, removeLoginInfo } from '@/utils/cookie';
import { setDocumentTitle } from '@/utils/menu';
import { ScrollbarInstance } from 'element-plus';
import * as _ from 'lodash-es';
import { defineStore } from 'pinia';
import * as types from './actionTypes';

function changeFavicon(href: string) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    (link as Element & ElementLinkInterface).type = 'image/x-icon';
    (link as Element & ElementLinkInterface).rel = 'shortcut icon';
    (link as Element & ElementLinkInterface).href = href;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function getCompanyParams(type: number, lang: string, jurisdiction?: any) {
    const params: CompanyParamsInterface = { type: type, lang: lang };
    if (jurisdiction) params.jurisdiction = jurisdiction;
    return params;
}

export const useAppStore = defineStore<'app', AppStore>('app', {
    state: () => ({
        pageScrollRef: null,
        loginInfoId: localStorage.getItem('login_id') || '',
        loginUser: getCookie('member_login_user') || '',
        memberToken: getCookie('member_token') || '',
        locale: getCookie('member_locale') || LANG_EN,
        allLanguages: [
            {
                display_name: 'English',
                is_default: 1,
                language: LANG_EN,
                language_id: 1,
                status: 1,
            },
        ],
        memberInfo: {},
        systemType: sessionStorage.getItem('System_type') || 'Member_Portal',
        companyList: {
            email: '',
            web_title: '',
            web_site: '',
            outline_logo: '',
            logo: '',
        },
        tradingTools: [],
        isRequestTradingTools: false,
        uploadProgress: false,
        transactionsType: [],
        allCountry: [],
        allCountryMap: [],
        toDoList: [],
        title: [],
        allTradingServer: [],
        globalTradingDialog: {
            visible: false,
            externalID: '',
            tradingServerID: '',
        },
        memberPermissions: {},
        allSymbolName: [],
        pointsCurrencyName: '',
        walletInfo: {
            currencies: [],
            not_ib_accounts: [],
            ib_accounts: [],
        },
        responsive: {
            sm: false,
            md: false,
            lg: false,
            xl: false,
        },
        screenLoading: false,
        permissionLoading: false,
    }),

    getters: {
        // 全部交易类型
        GetTransactionsType: (state) => {
            return state.transactionsType || [];
        },
        // 固定交易类型
        getTransactionsTypeHard: (state) => {
            return state.transactionsType.filter((val) => {
                return !val.canedit;
            });
        },
        // 自定义交易类型
        getTransactionsTypeSoft: (state) => {
            return state.transactionsType.filter((val) => {
                return val.canedit;
            });
        },

        getTransactionTypeList() {
            let list: any[] = [
                {
                    value: -1,
                    type: 'All',
                    label: 'IB.all',
                },
            ];

            const softList = this.getTransactionsTypeSoft;
            const hardList = this.getTransactionsTypeHard;

            const realHardList = hardList.map((val: any) => {
                switch (val.type.toLowerCase()) {
                    case 'deposit': {
                        val.value = val.transaction_type_id;
                        val.class = 'deposit';
                        val.label = 'FundingManagement.type.deposit';
                        break;
                    }
                    case 'internal tf': {
                        val.value = val.transaction_type_id;
                        val.class = 'internaltf';
                        val.label = 'FundingManagement.type.internalTF';
                        break;
                    }
                    case 'withdrawal': {
                        val.value = val.transaction_type_id;
                        val.class = 'withdrawal';
                        val.label = 'FundingManagement.type.withdrawal';
                        break;
                    }
                    case 'credit': {
                        val.value = val.transaction_type_id;
                        val.class = 'credit';
                        val.label = 'FundingManagement.type.credit';
                        break;
                    }
                    case 'points': {
                        val.value = val.transaction_type_id;
                        val.class = 'points';
                        val.label = 'FundingManagement.type.points';
                        break;
                    }
                    case 'transfer to sub': {
                        val.value = val.transaction_type_id;
                        val.class = 'transferToSub';
                        val.label = 'FundingManagement.type.internalTF_sub';
                        break;
                    }
                }
                return val;
            });
            const realOtherList = softList.map((val: any) => {
                val.value = val.transaction_type_id;
                val.class = 'other';
                val.label = val.type;
                return val;
            });

            list = list.concat(realHardList, realOtherList);

            return list;
        },
        getTransactionTypeFilterList() {
            const hardListIds = _.concat(['All'], _.map(this.getTransactionsTypeHard as any, 'type'));

            return _.filter(this.getTransactionTypeList, (item: any) => {
                return (item.status === 1 || hardListIds.includes(item.type)) && !item.deleted_at;
            });
        },

        getAllTransDisplayName: (state) => {
            const data = state.transactionsType;
            const language = state.locale;
            const result = {};
            data.forEach((val) => {
                const display_name = val.display_name;
                if (display_name?.length) {
                    const res = display_name.find((item) => {
                        return item.language === language;
                    });
                    result[val.type] = res?.value ? res.value : val.type;
                } else {
                    result[val.type] = val.type;
                }
            });
            return result;
        },
        getAllTransClass: (state) => {
            const result = {
                [DEPOSIT]: 'deposit',
                [WITHDRAWAL]: 'withdrawal',
                [INTERNAL_TF]: 'internaltf',
                [CREDIT]: 'credit',
                [POINTS]: 'points',
            };
            const data = state.transactionsType;
            data.forEach((val) => {
                if (!result[val.type]) {
                    result[val.type] = 'other';
                }
            });
            return result;
        },
        getAllCountryGetters: (state) => {
            const allCountry = state.allCountry;
            if (!allCountry) {
                return [];
            }
            const locale = state.locale;
            return allCountry.map((val) => {
                return {
                    value: val.name,
                    label: val[locale] || val[LANG_EN],
                    area_code: val.area_code,
                    is_banned: val.is_banned,
                    country_abb: val.country_abb,
                    country_abb_low: String(val.country_abb).toLowerCase(),
                    code: val.code,
                };
            });
        },
        getTsDisplayNames: (state) => {
            const result = {};
            state.allTradingServer.forEach((v) => {
                result[v.trading_server_id] = v.name;
                if (Array.isArray(v.ts_display_name)) {
                    v.ts_display_name.forEach((k) => {
                        result[`${v.trading_server_id}_${k.jurisdiction}`] = k.display_name;
                    });
                }
            });
            return result;
        },
        getAllSymbolNameGetters: (state) => {
            const result = {};
            //如果没配置语言，显示英文display name。英文也没配置，就显示Symbol Group Name
            state.allSymbolName.forEach((val) => {
                let currentLang = val.languages.find((item) => {
                    return item.language === state.locale;
                });
                if (!currentLang?.display_name) {
                    currentLang = val.languages.find((item) => {
                        return item.language === LANG_EN;
                    });
                }
                result[val.name] = currentLang ? currentLang.display_name : val.name;
            });
            return result;
        },
        getPointsCurrencyName: (state) => {
            const pointsCurrencyName = state.pointsCurrencyName;
            if (!state.pointsCurrencyName) {
                return '';
            }
            return pointsCurrencyName[state.locale] || pointsCurrencyName[LANG_EN];
        },
        getResponsiveValue: (state) => {
            return state.responsive;
        },
    },

    actions: {
        [types.SET_SCROLLBAR_REF](scrollbar: ScrollbarInstance | null) {
            if (scrollbar) {
                this.pageScrollRef = scrollbar;
            }
        },
        [types.SET_TOKEN](token) {
            this.memberToken = token;
        },
        [types.SET_LOGIN_USER](user) {
            this.loginUser = user;
        },
        [types.CHANGE_LANGUAGE](params) {
            this.locale = params;
        },
        [types.SET_LANGUAGES](params) {
            this.allLanguages = params;
        },
        [types.SET_SYSTEM_TYPE](params) {
            this.systemType = params;
            sessionStorage.setItem('System_type', params);
        },
        [types.CHANGE_COMPANY](payload) {
            this.companyList = payload;
        },
        [types.SET_MEMBER_INFO](params) {
            this.memberInfo = params;
            this.loginInfoId = params.login_id;
            localStorage.setItem('login_id', params.login_id);
        },
        [types.SET_TRADING_TOOLS](data) {
            this.tradingTools = data;
        },
        [types.SET_REQUEST_TRADING_TOOLS](data) {
            this.isRequestTradingTools = data;
        },
        [types.SET_UPLOAD_PROGRESS](params) {
            this.uploadProgress = params;
        },
        [types.SET_TRANSACTIONS_TYPE](params) {
            this.transactionsType = params;
        },
        [types.SET_ALL_COUNTRY](params) {
            this.allCountry = params;
        },
        [types.SET_ALL_COUNTRY_MAP](params) {
            this.allCountryMap = params;
        },
        [types.SET_TODO_LIST](params) {
            this.toDoList = params;
        },
        [types.SET_TITLE](params) {
            this.title = params;
        },
        [types.SET_ALL_TRADING_SERVER](params) {
            this.allTradingServer = params;
        },
        [types.SET_GLOBAL_TRADING_DIALOG](params) {
            this.globalTradingDialog = params;
        },
        [types.SET_MEMBER_PERMISSIONS](params) {
            this.memberPermissions[params.name] = params.data;
        },
        [types.SET_SYMBOL_NAME](params) {
            this.allSymbolName = params;
        },
        [types.SET_POINTS_CURRENCY_NAME](params) {
            this.pointsCurrencyName = params;
        },
        [types.SET_WALLET_INFO](params: WalletInfo) {
            this.walletInfo = params;
        },
        [types.SET_RESPONSIVE_VALUE](params) {
            this.responsive.sm = params.sm;
            this.responsive.lg = params.lg;
            this.responsive.md = params.md;
            this.responsive.xl = params.xl;
        },
        [types.SET_AUTH_LIST](params) {
            this.memberInfo.user_authenticator = params;
        },
        [types.SET_SCREEN_LOADING](params: boolean) {
            this.screenLoading = params;
        },
        [types.SET_PERMISSION_LOADING](params: boolean) {
            this.permissionLoading = params;
        },

        [types.GET_ALL_LANGUAGES]() {
            return new Promise((resolve: any) => {
                getLanguageList({ status: 1 }).then((res) => {
                    if (res) {
                        this[types.SET_LANGUAGES](res);
                        resolve(res);
                    } else {
                        resolve();
                    }
                });
            });
        },
        async [types.GET_COMPANY_INFO](payload: any) {
            const { route, hasJurisdiction, refresh } = payload;
            const state = this.$state;
            //It needs to check if the page is a 404 page. Otherwise, users of the blacklist IP can not be redirected to the 404 page.
            if (route.name !== 'NoAccess') {
                let res: any = {};
                if (refresh || _.isEmpty(state.companyList)) {
                    let params = getCompanyParams(1, state.locale as string);
                    if (route.meta?.requiresAuth && state.memberInfo.jurisdiction) {
                        params = getCompanyParams(2, state.locale as string, state.memberInfo.jurisdiction);
                    }

                    if (hasJurisdiction) {
                        params.jurisdiction = hasJurisdiction;
                        params.type = 2;
                    }

                    res = await getCompanyData(params);
                } else {
                    res = state.companyList;
                }

                if (!_.isEmpty(res)) {
                    if (res.favicon) changeFavicon(res.favicon);
                    this[types.CHANGE_COMPANY](res);
                }
            }
            setDocumentTitle(route);
        },
        async [types.GET_MEMBER_INFO](payload?: { refresh: boolean }) {
            const refresh = payload?.refresh;
            if (!refresh && !_.isEmpty(this.memberInfo)) {
                return this.memberInfo;
            }
            return new Promise((resolve: any) => {
                getMemberInfo().then((res) => {
                    if (res) {
                        this[types.SET_MEMBER_INFO](res);
                        this[types.GET_TRANSACTIONS]();
                        resolve(res);
                    } else {
                        resolve();
                    }
                });
            });
        },
        async [types.GET_TRADING_TOOLS_INFO]() {
            if (!_.isEmpty(this.tradingTools)) return;

            const response: any = await getTradingTools({ jurisdiction: this.memberInfo.jurisdiction ?? '' });
            if (response) {
                this[types.SET_TRADING_TOOLS](response);
                this[types.SET_REQUEST_TRADING_TOOLS](true);
            }
        },
        async [types.AUTH_SIGN_OUT]() {
            return Logout().then((response) => {
                if (response) {
                    removeLoginInfo();
                }
                return response;
            });
        },
        [types.GET_TRANSACTIONS]() {
            const { open_status } = this.memberInfo;

            return getTransactionsType({}, !!open_status).then((res: any) => {
                if (res) {
                    this[types.SET_TRANSACTIONS_TYPE](res.data || []);
                }
            });
        },
        async [types.GET_ALL_COUNTRY](): Promise<any[]> {
            if (this.allCountry.length) {
                return this.allCountry;
            }
            return new Promise((resolve) => {
                getCountryList().then((res: any) => {
                    if (res) {
                        // 优先排序country
                        const priorityCountry = JSON.parse(window.vueConfig.VUE_APP_DEFAULT_COUNTRY || '[]');
                        let list: any = [];
                        for (const key in res) {
                            if (priorityCountry.includes(key)) {
                                continue;
                            }
                            list.push(res[key]);
                        }
                        const p_list: any = [];
                        priorityCountry.forEach((val) => {
                            p_list.push(res[val]);
                        });
                        list = p_list.concat(_.sortBy(list, LANG_EN));
                        this[types.SET_ALL_COUNTRY](list);
                        this[types.SET_ALL_COUNTRY_MAP](res);
                        resolve(list);
                    } else {
                        resolve([]);
                    }
                });
            });
        },
        async [types.GET_TODO_LIST](payload: any) {
            const refresh = payload?.refresh;
            if (!refresh && !_.isEmpty(this.toDoList)) return;

            const response: any = await getTodoList({ language: this.locale });
            if (response) this[types.SET_TODO_LIST](response);
        },
        [types.GET_ALL_TRADING_SERVER]() {
            if (this.allTradingServer.length) {
                return this.allTradingServer;
            }
            return new Promise((resolve) => {
                /**
                     * /tradingServers/index --升级--> /tradingServers/list 的参数变化
                    type=1 -> filter=4&filter_value=jurisdiction
                    type=2 and member portal -> filter=3&filter_value=jurisdiction
                    type=2 and ib portal -> filter=0
                    type=2 and sale portal -> filter=0
                    type=2 and admin portal -> filter=0
                    type=2 and god portal -> filter=0
                     */
                getTradingServer({ filter: 0 }).then((res: any) => {
                    if (res) {
                        const temp = _.orderBy(res, 'trading_server_id');
                        this[types.SET_ALL_TRADING_SERVER](temp);
                        resolve(temp);
                    } else {
                        resolve([]);
                    }
                });
            });
        },
        async [types.GET_MEMBER_PERMISSIONS](payload: any) {
            const name = payload?.name;
            this[types.SET_PERMISSION_LOADING](true);
            const response = await getMemberPermissions({ permissions: payload.payload });
            if (response) {
                const result = { name: name, data: response };
                this[types.SET_MEMBER_PERMISSIONS](result);
            }
            this[types.SET_PERMISSION_LOADING](false);
        },
        async [types.GET_SYMBOL_NAME](payload: any) {
            const refresh = payload?.refresh;
            if (!refresh && this.allSymbolName.length) {
                return this.allSymbolName;
            }
            return new Promise((resolve) => {
                getAllSymbolDisplayName().then((res) => {
                    if (res) {
                        this[types.SET_SYMBOL_NAME](res);
                        resolve(res);
                    } else {
                        resolve([]);
                    }
                });
            });
        },
        [types.GET_POINTS_CURRENCY_DISPLAY_NAME]() {
            if (this.pointsCurrencyName) {
                return this.pointsCurrencyName;
            }
            return new Promise((resolve) => {
                getGlobalOption({ keys: ['points_display_name'] }).then((res: any) => {
                    if (res?.length) {
                        const result = res[0].value;
                        this[types.SET_POINTS_CURRENCY_NAME](result);
                        resolve(result);
                    } else {
                        resolve('');
                    }
                });
            });
        },
        async [types.GET_WALLET_INFO]() {
            const res = await walletIndex<WalletInfo>();
            if (res) {
                this[types.SET_WALLET_INFO](res as WalletInfo);
            }
        },
    },
});
