import { hasPermission } from '@/directives/permission';
import { fileFormat, formatTSDisplayNameByGetters, getIBUserTypes, hasStringValue, openNewWindow, tsType } from '@/utils';
import NP from '@/utils/NP';
import { NumFormat, PointFormat, PriceFormat } from '@/utils/format';
import dayjs from 'dayjs';
NP.enableBoundaryChecking(false); // 不显示数据越界警告

export default function install(app) {
    app.config.globalProperties.$fileFormat = fileFormat;
    app.config.globalProperties.$getIBUserTypes = getIBUserTypes;
    app.config.globalProperties.$hasStringValue = hasStringValue;
    app.config.globalProperties.$openNewWindow = openNewWindow;
    app.config.globalProperties.$tsType = tsType;
    app.config.globalProperties.$formatTSDisplayNameByGetters = formatTSDisplayNameByGetters;
    app.config.globalProperties.$NP = NP;
    app.config.globalProperties.$hasPermission = hasPermission;
    app.config.globalProperties.$NumFormat = NumFormat;
    app.config.globalProperties.$PriceFormat = PriceFormat;
    app.config.globalProperties.$PointFormat = PointFormat;
    app.config.globalProperties.$dayjs = dayjs;
}
