import { LANG_ZH } from '@/utils/constant';
import en from 'element-plus/dist/locale/en.mjs';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

export const enLocale = en;
export const zhLocale = zhCn;

export const getElementPlusLang = (lang: string): any => {
    return lang === LANG_ZH ? zhCn : en;
};
