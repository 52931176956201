import { $message, ElFormType } from '@/plugins/element';
import router from '@/router/index';
import { useAppStore } from '@/stores/app';
import { TradingAccountStatusEnum } from '@/types';
import { $t } from '@/utils/i18n';

/**
 * @param {*} { href, full, query = {} }
 * @href 必填 {string}  跳转的 href。可以输入相对路径或者绝对路径，注意的是，如果是相对路径，必须是/开头。否则会出错。
 * @full 可选 {boolean}  当 href 为绝对路径时，将其设置为 true
 * @query 可选 {object}	当 href 为相对路径时，可以选择的附带参数。
 * 举例：openNewWindow({ href: 'https://baidu.com', full: true })
 * 举例：openNewWindow({ href: '/accountDetail/7800722' })
 * 举例：openNewWindow({ href: '/accountDetail/7800722', {query: {trading_server_id: 1}} })
 * 该方法已在全局加入，可以通过 this.openNewWindow 使用
 */
export function openNewWindow({ href, full, query = {} }: any) {
    if (!full) {
        const newRoute = router.resolve({ path: href, query });
        href = newRoute.href;
    }
    const link = document.createElement('a');
    link.rel = 'noreferrer noopener';
    link.target = '_blank';
    link.href = href;
    link.click();
}

export function fileFormat({ key, params = {}, size = 'single' }: any) {
    const file_size = size === 'single' ? window.vueConfig.VUE_APP_SINGLE_UPLOAD_LIMIT : window.vueConfig.VUE_APP_MULTIPLE_UPLOAD_LIMIT;
    return $t(key, Object.assign({ size: file_size }, params));
}

export const checkFileSize = (size: number, limitType = 'single') => {
    const limitSize: any = limitType === 'multiple' ? window.vueConfig.VUE_APP_MULTIPLE_UPLOAD_LIMIT : window.vueConfig.VUE_APP_SINGLE_UPLOAD_LIMIT;
    if (limitSize > 0 && size / 1024 / 1024 > Number(limitSize)) {
        if (limitType === 'multiple') {
            $message.error($t('Tips.total_file_size_limit', { size: limitSize }));
        } else {
            $message.error($t('Tips.file_size_limit', { size: limitSize }));
        }
        return false;
    } else if (size === 0) {
        $message.error($t('Tips.upload_an_invalid_file'));
        return false;
    }
    return true;
};

const TRADING_ACCOUNT_STATUS_MAP = {
    [TradingAccountStatusEnum.EN]: { label: 'en', i18n: 'en', color: 'text-secondary' },
    [TradingAccountStatusEnum.RO]: { label: 'ro', i18n: 'ro', color: 'text-battleship' },
    [TradingAccountStatusEnum.DIS]: { label: 'dis', i18n: 'dis', color: 'text-red' },
    [TradingAccountStatusEnum.AC]: { label: 'ac', i18n: 'ac', color: 'text-battleship' },
} as const;

/**
 * Get the i18n string based on the trading account status code.
 * @param v      Trading account status code, see {@link src/types/index.ts} for details.
 * @param prefix Prefix for the i18n string.
 * @example
 * f(1);                    // 'en'
 * f(1, 'trading_account'); // $t('trading_account.en')
 */
export function getTradingAccountI18n(v: TradingAccountStatusEnum, prefix = '') {
    const plainI18n = TRADING_ACCOUNT_STATUS_MAP[v].i18n;

    return $t(prefix ? `${prefix}.${plainI18n}` : plainI18n);
}

/**
 * Get the color string based on the trading account status code.
 * @param v      Trading Account status code, see {@link src/types/index.ts} for details.
 * @param prefix Prefix for the color string.
 * @example
 * f(1); // 'text-secondary'
 */
export function getTradingAccountColor(v: TradingAccountStatusEnum) {
    return TRADING_ACCOUNT_STATUS_MAP[v].color;
}

/**
 * Get the readable trading account status string based on the trading account status code.
 * @param v Trading account status code, see {@link src/types/index.ts} for details.
 * @example
 * f(1); // 'en'
 * f(2); // 'ro'
 * f(3); // 'dis'
 * f(4); // 'ac'
 */
export function getTradingAccountStatus(v: TradingAccountStatusEnum) {
    return TRADING_ACCOUNT_STATUS_MAP[v].label;
}

export const getServerTypes = (isTransaction = false, isMemberOrder = false) => {
    const appStore = useAppStore();
    const mt5Enabled = appStore.memberInfo.mt5_enabled;
    const serverTypes = [{ label: 'MT4 Live', value: 'MT4 Live' }];
    if (mt5Enabled) {
        serverTypes.push({ label: 'MT5 Live', value: 'MT5 Live' });
    }
    if (isTransaction) {
        serverTypes.push({ label: 'CRM Internal TS', value: 'CRM Internal TS' });
    }
    // Member portal Order report Server Type filter 添加添加MT4 Demo Server 和 MT5 Demo Server
    if (isMemberOrder) {
        serverTypes.push({ label: 'MT4 Demo', value: 'MT4 Demo' });
        if (mt5Enabled) {
            serverTypes.push({ label: 'MT5 Demo', value: 'MT5 Demo' });
        }
    }
    return serverTypes;
};

export const getIBUserTypes = (level: number): string => {
    const types = [
        {
            level: null,
            levelName: $t('tree_customer'),
        },
        {
            level: 0,
            levelName: $t('tree_customer'),
        },
        {
            level: 1,
            levelName: 'IB',
        },
        {
            level: 2,
            levelName: 'MIB',
        },
        {
            level: 3,
            levelName: 'PIB',
        },
        {
            level: 4,
            levelName: 'SPIB',
        },
        {
            level: 5,
            levelName: 'VIB',
        },
        {
            level: 6,
            levelName: 'SVIB',
        },
        {
            level: 7,
            levelName: 'Super IB',
        },
        {
            level: 8,
            levelName: $t('tree_customer'),
        },
    ];
    const filterTypes = types.find((val) => val.level === level);
    return filterTypes ? filterTypes.levelName : types[0].levelName;
};

export const getSystemOS = () => {
    // 判断所处操作系统
    const sUserAgent = navigator.userAgent.toLowerCase();
    if (sUserAgent.indexOf('micromessenger') !== -1) {
        return 'WeChat';
    }
    if (navigator.platform === 'iPhone' || sUserAgent.indexOf('iphone') !== -1) {
        return 'iPhone';
    }
    const bIsAndroid = sUserAgent.indexOf('android') !== -1;
    if (bIsAndroid) {
        return 'Android';
    }
    const isWin = navigator.platform === 'Win32' || navigator.platform === 'Win64' || navigator.platform === 'wow64';
    if (isWin) return 'Windows';
    const isMac = navigator.platform === 'Mac68K' || navigator.platform === 'MacPPC' || navigator.platform === 'Macintosh' || navigator.platform === 'MacIntel';
    if (isMac) return 'Mac';
    const isUnix = navigator.platform === 'X11' && !isWin && !isMac;
    if (isUnix) return 'Unix';
    const isLinux = String(navigator.platform).indexOf('Linux') > -1;
    if (isLinux && !bIsAndroid) {
        return 'Linux';
    }
    return 'Other';
};

export function hasStringValue(str) {
    return typeof str === 'string' && str.trim() !== '';
}

interface GlobalType {
    [index: string]: string | string[];
}
const globalType: GlobalType = {
    wallet: 'CRM Internal TS',
    manual: 'Manual',
    hubx: 'HUBX TS',
    isMT: ['MT4 Live', 'MT4 Demo', 'MT5 Live', 'MT5 Demo'],
    isMTDemo: ['MT4 Demo', 'MT5 Demo'],
    isMTAndHubx: ['MT4 Live', 'MT4 Demo', 'MT5 Live', 'MT5 Demo', 'HUBX TS'],
    isWalletAndManual: ['CRM Internal TS', 'Manual'],
    isWalletAndLive: ['CRM Internal TS', 'MT4 Live', 'MT5 Live'],
    isLive: ['MT4 Live', 'MT5 Live'],
};

export function tsType(typeColumn: string, typeName: string) {
    const target = globalType[typeName];
    return typeof target === 'string' ? typeColumn === target : target.includes(typeColumn);
}

// 获取交易类型的显示名称
/**
 *
 * @param {String} transactionType 交易类型
 * @param {string} [type='name'] 选择要获取的内容，name: 交易类型的名称，class： 交易类型的 class
 */
export function transType(transactionType: string, type = 'name') {
    const appStore = useAppStore();
    const res = type === 'name' ? appStore.getAllTransDisplayName : appStore.getAllTransClass;
    if (!res) {
        return '';
    }

    if (type === 'name') {
        return res[transactionType] || transactionType;
    } else {
        return res[transactionType] || 'other';
    }
}

/**
 * 此方法和 formatTSDisplayName 的区别: formatTSDisplayName 是从原始的 listServer 列表中取数据,
 * 大部分情况是从所有的 listServer 中取数据,此时使用 formatTSDisplayNameByGetters,
 * 少部分是有过滤条件的 listServer,此时使用 formatTSDisplayName
 * @param {*} serverId
 * @param {*} jurisdiction
 * @returns
 */
export function formatTSDisplayNameByGetters(serverId, jurisdiction) {
    const appStore = useAppStore();
    return appStore.getTsDisplayNames[`${serverId}_${jurisdiction}`] || appStore.getTsDisplayNames[serverId];
}

/**
 * 此方法用于更新确认密码的校验状态, 即关联起password、confirm password的校验交互
 * 适用于使用ThePassword.vue的组件
 * @param {*} ruleFormRef 校验的form
 * @param {*} field confirmPassword key name
 * @param registerShowConfirmPwd :number 注册页面没有登录信息 因此这里传注册配置中是否需要确认密码的参数
 */
type FormInstance = InstanceType<typeof ElFormType>;
export const handleCheckConfirmPassword = (ruleFormRef: FormInstance | null, field: string, registerShowConfirmPwd = 0) => {
    const appStore = useAppStore();
    if (appStore.memberInfo.confirm_password || registerShowConfirmPwd) {
        ruleFormRef?.validateField(field, () => null);
    }
};
