// vite-plugin-generate-error-codelist.js
import fs from 'fs';
import path from 'path';
import en from '../i18n/en';
import zh from '../i18n/zh';
import { codeList } from '../utils/errorCodeList';

// 将对象数组转换为 Markdown 表格格式
function convertToMarkdown(dataArray) {
    if (!Array.isArray(dataArray) || dataArray.length === 0) {
        return 'No data available to convert.';
    }

    // 获取对象的键作为表头
    const headers = Object.keys(dataArray[0]);

    // 创建 Markdown 表头
    const headerLine = `| ${headers.join(' | ')} |`;
    const separatorLine = `| ${headers.map(() => '---').join(' | ')} |`;

    // 创建 Markdown 数据行
    const dataLines = dataArray.map((item) => {
        const values = headers.map((header) => item[header]);
        return `| ${values.join(' | ')} |`;
    });

    // 组合表头、分隔线和数据行
    return [headerLine, separatorLine, ...dataLines].join('\n');
}

function generateErrorCodelistPlugin() {
    function fillCodeMessages() {
        const sortCodeList = codeList.sort((a, b) => a.code - b.code);

        return sortCodeList.map((item) => {
            const keys = item.message.split('.');
            let value1 = zh;
            let value2 = en;

            // 遍历 keys 数组，通过链式访问获取值
            for (const key of keys) {
                if (value1[key] !== undefined) {
                    value1 = value1[key];
                }

                if (value2[key] !== undefined) {
                    value2 = value2[key];
                }
            }

            // 返回一个新的对象，包含原有的 code 和从 obj 中获取的值
            return {
                code: item.code,
                zh: value1,
                en: value2,
            };
        });
    }

    function translateErrorCode() {
        const codeListWithTranslation = fillCodeMessages();
        const outputPath = path.join(__dirname, '../../public/codeListWithTranslation.md');
        const markdownContent = convertToMarkdown(codeListWithTranslation);

        fs.writeFile(outputPath, markdownContent, 'utf8', (err) => {
            if (err) {
                /* eslint-disable no-console */
                console.error('Error writing to file:', err);
            } else {
                /* eslint-disable no-console */
                console.log('ErrorCodeList successfully written into markdown file.');
            }
        });
    }

    return {
        name: 'generate-error-codelist',
        async buildStart() {
            try {
                translateErrorCode();
            } catch (error) {
                /* eslint-disable no-console */
                console.error('Error generating errorCodelist.json:', error);
            }
        },
    };
}

module.exports = generateErrorCodelistPlugin;
