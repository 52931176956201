import { createApp } from 'vue';

const moduleList = {};

const modules = import.meta.glob('./*.ts', { eager: true });

for (const path in modules) {
    moduleList[path] = modules[path];
}

export function loadAllPlugins(app: ReturnType<typeof createApp>) {
    Object.keys(moduleList).forEach((key) => {
        if (typeof moduleList[key].default === 'function') {
            if (key !== './index.ts') moduleList[key].default(app);
        }
    });
}
