<template>
    <el-config-provider :locale="localLanguage">
        <div class="app-box">
            <router-view />
        </div>
    </el-config-provider>
</template>

<script lang="ts" setup>
import langShareFunction from '@/composables/lang';
import useMatchMedia from '@/composables/useMatchMedia';
import { getElementPlusLang } from '@/i18n/element';
import { GET_ALL_LANGUAGES } from '@/stores/app/actionTypes';
import { computed, nextTick } from 'vue';
import { useAppStore } from './stores/app';

export interface ElementStyle {
    style: any;
}

const appStore = useAppStore();

const locale = computed(() => appStore.locale);
const localLanguage = computed((): string => getElementPlusLang(locale.value));

const { initLoadLang } = langShareFunction();

async function init() {
    // 绑定 resize
    useMatchMedia();

    await appStore[GET_ALL_LANGUAGES]();
    await initLoadLang();

    // 页面初始化的 loading
    const el = document.querySelector('.screen-loading-wrapper');
    await nextTick();
    (el as Element & ElementStyle).style.display = 'none';
}

init();
</script>

<style lang="scss">
.layout-container {
    padding-left: 50px;
    padding-right: 50px;
}

.pc-only {
    display: block !important;
}

.mobile-only {
    display: none !important;
}

@include screen-large {
    .layout-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@include screen-tablet {
    .pc-only {
        display: none !important;
    }

    .mobile-only {
        display: block !important;
    }
}

@include screen-tablet-above {
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        transition: background-color 0.3s;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(144, 147, 153, 0.3);
    }
}
</style>
