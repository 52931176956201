import router from '@/router';
import { useAppStore } from '@/stores/app';
import { GET_COMPANY_INFO, GET_MEMBER_INFO, GET_TODO_LIST, SET_SCREEN_LOADING, SET_SYSTEM_TYPE } from '@/stores/app/actionTypes';
import { useRegistrationStore } from '@/stores/registration';
import { isTokenEnable, removeLoginInfo, setLoginUserInfo } from '@/utils/cookie';
import * as _ from 'lodash-es';
import { requestStatistics } from '../../template';
const openLoading = () => {
    const appStore = useAppStore();
    appStore[SET_SCREEN_LOADING](true);
};
const closeLoading = () => {
    const appStore = useAppStore();
    appStore[SET_SCREEN_LOADING](false);
};
const routerTransition = (to, from) => {
    const fromPath = from.path;
    // registration page click menu without animation
    to.meta.transition = fromPath.includes('/member-register') ? '' : 'fade-transform';
};
const checkRegisterIsDone = () => {
    const registrationStore = useRegistrationStore();
    const userSequence = registrationStore.userSequence;
    if (userSequence === '' || userSequence === 'SP') {
        return true;
    }
    return false;
};
export default {
    authorize() {
        router.beforeEach(async (to, from, next) => {
            const appStore = useAppStore();
            openLoading();
            // need to refresh the company data after logging in
            let needRefreshCompany = false;
            const query = to.query;
            // Login user from the admin portal.
            if (query.access_token && query.expires_in && query.refresh_token && query.loginUser) {
                setLoginUserInfo({ res: query, loginUser: query.loginUser.toString(), continuedLogin: false });
                appStore[SET_SYSTEM_TYPE]('Member_Portal');
                return next('/');
            }
            if (to.matched.some((route) => route.meta && route.meta.requiresAuth)) {
                const token = await isTokenEnable();
                if (!token) {
                    const nonRedirect = ['/', '/login', '/member-register', '/member-register-demo', '/retrieve-password', '/page404', '/register-error', '/mock-deposit', '/no-access'];
                    removeLoginInfo();
                    if (nonRedirect.includes(to.fullPath)) return next({ path: '/login' });
                    else {
                        return next({ path: `/login`, query: { redirect: to.fullPath } });
                    }
                }
                if (_.isEmpty(appStore.memberInfo)) {
                    needRefreshCompany = true;
                    await appStore[GET_MEMBER_INFO]();
                } else if (to.path.includes('rewards')) {
                    // 实时更新 points 和 coupons 值，rewards rewards 子路由都需要重新调用一次
                    //Todo: 优化member info api，将points拆开，具体看wallet.vue
                    appStore[GET_MEMBER_INFO]({
                        refresh: true,
                    });
                }
                await appStore[GET_TODO_LIST]({
                    refresh: to.name === 'dashboard',
                });
                if (appStore.memberInfo) {
                    const fundingPage = !appStore.memberInfo.funding_management && String(to.name)?.includes('member-funding');
                    const tradingToolsPage = !appStore.memberInfo.trading_tools && to.name === 'trading-tools';
                    const rewardsPage = !appStore.memberInfo.points_store && String(to.name)?.includes('rewards');
                    const flatReportPage = !appStore.memberInfo.full_access && to.name === 'flat-reports';
                    if (fundingPage || tradingToolsPage || rewardsPage || flatReportPage) return next({ path: '/page404' });
                }
                if (to.meta.category === 'register' && checkRegisterIsDone()) {
                    // Prevent route change if navigating from the dashboard to the same page
                    if (from.name === 'dashboard') {
                        closeLoading();
                        return next(false);
                    }
                    return next({ path: '/' });
                }
            }
            // email verify 单独处理。具体看src/views/EmailVerification/index.vue
            if (to.name !== 'email-verify') {
                await appStore[GET_COMPANY_INFO]({ route: to, refresh: needRefreshCompany || to.name === 'login' });
            }
            return next();
        });
        router.afterEach((to, from) => {
            closeLoading();
            requestStatistics();
            routerTransition(to, from);
        });
        router.onError((err) => {
            console.error('Routing error: ', err);
        });
    },
};
