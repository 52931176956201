import { getTradeAccountTree } from '@/api/request';
import * as _ from 'lodash-es';
import { defineStore } from 'pinia';
import * as actionTypes from './actionTypes';

export type State = {
    reportType: string;
    rebateTree: Array<Record<string, any>>;
    rebateAccountDetails: Record<string, any>;
    rebateReportFilter: Record<string, any>;
    rebateReportMenuList: Array<Record<string, any>>;
    orderHistoricalTree: Array<Record<string, any>>;
    orderHistoricalAccountDetails: Record<string, any>;
    orderHistoricalReportFilter: Record<string, any>;
    orderHistoricalReportMenuList: Array<Record<string, any>>;
    orderRecentTree: Array<Record<string, any>>;
    orderRecentAccountDetails: Record<string, any>;
    orderRecentReportFilter: Record<string, any>;
    orderRecentReportMenuList: Array<Record<string, any>>;
    rebateFlatTree: Array<Record<string, any>>;
    rebateFlatAccountDetails: Record<string, any>;
    rebateFlatReportFilter: Record<string, any>;
    rebateFlatReportMenuList: Array<Record<string, any>>;
};

export const useIbReportStore = defineStore('ibReport', {
    state: (): State => ({
        reportType: '',
        rebateTree: [],
        rebateAccountDetails: {},
        rebateReportFilter: {},
        rebateReportMenuList: [
            {
                menu_id: 'tree',
                isActive: true,
            },
            {
                menu_id: 'request',
                isActive: false,
            },
        ],
        orderHistoricalTree: [],
        orderHistoricalAccountDetails: {},
        orderHistoricalReportFilter: {},
        orderHistoricalReportMenuList: [
            {
                menu_id: 'tree',
                isActive: true,
            },
            {
                menu_id: 'request',
                isActive: false,
            },
        ],
        orderRecentTree: [],
        orderRecentAccountDetails: {},
        orderRecentReportFilter: {},
        orderRecentReportMenuList: [
            {
                menu_id: 'tree',
                isActive: true,
            },
        ],
        rebateFlatTree: [],
        rebateFlatAccountDetails: {},
        rebateFlatReportFilter: {},
        rebateFlatReportMenuList: [
            {
                menu_id: 'request',
                isActive: true,
            },
        ],
    }),

    getters: {
        getReportList(state): any[] {
            if (!state.reportType) return [];
            return state[`${state.reportType}ReportMenuList`];
        },
        getReportActiveMenu(state): Record<string, any> {
            if (!state.reportType) return {};
            return state[`${state.reportType}ReportMenuList`].find((val) => val.isActive);
        },
        getReportFilter(state): Record<string, any> {
            if (!state.reportType) return {};
            const activeMenu = this.getReportActiveMenu;
            return state[`${state.reportType}ReportFilter`][activeMenu.menu_id] ?? {};
        },
    },

    actions: {
        [actionTypes.CHANGE_REPORT_TYPE](value) {
            if (value) this.reportType = value;
        },
        [actionTypes.SET_REPORT_TREE](value) {
            if (value) this[`${this.reportType}Tree`] = value;
        },
        [actionTypes.SET_REPORT_ACCOUNT](value) {
            const { external_id, search_type } = value;
            this[`${this.reportType}AccountDetails`] = { external_id, search_type };
        },
        [actionTypes.UPDATE_REPORT_FILTER]({ key, value }) {
            this[`${this.reportType}ReportFilter`][key] = value;
        },
        [actionTypes.ADD_REPORT_LIST](value) {
            // 存在不同服务器external_id可能一样的情况，所以由external_id和trading_serve_id拼接的menu_id为标识
            const { menu_id } = value;
            let isExist = false;
            let newList = _.cloneDeep(this[`${this.reportType}ReportMenuList`]);
            newList = newList.map((val) => {
                if (val.menu_id === menu_id) {
                    isExist = true;
                    // 如果val存在，则返回最新传进来的值
                    return value;
                } else {
                    val.isActive = false;
                    return val;
                }
            });
            if (!isExist) {
                newList.push(value);
            }
            this[`${this.reportType}ReportMenuList`] = newList;
        },
        [actionTypes.REMOVE_REPORT_LIST](value) {
            const { menu_id } = value;
            this[`${this.reportType}ReportMenuList`] = _.filter(this[`${this.reportType}ReportMenuList`], (item) => item.menu_id !== menu_id);
        },
        [actionTypes.RESET_REPORT]({ type, resetDetail, hasRequest }: { type?: string; resetDetail?: any; hasRequest?: boolean }) {
            if (resetDetail) this[`${type}Tree`] = [];
            if (resetDetail) this[`${type}AccountDetails`] = {};

            this[`${type}ReportFilter`] = {};

            this[`${type}ReportMenuList`] = hasRequest
                ? [
                      {
                          menu_id: 'tree',
                          isActive: true,
                      },
                      {
                          menu_id: 'request',
                          isActive: false,
                      },
                  ]
                : [
                      {
                          menu_id: 'tree',
                          isActive: true,
                      },
                  ];
        },
        [actionTypes.REMOVE_REPORT_FILTER](value) {
            if (this[`${this.reportType}ReportFilter`][value]) {
                delete this[`${this.reportType}ReportFilter`][value];
            }
        },
        async [actionTypes.GET_TRADE_ACCOUNT_TREE](payload: any): Promise<void> {
            try {
                const response = await getTradeAccountTree<any>({ query: payload.query, external_id: payload.external_id });
                if (!response) return;
                this[actionTypes.SET_REPORT_ACCOUNT]({
                    external_id: payload.external_id,
                    search_type: payload.search_type,
                });
                this[actionTypes.SET_REPORT_TREE](response);
                this[actionTypes.ADD_REPORT_LIST]({ menu_id: 'tree', isActive: true });
            } catch (err) {
                console.error(err);
            }
        },
        [actionTypes.RESET_ALL_REPORT](): void {
            this[actionTypes.RESET_REPORT]({ type: 'rebate', resetDetail: true, hasRequest: true });
            this[actionTypes.RESET_REPORT]({ type: 'orderHistorical', resetDetail: true, hasRequest: true });
            this[actionTypes.RESET_REPORT]({ type: 'orderRecent', resetDetail: true, hasRequest: false });
        },
    },
});
