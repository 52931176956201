import i18n from '@/i18n/i18n';
import { LANG_EN, LANG_ZH } from './constant';

/**
 * @param path  'member_upgrade.template_name'
 * @param parameters { name: 'test' } { key: value } Fill the value in the translation replacing {key}, multiple key-value pairs allowed
 * @param lang { locale: 'en' } Could have other settings when needed, currently get the required(en) resource in other language env
 * @returns '*Click to download test template'
 */
export const $t = (path: string, parameters = {}, lang = {}): string => {
    // @ts-ignore
    return i18n.global.t(path, parameters, lang);
};

// https://vue-i18n.intlify.dev/guide/advanced/composition.html#pluralization
export const $tc = (path: string, number, parameters = {}) => {
    // @ts-ignore
    return i18n.global.t(path, parameters, number);
};

export const changeLocale = (lang: string): void => {
    i18n.global.locale.value = lang;
};

export const setLocaleMessage = (lang: string, temp: any): void => {
    i18n.global.setLocaleMessage(lang, temp);
};

export const defaultLang = [LANG_EN, LANG_ZH];
