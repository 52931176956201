import { Objects } from 'src/model/common';

/**
 * 此文件为api内Accept字段的配置根据目前后台提供三种如以后需要新增需新增ACCEPT字段
 * 具体使用在api配置时传入accpet字段，目前默认为prs-v2
 */
const ACCEPT: Objects = {
    'prs-v1': 'application/prs.CRM-Back-End.v1+json',
    'prs-v2': 'application/prs.CRM-Back-End.v2+json',
    'prs-v3': 'application/prs.CRM-Back-End.v3+json',
};

export default ACCEPT;
