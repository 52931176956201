import { CaptchaTypeResponse, ExpireTime, ReferralCodeParam, ValidateReferralCode } from '@/model/api/registration/type';
import { TradingPlatformInterface } from '@/model/api/request';
import { Objects, ResponseError } from '@/model/common';
import {
    GetMemberInBlackRes,
    PostChangeEmailReq,
    PostChangeEmailRes,
    PostCheckEmailValidityReq,
    PostCheckEmailValidityRes,
    PostEmailVerifyCodeReq,
    PostEmailVerifyCodeRes,
    PostVerifyOtpOrEmailCodeReq,
    PostVerifyOtpOrEmailCodeRes,
} from '@/model/views/member/changeEmail/interface';
import { GetDemoTAInfoReq, GetDemoTAInfoRes } from '@/model/views/member/registration/accountConfiguration';
import { reqJsonData } from './axios';

// 接口文档: https://github.com/found-solutions/CRM-Back-End/tree/develop

/**
 * User Login and Permissions
 */

export function userLogin(params?: Objects) {
    return reqJsonData<ResponseError | Objects>({ url: '/authorizations/member', params, method: 'post' });
}
// 一个 24 小时有效的登陆 api
export function redirectSign<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'support/officialRedirect', params, method: 'get' });
}
// 切换登录
export function loginAsByUserId<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/authorizations/login', params, method: 'get' });
}
export function Logout<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'authorizations/currentMember', params, method: 'delete' });
}
//新增权限控制
export function getMemberPermissions<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/getUerPermissions', params, method: 'post' });
}
// refresh token
export function refreshToken<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/authorizations/currentMember', params, method: 'put' });
}
export function getLanguageList(params?: Objects) {
    return reqJsonData<CommonApi.Language[]>({ url: '/languages/index', params, method: 'get' });
}
export function getGlobalOption<T>(params?: Objects, is_ib?: boolean) {
    const url = is_ib ? '/ibp/options/show' : '/front/options/show';
    return reqJsonData<T>({ url, params, method: 'get' });
}
export function getCommonGlobalOption<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/options/show', params, method: 'get' });
}

export function getMemberInfo(params?: Objects) {
    return reqJsonData<any>({ url: '/front/members/infoMember', params, method: 'get' });
}
export function apiGetMemberInBlack() {
    return reqJsonData<GetMemberInBlackRes>({ url: '/front/members/checkInBlackList', method: 'get' });
}

export function apiPostEmailVerifyCode(params?: PostEmailVerifyCodeReq) {
    return reqJsonData<PostEmailVerifyCodeRes>({ url: '/front/members/sendVerificationCode', params, method: 'post' });
}

export function apiPostVerifyOtpOrEmailCode(params?: PostVerifyOtpOrEmailCodeReq) {
    return reqJsonData<PostVerifyOtpOrEmailCodeRes | ResponseError>({ url: '/front/members/verifyCode', params, method: 'post' });
}

export function apiPostChangeEmail(params?: PostChangeEmailReq) {
    return reqJsonData<PostChangeEmailRes | ResponseError>({ url: '/front/members/changeEmail', params, method: 'post' });
}
export function apiPostCheckEmailValidity(params?: PostCheckEmailValidityReq) {
    return reqJsonData<PostCheckEmailValidityRes | ResponseError>({ url: '/front/members/checkEmailValidity', params, method: 'post' });
}

/**
 * User Register
 */

export function getJurisdictionChangeNotice<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/getJurisdictionChangeNotice', params, method: 'get', accept: 'prs-v3' });
}
export function registerOne<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/registerOne', params, method: 'post', checkValue: true });
}
export function registerTwo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/registerTwo', params, method: 'post', checkValue: true });
}
export function registerThree<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/registerThree', params, method: 'post', checkValue: true });
}
export function registerFour<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/registerFour', params, method: 'post', checkValue: true });
}
export function stepOneInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/stepOneInfo', params, method: 'get', accept: 'prs-v3' });
}
export function stepTwoInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/stepTwoInfo', params, method: 'get', accept: 'prs-v3' });
}

export function stepOne<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/stepOne', params, method: 'post', checkValue: true, isFormData: true, accept: 'prs-v3' });
}
export function stepTwo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/stepTwo', params, method: 'post', checkValue: true, accept: 'prs-v3' });
}
export function uploadProof<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/uploadProof', params, method: 'post', checkValue: true, isFormData: true, accept: 'prs-v3' });
}
export function checkStatus<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/checkStatus', params, method: 'get', accept: 'prs-v3' });
}
export function getCountryList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/countries', params, method: 'get' });
}
export const getAddressListApi = <T>(params) => {
    return reqJsonData<T>({ url: '/addressList', params, method: 'get', accept: 'prs-v3' });
};
export function getCaptcha<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/captchas/captcha', params, method: 'get' });
}
export function getEnabledCharacters<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/allowedCharacters/memberindex', params, method: 'get' });
}
export function getRegisterInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/registerMember', params, method: 'get', accept: 'prs-v3' });
}
export function getRegisterJurisdictionInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/getJurisdictionAssignRuleInfo', params, method: 'get', accept: 'prs-v3' });
}
export function createdRegisterInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/registerMember', params, method: 'post', accept: 'prs-v3', checkValue: true });
}
export function sendRegisterEmail<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/sendLink', params, method: 'post', checkValue: true, accept: 'prs-v3' });
}
export function rejoinRegister<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/rejoinRegister', params, method: 'post', checkValue: true, accept: 'prs-v3' });
}

// demo
export function createdDemoTradingAccount<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/demoTradingAccounts/create', params, method: 'post', checkValue: true });
}
export function apiGetDemoTaInfo(params?: GetDemoTAInfoReq) {
    return reqJsonData<GetDemoTAInfoRes>({ url: '/demoTAInfo', params, method: 'get', accept: 'prs-v3' });
}

// detailAgreement
export function getDetailAgreement<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/stepThreeInfo', params, method: 'get', accept: 'prs-v3' });
}
export function createLiveTa<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/stepThree', params, method: 'post', accept: 'prs-v3', checkValue: true });
}

/**
 * 切换语言
 */
export function editAdminLanguage<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/languages/editCurrent', params, method: 'put' });
}
export function getLanguage<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/languages/index', params, method: 'get' });
}
export function getJurisdictionList<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'jurisdictions/index', params, method: 'get' });
}

/**
 * 找回密码
 */

export function getRetrievePasswordCaptcha(params?: { action: number }) {
    return reqJsonData<CaptchaTypeResponse>({ url: '/captchas/captcha', params, method: 'get' });
}
export function retrievePasswordSendEmail<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/authorizations/email', params, method: 'post' });
}
export function apiRetrievePassword<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/authorizations/retrieve', params, method: 'post' });
}

/**
 * 获取 tradingtools 的子菜单
 */

export function getTradingTools<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/tradingToolSubTabs/list', params, method: 'get' });
}

/**
 * 获取 tradingTools popup 列表
 */

export function getTradingToolsPopupList<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/download/popupList', params, method: 'get' });
}
export function changeTradingToolsPopupStatus<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/tradingToolSubTabs/changePopupStatus', params, method: 'put' });
}

/**
 * 同意协议
 */

export function agreeTradingToolsPopup<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/tradingToolSubTabs/agree', params, method: 'post' });
}

/**
 * 获取具体的 iframe url
 */

export function getIframeUrlById<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/tradingToolSubTabs/iframe', params, method: 'get' });
}

/**
 * edit profile
 */

export function editProfile<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/members/editProfile', params, method: 'post' });
}

/**
 * IB portal
 */

/**
 * Base Api:
 * Get salesCode list (Global Permission)
 */

export function getSalesCodeList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/ibp/saleCodes/index', params, method: 'get' });
}

/**
 * Base Api:
 * Show trading account
 */

export function getTradeAccountDetail<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/ibp/member/tradingAccounts/show', params, method: 'get' });
}

/**
 * Base Api:
 * Show trading account on popup
 */

export function getTradeAccountDetailOnPop<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/ibp/tradingAccounts/pop', params, method: 'get' });
}

/**
 * get system all tradingSever
 */

export function getTradingServer<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingServers/list', params, method: 'get' });
}

/**
 * Base Api:
 * Get funding method list
 */

export function getPaymentMethodList<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/fundingMethods/index', params, method: 'get' });
}

/**
 * Base Api:
 * Edit trading account
 */

export function updateTradeAccount<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/tradingAccounts/edit', params, method: 'put' });
}

/**
 * Base Api:
 * Get trading server group list
 */

export function getTradingServerGroupList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/ibp/tradingServerGroups/index', params, method: 'get' });
}

/**
 * transactionsType
 */

export function getTransactionsType<T>(params?: Objects, is_ib?: boolean) {
    const url = !is_ib ? '/transactions/indexType' : '/ibp/transactions/indexType';
    return reqJsonData<T>({ url, params, method: 'get' });
}
export function getSearchTransactionList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/ibp/transactions/index', params, method: 'get', checkValue: true });
}
export function getSearchOrderReportList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/ibp/orders/index', params, method: 'get', checkValue: true });
}
export function showTransactionDetail<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/ibp/transactions/show', params, method: 'get', accept: 'prs-v3' });
}
export function getCompanyData<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/companies/show', params, method: 'get' });
}
/* register verifyCaptcha */
export function verifyCaptcha<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/verifyCaptcha', params, method: 'post' });
}
export function getTradeAccountTree<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/tree', params, method: 'get' });
}
/* 下载页面 api */
export function apiGetDownloadInfos<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/downloads/list', params, method: 'get' });
}
export function getUploadProof<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/getProof', params, method: 'get', accept: 'prs-v3' });
}
// 获取积分商城 url
export function getPointStore<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/pointStore/show', params, method: 'get' });
}
// 获取具体语言翻译
export function getLanguageInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'multiLanguage/detail', params, method: 'get' });
}
/* 删除 proof */
export function deleteProof<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/deleteProof', params, method: 'delete', accept: 'prs-v3' });
}
/* 获取注册的公共信息 */
export function getCommonInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/regoCommonInfo', params, method: 'get', accept: 'prs-v3' });
}
/* 验证 node code */
export function validateNodeCode<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/validateNodeCode', params, method: 'get', accept: 'prs-v3' });
}

/* 验证推荐码 */
export function validateReferralCode(params?: ReferralCodeParam) {
    return reqJsonData<ValidateReferralCode>({ url: '/validateReferralCode', params, method: 'get', accept: 'prs-v3' });
}
/* 获取证明文件验证详细 */
export function getVerificationInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/getVerificationInfo', params, method: 'get', accept: 'prs-v3' });
}
export function getDomain<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/memberDomain', params, method: 'get' });
}
// campaign link / sales link 获取有效期，并且提交设备信息
export function getExpireTimeAndStatistics(params?: Objects) {
    return reqJsonData<ExpireTime>({ url: 'visiting/getExpireTime', params, method: 'get' });
}
export function getIBProfile<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/member/profile', params, method: 'get' });
}
// 获取 Rebate Settings
export function getRebateList<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/nodes/index', params, method: 'get' });
}
// 获取通知
export function getNotification<T>(params?: Objects, isIb?: boolean) {
    const url = isIb ? 'ibp/popup/list' : 'front/popup/list';
    return reqJsonData<T>({ url, params, method: 'get' });
}
export const changeNotificationStatus = <T>(params?: Objects, isIb?: boolean) => {
    const url = isIb ? 'ibp/popup/changeStatus' : 'front/popup/changeStatus';
    return reqJsonData<T>({ url, params, method: 'put' });
};
// IB Referral Links Api:
export function getReferralLinkList<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/index', params, method: 'get' });
}
export function showReferralLink<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/show', params, method: 'get' });
}
export function getReferralTAList<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/tradingAccount', params, method: 'get' });
}
export function exportReferral<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/export', params, method: 'get' });
}
export function createReferralLink<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/create', params, method: 'post' });
}
export function editReferralLink<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/edit', params, method: 'put' });
}
export function apiGetReferralLinkInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/getInfo', params, method: 'get' });
}
export function apiGetReferralLinkDomains<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/domains', params, method: 'get' });
}
// 远程搜索 IB
export function ReferralLinkSearchIB<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'ibp/referralLink/search', params, method: 'get' });
}
// 获取牌照相关信息
export function getJurisdictionsRelevantInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/jurisdictions/relevantInfo', params, method: 'get' });
}
export function getUpgradeInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'memberAccountType/baseInfo', params, method: 'get' });
}
// Upgrade Process
export function getUpgradeAccountType<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/memberUpgrade/pageInfo', params, method: 'get' });
}
export function getUpgradeAccountTypeDetail<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/memberUpgrade/clientUpgradeDetail', params, method: 'get' });
}
// 下载文件
export function getFile<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'member/download', params, responseType: 'blob', method: 'get' });
}
export function apiGetAccountTypes<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'accountTypes/index', params, method: 'get' });
}
export function apiGetLeverages<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'leverages/index', params, method: 'get' });
}
// TA 下的所有 account type
export function getTaTypeList<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/tradingAccounts/taTypeList', params, method: 'get' });
}
// get all symbol group name
export function getAllSymbolDisplayName<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'symbolGroups/multiLanguage', params, method: 'get' });
}
// send verification email
export function sendVerificationEmail<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'sendVerificationEmail', params, method: 'post', accept: 'prs-v3' });
}
export function verifyEmail<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'verifyEmail', params, method: 'post' });
}

// get all trading platform list
export const apiGetTradingPlatformList = (params) => {
    return reqJsonData<TradingPlatformInterface[]>({ url: '/tradingPlatform/list', params, method: 'get' });
};

// get tracking info
export const apiGetTrackingParams = (params) => {
    return reqJsonData({ url: 'front/member/getTrackingParams', params, method: 'get' });
};
