import { useAppStore } from '@/stores/app';
import { $t } from '@/utils/i18n';
import http from 'http';
import * as _ from 'lodash-es';
import PapaParse from 'papaparse';

/**
 *
 *
 * @param {Array[Object]|Array[Array]} data 需要导出的内容，可以是对象数组，或者二维数组
 * @param {String} name 导出的文件名
 * @param {Array[String]} omitKeys 要忽略的属性，['xxx']
 * @param {Array[String]} [checkKeys=true] 当changeNameObj存在时，是否需要去除原有的key
 * @param {Object} [changeNameObj={}] 要修改名称的字段。key为原有的属性名，value为新的名称. {oldName: newName}
 * @param {Array[String]} [sortKeys=[]] 导出文件的顺序，值为真实渲染的表头。如果不传递，则会按照Object.keys的顺序导出
 * @returns
 */
// example: exportCsv({data: exportData, name: 'test'});
export const exportCsv = (payload: any) => {
    let { data } = payload;
    const { name, omitKeys, changeNameObj = {}, checkKeys = true, sortKeys = [] } = payload;

    if (!data.length) {
        throw new Error('empty_data_export');
    }
    if (_.isArray(omitKeys) && omitKeys.length) {
        data = data.map((val: any) => {
            return _.omit(val, omitKeys);
        });
    }
    if (!_.isEmpty(changeNameObj)) {
        if (checkKeys) {
            const keys = Object.keys(changeNameObj);
            data = data.map((val: any) => {
                const item: any = {};
                // 当changeNameObj对象不存在的key将不会再出现在生成在csv文件中
                keys.forEach((v) => {
                    if (Object.prototype.hasOwnProperty.call(val, v)) {
                        item[changeNameObj[v]] = val[v];
                    }
                });
                return item;
            });
        } else {
            data = data.map((val: any) => {
                return _.mapKeys(val, (v, k) => {
                    return changeNameObj[k];
                });
            });
        }
    }
    if (!_.isArray(data[0])) {
        const notSortKeys: string[] = _.difference(Object.keys(data[0]), sortKeys);
        const newKeys: string[] = _.concat(sortKeys, notSortKeys);
        const result: string[][] = [];
        result.push(newKeys);
        data.forEach((val: any) => {
            const tmp: any[] = [];
            newKeys.forEach((key, i) => {
                tmp[i] = val[key];
            });
            result.push(tmp);
        });
        data = result;
    }
    let csv = PapaParse.unparse(data, { encoding: 'ANSI' });
    csv = '\uFEFF' + csv;
    saveAs(new Blob([csv], { type: 'text/csv,charset=UTF-8' }), `${name}.csv`);
    // 因为excel默认是以ANSI格式进行打开的，而csv默认是以utf-8保存的。所以要添加路径后缀。
    // encodeURI 无法转义 '#' 这个符号。当comments中存在这个符号时，会导致该符号后续的内容都无法显示
    // Download('data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csv), `${name}.csv`, 'application/csv');
};
// 导出文件函数
export const saveAs = (obj: any, fileName: string) => {
    // 当然可以自定义简单的下载文件实现方式
    const tmpa = document.createElement('a');
    tmpa.download = fileName || '下载';
    tmpa.href = URL.createObjectURL(obj); // 绑定a标签
    tmpa.style.display = 'none';
    document.body.appendChild(tmpa); // 兼容火狐浏览器
    tmpa.click(); // 模拟点击实现下载
    setTimeout(function () {
        // 延时释放
        URL.revokeObjectURL(obj); // 用URL.revokeObjectURL()来释放这个object URL
        document.body.removeChild(tmpa); // 兼容火狐浏览器
    }, 100);
};

export const parseCsvString = (url: string): any => {
    return new Promise((resolve) => {
        PapaParse.parse(url, {
            download: true,
            complete: function (res: any) {
                resolve(res);
            },
        });
    });
};

export function translateData(data) {
    if (!data || !data.length) {
        return [];
    }
    let result = _.cloneDeep(data);
    result = result.filter((val) => {
        return String(val[0]).trim() !== '';
    });
    // 去除header可能存在的空格
    const data_header = result.shift();
    const key_headers = translateHeader(data_header, 2);
    result = result.map((item) => {
        const tmp = {};
        item.forEach((value, index) => {
            tmp[key_headers[index]] = value || '';
        });
        return tmp;
    });
    return result;
}

export function getHeaders() {
    const headerI18n = {
        ticket: 'IB.order_id',
        ts_type: 'IB.trading_server_type',
        ts_display_name: 'IB.trading_server',
        external_id: 'IB.account',
        'TA Type': 'trading_account_type',
        member_name: 'IB.account_name',
        open_time: 'IB.open_time',
        cmd: 'IB.order_type',
        symbol: 'IB.symbol',
        volume: 'IB.volume',
        open_price: 'IB.open_price',
        sl: 'IB.sl',
        tp: 'IB.tp',
        close_price: 'IB.last_price',
        close_time: 'IB.close_time',
        commission: 'IB.commission',
        swaps: 'IB.swap',
        profit: 'IB.profit',
        comment: 'IB.comment',
    };

    const headers = _.map(headerI18n, (value, key) => {
        return {
            key,
            value: $t(value, {}, { locale: 'en' }),
            locale: $t(value),
        };
    });

    return headers;
}

// 将csv中的header进行本地化翻译，用于表格导出
// type 1 将key转成翻译，例如ticket -> Order ID
// type 2 将英文翻译转成key，例如Order ID - > ticket 仅限英文，用于解析后端返回的csv
// type 3 将英文翻译转成翻译，例如Order ID - > 订单号 仅限英文，用于导出后端返回的csv，转换表头翻译
export function translateHeader(data, type = 1) {
    const headers = getHeaders();

    return data.map((i) => {
        const tmp_res = String(i).trim();
        let tmp_item;
        if (type === 1) {
            tmp_item = headers.find((v) => {
                return v.key === tmp_res;
            });
            return tmp_item ? tmp_item.locale : tmp_res;
        } else if (type === 2 || type === 3) {
            tmp_item = headers.find((v) => {
                return v.value === tmp_res;
            });
            return tmp_item ? (type === 3 ? tmp_item.locale : tmp_item.key) : tmp_res;
        }
    });
}

export const options = [
    {
        value: 'All',
        label: 'IB.all',
    },
    {
        value: 'Buy',
        label: 'IB.buy',
    },
    {
        value: 'Sell',
        label: 'IB.sell',
    },
    {
        value: 'Buy Limit',
        label: 'IB.buy_limit',
    },
    {
        value: 'Sell Limit',
        label: 'IB.sell_limit',
    },
    {
        value: 'Buy Stop',
        label: 'IB.buy_stop',
    },
    {
        value: 'Sell Stop',
        label: 'IB.sell_stop',
    },
    {
        value: 'Buy Stop Limit',
        label: 'IB.buy_stop_limit',
    },
    {
        value: 'Sell Stop Limit',
        label: 'IB.sell_stop_limit',
    },
];

export function orderTypeOption() {
    const result = {};

    options.forEach((val) => {
        result[val.value] = $t(val.label);
    });

    return result;
}

export function exportFunc({ data, name, is_csv = false }) {
    const headers = getHeaders();
    let result = _.cloneDeep(data);

    if (is_csv) {
        result = translateData(data);
    }
    if (!result.length) {
        throw new Error($t('Tips.can_not_export'));
    }

    const orderTypeOptions = orderTypeOption();

    // 对order type进行翻译
    result = result.map((val) => {
        val.cmd = orderTypeOptions[val.cmd];
        return val;
    });
    const changeNameObj = {};
    // 修改headers的翻译
    headers.forEach((val) => {
        changeNameObj[val.key] = val.locale;
    });

    const appStore = useAppStore();
    if (!appStore.memberInfo.display_trading_account_type) {
        //TA Type受权限影响
        delete result[0]['TA Type'];
    }
    exportCsv({ data: result, name, changeNameObj });
}

export const requestPDFBuffer = (url: string) => {
    return new Promise((resolve) => {
        http.get(url, (res: any) => {
            const result: any = [];
            let size = 0;
            res.on('data', (data: any) => {
                // 这里不可使用 result += data，因为会有隐形转换, 实际运行的是 result = result.toString() + data.toString(); --leo
                result.push(data);
                size += data.length;
            });
            res.on('end', () => {
                return resolve(Buffer.concat(result, size));
            });
        }).on('error', (err: any) => {
            console.info('requestPDFBuffer----err', err);
            resolve(err);
        });
    });
};

export const requestBlobPDFBuffer = (data: any) => {
    return new Promise((resolve) => {
        fetch(data)
            .then((r) => r.blob())
            .then((blob) => {
                const file_name = Math.random().toString(36).substring(6) + '_name.pdf';
                const file = new File([blob], file_name, { type: 'application/pdf' });
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = (evt: any) => {
                    if (evt.target.readyState === FileReader.DONE) {
                        const arrayBuffer = evt.target.result;
                        const fileByteArray = new Uint8Array(arrayBuffer);
                        return resolve(fileByteArray);
                    }
                };
            });
    });
};

// 下载文件,自定义options
export const downloadFileWithOptions = (data: any, fileName: string, options?: BlobPropertyBag) => {
    const bol = new Blob([data], options);
    const url = URL.createObjectURL(bol);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

// Download file By URL
export const downloadFileWithURL = async (url, fileName) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error('download file error:', error);
    }
};
