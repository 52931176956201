export const GET_TRADE_ACCOUNT_TREE = 'GET_TRADE_ACCOUNT_TREE';
export const RESET_ALL_REPORT = 'RESET_ALL_REPORT';
export const CHANGE_REPORT_TYPE = 'CHANGE_REPORT_TYPE';
export const SET_REPORT_TREE = 'SET_REPORT_TREE';
export const SET_REPORT_ACCOUNT = 'SET_REPORT_ACCOUNT';
export const UPDATE_REPORT_FILTER = 'UPDATE_REPORT_FILTER';
export const ADD_REPORT_LIST = 'ADD_REPORT_LIST';
export const REMOVE_REPORT_LIST = 'REMOVE_REPORT_LIST';
export const RESET_REPORT = 'RESET_REPORT';
export const REMOVE_REPORT_FILTER = 'REMOVE_REPORT_FILTER';
