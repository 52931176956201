import { $message } from '@/plugins/element';
import { useAppStore } from '@/stores/app';
import { $t } from '@/utils/i18n';
import { Objects } from 'src/model/common';
import { codeList } from './errorCodeList';

export const getErrorMsgByCode = (code: number, closeIcon = false, time = 3000) => {
    const filter: any = codeList.find((val: Objects) => val.code === code);
    const params: any = {};
    if (code === 80052) {
        const appStore = useAppStore();
        params.email = (appStore.companyList && appStore.companyList.email) || '';
    }
    return {
        message: $t(filter ? filter.message : 'IB.system_error', params),
        type: filter?.type || 'error',
        showClose: closeIcon,
        duration: time,
    };
};

export const errorHandler = (err: any) => {
    if (import.meta.env.DEV) console.info('err', err);
    if (err.message === 'Request Cancelled') return; // if cancel request then direct return
    if (err.response && err.response.data && err.response.data.message) return console.info(err.response.data.message);
    if (err.code) {
        const msg = getErrorMsgByCode(err.code);

        if (msg) {
            $message(msg);
        }

        return;
    }
    if (err.message && err.message === 'Network Error') return $message.error($t('IB.network_error'));
};

/** 去除对象中的空值, 空值包括空字符串，undefined, null
 * @param {Object} obj 要执行的对象
 * @param {Array[String]} omitKeys 需要忽略检查的属性
 */
export function removeEmptyValue(obj: Objects, omitKeys: unknown[] = []): Objects {
    const data: Objects = Object.assign({}, obj);
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            let ele: unknown = data[key];
            if (typeof ele === 'string') {
                ele = ele.trim() as string;
                data[key] = ele;
                if (ele === '' && !omitKeys.includes(key)) {
                    delete data[key];
                }
            } else if (Object.prototype.toString.call(ele) === '[object Object]') {
                data[key] = removeEmptyValue(ele as Objects, omitKeys);
            } else if (ele === undefined || ele === null) {
                if (!omitKeys.includes(key)) {
                    delete data[key];
                }
            }
        }
    }
    return data;
}
