import '@/style/index.scss';
import { createApp, Directive } from 'vue';
import App from './App.vue';
import router from './router';
import pinia from './stores';

const app = createApp(App);
app.use(pinia);
app.use(router);

import { loadAllPlugins } from '@/plugins';
loadAllPlugins(app);

import * as directives from '@/directives';
Object.keys(directives).forEach((key) => {
    app.directive(key, (directives as { [key: string]: Directive })[key]);
});

import AppIcon from '@/components/AppIcon';
import Loading from '@/components/Frame/Loading.vue';

app.component('Loading', Loading);
app.component('AppIcon', AppIcon);

import '@/utils/tracking';

app.mount('#app');
