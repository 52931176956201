import load from 'load-script';

export const loadScript = function (scriptSrc) {
    return new Promise((resolve) => {
        load(scriptSrc, (err, script) => {
            resolve(script);
            if (err) {
                console.info(err);
            }
        });
    });
};

const syncLoadScript = function (el) {
    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.innerHTML = el.innerHTML;
    el.appendChild(newScript);
};

const fireEvent = function (element, event) {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent(event, true, true);
    return !element.dispatchEvent(evt);
};

const slice = [].slice,
    singleTagRE = /^<(\w+)\s*\/?>(?:<\/\1>|)$/,
    tagExpanderRE = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([\w:]+)[^>]*)\/>/gi,
    table = document.createElement('table'),
    fragmentRE = /^\s*<(\w+|!)[^>]*>/,
    tableRow = document.createElement('tr'),
    containers = {
        tr: document.createElement('tbody'),
        tbody: table,
        thead: table,
        tfoot: table,
        td: tableRow,
        th: tableRow,
        '*': document.createElement('html'),
    };

const fragment = function (html, name?) {
    let dom, container;
    // A special case optimization for a single tag
    if (singleTagRE.test(html)) dom = document.createElement(RegExp.$1);
    if (!dom) {
        if (html.replace) html = html.replace(tagExpanderRE, '<$1></$2>');
        if (name === undefined) name = fragmentRE.test(html) && RegExp.$1;
        if (!(name in containers)) name = '*';

        container = containers[name];
        container.innerHTML = '' + html;
        dom = slice.call(container.childNodes).map(function (child) {
            return container.removeChild(child);
        });
    }

    return dom;
};

function traverseNode(node, fun) {
    fun(node);
    for (const key in node.childNodes) {
        traverseNode(node.childNodes[key], fun);
    }
}

const append = function (nodes, target, cb) {
    const sync_script = <any[]>[];
    const async_script = <any[]>[];
    nodes.forEach(function (_node) {
        const node = _node.cloneNode(true);
        if (document.documentElement !== target && document.documentElement.contains(target)) {
            traverseNode(target.insertBefore(node, null), function (el) {
                if (el.nodeName != null && el.nodeName.toUpperCase() === 'SCRIPT' && (!el.type || el.type === 'text/javascript')) {
                    if (el.src) {
                        async_script.push(el.src);
                    } else {
                        sync_script.push(el);
                    }
                }
            });
        }
    });
    (async function () {
        for (let i = 0; i < async_script.length; i += 1) {
            await loadScript(async_script[i]);
        }
        for (let i = 0; i < sync_script.length; i += 1) {
            syncLoadScript(sync_script[i]);
        }
        cb();
    })();
};

const exec = function (el, val) {
    if (val) {
        try {
            el.innerHTML = '';
            append(fragment(val), el, function cb() {
                fireEvent(el, 'appended');
            });
        } catch (e) {
            fireEvent(el, 'appenderr');
            console.error(e);
        }
    }
};

export default exec;
