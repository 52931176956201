import { defineAsyncComponent } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import identity from './identity';
const customContainerPages = ['Promotions'];

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "base-layout" */ '@/views/Layouts/BaseLayout.vue'),
        meta: {
            category: 'member',
            requiresAuth: true,
        },
        props: (route) => {
            if (typeof route.meta.category === 'string') {
                if (customContainerPages.includes(route.meta.category)) {
                    return { needCustomContainer: true };
                }
            }
        },
        children: [
            {
                path: '',
                redirect: '/dashboard',
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/MemberDashboard/index.vue'),
                meta: { requiresAuth: true, category: 'MemberDashboard' },
            },
            {
                path: 'funding',
                name: 'funding',
                redirect: '/funding/deposit',
                component: () => import(/* webpackChunkName: "funding" */ '@/views/MemberFunding/index.vue'),
                meta: { requiresAuth: true, category: 'MemberFunding' },
                children: [
                    {
                        path: 'deposit',
                        name: 'member-funding-deposit',
                        component: () => import(/* webpackChunkName: "member-funding-deposit" */ '@/views/MemberFunding/DepositWithdrawal.vue'),
                        meta: { requiresAuth: true, category: 'MemberFunding' },
                    },
                    {
                        path: 'withdrawal',
                        name: 'member-funding-withdrawal',
                        component: () => import(/* webpackChunkName: "member-funding-withdrawal" */ '@/views/MemberFunding/DepositWithdrawal.vue'),
                        meta: { requiresAuth: true, category: 'MemberFunding' },
                    },
                    {
                        path: 'transfer',
                        name: 'member-funding-transfer',
                        component: () => import(/* webpackChunkName: "member-funding-transfer" */ '@/views/MemberFunding/Transfer.vue'),
                        meta: { requiresAuth: true, category: 'MemberFunding' },
                    },
                ],
            },
            {
                path: 'trading-tools',
                name: 'trading-tools',
                component: () => import(/* webpackChunkName: "trading-tools" */ '@/views/MemberTradingTools/index.vue'),
                meta: { requiresAuth: true, category: 'TradingTools' },
                children: [
                    {
                        path: ':subTabId',
                        name: 'trading-tools-subTabId',
                        component: () => import(/* webpackChunkName: "trading-tools" */ '@/views/MemberTradingTools/index.vue'),
                        meta: { requiresAuth: true },
                    },
                    // 这里用两个路由显示一个文件的原因是，iframe detail是要通过同意之后才打开的，而且url上是不能携带id参数，导致分开文件的话，需要重复查找iframe id才可以。
                    {
                        path: ':subTabId/:iframeId',
                        name: 'trading-tools-iframeId',
                        component: () => import(/* webpackChunkName: "trading-tools" */ '@/views/MemberTradingTools/index.vue'),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: 'downloads',
                name: 'downloads',
                component: () => import(/* webpackChunkName: "downloads" */ '@/views/MemberDownloads/index.vue'),
                meta: { requiresAuth: true, category: 'Downloads' },
            },
            {
                path: 'reports',
                name: 'reports',
                component: () => import(/* webpackChunkName: "reports" */ '@/views/MemberReports/index.vue'),
                meta: { requiresAuth: true, category: 'Reports' },
                children: [
                    {
                        path: '',
                        name: 'redirect-transaction-report',
                        redirect: '/reports/transaction-report',
                    },
                    {
                        path: 'transaction-report',
                        name: 'transaction-report',
                        component: () => import(/* webpackChunkName: "reports-transaction-report" */ '@/views/MemberReports/TransactionReport.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'order-report',
                        name: 'order-report',
                        component: () => import(/* webpackChunkName: "reports-order-report" */ '@/views/MemberReports/OrderReport.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'leverage-change-report',
                        name: 'leverage-change-report',
                        component: () => import(/* webpackChunkName: "reports-leverage-change-report" */ '@/views/MemberReports/LeverageChangeReport.vue'),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: 'documents',
                name: 'documents',
                component: () => import(/* webpackChunkName: "documents" */ '@/views/MemberDocuments/index.vue'),
                meta: { requiresAuth: true, category: 'Documents' },
            },
            {
                path: 'promotions',
                name: 'promotion',
                component: () => import(/* webpackChunkName: "promotions" */ '@/views/MemberPromotions/index.vue'),
                meta: { requiresAuth: true, category: 'Promotions' },
                children: [
                    {
                        path: '',
                        name: 'promotions',
                        component: () => import(/* webpackChunkName: "promotions-home" */ '@/views/MemberPromotions/PromotionsMain.vue'),
                    },
                    {
                        path: 'activity-rules',
                        name: 'activity-rules',
                        component: () => import(/* webpackChunkName: "promotions-activity" */ '@/views/MemberPromotions/ActivityRules.vue'),
                    },
                    {
                        path: 'card-detail',
                        name: 'card-detail',
                        component: () => import(/* webpackChunkName: "promotions-detail" */ '@/views/MemberPromotions/CardDetail.vue'),
                    },
                    {
                        path: 'ended-detail',
                        name: 'ended-detail',
                        component: () => import(/* webpackChunkName: "promotions-detail" */ '@/views/MemberPromotions/CardDetail.vue'),
                    },
                ],
            },
            {
                path: 'rewards',
                name: 'rewards',
                component: () => import(/* webpackChunkName: "rewards" */ '@/views/MemberRewards/index.vue'),
                meta: { requiresAuth: true, category: 'Rewards' },
                children: [
                    {
                        path: '',
                        name: 'rewards-home',
                        component: () => import(/* webpackChunkName: "rewards-home" */ '@/views/MemberRewards/Home.vue'),
                    },
                    {
                        path: 'coupons',
                        name: 'rewards-coupons',
                        component: () => import(/* webpackChunkName: "rewards-coupons" */ '@/views/MemberRewards/Coupons.vue'),
                    },
                    {
                        path: 'products',
                        name: 'rewards-product-list',
                        component: () => import(/* webpackChunkName: "rewards-product-list" */ '@/views/MemberRewards/ProductList.vue'),
                    },
                    {
                        path: 'products/:id',
                        name: 'rewards-product-info',
                        component: () => import(/* webpackChunkName: "rewards-product-detail" */ '@/views/MemberRewards/ProductDetail.vue'),
                    },
                    {
                        path: 'order-submittal',
                        name: 'rewards-order-submittal',
                        component: () => import(/* webpackChunkName: "rewards-order-submittal" */ '@/views/MemberRewards/OrderSubmittal.vue'),
                    },
                    {
                        path: 'orders',
                        name: 'rewards-order-list',
                        component: () => import(/* webpackChunkName: "rewards-order-list" */ '@/views/MemberRewards/OrderList.vue'),
                    },
                    {
                        path: 'address',
                        name: 'rewards-address',
                        component: () => import(/* webpackChunkName: "rewards-address" */ '@/views/MemberRewards/Address.vue'),
                    },
                ],
            },
            {
                path: 'user-account',
                name: 'user-account',
                component: () => import(/* webpackChunkName: "user-account" */ '@/views/UserAccount/index.vue'),
                children: [
                    {
                        path: 'account-settings',
                        name: 'account-settings',
                        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/UserAccount/AccountSettings.vue'),
                        meta: { requiresAuth: true, category: 'AccountSettings', pageName: 'menu.account_settings' },
                    },
                    {
                        path: 'message-center',
                        name: 'message-center',
                        component: () => import(/* webpackChunkName: "message-center" */ '@/views/UserAccount/MessageCenter/index.vue'),
                        meta: { requiresAuth: true, category: 'MessageCenter' },
                        children: [
                            {
                                path: '',
                                name: 'redirect-inbox',
                                redirect: '/user-account/message-center/inbox',
                            },
                            {
                                path: 'inbox',
                                name: 'inbox',
                                component: () => import(/* webpackChunkName: "message-center-inbox" */ '@/views/UserAccount/MessageCenter/Inbox.vue'),
                                meta: { requiresAuth: true, pageName: 'menu.message_center' },
                            },
                            {
                                path: 'archive',
                                name: 'archive',
                                component: () => import(/* webpackChunkName: "message-center-archive" */ '@/views/UserAccount/MessageCenter/Archive.vue'),
                                meta: { requiresAuth: true, pageName: 'menu.message_center' },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'member-register',
                name: 'member-register',
                component: () => import(/* webpackChunkName: "member-register" */ '@/views/MemberRegistration/LiveUser.vue'),
                meta: { requiresAuth: true, category: 'register' },
                props: (route) => {
                    if (route.name !== 'personal-info') {
                        return { needDisplay: true };
                    }
                    return { needDisplay: false };
                },
                children: [
                    {
                        path: 'personal-info',
                        name: 'personal-info',
                        component: () => import(/* webpackChunkName: "member-register-personal-info" */ '@/views/MemberRegistration/PersonalInfo.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'additional-info',
                        name: 'additional-info',
                        component: () => import(/* webpackChunkName: "member-register-additional-info" */ '@/views/MemberRegistration/AdditionalInfo.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'account-configuration',
                        name: 'account-configuration',
                        component: () => import(/* webpackChunkName: "member-register-account-configuration" */ '@/views/MemberRegistration/AccountConfiguration.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'questionnaire',
                        name: 'questionnaire',
                        component: () => import(/* webpackChunkName: "member-register-questionnaire" */ '@/views/MemberRegistration/Questionnaire.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'verification',
                        name: 'verification',
                        component: () => import(/* webpackChunkName: "member-register-verification" */ '@/views/MemberRegistration/Verification.vue'),
                        meta: { requiresAuth: true },
                        children: [
                            {
                                path: 'ev',
                                name: 'ev',
                                component: () => import(/* webpackChunkName: "member-register-verification-ev" */ '@/views/MemberRegistration/VerificationEV.vue'),
                                meta: { requiresAuth: true },
                            },
                            {
                                path: 'ocr',
                                name: 'ocr',
                                component: defineAsyncComponent(() => import(/* webpackChunkName: "member-register-verification-ocr" */ '@/views/MemberRegistration/VerificationOCR.vue')),
                                meta: { requiresAuth: true },
                            },
                            {
                                path: 'personal-Info',
                                name: 'personal-Info',
                                component: () => import(/* webpackChunkName: "member-register-verification-personal-info" */ '@/views/MemberRegistration/VerificationPersonalInfo.vue'),
                                meta: { requiresAuth: true },
                            },
                            {
                                path: 'document',
                                name: 'document',
                                component: () => import(/* webpackChunkName: "member-register-verification-document" */ '@/views/MemberRegistration/VerificationDocument.vue'),
                                meta: { requiresAuth: true },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'member-register-demo',
                name: 'member-register-demo',
                component: () => import(/* webpackChunkName: "member-register-demo" */ '@/views/MemberRegistration/DemoUser.vue'),
                meta: { requiresAuth: true, category: 'register' },
                children: [
                    {
                        path: 'demo-account-configuration',
                        name: 'demo-account-configuration',
                        component: () => import(/* webpackChunkName: "member-register-demo-account-configuration" */ '@/views/MemberRegistration/DemoAccountConfiguration.vue'),
                        meta: { requiresAuth: true },
                    },
                ],
            },
        ],
    },
    {
        path: '/preview',
        name: 'preview',
        meta: { category: 'preview' },
        component: () => import(/* webpackChunkName: "member-register-demo" */ '@/views/Preview/index.vue'),
        children: [
            {
                path: 'promotions',
                children: [
                    {
                        path: '',
                        name: 'preview-promotion',
                        component: () => import(/* webpackChunkName: "member-register-demo-account-configuration" */ '@/views/Preview/Promotions.vue'),
                    },
                    {
                        path: 'card-detail',
                        name: 'preview-card-detail',
                        component: () => import(/* webpackChunkName: "promotions-detail" */ '@/views/MemberPromotions/CardDetail.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/ib',
        component: () => import(/* webpackChunkName: "base-layout" */ '@/views/Layouts/BaseLayout.vue'),
        meta: {
            category: 'ib',
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                redirect: '/ib/dashboard',
            },
            {
                path: 'dashboard',
                name: 'ib-dashboard',
                component: () => import(/* webpackChunkName: "ib-dashboard" */ '@/views/IBDashboard/index.vue'),
                meta: { requiresAuth: true, category: 'IBDashboard' },
            },
            {
                path: 'funding',
                name: 'ib-funding',
                component: () => import(/* webpackChunkName: "ib-funding" */ '@/views/IBFunding/index.vue'),
                meta: { requiresAuth: true, category: 'IBFunding' },
            },
            {
                path: 'clients-report',
                name: 'clients-report',
                component: () => import(/* webpackChunkName: "clients-report" */ '@/views/IBClientsReport/index.vue'),
                meta: { requiresAuth: true, category: 'ClientsReport' },
                children: [
                    {
                        path: '',
                        name: 'redirect-member-detail',
                        redirect: '/ib/clients-report/member-detail',
                    },
                    {
                        path: 'member-detail',
                        name: 'member-detail',
                        component: () => import(/* webpackChunkName: "clients-report-member-detail" */ '@/views/IBClientsReport/MemberDetail.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'pending-customers',
                        name: 'pending-customers',
                        component: () => import(/* webpackChunkName: "clients-report-pending-customers" */ '@/views/IBClientsReport/PendingCustomers.vue'),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: 'rebate-report-management',
                name: 'rebate-report-management',
                component: () => import(/* webpackChunkName: "rebate-report-management" */ '@/views/IBRebateReportManagement/index.vue'),
                meta: { requiresAuth: true, category: 'RebateReportManagement' },
                children: [
                    {
                        path: '',
                        name: 'redirect-rebate-report-management',
                        redirect: '/ib/rebate-report-management/rebate-reports',
                    },
                    {
                        path: 'rebate-reports',
                        name: 'rebate-reports',
                        component: () => import(/* webpackChunkName: "rebate-report-management-rebate-reports" */ '@/views/IBRebateReportManagement/RebateReport.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'flat-reports',
                        name: 'flat-reports',
                        component: () => import(/* webpackChunkName: "rebate-report-management-flat-reports" */ '@/views/IBRebateReportManagement/FlatReport.vue'),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: 'orders-report-management',
                name: 'orders-report-management',
                component: () => import(/* webpackChunkName: "orders-report-management" */ '@/views/IBOrdersReportManagement/index.vue'),
                meta: { requiresAuth: true, category: 'OrdersReportManagement' },
                children: [
                    {
                        path: '',
                        name: 'redirect-recent-orders',
                        redirect: '/ib/orders-report-management/recent-orders',
                    },
                    {
                        path: 'recent-orders',
                        name: 'recent-orders',
                        component: () => import(/* webpackChunkName: "orders-report-management-recent-orders" */ '@/views/IBOrdersReportManagement/RecentOrders.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'historical-orders',
                        name: 'historical-orders',
                        component: () => import(/* webpackChunkName: "orders-report-management-historical-orders" */ '@/views/IBOrdersReportManagement/HistoricalOrders.vue'),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: 'ib-profile',
                name: 'ib-profile',
                component: () => import(/* webpackChunkName: "ib-profile" */ '@/views/IBProfile/index.vue'),
                meta: { requiresAuth: true, category: 'IBProfile' },
                children: [
                    {
                        path: '',
                        name: 'redirect-rebate-node',
                        redirect: '/ib/ib-profile/rebate-node',
                    },
                    {
                        path: 'rebate-node',
                        name: 'rebate-node',
                        component: () => import(/* webpackChunkName: "rebate-node" */ '@/views/IBProfile/RebateNode.vue'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'referral-links',
                        name: 'referral-links',
                        component: () => import(/* webpackChunkName: "referral-links" */ '@/views/IBProfile/ReferralLinks.vue'),
                        meta: { requiresAuth: true },
                    },
                ],
            },
        ],
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "login-layout" */ '@/views/Layouts/LoginLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ '@/views/Login/index.vue'),
                meta: { category: 'login' },
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/Password/ForgotPassword.vue'),
                meta: { category: 'retrieve-password' },
            },
            {
                path: 'retrieve-password',
                name: 'RetrievePassword',
                component: () => import(/* webpackChunkName: "retrieve-password" */ '@/views/Password/RetrievePassword.vue'),
                meta: { category: 'retrieve-password' },
            },
        ],
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "registration-layout" */ '@/views/Layouts/RegistrationLayout.vue'),
        children: [
            {
                path: 'register',
                name: 'register',
                component: () => import(/* webpackChunkName: "register" */ '@/views/Registration/index.vue'),
                meta: { category: 'register' },
            },
        ],
    },
    { path: '/official-redirect', name: 'official-redirect', component: () => import(/* webpackChunkName: "official-redirect" */ '@/views/OfficialRedirect.vue') },
    { path: '/referral', name: 'referral', component: () => import(/* webpackChunkName: "referral" */ '../views/Referral.vue') },
    { path: '/marketing', name: 'marketing-campaign', component: () => import(/* webpackChunkName: "marketing-campaign" */ '@/views/MarketingCampaign.vue') },
    { path: '/via-email-redirect', name: 'via-email-redirect', component: () => import(/* webpackChunkName: "via-email-redirect" */ '@/views/ViaEmailRedirect.vue') },
    { path: '/email-verify', name: 'email-verify', component: () => import(/* webpackChunkName: "email-verify" */ '@/views/EmailVerification/index.vue') },
    {
        path: '/mock-deposit',
        name: 'mock-deposit',
        component: () => import(/* webpackChunkName: "mock-deposit" */ '@/views/MemberFunding/MockDeposit.vue'),
    },
    {
        path: '/external-deposit',
        name: 'external-deposit',
        component: () => import(/* webpackChunkName: "external-deposit" */ '@/views/MemberFunding/ExternalDeposit.vue'),
        meta: { requiresAuth: true },
    },
    { path: '/page404', name: 'Page404', component: () => import(/* webpackChunkName: "page-404" */ '@/views/Page404.vue') },
    { path: '/faceVerificationResult', name: 'faceVerificationResult', component: () => import(/* webpackChunkName: "faceVerificationResult" */ '@/views/faceVerificationResult.vue') },
    { path: '/faceVerificationTransfer', name: 'faceVerificationTransfer', component: () => import(/* webpackChunkName: "faceVerificationResult" */ '@/views/faceVerificationTransfer.vue') },
    { path: '/no-access', name: 'NoAccess', component: () => import(/* webpackChunkName: "page-404" */ '@/views/Page404.vue') },
    { path: '/:catchAll(.*)', component: () => import(/* webpackChunkName: "page-404" */ '@/views/Page404.vue') },
];
const demoRouter = {
    path: '/demo',
    component: () => import(/* webpackChunkName: "demo" */ '@/views/Demo.vue'),
};
if (import.meta.env.DEV) {
    routes.push(demoRouter);
}
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

export default router;
identity.authorize();
