const cssSource = `
<style>
.loader {
    margin: 0 auto;
	width: 64px;
	height: 64px;
	display: block;
	background: url('/ttgloadingicon256.gif') no-repeat center center;
	background-size: contain;
}
.loader.mini {
	width: 48px;
	height: 48px;
}
</style>
`;

const requestStatistics = () => {
    let baiduConfig = window.vueConfig.VUE_APP_BAIDU_STATISTICS;
    if (!window.baiduId && baiduConfig) {
        if (baiduConfig) {
            baiduConfig = JSON.parse(baiduConfig);
            let config = baiduConfig.find((val) => {
                return window.location.origin.indexOf(val.domain) !== -1;
            });
            if (config) {
                window.baiduId = config.key;
            }
        }
    }
    if (window.baiduId) {
        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
        var hm = document.createElement('script');
        hm.src = `https://hm.baidu.com/hm.js?${window.baiduId}`;
        hm.id = 'baidu_tj';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
    }
};

export default { cssSource };
export { requestStatistics };
