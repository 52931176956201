import { useAppStore } from '@/stores/app';
import { Directive } from 'vue';

/** 权限指令**/
/*
    1: live_ta_application
    2: demo_ta_application
    3: wallet_ta_application
    4: wallet_account
    5: promotions
    9: funding_management
    10: points_store
    11: deposit
    12: wallet_deposit
    13: wallet_points_deposit
    14: withdrawal
    15: wallet_withdrawal
    16: wallet_points_withdrawal
    17: transfer
    18: wallet_transfer
    19: wallet_points_transfer
    20: ib_promotions
    21: trading_tools
*/
export const hasPermission = ({ name, key }) => {
    const appStore = useAppStore();
    const getPermission = appStore.memberPermissions[name];
    return getPermission && getPermission[key] === 1;
};

const loadingFun = (el: any, binding: any) => {
    el.style.position = 'relative';
    if (binding.value) {
        const wrapper = document.createElement('div');
        const loadingBox = document.createElement('div');
        wrapper.className = 'loading-wrapper maskWrapper';
        loadingBox.className = 'loader mini';
        wrapper.appendChild(loadingBox);
        el.appendChild(wrapper);
    } else {
        let loadingDom = null;
        const loadingChildNodes = [...el.childNodes];
        loadingChildNodes.forEach((val) => {
            if (val.className === 'loading-wrapper maskWrapper') loadingDom = val;
        });
        if (loadingDom) el.removeChild(loadingDom);
    }
};

export const tableLoading: Directive = {
    mounted: (el, binding) => {
        loadingFun(el, binding);
    },
    updated: (el, binding) => {
        if (binding.oldValue !== binding.value) {
            loadingFun(el, binding);
        }
    },
};
