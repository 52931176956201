function pushGtmEvent(eventName: string, params: Record<string, unknown>) {
    try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
            Object.assign(params, {
                event: eventName,
            }),
        );
    } catch (error: any) {
        console.info(error);
    }
}
// 接收一个字符串作为参数，返回一个转换后的字符串，小写，用下划线连接
function toLowerCaseAndUnderscore(str: string): string {
    const lowerCaseStr = str.toLowerCase();
    const underscoreStr = lowerCaseStr.replace(/ /g, '_');
    return underscoreStr;
}
function isTestUser(code) {
    return ['Test_salescode'].includes(code);
}
// 添加 event 后，请在EventCategory进行归类，以方便不同的 broker 做个性化配置
export class GtagHandler {
    login(memberInfo, params): void {
        pushGtmEvent('login', { email: params.email });
    }
    logout(memberInfo): void {
        pushGtmEvent('logout', { user_id: memberInfo.login_id, email: memberInfo.login_name, is_test: isTestUser(memberInfo.member_sales_code) });
    }
    signupLive(memberInfo, params): void {
        pushGtmEvent('signuplive', params);
    }
    signupDemo(memberInfo, params): void {
        pushGtmEvent('signupdemo', params);
    }
    KYCSubmit(memberInfo, params): void {
        const name = `submit_${toLowerCaseAndUnderscore(params.step)}`;
        const payload = {
            email: memberInfo.login_name,
            country: memberInfo.country,
            user_id: memberInfo.login_id,
            phone: `${memberInfo.country_code} ${memberInfo.mobile}`,
        };
        if (name === 'submit_account_configuration') {
            payload['account_type'] = params.type_name;
            payload['account_leverage'] = `1:${params.leverage}`;
        }
        pushGtmEvent(name, payload);
    }
    KYCBack(memberInfo, params): void {
        const name = `back_${toLowerCaseAndUnderscore(params.step)}`;
        pushGtmEvent(name, {
            email: memberInfo.login_name,
            country: memberInfo.country,
            user_id: memberInfo.login_id,
            phone: `${memberInfo.country_code} ${memberInfo.mobile}`,
        });
    }
    fundingDeposit(memberInfo, params): void {
        pushGtmEvent('submit_deposit', {
            email: memberInfo.login_name,
            country: memberInfo.country,
            user_id: memberInfo.login_id,
            phone: `${memberInfo.country_code} ${memberInfo.mobile}`,
            value: params.amount,
            deposit_id: params.transaction_no,
            is_ftd: params.is_ftd,
            deposit_count: params.deposit_count,
            currency: params.payment_currency,
            account_balance: params.account_balance,
            is_test: isTestUser(memberInfo.member_sales_code),
        });
    }
    fundingWithdraw(memberInfo, params): void {
        pushGtmEvent('submit_withdraw', {
            email: memberInfo.login_name,
            country: memberInfo.country,
            user_id: memberInfo.login_id,
            phone: `${memberInfo.country_code} ${memberInfo.mobile}`,
            value: params.amount,
            withdraw_id: params.transaction_no,
            withdraw_count: params.withdraw_count,
            currency: params.payment_currency,
            account_balance: params.account_balance,
            is_test: isTestUser(memberInfo.member_sales_code),
        });
    }
    fundingTransfer(memberInfo, params): void {
        pushGtmEvent('submit_transfer', {
            email: memberInfo.login_name,
            country: memberInfo.country,
            user_id: memberInfo.login_id,
            phone: `${memberInfo.country_code} ${memberInfo.mobile}`,
            value: params.fund_amount,
            transfer_id: params.transaction_no,
            transfer_count: params.transfer_count,
            currency: params.fund_currency,
            account_balance: params.account_balance,
            is_test: isTestUser(memberInfo.member_sales_code),
        });
    }
}

const EventCategory = {
    Auth: ['login', 'logout'],
    Register: ['signupLive', 'signupDemo'],
    KYC: ['KYCSubmit', 'KYCBack'],
    Funding: ['fundingDeposit', 'fundingWithdraw', 'fundingTransfer'],
};

export const getAllEventByConfig = (config) => {
    if (!Array.isArray(config)) {
        return [];
    }
    let allEvent: string[] = [];
    for (const key in EventCategory) {
        if (Object.prototype.hasOwnProperty.call(EventCategory, key)) {
            const element = EventCategory[key];
            if (config.includes(key)) {
                allEvent = allEvent.concat(element);
            }
        }
    }
    return allEvent;
};
