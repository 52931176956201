export const GET_ALL_LANGUAGES = 'GET_ALL_LANGUAGES';
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_MEMBER_INFO = 'GET_MEMBER_INFO';
export const GET_TRADING_TOOLS_INFO = 'GET_TRADING_TOOLS_INFO';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY';
export const GET_TODO_LIST = 'GET_TODO_LIST';
export const GET_ALL_TRADING_SERVER = 'GET_ALL_TRADING_SERVER';
export const GET_MEMBER_PERMISSIONS = 'GET_MEMBER_PERMISSIONS';
export const GET_SYMBOL_NAME = 'GET_SYMBOL_NAME';
export const GET_POINTS_CURRENCY_DISPLAY_NAME = 'GET_POINTS_CURRENCY_DISPLAY_NAME';
export const GET_WALLET_INFO = 'GET_WALLET_INFO';
export const CHANGE_COMPANY = 'CHANGE_COMPANY';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOGIN_USER = 'SET_LOGIN_USER';
export const SET_SYSTEM_TYPE = 'SET_SYSTEM_TYPE';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_MEMBER_INFO = 'SET_MEMBER_INFO';
export const SET_TRADING_TOOLS = 'SET_TRADING_TOOLS';
export const SET_REQUEST_TRADING_TOOLS = 'SET_REQUEST_TRADING_TOOLS';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_TRANSACTIONS_TYPE = 'SET_TRANSACTIONS_TYPE';
export const SET_ALL_COUNTRY = 'SET_ALL_COUNTRY';
export const SET_ALL_COUNTRY_MAP = 'SET_ALL_COUNTRY_MAP';
export const SET_TODO_LIST = 'SET_TODO_LIST';
export const SET_TITLE = 'SET_TITLE';
export const SET_ALL_TRADING_SERVER = 'SET_ALL_TRADING_SERVER';
export const SET_GLOBAL_TRADING_DIALOG = 'SET_GLOBAL_TRADING_DIALOG';
export const SET_MEMBER_PERMISSIONS = 'SET_MEMBER_PERMISSIONS';
export const SET_SYMBOL_NAME = 'SET_SYMBOL_NAME';
export const SET_POINTS_CURRENCY_NAME = 'SET_POINTS_CURRENCY_NAME';
export const SET_WALLET_INFO = 'SET_WALLET_INFO';
export const SET_RESPONSIVE_VALUE = 'SET_RESPONSIVE_VALUE';
export const SET_AUTH_LIST = 'SET_AUTH_LIST';
export const SET_SCREEN_LOADING = 'SET_SCREEN_LOADING';
export const SET_PERMISSION_LOADING = 'SET_PERMISSION_LOADING';
export const SET_SCROLLBAR_REF = 'SET_SCROLLBAR_REF';
