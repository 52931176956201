import { QuestionStacksInterface } from '@/model/store/registration';
import { defineStore } from 'pinia';
import { useAppStore } from '../app';
import * as actionTypes from './actionTypes';

export type State = {
    registrationSequence: Array<string>;
    registerStepZeroForm: Record<string, any>;
    demoSequence: Array<string>;
    questionStacks: Array<QuestionStacksInterface>;
    activeQuestionListIndex: number;
};

export const useRegistrationStore = defineStore('registration', {
    state: (): State => ({
        registrationSequence: [],
        registerStepZeroForm: {},
        demoSequence: [],
        questionStacks: [],
        activeQuestionListIndex: 0,
    }),

    getters: {
        userSequence() {
            const appStore = useAppStore();
            const pendingCustom = appStore.toDoList.find((f) => f.type === 'live_ta');
            return pendingCustom ? pendingCustom.register_step : '';
        },
        userDemoSequence() {
            const appStore = useAppStore();
            const demoUser = appStore.toDoList.find((f) => f.type === 'demo_ta');
            return demoUser ? 'AC' : '';
        },
        demoRegistrationSuccess() {
            const appStore = useAppStore();
            const config = appStore.toDoList.find((f) => f.type === 'config');
            return config ? config.demo_registration_success : false;
        },
    },

    actions: {
        [actionTypes.SET_REGISTRATION_SEQUENCE](params: Array<string>) {
            this.registrationSequence = params;
        },
        [actionTypes.SET_STEP_ZERO_FORM](params: Record<string, any>) {
            this.registerStepZeroForm = params;
        },
        [actionTypes.SET_DEMO_SEQUENCE](params: Array<string>) {
            this.demoSequence = params;
        },
        [actionTypes.SET_QUESTION_STACKS](params: Array<QuestionStacksInterface>) {
            this.questionStacks = params;
        },
        [actionTypes.SET_ACTIVE_QUESTION_LIST_INDEX](params: number) {
            this.activeQuestionListIndex = params;
        },
    },
});
