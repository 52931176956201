import { useAppStore } from '@/stores/app';
import { GtagHandler, getAllEventByConfig } from './gtag';
import { PlausibleHandler } from './plausible';
const hasGTM = window.dataLayer?.length > 0;
const hasPlausible = window.vueConfig.VUE_APP_PLAUSIBLE_API_HOST !== '';
const gtagHandler = new GtagHandler();
const plausibleHandler = new PlausibleHandler();
const allEvent = getAllEventByConfig(window.vueConfig.VUE_APP_GTM_SUBSCRIBE_EVENT);
function pushTracking(eventName: string, params?: Record<string, unknown>) {
    const appStore = useAppStore();
    const memberInfo = appStore.memberInfo;
    if (hasGTM && allEvent.includes(eventName) && gtagHandler[eventName]) {
        gtagHandler[eventName](memberInfo, params);
    }
    if (hasPlausible && plausibleHandler[eventName]) {
        plausibleHandler[eventName](memberInfo, params);
    }
}
window.pushTracking = pushTracking;
