/**
 * 全部用小写，加下划线区分内容。因为这样的长文字可读性比驼峰高
 * 命名不可随意命名，必须是能代表有相关意思
 * 字段写入的位置必须跟 zh.js 的位置。这是为了后续的外部扩展语言文件的功能准备的
 */
const zh_local = {
    inverse: '反选',
    home_page: '门户首页',
    isno: '否',
    isyes: '是',
    apply_now: '立即报名',
    one_click_close: '关闭所有选项卡',
    login_name: '登录名称',
    login_email: '邮箱',
    loading: '加载中',
    login: '登录',
    welcome_to: '欢迎',
    login_to_access_your_account: '登录您的账户',
    remember_me: '7天内保持登录',
    forgot_password: '忘记密码?',
    copyright: '版权所有',
    login_tip: '如果你没有账户,',
    sign_up: '注册',
    account: '账户',
    password: '密码',
    sign_in_cap: '登录',
    register_in_cap: '注册',
    sign_in: '登录',
    enter_the_correct_date_format: '请输入正确的日期格式',
    date_format_correct: '格式正确',
    register_in: '注册',
    edit: '编辑',
    add: '添加',
    close: '关闭',
    submit: '提交',
    submit_lc: '提交',
    export: '导出',
    email: '发送',
    reset: '重置',
    reset_lc: '重置',
    search: '搜索',
    cancel: '取消',
    cancel_lc: '取消',
    choose_lc: '选择',
    confirm: '确认',
    view: '查看',
    go: '前往',
    filter: '筛选',
    send: '发送',
    send_lc: '发送',
    yes: '确定',
    yes_lc: '确定',
    save: '保存',
    update: '更新',
    delete: '删除',
    failed: '失败',
    back: '返回',
    back_lc: '返回',
    next: '下一步',
    next_lc: '下一步',
    From: '从',
    To: '至',
    to: '至',
    upload: '上传',
    upload_file: '上传文件',
    sign_out: '登出',
    apply: '应用',
    apply_lc: '应用',
    trade_account: '交易账户',
    select: '请选择',
    export_csv: '导出CSV',
    export_all: '导出所有数据',
    export_search: '导出查询结果',
    back_login: '返回登录',
    question: '问题',
    empty_data_export: '数据为空，无法导出!',
    switch_to: '切换',
    ib_portal: 'IB门户',
    member_portal: '会员 门户',
    mt4_time_tip: '* 请注意此页面内所显示时间均为MT4/5时区。',
    choose: '选择',
    delete_agreement: '删除协议',
    select_file: '选择文件',
    no_lang: '目前还没有这种语言的翻译',
    the_password: '输入格式不正确',
    the_password_lowercase: '密码缺少小写字母',
    the_password_uppercase: '密码缺少大写字母',
    the_password_digits: '密码缺少数字',
    tree_customer: '散户',
    tree_client: '客户',
    enter_password: '请输入您的密码',
    confirm_password_error: '输入的密码不一致',
    error_message: '很抱歉，我们暂时找不到您所访问的内容。请联系TTG官网客服寻求帮助。',
    optional: '(选填)',
    en: '启用',
    dis: '禁用',
    ro: '只读',
    ac: '归档',
    disabled: '禁用',
    enabled: '启用',
    readonly: '只读',
    archive: '归档',
    id_vf: '验证身份证',
    add_vf: '验证地址',
    bank_vf: '验证银行卡',
    Verified: '已验证',
    Unverified: '未验证',
    rejected: '文本无法被接受',
    pending: '待处理',
    ambiguous: '文件内容模糊',
    incorrect: '文件内容有误',
    qr_code: '二维码',
    copy: '复制链接',
    open_link: '打开链接',
    desktop: '桌面端',
    mobile: '移动端',
    domain: '域名',
    display_name: '展示名称',
    node_link_title: '%{sever} -%{account} IB 链接 >>',
    got_it: '好的',
    country: '国家和地区',
    page: {
        next: '下一页',
        prev: '上一页',
        get_total: '获取全部',
    },
    agree: '同意',
    collapse_all_TA: '收起所有交易账户',
    expand_all_TA: '展开所有交易账户',
    generate_flat_report: '生成全用户报告',
    download_btn: '下载',
    disclaimer_foot: '免责声明',
    generate_all_historical_orders: '生成所有历史订单报告',
    recent_order_tip: '* 近期订单只能查询到最近 {days} 天的订单，如果需要查询 {days} 天之前的订单请使用历史订单板块。 请注意此页面内所显示时间均为MT4/5时区。',
    history_order_tip: '* 历史订单只能查询到 {days} 天以前的订单，如果需要查询近期 {days} 天的订单请使用近期订单板块。 请注意此页面内所显示时间均为MT4/5时区。',
    an_email_has_been_sent_to_your_email_address: '已发送至您的邮箱',
    trading_account_type: '交易账户类型',
    ta: '交易账户',
    continue: '继续',
    certified: '认证通过',
    auth_fail: '认证失败',
    jump_fail: '跳转失败',
    jump_fail_tip: '请返回会员门户，刷新页面并重新进行认证',
    certified_tip: '请返回会员门户',
    auth_fail_tip: '请返回会员门户，重新进行认证',
    auth_hardware_fail_tip: '请返回会员门户，联系管理员',
    menu: {
        email_verify: '邮箱验证',
        dashboard: '首页',
        clients_report: '客户报告',
        funding: '资金',
        orders_report_management: '订单报告管理',
        rebate_report_management: '佣金报告管理',
        ib_profile: '代理资料',
        trading_tools: '交易工具',
        downloads: '下载',
        reports: '报告',
        documents: '协议及文档',
        rewards: '积分商城',
        account_settings: '账户设置',
        message_center: '消息中心',
        payment_info: '已存支付信息',
        verification: '验证',
        trading_accounts: '交易账户',
        web_mt4: '网页端MT4',
        web_trader: '网页交易端',
        trading_central: 'Trading Central',
        member_profile: '个人资料',
        ib_management: 'IB管理',
        node_link: '代理账号链接',
        referral_links: '自定义推广链接',
        login: '登录',
        register: '注册',
        Retrieve_Password: '重置密码',
        applyDemoTa: '申请模拟交易账户',
        applyLiveTa: '申请真实交易账户',
        get_verified_now: '立即获得验证',
        promotions: '活动',
    },
    promotions: {
        lost_authenticator: '如果您丢失了验证器，请联系我们的客户团队。',
        promotion_will_finished: '你好，请选择您想要兑换的奖励！一旦奖励被兑换，活动将即刻停止。',
        view_details: '查看详情',
        you_have_won: '您已赢得',
        you_can_win_points: '您可赢得积分',
        you_can_win_coupons: '您可赢得优惠券',
        await_win: '多样化的奖励等待您的赢取',
        one_of_the_following_rewards: '以下奖励中的一个',
        reward_description: '奖励明细',
        claim_one_points: '满足条件，即可领取其中一个奖励',
        check_gift_list: '礼品清单详见活动说明页',
        earned_coupons: '赢得的优惠券',
        redeem_your_reward: '兑换您的奖励',
        choose_the_reward: '请选择您要兑换的奖励',
        have_redeemed: '恭喜您成功兑换以下奖励！',
        redeemed_successfully: '领取成功的奖励',
        some_failure: '存在部分奖励兑换失败，请联系我们的客服团队获得帮助。',
        failed_redeem: '兑换失败的奖励',
        apologize_failure: '我们为以下奖励领取失败感到抱歉，请您联系我们的客服团队获得帮助。',
        redeem_now_better: '你想现在就兑换奖励吗？您能通过继续交易来获得更好的奖励！',
        redeem_now_2: '立即兑换？',
        continue_to_trade: '继续交易',

        coupon_claim_not_successful: '您的优惠券领取失败，请联系我们的客户支持团队',
        have_received_rewards: '您成功领取了{rewards}！',
        apologize_for_failure: '很抱歉，有{coupons}领取失败，请联系我们的客户支持团队。',
        ended_detail: '已完结',
        Timezone: '时区',
        I_have_read_and_agreed: '我已阅读并同意 ',
        Completed_Volumes: '完成交易手数',
        Completed_Deposits: '完成入金金额',
        Need_Agree: '您需要同意以上协议',
        Activation_Tips: '在活动正式开始前，您需要满足以下的激活条件',
        Please_Reload: '我们需要一点时间计算您的最新活动进度。请重新加载页面查看最新进度。',
        No_Promotion: '当前暂无活动，敬请期待',
        Check_Rewards: '您可在{menu}中查看',
        Promotion_Name: '活动名',
        Total_Rewards: '总共奖励',
        CouponsNum: '1张优惠券 | {num}张优惠券',
        PointsNum_CouponsNum: '{pointsNum}{pointsDisplay}和1张优惠券 | {pointsNum}{pointsDisplay}和{couponsNum}张优惠券',
        Points_Coupons: '{pointsDisplay}和优惠券',
        Funding_Method: '交易方式',
        Activity_Rules: '活动规则',
        Can_Use_It: '您可以在积分商城中使用积分与优惠券兑换商品。',
        Coupons_And_Vouchers: '您赢得的兑换券与优惠券',
        MoreCoupons: '以及{num}+张券',
        and: '和',
        Adjust_Progress: '调整进度结算的顺序',
        Change_Priority_Title: '您是否要更改活动的优先级？',
        Change_Priority_Message: '入金及交易进度将会累计到优先级高的活动中。',
        Change_Priority_Tip: '*更改活动的优先级可能会对进度计算的准确性造成影响。',
        Send_Backward: '往后一个',
        Bring_Forward: '往前一个',
        Participating_trading_account: '已参与交易账号',
        Participated_trading_account: '已参与交易账号',
        Promotion_Period: '活动时间',
        Application_Period: '报名时间',
        Shared_Progress: '共享进度',
        TIME: '时间',
        points: '{num}{pointsDisplay}',
        Earned_Coupons: '获得的优惠券',
        Completed_Promotion_Tip: '完成您的第一个活动以获取优惠券！',
        deposit: '入金',
        volume: '手数',
        ACTIVATING: '激活中',
        Accumulated_Balance: '累计获得',
        View_all_coupons: '查看所有优惠券 >',
        CONGRATULATIONS: '恭喜您！',
        COUPONS_AWARDED: '获得的优惠券',
        Coming_Soon: '即将推出',
        Coming_soon_day_left: '即将开始：还有{dayNum}天{time}',
        Completed_Entries: '完成次数',
        Congratulations: '恭喜您！',
        Reward_Requirements: '您已达到活动要求，可兑换奖励',
        Congratulations_rewards: '您赢得了{rewards}',
        DETAIL: '详情',
        Deposit_Now: '立即入金',
        ENDING_TIME: '结束时间',
        Ended: '已完结',
        Finish: '完成',
        I_got_it: '明白',
        Join_Again_Now: '再次参与',
        Join_again_now: '再次参与！',
        Learn_More: '查看更多',
        OFF: '折扣',
        On_going: '进行中',
        One_time_Reward: '一次性奖励',
        PARTICIPATE_NOW: '立即参与',
        PAUSED: '暂停',
        PROGRESS_PAUSED: '进度-暂停',
        PROGRESS_FINISHED: '进度-已完成',
        PROGRESS_READY: '进度-未开始',
        PROGRESS_PROMOTION_ACTIVATING: '进度-活动激活中',
        PROGRESS_PROMOTION_STARTED: '进度-活动已开始',
        PROGRESS_REDEEMABLE: '进度-可兑奖',
        Participable: '可参与',
        Participate_Now: '立即参与',
        Participated_Period: '参与周期',
        Previously_participated: '参与过的活动',
        Promotion_Description: '活动描述',
        Promotion_Progress: '活动进度',
        READY: '未开始',
        REDEEMABLE: '可兑奖',
        RELATED_TRANSACTIONS_ORDERS: '关联的资金及订单',
        Record_participation: '活动记录',
        Redeem_Now: '立即兑换',
        STAGE: '第{stage}阶段',
        STARTED: '已开始',
        Sign_up_Now: '立即报名',
        TOTAL_WIN: '赢得{rewards}！',
        You_can_win: '您可赢得',
        day_left: '还有{dayNum}天{time}结束',
        join_again: '是否再次参与活动？',
        participated_promotion: '你已成功参与活动！',
        redeem_reward_congratulations: '恭喜您！',
        win_in_each_round: '你可在每轮赢得{rewards}！',
        win_more_rewards: '你可赢得更多奖励',
        account_type: '账户类型',
        transaction_no: '交易单号',
        trading_account: '交易账号',
        fund_in_amount: '资金金额',
        time_submitted: '提交时间',
        time_completed: '完成时间',
    },
    leftBar: {
        member_info_title: '注册真实账户',
        member_info_content: '您的个人信息',
        step_one_content: '关于您的更多信息',
        step_two_content: '请完成问卷测试',
        step_three_content: '账户配置',
        demo_title: '注册模拟账户',
        demo_content: '您的个人信息',
        demo_ta_content: '账户配置',
    },
    member_upgrade: {
        drawer_close_tip: '已填写的内容不会保存，是否退出？',
        component_back_tip: '已填写的内容不会保存，是否确认返回？',
        submit_tip: '是否确认提交申请？',
        question_error: '你的问卷回答错误次数超限，请于 {time} 后重试',
        document_apply_tip: '您的文件正在被审核，审核完成会通知您',
        submit_success: '已成功提交升级申请',
        upgrade_account_title: '升级会员账户',
        member_upgrade_title: '会员升级',
        agreement_desc_title: '协议',
        agreement_desc_sub_title: '会员账户升级协议',
        questionnaire_desc_title: '问卷',
        questionnaire_desc_sub_title: '请完成问卷测试',
        verification_desc_title: '认证',
        verification_desc_sub_title: '请上传文件完成认证',
        template_name: '*下载模版描述',
        become_a: '成为',
        pro_trader: 'TTG机构户',
    },
    register: {
        send_reminder: '验证邮件已发送至 {email}，请点击邮件中的链接进行验证',
        expired_reminder: '此链接已失效，新的验证邮件已发送至 {email}',
        invalid_reminder: '链接已失效',
        pass_reminder: '邮箱已验证，请关闭此页面',
        you_have_uploaded_maximum_number_of_documents: '已达到文件上传数量上限',
        member_title_live: '申请真实交易账户',
        member_title_demo: '申请模拟交易账户',
        personal_info: '个人信息',
        additional_info: '额外信息',
        questionnaire: ' 问卷',
        continue_questionnaire: '继续问卷',
        account_configuration: '账户配置',
        demo_completed: '模拟交易账户注册成功',
        live_completed: '真实交易账户注册成功',
        crm_password: 'CRM 密码',
        set_invest: '设置观摩密码',
        set_trading_password: '设置交易密码',
        set_trading: '设置密码',
        edit_notes: '备注',
        invest_password: '观摩密码',
        trading_password: '交易密码',
        trading_server: '交易服务器',
        live_title: '申请真实交易账户',
        upload_tips1: '您的身份验证没有通过，请上传证明文件，或者重新编辑身份信息并再次提交。',
        upload_tips: '（至少上传2种）',
        identificationProofLabel: '身份证件',
        addressProofLabel: '地址证明',
        bankStatementLabel: '银行对账单',
        upload_file: '将文件拖到此处或点击上传',
        accept_file_label: '请上传小于10M的 .jpg .png .bmp类型的文件',
        fill: '填写您的个人信息以创建账户',
        demo_title: '申请模拟交易账户',
        deposit: '账户初始金额',
        upload_proof_document: '验证您的账户',
        title: '称谓',
        id_number: '证件号码',
        country_of_origin: '原籍',
        is_black_tips: '我们无法为您所在的国家或地区提供服务',
        please_select_birth_date: '请选择出生日期',
        year: '年',
        month: '月',
        day: '日',
        please_select_the_field: '请选择 {field}',
        please_enter_the_field: '请输入 {field}',
        do_not_input_char: '不能输入以下非法字符 {char}',
        the_fields_in_red_are_required: '红色字体为必选项',
        please_upload_all_documents_as_required: '请根据要求上传所有文件',
        mobile_number: '手机号码',
        chinese_name: '中文名',
        please_enter_chinese: '请输入汉字',
        info_title_tips: '请注意，您所提供的个人资料，皆需提供一致的身份证明文件（例如，身份证或护照）',
        verification: {
            tips_1: '在您支付定金并开始与我们交易之前，我们需要验证您的身份。',
            tips_2: '我们可以通过电子方式验证您的身份，只需填写下面的信息即可继续。',
            confirm: '我同意TTG Markets以电子方式验证我的身份，并向身份验证中心提供我的个人信息文件（包括身份证明文件）以获得评估与验证',
            choose_method: '请选择您的验证方式:',
            document_details: '文件详情',
            method: {
                au_driver_licence_residential: '澳洲驾照',
                au_medicare_residential: '澳洲医疗卡',
            },
            upload_at_least_doc_tip_for_photo_id: '{proof_type}至少需上传 {x} 份{document_wording}',
            upload_at_least_doc_tip_for_proof_of_addr: '{proof_type}至少需上传 {x} 份{document_wording}',
            good_job: '你真棒！',
            your_documents_were_sent_for_verification: '您的文件正在审核中。',
            you_will_receive_an_email_once_they_are_verified: ' 验证通过后，您将收到一封电子邮件通知。',
            GO_TO_PORTAL: '进入您的个人主页',
            electronic_description_1: '为了加快验证过程，请在我们的在线门户中输入您的证件信息以进行即时验证。',
            electronic_description_2: '如果我们无法通过电子方式验证您，我们将需要您在下方上传证明文件。 我们会在您的帐户验证通过后立即通知您。',
            photo_description_1: '请上传以下文件之一',
            photo_description_2: '所有带有照片的证件必须有效并清楚地显示您的全名、出生日期和签发/到期日期。',
            proof_description_2: '地址证明文件应包含您的完整住址，并且为最近三个月内的日期。',
            ocr: {
                title: '请选择您的验证方式',
                uploadChineseId: '上传中国身份证',
                typeInInformation: '手动填写信息',
                tip: '如果您没有中国身份证，请手动输入信息(选项在右侧)',
                id_front: '证件正面',
                id_back: '证件反面',
                mobile_tip: '请务必提供您真实的手机号码，否则验证将失败。',
                input_manually: '请手动输入',
                continue_button: '继续',
                error_tip1_title: '哎呀--出现错误了！',
                error_tip1_desc: '抱歉，我们无法完成自动核查。请手动上传您的文件，我们的团队将在一小时内处理您的验证。',
                error_tip2_desc:
                    '抱歉，我们无法正确检测到您的ID，请确保上传的证件图片完整，图中文字、证件号码清晰可辨，请避免裁剪边角框、污迹、划痕或折痕。请使用彩色图片，请勿提交黑白图片或复印件。此外，您也可以选择手动输入上传您的信息。',
                error_tip3_desc: '抱歉，我们无法正确检测到您的ID。请手动输入您的信息，谢谢！',
                error_tip4_desc: '您上传的证件已经过期，请重新上传有效证件，谢谢！',
                recommendation: '拍摄建议',
                single_bg_tip: '在光线充足的单色背景下拍摄彩色照片',
                uncropped_tip: '请确保照片完整，无需裁剪，证件信息清晰可见',
                overexposure_tip: '照片应保持清晰，避免模糊、过曝和光斑的现象',
                guiding_example: '正确示范',
                cropping: '裁切',
                excessive_blank_space: '过多空白',
                blurriness: '模糊',
                overexposure: '过曝',
                glare: '光点',
            },
            group: {
                no_ev: '我没有以上证件',
                cn_id_2d_and_3d: '中国身份验证',
                au_driver_licence_residential: '澳大利亚驾照',
                au_passport_residential: '澳大利亚护照',
                au_medicare_residential: '澳大利亚医疗卡',
                au_international_passport_visa: '国际护照',
                hk_id_residential: '香港居民身份证',
                indonesia_id_tax: '印度尼西亚居民身份证',
                my_id_credit_bureau: '马来西亚居民身份证',
                sg_credit_bureau_utility: '新加坡身份证',
                ph_id_credit_bureau: '菲律宾居民身份证',
                vn_id_social_security: '越南居民身份证',
                th_national_id: '泰国居民身份证',
                sa_national_id: '南非居民身份证',
                kh_national_id: '柬埔寨居民身份证',
                id3_passport: '护照',
            },
            field: {
                driversLicenceNo: '驾照号码',
                driversLicenceCardNo: '驾照卡号',
                driversLicenceStateOfIssue: '驾照签发州',
                driversLicenceExpiryDate: '过期日期',
                passportNo: '护照号码',
                passportExpiry: '护照过期日期',
                medicareCardType: '医疗卡类型',
                medicareCardNo: '医疗卡号码',
                medicareExpiry: '医疗卡过期日期',
                medicareReferenceNo: '医疗卡参考号码',
                passportIssuerCountry: '护照颁发国家和地区',
                passportCountry: '护照颁发国家和地区',
                nationalIDNo: '身份证号码',
                nik: 'NIK',
                npwp: 'NPWP',
                phoneNo: '电话号码',
                nationalIDType: '身份证件类型',
                idCardNo: '身份证号码',
                laserNo: '激光号码',
                thaiFirstName: '名(泰文)',
                thaiLastName: '姓(泰文)',
                Number: '护照号码',
                CountryOfOrigin: '护照颁发国家',
                PassportExpiry: '过期日期',
                gender: '性别',
                voterNo: '投票号码',
                khmerScriptFirstName: '名(高棉文)',
                khmerScriptLastName: '姓(高棉文)',
                id_number: '身份证号码',
                name: '姓名',
                phone_number: '手机号码',
                cn_name: '中文姓名',
            },
            tip: {
                driversLicenceCardNo_tip: '*VIC与QLD颁发的驾驶执照不需要填写卡号',
            },
            dialog: {
                dialog_1_title: '恭喜!',
                dialog_1_message: '验证已成功！即刻交易全球市场！',
                goto_dashboard: '跳转到主页',
                mark_a_deposit: '即刻入金',
                dialog_2_title: '恭喜!',
                dialog_3_title: '抱歉，信息有误',
                dialog_3_message: '很抱歉的通知您，我们无法自动验证您的身份信息。您可以选择重新填写或手动上传您的文档进行验证，我们将在1小时内回复您此次验证结果。',
                try_again: '再试一次',
                double_check_details: '请仔细检查您提供的的信息。',
                upload_documents: '上传文档',
                dialog_4_title: '验证失败',
                dialog_4_message: '很抱歉的通知您，我们无法自动验证您的身份信息。由于第三次尝试失败，我们需要您手动上传您的文档，我们将在1小时内回复您。',
                dialog_5_message: '您的文件已提交成功！验证完成时您将会收到一封电子邮件。',
                id_used_tip: '您好，该证件号码已被注册，相同证件号不可重复注册。如果您想要修改登录邮箱，或重新启用已有账号，请联络我们的客服团队。',
            },
        },
        upload_documents: {
            photo_id: '带照片的身份证*',
            poi_notes: '请上传下列文档之一',
            passport: '护照',
            national_id: '身份证',
            drivers_license: '驾驶证',
            poi_hints: '*所有带照片的身份证明必须真实有效，并清楚显示您的全名、出生日期和签发/失效日期。',
            selfie: '自拍*',
            selfie_notes: '请上传一张您手持第一项上传文件(您的证件)的照片',
            selfie_hints: '*所有带照片的身份证明必须真实有效，并清楚显示您的全名、出生日期和签发/失效日期。',
            poa: '地址证明*',
            notes: '请上传下列文档',
            utility_bill: '物业账单/水电费账单',
            gas_water_electricity: '煤气/水/电',
            Bank_Credit: '银行/信用卡对账单',
            utility_bill_or: '物业账单或固定电话/宽带账单',
            poa_hints: '您最近三个月内的水电费账单或固定电话/宽带账单需要对应您的完整住址。',
            bank: '银行对帐单*',
            bank_hints: '您的银行对帐单应确认您的完整住址，并注明最近三个月的日期。',
            thank_you: '感谢您的配合!',
            upload_success_notes: '你的文件已经送去核实了。一旦验证通过，您将收到一封电子邮件。',
            go_to_dashboard: '跳转到主页',
            take_a_picture: '从您的计算机或移动设备上拍照',
            select_a_file: '选择上传本地文件或直接从文件夹拖放文件',
            mobile_upload_document: '上传您的文档',
            mobile_new_document: '上传新文件',
        },
        isRegisterTips: '您所使用的邮箱已有注册记录，但尚未完成所有步骤。您需要我们发送一封电子邮件到此邮箱，以完成您的注册吗？',
        yes: '需要',
        use_a_different_email: '不需要，我将用新邮箱重新注册',
        send_me_the_link: '点击发送链接',
        no_tips: '不需要 (我将用新邮箱重新注册)',
        ok: '确认',
        The_link_has_been_sent_please_kindly_check_your_inbox: '链接已发送，请查收您的注册邮箱。',
        stepOne: {
            first_name: '名',
            middle_name: '中间名',
            last_name: '姓',
            first_name_cn: '名(拼音)',
            middle_name_cn: '中间名',
            last_name_cn: '姓(拼音)',
            first_name_cn_ev: '名(汉字)',
            last_name_cn_ev: '姓(汉字)',
            country: '国家及地区',
            email: '电子邮件',
            mobile: '手机',
            code: '国际电话区号',
            referral_code: '推荐码',
            client_portal_password: '客户端密码',
            apply_member_account: '申请会员帐号',
            upload_tip: '我们需要更多信息以认证您的账户',
            country_region_of_residence: '国家和地区',
        },
        additionalInfo: {
            date_title_tips: '（*请按照正确的日期格式输入）',
            date_format_tips: '生于 YYYY-MM-DD',
            pick_a_date: '请选择日期',
            photo_id: '身份证明',
            photo_id_number: '身份证明号码',
            photo_id_expiry_date: '身份证明有效期限',
            id_number: '证件号码',
            id_selection: '电子身份验证',
            china_national_id: '中国身份证号码',
            title: '称谓',
            phone: '电话',
            gender: '性别',
            birth: '出生日期',
            birth_format: '出生日期（YYYY-MM-DD）',
            postcode: '邮编',
            address: '地址',
            province: '省份',
            city: '城市',
            suburb: '地区',
            prefecture: '都道府县',
            city_town_village: '区市町村',
            address_one: '详细地址',
            address_two: '详细地址',
            town: '城镇',
            state: '州',
            district: '区',
            state_province: '州/省份',
            province_city: '省份/城市',
            city_post_town: '城市/镇',
            village_road: '村/路',
            flat_house: '公寓/房',
            street_region: '街/区',
            ward_town: '区/镇',
            region: '区',
            building_street: '楼/街',
            building_unit: '楼/单元',
            village_subdistrict: '村/小区',
            district_town: '区/镇',
            street_name_and_number: '街道号以及名称',
            barangay_suburb_or_village: '城区',
            unit_or_flat_number: '单元号或单位号',
            street_address_of_building: '街道名称',
            street_level_information: '街道楼层信息',
            unit_number: '单元号',
            passport: '国际护照',
            reference_number: '卡面姓名前号码',
            expiry_time: '有效期',
            country_of_origin: '原籍',
            drive_state: '驾照所在州',
            male: '男性',
            female: '女性',
            other: '其他',
            referral_code: '推荐代码',
            mr: '先生',
            mrs: '夫人',
            mx: 'Mx',
            ms: '女士',
            miss: '小姐',
            dr: '博士',
            delete_proof_tips: '是否删除此证明文件？',
            other_information: '其他信息',
            verification: '认证',
            residential_address: '居住地址',
            residential_address_tips: '(*请确保您所输的家庭地址正确完整)',
            process_to_next_step: '我确认所填住址信息正确完整',
            close_attention_popup: '我将检查并修改住址信息',
            address_confirm_tips: '请确保您填写的住址信息正确完整，住址信息不全或错误或将导致开户延迟。',
        },
        stepThree: {
            answer: '回答测验',
            multiple: '多选',
            option: '选答',
            server: '交易平台',
            textarea: '请输入答案',
            q_title: '问卷 %{index}',
            country_list: '国家和地区列表',
            answer2: '回答',
            warning: '注意',
            test_failed: '测试未通过',
            did_not_pass: '抱歉，您没有通过本次问卷测试，',
            review_or_try: '您可以选择查看结果或再次尝试。',
            review_and_retry: '您必须选择正确的答案才能进入下一步，请检查并修改。',
            close: `使用您可以承受损失的资金进行交易非常重要。 不幸的是，您的收入和/或储蓄不符合我们的内部标准，因此我们无法为您开设账户。 请检查您是否提供了正确的信息。 如果您有任何疑问，请与我们联系。`,
            result: '查看结果',
            retry: '重试',
            please_try_after: '请{time}后再试',
            questionnaire_count_down: '您已用完本次问卷测试可尝试次数，请{time}后再继续。',
            popup_tip_title: '提示弹窗',
        },
        stepFour: {
            login_name: '登录名',
            login_rules: '* 6到16个字符，可以是数字，小写字母，大写字母，下划线',
            account: '交易账户类型',
            leverage: '交易账户杠杆',
            currency: '交易账户货币',
            password: '密码',
            captcha: '验证码',
            change_one: '换一个',
            not_changed_account_name: '*账号建立成功后将不可被修改',
            trading_platform: '交易平台',
        },
        complete: {
            title: '会员账号注册完成',
            text_one: '感谢您选择%{companyName}作为您的投资伙伴。我们的工作人员会尽快联系您。您将收到的邮件如下:',
            text_two: '同时，如果您有任何问题，请随时联系我们的客服邮箱:',
            login_to_portal: '好的',
        },
        id: {
            malaysia_national_id: '马来西亚国民身份证',
            vietnam_national_id: '越南国民身份证',
            philippines_credit_bureau: '菲律宾居民证明',
            cambodia_national_id: '柬埔寨国民身份证',
            indonesia_resident_identity_card: '印尼居民身份证',
            south_africa_national_id: '南非国民身份证 ',

            australia_driving_licence: '澳大利亚驾驶证',
            australia_medicare: '澳大利亚医保',
            australia_short_passport: '澳大利亚护照',
            international_passport: '国际护照',
            identification_card: '中国居民身份证',
            // 中国居民身份证（Deep Finch Aliyun） 不是相同翻译，一个是使用阿里云，一个不是
            deep_finch_aliyun: '中国居民身份证',
            hk_id_card: '香港身份证',
            no_certificate: '我没有以上证件',
        },
        error: {
            mobile_error: '请输入正确电话号码',
            null_error: '输入不能为空',
            email_error: '请输入正确电子邮件',
            num_error: '请输入数字',
            id_name_error: '需要输入字母或数字',
            name_error: '只能输入英文字母',
            no_year: '您不满足开户所需的最低年龄%{year}',
            number_count: '只能输入一个数字',
            id_length: '必须输入18字符',
            passport_min_length: '输入至少7个字符',
            passport_max_length: '最多可输入44个字符',
            chinese: '不能输入汉字',
            china_count: '请输入汉字',
            id_card: '请输入正确的身份证号码',
            en_error: '请输入英文大写',
            postcode_error: '请输入正确邮编',
            tip: '请输入手机号码',
            mobile_num_error: '请输入至少8位电话号码',
            enter_number: '只能输入数字',
            please_enter_your_field: '请输入您的%{field}',
            please_enter_voter_id: '请输入有效的Voter ID',
            please_finish_all_required_questionnaires: '请完成所有必填问题',
        },
        processing: '我们正在处理您的创建账户请求，请不要离开此页面',
        error_wrong: '创建账户的请求出现问题',
        error_account: '',
        error_content: '对于给您带来的不便，我们感到很抱歉。 您可以联系您的客户经理或通过电子邮件联系我们。',
        go_back: '返回 >',
        btn_back: '返回',
        btn_next: '下一步',
        btn_submit: '提交',
    },
    transfer: {
        popup: {
            title: '正在内转',
            from: '从',
            to: '到',
            trading_account: '交易账号',
            fund_out_amount: '转出金额',
            exchange_rate: '汇率',
            payment_amount: '支付金额',
            surcharge: '手续费',
            fund_in_amount: '转入金额',
            to_symbol: '(到)',
            from_symbol: '(从)',
            balance: '余额',
            credit: '赠金',
            equity: '净值',
            margin: '已用保证金',
            free_margin: '可用保证金',
            margin_level: '保证金比例',
            transfer_successfully: '内转交易提交成功!',
            comment:
                '*在执行转账操作之前，请确保所支付的金额及收款人信息无误。如果您提供的信息存在错误，我们不会承担相应的责任，也不会对由此产生的任何财务或其他类型的损失承担赔偿责任。为避免任何不便，请确保提交信息的正确性。',
        },
    },
    transfer_sub: {
        safety_tips: '安全提示',
        add_now: '立即添加',
        safety_text: '为了您的资金安全，请前往账号设置添加验证器！',
        email: '被转账人邮箱',
        same_account_error: '转出账户和转入账户不能相同。',
        trading_account: '被转账人交易账号',
        not_config_rate: '当前汇率没有配置，请联系客服处理。',
        transfer_sub_title: '您正在向 {display} 的交易帐户 {account} 转账。',
        insufficient_amount: '可用金额不足。',
    },
    withdrawal: {
        popup: {
            title: '正在出金 - {serve}-{account}',
            fund_out_amount: '出金金额',
            payment_currency: '支付货币',
            bank_info: '已保存的出金信息',
            bank_name: '银行名称',
            bank_branch_name: '银行分行',
            bank_province: '银行所属州(省份)',
            bank_city: '银行所属城市',
            bank_account_number: '银行账号',
            bank_account_name: '银行账号名称',
            bsb: 'BSB',
            bank_address: '银行地址',
            international_bank_code: '银行国际代码',
            international_bank_code_au: '银行国际代码',
            account_address: '账户持有人地址',
            account_name: '账户持有人名字',
            abn: '澳大利亚商业编号',
            saved_fund_info: '保存出金信息',
            get_code: '获取验证码',
            send_code: '验证码已发送到您的邮箱，有效期为 {minutes} 分钟，请尽快输入该验证码完成验证操作。',
            address_is_duplicate: '此地址已被其他用户注册。',
            usdt_format: 'USDT地址只允许包含数字和字母。',
            iban: '银行账号/国际银行账号',
            swift_code: '(Swift Code)',
            sort_code: '银行区号',
            optional_uk: '建议英国客户填写',
            set_name: '出金信息别称',
            withdrawable_successful: '出金交易提交成功!',
            not_allowed_0: '金额不能为0',
            can_not_be_empty: '不能为空',
            number_only: '只允许输入不超过两位小数的正数',
            AU: '澳大利亚',
            Global: '全球',
            can_number_only: '只能输入数字',
            account_number: '账户号码',
            fund_info: '支付信息',
            neteller_account: 'Neteller账号',
            fasapay_account_number: 'Fasapay 账号号码',
            skrill_account: 'Skrill 账号',
            sticPay_account: 'SticPay 账号',
            id_upload_message: '由于您是首次提交出金申请，我们需要您在下方上传身份证明文件进行验证，验证通过后即可进行取款申请提交。该程序只需要进行一次。',
            id_upload_title: '请提供以下信息才能继续',
            id_upload_pending_title: '您的证件正在核实中',
            id_upload_pending_message: '当验证完成时，您将收到一封电子邮件。',
            bank_account_number_4_characters: '银行账号必须大于等于4位数字。',
        },
        email_verification: {
            email_verification_title: '邮箱验证',
            email_verification_text: '请输入邮件中的验证码完成验证。',
            use_authenticator_to_verify: '使用验证器进行验证。',
        },
    },
    funding: {
        cant_deposit_ib: 'IB交易账号不能入金。',
        cant_deposit_ib_transfer: 'IB交易账号不能接受非IB交易账号转入。',
        error_message: '请输入入金金额',
        title: '资金管理',
        trading_account_list: '交易账户列表',
        demo_account_list: '模拟交易账户列表',
        mam_account_list: '多账户管理账户列表',
        side_wallet_currency: '货币',
        side_wallet_balance: '余额',
        wallet_list: '钱包账户列表',
        ib_list: 'IB钱包账户列表',
        type: '类型',
        trading_server: '交易服务器',
        trading_account: '交易账户',
        account: '账号',
        status: '状态',
        currency: '货币',
        balance: '余额',
        equity: '净值',
        withdrawable_amount: '可出金金额',
        credit: '赠金',
        notes: '备注',
        deposit: '入金',
        withdrawal: '出金',
        transfer: '内转',
        transfer_1: '转账',
        saved_info: '已存支付信息',
        choose: '选择',
        action: '操作',
        method: '方式',
        processing_time: '处理时间',
        wallet_deposit: '钱包入金',
        wallet_withdrawal: '钱包出金',
        wallet_transfer: '钱包内转',
        wallet_dialog_title: '选择货币',
        fees: '相关费用',
        ib_popup_title: '选择IB账号',
        add_new_payment_info: '添加新的付款信息',
        select_payment_info: '选择已验证的付款信息',
        verify_payment_info_tips: '我们需要先验证您的付款信息',
        thank_you: '谢谢!',
        success_text: '谢谢。您的取款请求已收到，我们将尽快处理。处理完成后，您将收到一封确认电子邮件。',
        check_history: '查看历史记录',
        transaction_processing: '交易处理中',
        dear_client: '尊敬的客户，',
        duplicate_reminder: '您提交的出金申请正在处理中。请在2分钟后再提交新的出金申请。',
        confirm_usdt_title: '尊敬的客户，',
        confirm_usdt_address_content: '请核实您所输入的USDT地址 ({USDTAddress}) 的准确性。不准确的地址信息可能导致财务损失。',
        ta_placeholder: '请选择一个交易账号',
        dialog_box: {
            deposit: '正在入金 - ',
            deposit_via: '正在使用{method}方式入金',
            withdrawal: '正在出金 -',
            withdrawal_via: '正在使用{method}方式出金',
            saved_fund_info: '已存支付信息 - ',
        },
        funding_ev_dialog: {
            face_qr_tip: '请使用您的手机扫描QR码进行人脸识别',
            face_qr_mobile_tip: '请点击下一步进行人脸识别',
            face_qr_scanning_tip: '扫描成功，正在进行人脸识别...',
            face_qr_scanning_mobile_tip: '正在进行人脸识别...',
            face_qr_success_tip: '人脸识别已验证',
            face_qr_fail_tip: '人脸识别验证失败',
            face_qr_timeout_tip: '人脸识别超时',
            new_win_confirm_tip: '请在新窗口进行人脸认证',
            recognition: '重新识别',
            complete_face_tip: '请完成人脸识别',
            no_need_face_tip: '无需进行人脸识别',
            title: '入金用于',
            message1: '若要通过此渠道入金，请选择以下身份验证方式。',
            auto_verify: '自动验证',
            auto_verify_tip: '一旦通过自动验证，您就可以获得即时存款访问权限。',
            show_upload_selfie: '请上传您持有ID的自拍',
            upload_selfie_tip: '一旦我们的工作人员完成您的身份验证，您将获得入金访问权限。',
            self_taken_photo: '自拍照片',
            message21: '请上传您手持身份证或护照的照片。',
            message22: '所有带照片的身份证文件必须有效，并清楚显示您的全名、出生日期和签发/到期日期以供核实。',
            upload_selfie: '上传自拍照片',
            submit: '提交',
            upload: '上传文件',
            message31: '请提供以下信息以继续。',
            message32: '提供的信息似乎无效。请在提交前检查并更正输入内容。',
            message4: '很遗憾，您的自动验证失败，且已超出最大尝试次数，请您上传手持身份证自拍以开通此入金通道。',
            ev_succeed: '电子验证成功。',
            ev_failed: '电子验证失败。',
            upload_success: '我们已收到您上传的手持ID自拍，请耐心等待验证结果，验证时间大约为10~30mins，结果可在协议及文档板块查询.',
        },
        deposit_popup: {
            country_code: '国家区号',
            mobile_number: '手机号码',
            deposit_for: '正在入金 - ',
            register_this_card: '保存入金信息',
            set_name: '入金信息别称',
            upload_photo: '上传图片',
            bank_receipt_required: '请上传银行凭证',
            bank_receipt_upload: '银行凭证上传',
            fund_in_amount: '入金金额',
            exchange_rate: '汇率',
            surcharge: '手续费金额',
            payment_amount: '付款金额',
            transfer_amount: '转账金额',
            payment_card: '支付卡',
            transfer_currency: '支付币种',
            bank: '银行',
            voucher_no: '代金券号码',
            voucher_key: '代金券密匙',
            voucher_currency: '代金券货币',
            voucher_value: '代金券价值',
            card_number: '卡号',
            card_holder_name: '持卡人名字',
            expiry_date: '有效期',
            cvv_description: 'CVV是打印在卡片背面签名面板上的三位数字。',
            usdt_tip_1: '1. 请勿保存或转入到已使用过的旧地址。',
            usdt_tip_2: '2. 请使用正确的协议（{usdtType}）进行支付，否则无法到账。',
            usdt_tip_3: `3. 请确保您转账的 USDT 实际金额（不包括转账费和其他费用）与支付页面上显示的 "订单金额 "精确匹配，精确到小数点后的每一位。`,
            usdt_tip_4: '4. 转账完成后，请提交转账凭证以完成交易。',
            usdt_time_tip: '请在{minutes}分{seconds}秒内按照以下订单完成转账',
            usdt_address: '地址',
            embed_disclaimer:
                '若您使用页面中的二维码或支付链接进行支付，即表示您选择并自愿承担使用第三方支付服务的风险。您理解并同意，第三方服务可能存在技术误差、操作错误或延时等问题。若由于传输错误、技术缺陷、设备故障、网络设备遭非法干预、网络过载、第三方恶意阻断访问、互联网故障、服务中断或互联网服务提供商的其他系统错误等的情况，导致您在使用第三方支付服务时，遭受任何形式的损失、损害或额外成本，我们将不承担任何责任。',
            payto: {
                create_payment: '创建新的支付协议',
                canceled_payment: '已取消的支付协议',
                payto_notice_one: '根据国际反洗钱法的规定，TTG不接受任何第三方入金，请您用本人名下的银行账户创建支付协议。',
                payto_notice_two: '仅限使用您在网上或手机银行中已授权的活跃支付协议进行支付。',
                payto_notice_three: '每笔新入金都需要从客户门户重新提交订单。如果您未发起入金请求，资金不会自动从您的银行账户中扣除。',
                payto_notice_four: 'PayTo协议中规定了单笔入金限额和付款频率限制。请注意，您可以通过 PayTo 支付的金额也受到您的银行账户的限制。',
                success_message: '请在您的网上银行或手机银行中授权此支付协议。',
                limit_message: '您已达到协议数量上限。请使用现有的支付协议进行支付。',
                exist_message: '您所提供的银行账户已存在一个支付协议。请使用其他银行账户。',
                fail_message: '提供的银行账户无效或不符合PayTo支付条件。请仔细核对您的账户信息后重试。',
                pending_tip: '在您的网上银行或手机银行中授权此支付协议后，',
                paused_tip: '在您的网上银行或手机银行中恢复此支付协议后，',
                tip_2: '请刷新当前卡片获取最新状态！',
                refresh_success: '支付协议已激活，可用于支付！',
                refresh_pending: '请在您的网上或手机银行中授权此支付协议，并再次尝试刷新！',
                refresh_paused: '请在您的网上或手机银行中恢复此支付协议，并再次尝试刷新！',
                status_cancelled: '已取消',
                status_active: '已激活',
                status_pending: '待授权',
                status_paused: '已暂停',
                payment_agreement: '支付协议',
                got_it: '好的',
                deposit_success: "您的支付正在由PayTo处理中。<br />请稍后前往'资金报告'查看最新的交易状态。",
                deposit_failed: '支付失败，请检查银行账户或稍后再试。',
                deposit_processing: "您的支付正在由PayTo处理中。<br />请稍后前往'资金报告'查看最新的交易状态。",
            },
            double_check: '请仔细核对并按照该金额进行转账，否则有可能入账失败。',
            speed_up: '上传转账凭证将会加速USDT充值到账，我们会优先处理带有凭证的订单。',
            submit_credentials: '提交转账凭证',
            please_click_tip: '*转账完成后，请点击以下按钮提交转账凭证',
            requirements_receipt: '凭证要求：',
            requirements_receipt_app: '加密货币交易所、个人钱包等App的提币记录截图 {number}张，要求{transferAmount}、{hashId}清晰可见；',
            requirements_receipt_app_content: '转账金额',
            requirements_receipt_cold: '如果您使用冷钱包转账，则需要上传 {number} 张凭证，分别是{address}、{hashId}',
            requirements_receipt_cold_address: '个人钱包地址交易流水',
            requirements_receipt_cold_hash: '公链截图（带有 hash ID）',
            upload_limit: '最多上传两个文件，请重试',
            hash_id: 'hash ID',
            confirm_proceed: '确认并继续',
            exchange_rate_changed: '请注意！汇率已更改。',
            updated_amount_note: '更新后的金额如下：',
            exchange_rate_info_0: '支付金额 {currency}：{number}',
            exchange_rate_info_1: '汇率：{number}',
            exchange_rate_info_2: '入金金额 {currency}：{number}',
        },
        recently_used: '上次使用',
        add_as_my_favourite: '添加至收藏',
        remove_as_my_favourite: '从收藏移除',
        more_deposit_method: '更多入金方式',
        more_withdrawal_method: '更多出金方式',
        current_form: '当前（从）',
        after_transfer_out_from: '内转后（从）',
        current_to: '当前（到）',
        after_transfer_out_to: '内转后（到）',
        indicative_rate: '参考汇率',
    },
    trading_account: {
        wallet: '钱包',
        IB: '代理',
        wallet_account: '钱包账户',
        apply_new_account: '添加新账户',
        additional: '额外信息',
        not_account: '请您先在“首页”板块申请交易账户及完成身份认证以创建交易账户，',
        please_create_account: '交易账户创建成功后将自动开通资金管理功能。',
        message_center: '消息中心',
        todo: '待办事项',
        live_title: '申请真实交易账户',
        demo_title: '申请模拟交易账户',
        live_table_title: '真实交易账号',
        demo_table_title: '模拟交易账号',
        mam_table_title: '多账户管理账号',
        live_content: '您的真实交易账户将在身份验证完成后创建。',
        live_content_second: '请确保您的身份证明文件已经在“协议及文档”版块中上传。',
        demo_content: '一步即可开通模拟交易账户。',
        file_title: '验证您的账户',
        file_content: '我们需要验证您的证明文件以激活您的真实交易账户。',
        personal: '个人信息',
        questionnaire: '问卷',
        detail: '账号配置',
        continued: '继续',
        welcome: '欢迎, {userName}',
        apply: '申请HUBx',
        upgrade: '会员升级',
        apply_new_live_ta: '申请真实交易账户',
        apply_new_demo_ta: '申请模拟交易账户',
        type: '类型',
        trading_server: '交易服务器',
        trading_account: '交易账户',
        name: '姓名',
        TA_status: '账户状态',
        leverage: '杠杆',
        currency: '货币',
        credit: '赠金',
        balance: '余额',
        equity: '净值',
        floating_profit: '浮动盈利',
        stop_out: '强行平仓',
        notes: '备注',
        settings: '设置',
        settings_wallet: '设置',
        dis: '禁用',
        en: '启用',
        ro: '只读',
        ac: '归档',
        archived_trading_account: '归档交易账户',
        apply_success: '申请HUBx成功',
        HUBX_tip: '您的HUBX客户协议可以在',
        wallet_points: '钱包 - 积分',
        filter_type: '筛选选项',
        filters: '筛选',
        more: '更多',
        new_account: '新账户',
        account_type: '账户类型',
        mam: {
            master: 'MAM主账户',
            sub: 'MAM子账户',
            mam_type: '多账户管理账号类型',
            member_name: '会员名字',
            mam_status: '多账户管理账号状态',
            invitation: '发起邀请',
            invitation_notification: 'MAM邀请通知',
            invitation_sub: '发送邀请',
            active: '已激活',
            deactivated: '未激活',
            change_password: '更改密码',
            action: '操作',
            submit_success: '您已成功提交申请。',
            detach_from_mam: '申请MAM解绑',
            detach_confirm_title: 'MAM解绑申请',
            detach_confirm_subTitle_1: 'MAM子账户： ',
            detach_confirm_subTitle_2: 'MAM主账户： ',
            detach_confirm_content_1: '解绑后，您的MAM子账户将被停用并与MAM主账户断开连接。',
            detach_confirm_subTitle_3: '关于任何未平仓头寸',
            detach_confirm_content_2: `如果在解绑过程中MAM子账户仍有未平仓头寸，这些头寸将在MAM主账户中相应头寸关闭时自动平仓。在此期间，MAM主账户中开立的任何新仓位都不会影响您的MAM子账户。如果您希望立即平仓并退出，请使用注册邮箱发送到support{'@'}threetrader.com联系我们。`,
            mam_invitation: 'MAM 绑定邀请',
            invitation_content: '您有一份来自{name}的MAM绑定邀请',
            invitation_from: '邀请人',
            master_account: '主账号',
            agree_text: '我已阅读并且同意以上内容',
            confirm_reject: '你确认要拒绝此邀请吗？',
            reject: '拒绝',
            accept: '接受',
        },
        apply_mam: '申请多账户管理账号',
        popup: {
            deposit_title: '入金',
            apply_title: '申请新真实交易账号',
            apply_wallet_title: '申请新的钱包账户',
            node: '节点',
            choose_parent_title: '选择代理 >>',
            apply_new_parent: '代理',
            apply_new_server: '选择新账号在',
            leverage: '杠杆',
            currency: '货币',
            trading_platform: '交易平台',
            account_type: '账户类型',
            set_pwd_title: '设置密码 >>',
            set_hub_pin: '设置HUBx PIN >>',
            confirm_pin: '确认PIN',
            new_pwd: '新密码',
            new_pin: '新PIN',
            pin_require: '*4-6个字符，必须包含数字或字母。',
            confirm_pwd: '确认密码',
            set_invest_pwd_title: '设置观摩密码',
            set_trading_pwd_title: '设置密码 >>',
            edit_notes_title: '编辑备注 >>',
            edit_notes: '编辑备注',
            note_require: '*不能超过20字符',
            invest_password_not_equal: '输入的观摩密码不一致',
            setting_title: '{trading_server} {trading_account} 设置',
            setting_wallet_title: '{name} {account} 设置',
            choose_leverage_title: '选择杠杆',
            choose_account_details: '账号详情',
            choose_leverage: '选择从 1:{leverage} 到',
            change_password_title: '更改密码',
            pwd_type: '密码类型',
            trading_pwd: '交易密码',
            invest_pwd: '观摩密码',
            hubx_pwd: 'HUBx 独立跟单密码',
            cancel_confirm: '您确定要取消吗?',
            create_successfully: '申请新账号成功！',
            optional: '(可选)',
            update_success: '更新成功！',
            trading_password_not_equal: '交易密码不一致，请确认您的密码。',
            confirm_update_leverage: '杠杆更改将可能导致您账户的保证金状况发生改变。请问是否确认要更改杠杆？',
            confirm_update_password: '您确定要重置密码吗？',
            confirm_update_pin: '您确定要重置PIN吗？',
            update_password_success: '密码重置成功！',
            update_pin_success: 'PIN重置成功！',
            update_leverage_success: '您的杠杆申请已提交。请耐心等待。',
            cancel_leverage_success: '取消成功！',
            apply_demo_title: '申请新模拟交易账号',
            demo_trading_server: '交易服务器',
            deposit: '入金',
            hub_pin_not_equal: '输入的HUBx PIN不一致',
            inherit_agent: '跟随代理',
            application_password: '账户密码',
        },
        mam_popup: {
            apply_title: '提交MAM主账户申请，',
            apply_title_name: '更高效、更专业地管理您的交易',
            apply_sub_title: 'MAM（多账户管理器）',
            apply_sub_title_content_main:
                'MAM（多账户管理器）是一种简化和优化专业投资者、基金经理或资产管理团队处理多个账户交易管理的工具。使用MAM，主账户管理者只需在主账户上执行交易，这些交易会自动被复制并按设置比例在所有连接的子账户上执行。',
            apply_sub_title_bold: '适合用户:',
            apply_sub_title_content: ' 资管公司或团队，资深交易员，基金经理等。',
            apply_sub_title_benefits: '工具优势',
            apply_sub_title_benefits_bold_1: '简化交易管理：',
            apply_sub_title_benefits_content_1: '通过使用MAM，您能够同时管理多个账户，而无需为每个账户单独执行交易指令，为您节省宝贵的时间和精力。',
            apply_sub_title_benefits_bold_2: '高效执行交易：',
            apply_sub_title_benefits_content_2: 'MAM将主账户的交易指令自动复制并执行到所有子账户，确保所有账户能够保持相对固定的比例。',
            apply_sub_title_benefits_bold_3: '资金灵活分配： ',
            apply_sub_title_benefits_content_3: '您可以根据需要自由地将资金分配到各个子账户，满足不同客户的需求和风险偏好。',
            apply_sub_title_for: '如何申请',
            apply_sub_title_for_content: '如果您希望获得MAM工具的强大功能，只需点击申请，我们将为您提供详细的开通条件信息。满足条件后，您将享受到MAM带来的全方位功能和优势。',
            apply_sub_title_qualification: '激活资格',
            apply_sub_title_qualification_content: '当所有子账户的总存款达到$20,000美元后，我们将激活主账户资格，并发送MAM管理器的许可证。',
            apply_mam_third_party_disclaimer: '第三方软件（多账户管理器）免责声明',
            apply_mam_forbidden: '抱歉，您只能同时申请四个MAM主账号，请等待我们处理完后再提交新的申请。',
        },
        mam_leverage: {
            leverage_title: '交易平台和杠杆设置',
            leverage_title_sub: '多账户管理账号',
            apply_mam_confirm_title: '申请提交成功',
            apply_mam_confirm_content: '我们已收到您的多账户经理 (MAM) 申请，一旦您的申请获得批准，我们将通过邮件通知您。感谢您的耐心等待。',
            create_mam_successfully: '您的新MAN帐户已成功创建。',
            create_mam_failure: '抱歉，创建新的 MAM 帐户时出现错误，请联系我们寻求帮助。',
        },
        no_available_ta: `您当前无可用交易账号。请联系support{'@'}threetrader.com。`,
    },
    mam_template: {
        title: 'MAM邀请模板管理',
        add_new_template: '新增邀请模板',
        template_name: '模板名称',
        mam_commission_account: '收取佣金账号',
        management_fee: '管理费类型',
        fixed: '固定',
        fix_note: '美元',
        flat: '统一',
        flat_note: '美元每手交易量',
        percentage: '比例',
        percentage_note: '的账户余额',
        fixed_management_fee: '按固定金额收取管理费',
        flat_management_fee: '按交易量收取管理费',
        percentage_management_fee: '按比例收取管理费',
        management_fee_payment_frequency: '管理费支付周期',
        weekly: '每周',
        fortnightly: '每两周',
        monthly: '每月',
        profit_sharing_methods: '利润分配方案',
        high_water_mark: '高水位法',
        not_sharing: '不分润',
        performance_fee: '绩效费比例',
        percentage_rate_over: '最大1%',
        performance_fee_over: '最大50%',
        performance_fee_payment_frequency: '绩效费支付周期',
        mam_account_create_success: 'MAM邀请模板创建成功',
        mam_account_edit_success: 'MAM邀请模板保存成功',
        mam_account_delete_success: 'MAM邀请模板删除成功',
        management_fee_introduction: '若年化管理费率超过12%，您同意并接受此费率将固定为12%，超出部分不予考虑。',
        mam_template_tutorial: 'MAM账号邀请模板创建教程',
        tips: {
            mam_commission_account: '收取佣金账号必填',
            required: '必填',
            performance_fee_must_greater_than_zero: '绩效费比例必须大于0。若无需分润，请在“利润分配方案”一栏选择“不分润”。',
        },
        list: {
            fixed_management_fee: '{fee} 美元',
            flat_management_fee: '{fee} 美元每手交易量',
            percentage_management_fee: '{fee}% 的账户余额',
            performance_fee: '{fee}% 的绩效费',
            performance_fee_sub: '{fee}%',
        },
        send_invitation: {
            title: '创建邀请',
            mam_master_ta: 'MAM主账号',
            based_currency: '货币',
            based_currency_detail: 'USD',
            client_email: '受邀请人邮箱',
            client_last_name: '受邀请人姓氏拼音',
            client_full_name: '受邀请人全名',
            invitation_template: '邀请模板',
            user_not_found: '您所查找的用户不存在',
            user_no_authorize: '该用户无法被邀请',
            user_pending_invitation: '您已邀请过该用户',
            contract_header: 'LPOA & ATP',
            contract_checkbox: '我已阅读并同意上述内容',
            confirm_dialog_title: '邀请已发送',
            confirm_dialog_content: 'MAM邀请已发送，对方接受邀请后，子账户将显示在您的主账户下方。感谢您的耐心等待。',
            send_invitation_failure: '抱歉，暂时无法处理您的请求，请联系我们寻求帮助。',
            last_name_required: '受邀请人姓氏拼音必填',
        },
    },
    Tips: {
        email_verify: '邮箱已验证',
        email_not_verify_ta: '邮箱未验证，请于验证后进行开户',
        email_not_verify: '邮箱未验证',
        email_already_verify: '邮箱已验证，请勿重复请求',
        email_verify_not_access: '请于登录后进行邮箱验证',
        email_verify_repeat: '邮箱已验证，请勿重复请求',
        email_resend_second_tip: '没有收到邮件？请查看垃圾邮件文件夹或于{second}秒后重新获取',
        email_resend_tip: '没有收到邮件？请查看垃圾邮件文件夹或',
        resend_email: '重新获取',

        minimum_deposit_tips: '最低入金金额为 {currency}  {amount}。在符合最低入金金额之前，您创建的交易账户的状态会被设置成只读。',

        code_should_characters: '验证码需为6位字符',
        demo_deposit_limit_1: '最多还可入金{currency} {amount}',
        demo_deposit_limit_2: '账号余额上限{currency} {amount}',
        demo_deposit_limit_3: '单笔入金上限{currency} {amount}',
        demo_deposit_limit_4: '最少入金{currency} {amount}',

        querying_tips: '正在查询中，请稍后再尝试',
        export_all_used: '正在处理"{sectionName}"板块的"{typeName}"的报告，请稍后再尝试!',
        csv_content_error: '文件下载发生错误',
        exporting_close_tip: '正在导出所有用户，请不要刷新或者关闭这个浏览器窗口',
        export_list_timeout: '操作已超时，导出csv失败',
        csv_generate_tip: '文件正在生成中，请稍后!',
        no_data_export: '没有数据导出',

        cn_3d_phone_number: '请输入您的手机号（不包含国家代码）',
        contact_admin_tips: '请联系管理员',
        view_csv_limit: '最多只显示 {count} 条订单，请下载 CSV 以查看所有的订单',
        please_select_a_jurisdiction: '请选择监管',
        use_number_birth_date: '请使用数字填写{birthDate}',
        agree_trading_tools_tips: '您需要同意使用条款才能使用本功能。',
        enter_date_of_birth: '请输入出生日期',
        enter_one_time_password: '请输入一次性密码',
        enter_verification_code: '请输入验证码',
        use_number_only: '只能输入数字',
        enter_valid_date: '请输入有效日期',
        age_limit: '您不满足开户所需的最低年龄',
        can_not_find_deposit_template: '抱歉，查找不到入金模板',
        config_error: '配置不正确',
        question_try_again: '您上次未通过测试，请重试',
        select_jurisdiction: '请选择一个牌照',
        view_csv_search: '搜索交易账户,名字或返佣组别名称',
        loading_not_submit: '正在加载新页面，请不要重复提交',
        load_lang_error: '加载语言文件失败',
        password_tip: '密码长度必须为 8-15 个字符，且至少包含 1 个数字、1 个特殊字符、1 个大写字母和 1 个小写字母, 特殊字符仅包含{chars}。',
        file_upload_number_limit: '最多只能上传不超过%{num}份文件',
        length_min_tips: '长度不能小于%{num}位',
        upload_type_tips2: '仅接受.jpg.png文件',
        select_account_to_view: '请选择您想要查看的账户',
        one_close_confirm: '是否确认关闭所有标签？',
        deposit_payment_text: '请您在新页面中进行付款。',
        enter_trading_account_to_view: '请输入一个交易账户进行查看',
        trading_account_not_exit: '*交易账户不存在',
        rebate_report_status_refresh_hint: "'处理中' 的报告申请将需要30-60秒，请点击 '刷新' 按钮来更新状态",
        csv_data_error: '无法获取到数据',
        file_format_only: '仅限大小不超过%{size}M的%{files}文件',
        file_type_only: '仅接受%{files}文件',
        expiration_time_tips: '有效期不能小于当前时间',
        copy_link: '复制成功!',
        please_enter_your_account: '请输入您的邮箱地址。',
        please_enter_your_password: '请输入密码',
        please_enter_your_verification: '请输入验证码',
        two_password_errors: '两次输入密码不一致',
        number_length_tips: '数字不能大于8位',
        number_positive_tips: '请输入正数',
        number_point_tips: '小数不能超过%{num}位',
        can_not_empty: '输入不能为空',
        number_tips: '请输入数字',
        card_num_length_tips: '请输入有效的卡号',
        number_num_tips: '请输入%{num}位的数字',
        upload_type_tips: '仅接受.pdf.jpg.png.bmp文件',
        file_size_limit: '文件大小不可超过%{size}MB！',
        total_file_size_limit: '文件总大小不可超过%{size}MB!',
        upload_an_invalid_file: '您上传的文件无效，请重新上传！',
        submit_success: '提交成功',
        submit_doc_success: '提交成功',
        save_success: '保存成功',
        global_trading_server: '请选择交易服务器',
        only_english_letters: '只能输入英文字母',
        letter_number_tips: '请输入正确的格式',
        only_enter_number_english: '只能输入数字，或者字母',
        cannot_enter_numbers: '不能输入数字',
        BSB: '请输入六位小数',
        enter_email: '请输入您的邮箱',
        enter_correct_email: '请输入正确的邮箱',
        no_data: '没有数据',
        records: '*%{allRecords}条中的<u>%{record}</u>条',
        records_other: '*共有 %{allRecords}条记录',
        deposit_submit_success: '您已成功提交入金申请',
        deposit_submit_fail: '您的入金申请提交失败',
        deposit_success: '入金成功',
        deposit_fail: '入金失败',
        update_pwd: '是否更新密码？',
        add_new_ta: '是否添加新的账户？',
        add_new_wallet: '是否添加新的钱包账户?',
        update_success: '更新成功！',
        create_success: '创建成功!',
        add_successfully: '添加成功',
        delete_success: '删除成功！',
        update_success_password: '您的账户密码已更改，请重新登录。',
        change_email_success: '您的邮箱已成功修改为：{email}，请重新登录',
        not_enter_space: '不能输入空格',
        only_enter_lowercase: '只能输入小写字母',
        only_enter_capital: '只能输入大写字母',
        only_enter_letter: '只能输入字母',
        number_error: '只能输入数字',
        number_negative_zero_tips: '请输入小于等于0的数字',
        number_positive_zero_tips: '请输入大于等于0的数字',
        agreement_file_not_exist: '文件不存在',
        ta_change_success: '交易账户变更成功',
        can_not_export: '数据为空，不能导出！',
        different_trading_servers_in_crm: '我们检测到系统中不同服务器内存在相同的交易账号。请先进行树关系搜索，然后在树中选择您需要的目标账号生成订单报告。',
        reports_cannot_generated_demo_accounts: '无法为模拟帐户生成报告',
        flat_report_sent_success: '您申请的报告正在准备中，报告完成后我们将会邮件通知您前往下载。',
        you_cannot_apply_new_live_trading_account_now: `您当前无法申请真实交易账户，请联系 support{'@'}threetrader.com。`,
        you_cannot_apply_new_demo_trading_account_now: `您当前无法申请模拟交易账户，请联系 support{'@'}threetrader.com。`,
        you_cannot_apply_new_wallet_account_now: `您当前无法申请钱包账户，请联系 support{'@'}threetrader.com。`,
        you_cannot_apply_for_new_deposit_now: `您所选交易账号的入金功能已被关闭，请联系 support{'@'}threetrader.com。`,
        transfer_amount_error: '转账数量错误。',
        same_user_transfer_sub: '不能向同一用户的交易账号转账。',
        email_account_not_match: '邮箱和交易账号不匹配。',
        account_not_transfer: '无法向当前交易账号转账。',
        transfer_failed: '转账失败。',
        outstanding_order: '当前有未处理完成的订单，请稍后再试。',
        you_cannot_apply_for_new_withdrawal_now: `您所选交易账号的出金功能已被关闭，请联系 support{'@'}threetrader.com。`,
        you_cannot_apply_for_new_internal_transfer_now: `您所选交易账号的内转功能已被关闭，support{'@'}threetrader.com。`,
        you_cannot_apply_for_new_internal_transfer_sub: `您所选交易账号的转账至下级功能已被关闭，support{'@'}threetrader.com。`,
        please_complete_the_captcha: '请完成人机验证',
        please_enter_your_password_again: '请重新输入密码',
        please_enter_a_valid_email_address: '请输入有效的邮件地址',
        please_include_at_least_1_uppercase_letter: '需要包含 1 个大写字母',
        please_include_at_least_1_lowercase_letter: '需要包含 1 个小写字母',
        please_include_at_least_1_number: '需要包含 1 个数字',
        please_include_at_least_1_special_character: '需要包含 1 个特殊字符',
        password_character_limit: '特殊字符仅包含{chars}',
        Password_must_be_8_15_characters_in_length: '密码长度需要在 8 至 15 位之间',
        the_email_you_entered_has_already_been_registered: '您输入的邮箱已被注册。',
        The_link_expired_after_1_hour_or_has_already_been_used: '该链接已失效或已被使用。',
        not_found_group_tips: '我们正在处理您的创建账户请求，或请联系管理员',
        please_upload_verification_file: '请确认是否已上传所有要求的文件。',
        point_use: '您可以使用积分在商城兑换礼品。',
        attention: '提醒',
        report_confirm_text_paraOne: '尊敬的客户，',
        report_confirm_text_paraTwo: '由于此报告需要最多约一个半小时完成数据同步，当您选择当前时间作为报告结点时，部分最新数据或将不包括在此报告内。',
        please_fill_english: '请使用英文填写银行信息，不支持其他语言字符（如：ç 或 ã{noSpaceText}）。',
        please_fill_simplified_chinese: '请使用简体中文填写全部出金信息，不支持其他语言字符（如：a 或 ã{noSpaceText}）。',
        please_fill_number: '请使用数字填写。',
        space: ' 或空格',
        deposit_disabled: '您的入金功能已被关闭。',
        withdrawal_disabled: '您的出金功能已被关闭。',
        transfer_disabled: '您的转账功能已被关闭。',
        contact_support: `请联系 support{'@'}threetrader.com。`,
        exchange_error: '汇率错误，请联系客服。',
    },
    MemberManagement: {
        ActionMenu: {
            view_account: '查看账户',
            txn_report: '资金报告',
            recent_orders: '近期订单',
            rebase_report: '佣金报告',
            login_as: '登录',
        },
        incorrect_file_type: '该文件必须是以下类型的文件：txt，csv，xls，xlsx',
        member_name: '会员名: %{name}',
        search_title: '搜索交易账户',
    },
    FundingManagement: {
        type: {
            deposit: '入金',
            credit: '赠金',
            transfer: '转账',
            internalTF: '内转',
            internalTF_sub: '转账至下级',
            points: '积分',
            Rebate: '返佣',
            withdrawal: '出金',
            CFD: 'CFD',
            Rollover: 'Rollover',
            Misc: 'Misc',
            internal_TF: '内转',
        },
        SolveLaterList: {
            transaction_no: '交易单号',
            time_submitted: '提交时间',
            time_processed: '处理时间',
            time_completed: '完成时间',
            transaction_status: '交易状态',
            transaction_type: '交易类型',
            transaction_notes: '交易记录',
            member_name: '会员名字',
            country: '国家和地区',
            member_verification_status: '会员验证状态',
            payment_amount_in: '付款金额',
            to: '至',
            exchange_rate: '汇率',
            surcharge_amount: '手续费金额',
            fund_in: '资金金额',
            tradingAccount: '交易账户',
            trading_account_status: '账户状态',
            parent: '代理',
            trading_group: '交易组别',
            internal_notes: '内部备注',
            updated_by: '最后编辑',
            trading_server_comment: '交易服务器备注',
            transaction_time: '交易时间',
        },
        Dashboard: {
            Popup: {
                balance: '余额',
                equity: '净值',
                margin: '已用保证金',
                margin_free: '可用保证金',
                margin_level: '保证金比例',
                avaliable_amount: '可用金额',
                credit: '赠金',
                account_BSB: 'BSB',
                currency: '货币',
            },
        },
        History: {
            transactionTimeLabel: '交易时间',
            show: '显示',
            each_page: '(每页)',
            notVerified: '未验证',
            verified: '已验证',
            end_time: '结束时间不能为空',
            start_time: '开始时间不能为空',
            title: '所有交易记录列表',
        },
    },
    ClientsReport: {
        member_detail: '会员详情',
        pending_customers: '待操作用户',
        member_list: '会员列表',
        clients: '客户',
        ta: '交易账号',
        client_tip: '符合过滤条件的客户数量',
        ta_tip: '符合过滤条件的交易账号数量',
        member_type: '客户类型',
    },
    IB: {
        profile: {
            rebate_node: '组别及链接',
            referral_links: '自定义链接',
        },
        status_pending: '待操作',
        rejected: '已拒绝',
        referred_clients: '推荐客户',
        retail_clients: '普通客户',
        Live: '真实',
        Demo: '模拟',
        sales_link: '销售链接',
        ib_link: 'IB 链接',
        ib_referral_code: 'IB组别推荐码',
        language: '语言',
        register_node: '注册代理组别',
        register_ib: '注册关联代理',
        register_account_type: '注册账户类型',
        register_link_full: '注册链接',
        country: '国家和地区',
        jurisdiction: '牌照',
        // label for error message
        orders_type: '订单类型',
        generate_all_orders: '生成所有订单',
        generate_all_orders_tab: '所有订单',
        parent_title: '代理',
        sales_code_title: '销售代码',
        ta_server_title: '交易服务器',
        status_title: '账户状态',
        jurisdictionTitle: '牌照',
        member_detail_information: '会员详细信息',
        need_refresh_data: '数据发生改变，刷新页面后进行操作。',
        token_has_expired: 'Token已过期',
        the_token_has_been_blacklisted: '令牌已被列入黑名单',
        admin_user_not_exist: 'Admin user 不存在',
        incorrect_username_or_password: '您输入的邮箱或者密码错误',
        admin_Group_id_already_exist: 'Admin Group id 已存在',
        special_User_is_already_exist: 'special user 已存在',
        admin_group_not_exist: 'Admin group 不存在',
        code_is_already_exist: 'Code名称重复',
        no_mt4_account: '不存在的mt4账号',
        user_password_email_incorrect: '邮箱或者密码错误',
        ib_user_not_exist: '这个IB用户不存在',
        user_ID_is_required: '用户ID不能为空',
        trading_account_ID_is_required: '交易账户ID不能为空',
        file_does_not_exist: '请求的文件不存在',
        failed_to_get_oss_url: '获取文件资源地址失败',
        rebate_report_already_exists: '请求的佣金报告已存在',
        user_has_no_corresponding_children: '该用户没有对应的下级，无法生成对应数据',
        trading_account_does_not_exist: '不存在的交易账号',
        feature_not_open: '这个功能尚未开放！',
        select_user_tips: '请选择一个用户以查看详情！',
        ib_detail_information: 'IB详细信息',
        account_not_exist_portal: '账户在PORTAL中不存在',
        parent_account_not_exist: '这个代理IB交易账户不存在',
        trading_server_error: '交易服务器出错',
        the_currency_does_not_exist: '货币不存在',
        the_value_goes_wrong: '杠杆规则数据错误',
        the_leverage_does_not_exist: '杠杆不存在',
        the_set_name_does_not_exist: '组合不存在',
        the_country_does_not_exist: '国家不存在',
        the_trading_server_group_does_not_exist: '组别不存在',
        the_trading_server_does_not_exist: '交易服务器不存在',
        insufficient_credit: '信用不足',
        group_saved_failed: '组别添加失败',
        merged_all_report: '合并所有报告',
        merged_self_report: '合并个人报告',
        merged_direct_report: '合并直属报告',
        merged_full_report: '合并完整报告',
        trade_not_exist: '交易不存在',
        the_nodes_created_are_duplicated: '不可创建重复返佣组别',
        the_password_format_is_incorrect: '密码格式不正确',
        password_setting_failed: '密码设置失败',
        external_id_does_not_exist: '不存在的external_id',
        some_children_cannot_be_set: '存在子级，不能设置为非IB',
        customer_must_have_no_parent: '普通账户必须没有代理',
        the_account_must_be_a_leaf_node: '该账户有下级账户，不能移除上级',
        permission_already_exists: '权限已存在',
        role_not_exist: '角色不存在',
        platform_not_exist: '平台不存在',
        given_data_was_invalid: '请求参数无效',
        file_type_error: '文件类型不正确',
        system_customization_error: '系统自定义错误',
        operation_failure: '操作失败',
        user_disables_deletion: '该用户禁止删除',
        token_is_incorrect: '登录信息已失效',
        token_unauth: '未认证',
        token_grant_type: '不支持的授权类型',
        token_is_invalid: '刷新令牌无效',
        user_password_incorrect: '用户密码不正确',
        file_upload_failed: '文件上传失败',
        file_deleted_failed: '文件删除失败',
        agreement_file_name_cannot_be_repeated: '协议文件名不能重复',
        group_cannot_be_reused: '组别不可重复使用',
        mt4_id_cannot_be_reused: '交易账号不可重复使用',
        special_user_disabled: '用户已被禁用!',
        user_not_have_right_permissions: '用户没有正确的权限',
        permission_not_right: '权限关系不正确',
        system_error: '系统异常',
        network_error: '请求服务器失败,请再次尝试连接。',
        system_Verification_error: '验证码错误',
        system_Verification_failure: '验证码失效',
        user_rest_password_incorrect: '用户名或重置令牌错误',
        user_rest_password_email_expired: '重置密码邮件已过期',
        protocol_has_been_applied: '已经请求过HUB协议',
        the_pin_must_4: 'PIN至少4-6个字符',
        please_enter_PIN: '请输入PIN',
        two_password_errors: '两次密码输入不一致',
        search: '选择搜索',
        data_allowed_to_displayed: '仅允许显示20个数据！',
        choose_a_user: '请选择一个用户查看',
        tradingAccount: '交易账户',
        memberName: '会员名字',
        emailTitle: '电子邮件',
        registeredTime: '注册时间',
        tradingAccountStatus: '账户状态',
        verificationStatus: '认证状态',
        viewAccount: '查看账户',
        rebateReport: '佣金报告',
        flat_report: '全用户报表',
        flat_ledger_report: '全用户明细报表',
        flat_performance_report: '全用户绩效报表',
        TXNReport: '资金报告',
        MemberTXNReport: '会员资金报告',
        rebate_list: {
            name: '类别',
            IB_rebate: '代理',
            customer_rebate: '直客',
            rebate_rate: '佣金费率',
        },
        included_symbols: '包含的交易产品',
        orderReport: '订单报告',
        ib_orderReport: 'IB订单报告',
        phoneColumn: '联系方式',
        registeredTimeColumn: '注册时间',
        verificationColumn: '账户验证',
        QuickLinksColumn: '报告的快捷方式',
        sameOptionWarn: '相同选项不进行操作',
        changeAccountStatusText: '请确认是否更改此交易账户的状态？',
        changeStatusSuccess: '更改成功',
        viewMore: '查看更多',
        noMoreDataWarning: '没有更多数据了',
        statusChangeFaild: '状态设置失败',
        statusNoChange: '状态无需更改',
        generateMergedReport: '生成合并报告',
        selfReport: '个人报告',
        directReport: '直属报告',
        fullReport: '完整报告',
        fullOrdersReport: '完整交易报告',
        directBalanceReport: '直属资金报告',
        fullBalanceReport: '完整资金报告',
        switch_self_report: '切换个人报告',
        switch_direct_report: '切换直属报告',
        // switch_full_report: '切换完整报告',
        generateReport: '生成报告',
        // 0-粉色：未提供 1-绿色：已验证 2-黑色：未验证 case2183
        verified: '已验证',
        not_verified: '未验证',
        unverified: '未验证',
        pending: '未提供',
        enabled: '启用',
        readonly: '只读',
        disabled: '禁用',
        enabled_short: '启用',
        readonly_short: '只读',
        disabled_short: '禁用',
        // label for RebateReportList
        rebateReportTimeLabel: '请求历史时间过滤',
        requestID: '请求ID',
        report_type: '报告类型',
        rebate_summary_report: '佣金汇总报表',
        rebate_ledger_report: '佣金明细报表',
        rebate_performance_report: '佣金绩效报表',
        requestTime: '请求时间',
        reportTime: '报告时间',
        requestHistory: '请求历史',
        requestStatus: '请求状态',
        downloadCSV: '下载CSV',
        download_report: '下载报告',
        viewCSV: '预览CSV',
        view_report: '查看报告',
        sendEMail: '发送邮件',
        select_report_time: '选择报告时间',
        complete: '完成',
        process: '处理中',
        delete: '删除',
        deleted: '删除',
        failed: '失败',
        recentWeek: '近几周',
        history: '历史',
        before: '%{time} 之前',
        quiz_result: '测试成绩',
        all: '所有',
        ib: '代理',
        clients_ib: '有代理的客户',
        registration_time: '注册时间选择器',
        time: '时间',
        ta_create_time: '交易账号创建时间',
        retail: '散户',
        view_summary: '查看总结',
        rebateReportType: {
            user_full: '合并佣金报告',
            user_self: '合并个人报告',
            account_self: '交易账户个人报告',
            account_full: 'IB账户报告',
            merged: '合并',
            full: '全代理报告',
            self: '个人报告',
            ib_rebate: 'IB佣金',
        },
        // label for ViewCSV
        view_csv: {
            total: '总计',
            sub_ib: '子代理',
            direct_clients: '直客',
            trading_account: '交易账户',
            sales_code: '销售代码',
            total_rebate: '总返佣',
            group: '组别',
            member_name: '会员名字',
            name: '名字',
            account_type: '账户类型',
            agent: '代理',
            position: '仓位',
            node_name: '返佣组别名称',
            deposit: '入金',
            withdraw: '出金',
            net_deposit: '净入金',
            internal_transfer: '内转',
            rollover_and_dividend: '展期与股息',
            others: '其他',
            credit: '赠金',
            swap: '隔夜利息',
            commission: '手续费',
            profit: '盈利',
            total_profit: '总盈利',
            from_true_equity: '报告起始净值',
            to_true_equity: '报告最终净值',
            eq_change: '净值变化',
            rebate: '返佣',
            forex_vol: 'FOREX',
            bullion_vol: 'BULLION',
            oil_vol: 'OIL',
            cfd_vol: 'CFD',
            crypto_vol: 'CRYPTO',
            shares_vol: 'SHARES',
            ib_trading_account: 'IB交易账户',
            ib_name: 'IB名字',
            client_trading_account: '客户交易账户',
            client_name: '客户姓名',
            ib_agent_number: '代理编号',
            platform: '平台',
            rebate_method: '返佣方式',
            ib_currency: '代理货币',
            client_platform: '客户平台',
            symbol: '符号',
            ticket: '交易编号',
            open_time: '开仓时间',
            close_time: '平仓时间',
            vol_traded: '交易量',
            contractsize: '合约规模',
            category_name: '品类名称',
            rebate_reval_rate: '返佣比例',
            rebate_reval_symbol: '返佣符号',
            rebate_rate: '返佣比例',
            actual_rate: '实际比例',
            true_equity: '真实净值',
            adjusted_trading_vol: '调整后的交易量',
        },
        mergedFullReport: '合并完整报告',
        parent: '代理',
        deposit: '入金',
        withdr: '出金',
        netDepo: '净入金',
        swap: '隔夜利息',
        credit: '赠金',
        commissions: '手续费',
        profit: '盈利',
        totalProfit: '真实盈利',
        trueEQF: '实际净值',
        trueEQT: '实际净值',
        EQChange: '净值变化',
        tradedLots: '交易手数',
        forexVol: '外汇',
        bullionVol: '贵金属',
        spotOilVol: '原油现货',
        CFDVol: '差价合约',
        cryptoVol: '电子货币',
        totalContributedRebates: '总贡献佣金',
        totalSelfRebates: '总自身佣金',
        nodeA_CateI: 'NodeA_CateI',
        noteB_CateII: 'NodeA_CateII',
        eMailaddr: '邮箱地址',
        sendReport: '发送报告',
        eMailSuccess: '发送成功',
        downloadSuccess: '下载成功',
        downloadFailed: '下载失败',
        startTimeOver: '开始时间不能晚于结束时间',
        end_time_need: '结束时间不能为空',
        start_time_need: '开始时间不能为空',
        noneTimeWarning: '请选择报告时间',
        noneTradingAccount: '请输入交易账号',
        closeTabInfo: '你刚打开的请求不能关闭',
        // label for RebateSetting_detail
        rebateSetting: '佣金设置',
        input_placeholder: '请输入内容',
        node_setting: '返佣组别设置',
        no_data: '当前账户中没有数据',
        connect_error: '与服务器连接错误，请稍后再试！',
        max_rate: '最高费率',
        fixed_rate: '固定利率',
        max_r: '最高',
        fixed_r: '固定',
        check_add_node: '检查 & 添加返佣组别',
        node_name: '返佣组别名称',
        group_select: '组别选择',
        maximum_characters: '*最多16个字符',
        rebate_rate_type: '佣金费率类型',
        default_trading_platform: '默认交易平台',
        rebate_currency: '佣金基准货币',
        rebate_rates_setting: '佣金费率设置',
        commission_rebates: '佣金结算方式',
        notional: '合约名义价值',
        allow_show_rates_setting: '允许在IB Portal上显示佣金费率设置',
        add_new_category: '添加新类别',
        new_category_name: '新类别名称',
        available_symbols: '可用货币兑',
        selected_symbols: '已选货币兑',
        from_IB: '代理',
        from_customer: '直客',
        check_current_nodes: '检查当前返佣组别',
        manual_add_new_node: '手动添加新返佣组别',
        check_result: '检查结果',
        empty_node: '空返佣组别',
        group_not_in_current_node: '组不在当前返佣组别中',
        go_to_node: '跳转返佣组别',
        add_node: '添加返佣组别',
        edit_node: '编辑返佣组别',
        bind_with: '绑定对象',
        group_a_select: 'A组选择',
        group_b_select: 'B组选择',
        group_a: 'A组',
        group_b: 'B组',
        default_group: '选择IB链接默认组',
        rebate_setting_detail_save_success: '佣金设置详情更新成功',
        node_name_cannot_be_empty: '返佣组别名称不能为空！',
        // label for OrdersReport
        account: '交易账户',
        close_order_history: '交易历史',
        order_time: '交易时间',
        account_name: '账户姓名',
        open_time: '开仓时间',
        order_type: '多单/空单',
        symbol: '交易产品',
        open_price: '开仓价位',
        close_time: '平仓时间',
        commission: '手续费',
        last_price: '最新报价/平仓报价',
        comment: '备注',
        to: '到',
        order_id: '订单号',
        volume: '手数',
        sl: '止损',
        tp: '止盈',
        total_comm: '总手续费',
        total_swap: '总隔夜利息',
        total_profit: '真实盈利',
        total_total_profit: '真实盈利总额',
        open_orders: '已开仓位',
        close_orders: '已平仓位',
        pending_orders: '挂单',
        buy: '多单',
        sell: '空单',
        buy_limit: '买入限价',
        sell_limit: '卖出限价',
        buy_stop: '买入止损',
        sell_stop: '卖出止损',
        buy_stop_limit: '买入止损限价',
        sell_stop_limit: '卖出止损限价',
        no_orders: '没有交易记录',
        symbol_type: '交易品种',
        name: '交易品种',
        comm: '手续费',
        fx: '外汇',
        bullion: '贵金属',
        oil: '原油',
        cfd: '差价合约',
        crypto: '数字货币',
        total: '总额',
        adjusted_volume: '标准化总手数',

        // label for TXN Report
        submitted_time: '提交时间',
        processed_time: '处理时间',
        submitted: '提交',
        processed: '处理',
        completed: '完成',
        transaction_status: '交易状态',
        application_status: '状态',
        processed_by: '处理人',
        transaction_type: '交易类型',
        transaction_notes: '转账注释',
        customer_notes: '客户注释',
        transaction_no: '交易单号',
        transaction_time: '交易时间',
        member_name: '会员名字',
        original_amount: '原始金额',
        exchange_rate: '汇率',
        surcharge_amount: '手续费',
        amount: '金额',
        trading_account: '交易账户',
        trading_account_status: '账户状态',
        member_verfication_status: '会员验证状态',
        trading_acct_status: '账户状态',
        trading_group: '交易组别',
        trading_server_comments: '备注',
        enable: '启用',
        readOnly: '只读',
        disable: '禁用',
        noData: '没有数据',
        ib_ta_data: '输入IB交易账户',
        multi: '- 多项值 -',
        export_name_ib: 'IB用户',
        export_name_member: '会员用户',
        export_name_pending_customers: '待操作用户',
        // lable for Transaction Detail
        td_pending: '待处理',
        td_complete: '完成',
        td_confirmed: '确认',
        td_canceled: '取消',
        td_deposit: '入金',
        td_transfer: '内转',
        td_leverage: '杠杆',
        td_credit: '赠金',
        td_withdrawal: '出金',
        td_rebate: 'Rebate',
        td_rollover: 'Rollover',
        td_cfd: 'CFD',
        td_misc: 'Misc',
        transaction_detail: '交易明细 - ',
        trading_server: '交易服务器',
        trading_server_type: '交易服务器类型',
        currency: '货币',
        balance: '余额',
        sales_code: '销售代码',
        equity: '净值',
        margin: '已用保证金',
        margin_free: '可用保证金',
        margin_level: '保证金比例',
        available_amount: '可用金额',
        transaction_number: '收支明细编号',
        record_number: '记录编号',
        ticket: '票号',
        ticket_from: '票号（从）',
        ticket_to: '票号（到）',
        trading_server_comment: '交易服务器备注',
        trading_server_comment_from: '交易服务器备注（从）',
        trading_server_comment_to: '交易服务器备注（到）',
        internal_notes: '内部备注',
        notes_to_customer: '客户须知',
        deposit_information: '存款信息 >>',
        payment_gateway: '支付网关',
        external_notes: '外部注释',
        payment_amount: '支付金额',
        surcharge: '手续费',
        deposit_amount: '入金金额',
        withdrawal_information: '出金信息 >>',
        with_amount: '出金金额',
        payment_detail: '支付详情 >>',
        bank_name: '银行名称',
        account_Name: '用户名',
        account_number: '账户',
        account_BSB: '账户BSB',
        bank_swift: '银行代码',
        bank_branch: '银行支行',
        bank_province: '银行所在省',
        transfer_information: '内转信息 >>',
        transfer_from: '从转移',
        transfer_out_amount: '转出金额',
        transfer_to: '转移至',
        transfer_in_amount: '转入金额',
        funding_information: '资金信息 >>',
        credit_fund: '赠金',
        fund_amount: '入金金额',
        with_credit: '赠金出金',
        deposit_credit: '打入赠金',
        original_leverage: '原始杠杆',
        change_leverage_to: '更改杠杆',
        // report title
        reportTitle: {
            RequestList: '报告列表',
            RebateReportList: '佣金报告',
            TXNReport: '资金报告',
            RecentOrders: '近期订单',
            MergedReport: '合并报告',
        },
        settingTitle: {
            AccountDetail: '账户详情',
            MemberVerified: '客户认证',
            RebateSetting: '佣金设置',
            Agreement: '协议',
        },
        // TXN summary
        summaryTitle: {
            type: '类型',
            transaction_report: '资金报告',
            view_summary: '查看总结',
            transaction_status: '交易状态',
            transaction_type: '交易类型',
            Submitted: '提交',
            Attempted: '尝试中',
            Complete: '完成',
            Paid: '支付',
            InProcess: '处理中',
            Canceled: '取消',
            Remove: '已删除',
        },
        /*
      |--------------------------------------------------------------------------
      | Account Detail
      |--------------------------------------------------------------------------
      */
        AccountDetail: {
            input_not_null: '输入不能为空',
            name_limited: '输入长度不能超过15',
            state_limited: '输入长度不能超过24',
            country_limited: '输入长度不能超过30',
            phone_limited: '只能填写数字并不为空',
            number_only_not0: '只能填数字并且不能以0开始',
            number_only: '只能填数字',

            button: {
                edit: '编辑',
                cancel: '取消',
                save: '保存',
                reset: '重设',
                generatePassword: '生成密码',
                sendEmail: '发邮件给客户',
            },
            memberProfile: {
                title: '编辑CRM账号详情',
                firstName: '名',
                email: '邮件',
                address: '地址',
                country: '国家和地区',
                lastName: '姓',
                DOB: '出生日期',
                city: '城市',
                state: '省份',
                postCode: '邮编',
                mobile: '手机',
                hubMerchant: 'HUB 商户',
                hubCustomer: 'HUB 客户',
                is_ib: 'IB用户',
                resetPasswordTitle: '重设账户密码为',
                passwordLimit: '*输入8-15位密码，包括小写、大写和数字，支持特殊符号。',
                placeHolder: '输入电话号码',
                hub_money_manager: 'HUB 资金经理',
                button_banned_text: '禁用的支付方式列表',
            },
            tradingProfile: {
                // Up side
                title: '编辑交易账号详情',
                tradingServer: '交易服务器',
                parentServer: '代理交易服务器',
                tradingAccount: '交易账户',
                balance: '余额',
                equity: '净值',
                margin: '已用保证金',
                marginFree: '可用保证金',
                marginLevel: '保证金比例',
                credit: '赠金',
                parent: '代理',
                type: '交易账号类型',
                customer: '客户',
                sales_code: '销售代码',

                // left-down side
                enable: '激活',
                regDate: '注册日期',
                metaID: 'MetaQuotes ID',
                name: '名字',
                phonePassword: '电话密码',
                city: '城市',
                state: '省份',
                country: '国家和地区',
                address: '地址',
                zipCode: '邮编',
                phone: '电话',
                email: '邮件',
                idNUmber: 'ID号码',
                status: '状态',
                color: '颜色',
                group: '组别',
                comment: '备注',
                leverage: '杠杆',
                forexOnly: '仅限外汇',
                taxRate: '税率',
                agentAccount: '代理账号',
                leadSource: '主要来源',
                allowChangePassword: '允许修改密码',
                readOnly: '只读 (不交易)',
                sendReport: '发送报告',
                oneTimePassword: '允许一次性密码',

                // right-down side
                resetPasswordTitle: '重设交易账号',
                password: '密码',
                passwordType: '密码类型',
                resetPasswordTo: '重设密码为',
                IBNodeTitle: 'IB 链接',
                qrCode: '二维码',
                trading: '交易',
                investor: '观摩',
                refresh: '刷新',
                download: '下载',
            },
        },
        referral_links: {
            filters: '筛选',
            reset: '重置',
            search: '搜索',
            referral_name: '推荐名称',
            target_country: '适用国家和地区',
            referral_code: '推荐码',
            landing_page: '着陆页',
            website_link: '官网链接',
            referral_link: '推荐链接',
            ib_ta: '代理交易账户',
            ta: '交易账户',
            node_name: '组别名称',
            creation_time: '创建时间',
            action: '操作',
            default: '默认',
            global: '全球',
            add_new_link: '添加新链接',
            edit: '编辑',
            export_flat_report: '导出查询结果',
            export_all_report: '导出所有数据',
            referral_list: '代理推荐链接',
            view: '查看',
            page_view: '浏览量',
        },
        tips: {
            select_user: '在上方搜索框中选择一个用户，选择后将在此窗口显示用户的更多详情。',
            export_report: '导出属于该客户账号的所有交易账户的报告。',
            rebate_setting_rate_type: '如果将一个返佣组别的返佣类型设置为“固定”，则此客户所属的整个树都将被设置为“固定”。',
            confirmReset: '确定修改密码？',
            no_tree_view: '获取不到交易账户树状图, 请确认是否添加了交易子账户。',
            select_type: '请选择类型',
            change_ib: '更改了IB状态后详情窗口将会关闭',
            rebate_report_trade_account_select: '此栏中的选项会受交易账户栏的选项影响。 如果在交易账户栏中选择“合并”，则无法选择此栏中的“IB账户报告”。',
        },
    },
    reporting: {
        leverage_change: {
            leverage_change_list: '杠杆变更报告列表',
            export: '导出CSV',
            application_no: '申请号',
            member_name: '会员名字',
            time_submitted: '提交时间',
            time_processed: '处理时间',
            application_status: '申请状态',
            original_leverage: '初始杠杆',
            change_to: '新杠杆',
            trading_account: '交易账户',
            trading_account_status: '账户状态',
            enabled_short: '启用',
            readonly_short: '只读',
            disabled_short: '禁用',
            all: '所有',
            pending: '待处理',
            complete: '完成',
            canceled: '取消',
            view_more: '查看更多',
        },
        transaction_report_list: {
            view_more: '查看更多',
            main_title: '资金报告',
            title: '资金报告列表',
            To: '至',
            From: '从',
            delete_info: '是否取消交易',
            cancel_successfully: '取消成功',
            cancel_withdrawal: '取消提款',
            cancel_internalTF: '取消内转',
            type: {
                deposit: '入金',
                credit: '赠金',
                internalTF: '内转',
                Rebate: '返佣',
                withdrawal: '出金',
                CFD: 'CFD',
                Rollover: 'Rollover',
                Misc: 'Misc',
            },
            solve_Later_List: {
                server_type: '交易服务器类型',
                transaction_no: '交易单号',
                time_submitted: '提交时间',
                time_processed: '处理时间',
                time_completed: '完成时间',
                transaction_status: '交易状态',
                transaction_type: '交易类型',
                transaction_notes: '交易记录',
                member_name: '会员名字',
                payment_amount_in: '付款金额',
                to: '至',
                exchange_rate: '汇率',
                surcharge_amount: '手续费金额',
                fund_in: '资金金额',
                tradingAccount: '交易账户',
                trading_account_status: '账户状态',
                parent: '代理',
                trading_group: '交易组别',
                internal_notes: '内部备注',
                updated_by: '最后编辑',
                trading_server_comment: '交易服务器备注',
            },
            withdrawal_popup: {
                title: '付款详情',
                entry_tips: '点击交易单号，可查看出金详情',
                empty_reminder: '暂无支付信息。如需帮助，请联系客服。',
            },
        },
        order_report_list: {
            end_time_need: '结束时间不能为空',
            start_time_need: '开始时间不能为空',
            startTimeOver: '开始时间不能晚于结束时间',
            order_id_error: '订单号必须为数字',
            To: '至',
            From: '从',
            export: '导出CSV',
            export_valid_orders: '导出有效订单',
            view_more: '查看更多',
            main_title: '订单报告',
            title: '近期订单',
            records: '*%{allRecords}条中的<u>%{record}</u>条',
            account: '交易账户',
            close_order_history: '交易历史',
            order_time: '交易时间',
            account_name: '账户姓名',
            open_time: '开仓时间',
            order_type: '多单/空单',
            symbol: '交易产品',
            open_price: '开仓价位',
            close_time: '平仓时间',
            commission: '手续费',
            last_price: '最新报价/平仓报价',
            comment: '备注',
            to: '至',
            order_id: '订单号',
            volume: '手数',
            sl: '止损',
            tp: '止盈',
            total_volume: '标准化总手数',
            total_comm: '总手续费',
            total_swap: '总隔夜利息',
            total_profit: '真实盈利',
            total_total_profit: '真实盈利总额',
            swap: '隔夜利息',
            profit: '盈利',
            open_orders: '已开仓位',
            close_orders: '已平仓位',
            pending_orders: '挂单',
            buy: '多单',
            all: '所有',
            sell: '空单',
            buy_limit: '买入限价',
            sell_limit: '卖出限价',
            buy_stop: '买入止损',
            sell_stop: '卖出止损',
            buy_stop_limit: '买入止损限价',
            sell_stop_limit: '卖出止损限价',
            no_orders: '没有交易记录',
            symbol_type: '交易品种',
            name: '交易品种',
            comm: '手续费',
            fx: '外汇',
            bullion: '贵金属',
            oil: '原油',
            cfd: '差价合约',
            crypto: '数字货币',
            total: '总额',
            trading_server: '交易服务器',
            view_summary: '查看总结',
            adjusted_volume: '标准化总手数',
            server_tip: '当合并显示多台服务器的数据时，来自一台服务器的数据在此表格中最多仅显示%{limit}条',
            history_order_tip: '* 历史订单报告中可能不包括最近 30 分钟内的订单。请注意此页面内所显示时间均为MT4/5时区。',
            adjusted_volume_tip: '此处的计算公式为：外汇手数+黄金手数+(原油手数+CFD手数+虚拟货币手数)*0.1）',
        },
        leverage_change_report_list: {
            title: '杠杆变更报告列表',
            main_title: '杠杆变更报告',
        },
    },
    download: {
        qr_code_tip: '扫码下载',
        p_1: '最直接的市场报价',
        p_2: '最直观的技术图表',
        p_3: '最完整的分析工具',
        p_4: '最及时的财经新闻',
    },
    save_funding_info: {
        create_success_wait_for_verification: '创建支付信息成功！请等待验证通过',
        create_successfully: '创建支付信息成功！',
        delete_successfully: '删除支付信息成功！',
        save_successfully: '保存支付信息成功！',
        add_tip: '申请新支付信息',
        delete_confirm: '确定删除{name}吗？',
        number_type: '输入必须为数字或者英文',
        deposit: {
            title: '入金',
            add_title: '入金方式',
            second_title: '信用卡',
            second_title_1: 'Poli Pay',
            second_title_2: '自定义',
            card_name: '入金信息别称',
            Card_Status: '验证状态',
            Poli_Pay_Status: 'Poli Pay状态',
            not_verified: '未验证',
            Verified: '已验证',
            Reject: '已拒绝',
            card_num: '卡号',
            card_holder: '持卡人名字',
            cvv: 'CVV',
            expiry_date: '有效期',
            select_year_month: '选择年-月',
            upload_photo: '上传照片',
            imageLabel: '图片',
            areaLabel: '展示区域',
            add_type_credit_card: '信用卡',
            add_type_poli_pay: 'Poli Pay',
            add_type_customized: '自定义',
            click_upload: '点击上传',
            delete_customized_info: '是否删除自定义信息 %{name} ?',
            tips: {
                cvv_tips: 'CVV是印在卡背面签名面板上的三位数字。',
                card_name: '请输入账户名字',
                card_num: '请输入有效的卡号',
                card_holder: '请输入持卡人名字',
                cvv_empty: '请输入cvv号码',
                cvv_limit: 'cvv号码必须是3位数字',
                expiry_date: '请输入有效期',
                image: '请上传图片文件',
            },
            poli_pay: {
                account_name: '账号名字',
                account_number: '账号',
                bsb: 'BSB',
                tips: {
                    account_name: '请输入账号名字',
                },
            },
        },
        withdrawal: {
            title: '出金',
            bank_account: '银行账号',
            bank_name: '银行名称',
            add_placeholder: '选择类型',
            add_title: '出金方式',
            au: {
                self_bank_name: '出金信息别称',
                bank_name: '银行名称',
                bank_account_number: '银行账号',
                bsb: 'BSB',
                account_holder_name: '账号持有人姓名',
                international_bank_code: '银行国际代码',
                tips: {
                    self_bank_name: '请输入账户名字',
                    bank_name: '请输入银行名称',
                    bank_account_number: '请输入银行账号',
                    bsb: '请输入BSB',
                    account_holder_name: '请输入账号持有人姓名',
                    international_bank_code: '请输入银行国际代码',
                },
            },
            global: {
                self_bank_name: '出金信息别称',
                bank_name: '银行名称',
                bank_address: '银行地址',
                bank_account_number: '银行账号 / 国际银行账号',
                account_holder_address: '账号持有人地址',
                account_holder_name: '账号持有人姓名',
                international_bank_code: '银行国际代码',
                sort_code: '银行区号',
                tips: {
                    self_bank_name: '请输入账户名字',
                    bank_name: '请输入银行名称',
                    bank_address: '请输入银行地址',
                    bank_account_number: '请输入银行账号',
                    account_holder_address: '请输入账号持有人地址',
                    account_holder_name: '请输入账号持有人姓名',
                    international_bank_code: '请输入银行国际代码',
                    sort_code: '请输入银行区号',
                },
            },
            rmb: {
                self_bank_name: '出金信息别称',
                bank_name: '银行名称',
                bank_account_number: '银行账号',
                account_holder_address: '账号持有人地址',
                account_holder_name: '账号持有人姓名',
                bank_branch_name: '银行分行名称',
                bank_province: '银行所属州(省份)',
                bank_city: '银行所属城市',
                tips: {
                    self_bank_name: '请输入账户名字',
                    bank_name: '请输入银行名称',
                    bank_account_number: '请输入银行账号',
                    account_holder_address: '请输入账号持有人地址',
                    account_holder_name: '请输入账号持有人姓名',
                    bank_province: '请输入银行所属州(省份)',
                    bank_city: '请输入银行所属城市',
                    bank_branch_name: '请输入银行分行名称',
                },
            },
            other: {
                account_number: '%{title}账号号码',
                account: '%{title}账号',
                tips: {
                    account_number: '请输入账号号码',
                },
            },
            customization: {
                other_bank: '其他银行',
                tips: {
                    other_bank: '请输入其他银行名称',
                },
            },
        },
    },
    retrievePassword: {
        retrieve_password: '找回密码',
        retrieve_password_title1: '请输入您的邮箱地址。',
        retrieve_password_title2: '您将通过电子邮件收到一个链接，创建一个新的密码。',
        accountTip: '邮箱地址',
        verificationTip: '验证码',
        captcha_change: '换一张',
        get_new_password: '获取新密码',
        email_title1: '设置新密码的链接已发送到您的电子邮箱:',
        email_title2: '请尽快查看您的电子邮件并重置密码',
        reset_password: '重置密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        submit: '提交',
    },
    memberProfile: {
        default_setting: '设置',
        preferred_language: '首选语言:',
        subscription_news: '新闻订阅',
        receiving_email: '接收追加保证金的通知邮件',
        password_setting: '重设密码',
        login_email: '当前登录邮箱',
        new_email: 'New Email',
        change_email: '更改邮箱',
        change_password: '更改密码',
        account_info: '账户信息',
        crm_password: '客户端密码:',
        hub_pin_password: 'HUB PIN 密码:',
        yes: '是',
        no: '否',
        pin_verification: '*4-6个字符，必须包含数字或字母。',
        HUBX_tip: 'HUBX客户协议已成功签订，请为您的HUBX设置一个PIN',
        black_tip: '抱歉，暂时不支持修改邮箱。',
        please_enter_new_email: '请输入你的新邮箱地址。',
        enter_email_code_tip: '请输入 {email} 邮件中的验证码完成验证',
    },
    errorCode: {
        email_verify_limit: '请求次数超过限制，请于24小时后再试',
        preview_key_expire: '预览链接已失效',
        redeem_failed: '领取奖励失败，请联系客服团队.',
        participating_failed: '参与活动失败，您不满足活动激活要求且激活时间已过。',
        authenticator_existed: '验证器已存在',
        authenticator_not_exist: '验证器不存在',
        reset_fail: '重置验证器失败，密码或出生日期不正确。',
        incorrect_password: '一次性密码不正确',
        new_email_not_supported: '暂不支持修改成此邮箱。',
        promotion_end_cant_participate: '报名时间已结束，无法参与该活动',
        promotion_not_have_authorized: '抱歉，您没有参与该活动的权限，请联系管理员',
        promotion_have_withdrawn: '您已退出该活动，请您刷新界面',
        promotion_double_register: '请勿重复报名，请您刷新界面',
        promotion_cant_adjust_order: '抱歉，该活动不可调整进度结算的顺序',
        promotion_redeem_repeatedly: '请勿重复领奖，请您刷新界面',
        promotion_not_exist: '活动不存在',
        promotion_cannot_delete: '当前活动不能被删除',
        promotion_ended: '活动已结束，请您刷新界面',
        promotion_exception: '活动异常，请联系管理员',
        coupons_claim_not_successful: '您的优惠券领取未成功，请联系我们的客户支持团队',
        system_busy: '系统繁忙，请您刷新界面后重试',
        redemption_failed: '兑换失败，请您刷新界面后重试',
        product_not_exist: '商品不存在',
        product_not_sale: '商品未上架',
        product_not_redeem: '抱歉，您所在的国家无法兑换该商品',
        product_not_available: '抱歉，所选商品已下架，请重新选择。',
        invalid_product: '失效的返现商品',
        valid_trading: '请提供有效的交易帐号',
        amount_change: '商品支付金额发生变化，请您刷新界面后重试',
        coupon_not_available: '支付失败，优惠券不可用',
        please_open_wallet: '请先开通钱包账户',
        no_permission_order: '抱歉，您没有创建订单的权限',

        not_allowed_transfer_in_IB_TA: 'IB TA不允许内转入金',
        not_allowed_deposit_to_IB_TA: 'IB TA不允许入金',
        failed_please_contact_the_admin: '创建失败，请联系管理员',
        you_have_submitted_the_request_please_wait: '您已经提交了请求，请等待。',
        apply_ta_limit: '账户数量已到达上限，请等待管理员审核。',

        single_demo_deposit_limit: '入金金额超出单个账号限制',
        balance_demo_deposit_limit: '入金金额超出账号余额限制',
        disable_create_demo_ta: '您当前无法申请模拟交易账户，请联系管理员',

        link_error: '此链接已被删除，请与管理员确认。',
        reports_cannot_generated_manual_accounts: '无法为手动帐户生成报告',
        hub_code_201: '这条记录已存在',
        hub_code_202: '数据记录不存在',
        hub_code_205: 'HUBx TA 已达到最高限制数量，无法继续创建',
        hub_code_301: '账户余额不足',
        hub_code_302: '因为该交易账户有余额，无法修改组别',
        hub_code_303: '无法修改组别货币对',
        hub_code_305: '无法更新交易账号组别，因为该交易账户已被加入MAM中',
        hub_code_306: '无法出金，因为该交易账户在MAM中处于激活状态',
        hub_code_308: '因为该交易账户在MAM里面处于激活状态，无法修改交易账户的状态',
        hub_code_999: 'Core 错误',
        link_invalid: '这个链接已失效',
        missing_required_field: '缺少必填字段。',
        channel_name_exist: '通道名称已经存在',
        user_register_error: '用户注册失败',
        email_format_error: '邮箱格式错误',
        card_number_wrong: '卡号错误',
        card_info_wrong: '验证的卡片信息不正确',
        source_error: 'source参数错误',
        registration_link_error: '注册链接错误，请联系管理员',
        mam_slave_account_only_deposit: 'MAM附属账户只能入金',
        mam_account_cannot_deposit_withdraw: 'MAM主账户不能出入金',
        user_not_participate_promotion: '当前用户未参与该活动',
        exchange_rate_does_not_exit: '该汇率不存在',
        activity_not_started: '活动未开始',
        more_than_activation_time: '超过活动激活时间,无法参与该活动',
        promotion_can_not_withdrawal: '该账户当前正在参与促销活动, 无法进行出金',
        client_can_not_withdrawal: '由于参加了先领奖的活动，此用户将无法提交任何出金申请。',
        unable_to_add_wallet_account: '无法添加钱包账户，请联系管理员',
        account_has_exceeded: '钱包账户已超出最大上限，无法继续添加',
        points_internal_is_close: '该积分账户不能内转',
        points_withdraw_switch_is_closed: '该积分账户不能出金',
        points_deposit_switch_is_closed: '该积分账户不能入金',
        trading_server_type_is_unique: ' 服务器类型唯一',
        points_currency_cannot_be_added: 'POINTS货币不能新增',
        unable_participate_promotion: '无法再次参与活动',
        no_account_participate_promotion: '没有账号可参与活动',
        cannot_add_wallet_account: '无法添加钱包账户',
        lang_file_not_exist: '语言文件不存在',
        register_config_changed: '注册配置已更改，请刷新页面',
        exceeded_the_maximum_number_of_files: '已超过最大上传文件数',
        less_than_the_minimum_number_of_files: '小于允许文件数量',
        card_verified_can_not_edit_delete: '这张卡已通过验证，不可删除或者编辑。如有问题，请联系管理员。',
        rebate_reques_time_limit: '请求时间范围不可超过三个月，如果您需要请求额外的时间范围，请联系管理员。',
        payment_info_error: '付款信息不正确',
        deposit_channel_disable: '该支付方式正在维护中',
        missing_registration_configuration_information: '缺少必填参数',
        please_contact_the_administrator_to_register_account: '若需要注册请联系管理员',
        address_is_invalid: '地址未配置无法进行登入',
        country_has_added_configuration_information: '该国家已经添加配置信息',
        configuration_information_cant_be_deleted: '配置信息不能被刪除',
        please_upload_file: '请上传文件',
        incorrect_user_name_or_email_authentication_code: '用户名或邮件认证码错误',
        email_authentication_code_has_expired: '邮件认证码已过期',
        admin_cannot_add_demo_account: 'Admin用户无法添加模拟账户',
        the_configure_not_exists: '该配置不存在',
        demo_trading_account_through_the_registration_process: '推荐的用户不能通过注册流程注册模拟交易账户',
        account_no_rebate_report: '该账户无法生成报告',
        leverage_no_display_now: '由于数据传输原因杠杆未显示实际数值，请稍晚再尝试确认更改。',
        csv_sent_email: '因为此账号用户数据较多，将以email 的形式发送至您的邮箱',
        max_sent_email: '已呈现%{totalCount}条记录中的%{number}条，文件将通过电子邮件发送给您。如果您找不到您想要的数据，请更改筛选条件以缩小范围。',
        take_a_little_while: '数据下载需要一定时间，请稍候片刻',
        exceed_rate_limit: '已超出请求速率限制，请稍后再尝试',
        not_auth_review_account: '抱歉，您无权查看此账户',
        no_ib: '当前的用户不是IB用户',
        miss_system_config: '系统配置缺失',
        channel_config_file_error: '通道配置文件创建失败',
        payment_method_has_same_name: '当前支付方式已有同名通道',
        channel_config_delete_error: '通道配置文件删除失败',
        channel_config_edit_error: '通道配置文件编辑失败',
        bank_code_same_name: '当前支付方式已有同名银行码',
        default_channel_not_exist: '指定的默认通道不存在',
        amount_not_meet_the_requirements: '修改的金额不符合条件',
        method_has_exist: '方式已存在，方式名字不能重复',
        insufficient_balance: '余额不足',
        insufficient_withdrawal_amount: '账户可提现余额不足',
        transactio_blocked: '这条交易已经被阻止',
        csv: '没有可导出的内容',
        trans_edit_failed: '该交易修改失败',
        trans_approve_failed: '该交易批准失败',
        changes_this_state_not_allowed: '不允许更改到该状态',
        transactionTypeExist: 'Transaction Type 已存在',
        TransactionTypeNoEdit: 'Transaction Type 不允许编辑',
        TransactionTypeNoDelete: 'Transaction Type 不允许删除',
        data_change: '数据发生改变，请刷新页面后进行操作',
        BULK_FUNDING_UPLOAD: '此文件的列不正确',
        system_Verification_failure: '验证码失效',
        user_black_list: '抱歉，我们暂时无法为您开设账户。',
        language_invaild: '该语言无效',
        agreement_not_exist: '该协议不存在',
        trading_server_error: '没有可用的交易服务器',
        exists_error_tips: '设定的名称已存在',
        exist_name: '用户名已存在',
        exist_email: '该邮箱已存在',
        no_default: '没有设置问题',
        not_jurisdiction: '该牌照没有交易服务器',
        unable_select_the_server: '对不起，此交易服务器目前无法选择。请联系您的客户经理或发送电子邮件给我们（%{email}）。',
        not_trading_server_question: '对不起，没有设置问卷',
        not_exist_default_exchange_rate: '不存在默认汇率',
        internal_transfer_failed: '内转失败',
        not_exist_exchange_rate: '换算汇率不存在',
        fund_not_allow: '入金金额不在通道允许范围内',
        payment_not_allow: '支付金额不在通道允许范围内',
        channel_not_exist: '对不起，该通道正在维护中，请稍后尝试',
        amount_not_enough_withdrawal: '对不起，出金金额不足够支付手续费，请修改出金金额',
        unable_withdrawal: '对不起，支付方式正在维护。请稍后再试。',
        debounce_transaction: '我们正在处理您的交易，请在2分钟后进行新的交易!',
        no_permission: '无权取消该交易',
        fund_name_exist: '信息名称已存在',
        user_has_no_permission: '该用户无操作权限',
        withdrawal_bank_info_name_exist: '银行信息名称%{name}已存在',
        this_currency_disabled: '此货币暂时无法使用',
        chose_trading_server: '您所选的交易服务器与交易账户不匹配，请稍后重试',
        ib_users_no_trading_account: '抱歉，该账号您无权查看',
        invalid_request_address: '抱歉，您在此网站上无法使用您的账户。 如果有任何问题，请联系您的客户经理。',
        file_lock_cannot_deleted: '已被锁定的文件无法删除',
        file_lose_config: '配置文件缺失配置',
        missing_order_type: '入金下单类型错误',
        beyond_the_limitation_of_ta: '您的交易账户数量已达到上限，请联系我们的客服团队了解更多信息。',
        missing_deposit_parameters: '缺少入金参数',
        third_party_failed: '尊敬的客户，当前系统繁忙，请重试或者联系您的客户经理',
        channel_does_not_exist: '通道不存在',
        user_not_exist: '用户不存在.',
        error_deposit_request: '对不起，取消订单失败，请求退款错误',
        not_ib_portal: 'IB门户未开启',
        email_invalid: '邮箱格式错误',
        crm_under_maintenance_tip: '个人门户正在维护中，请稍后再试',
        function_disabled_tip: '该功能暂时不可用',
        duplicate_trading_account_exist: '我们检测到系统中不同服务器内存在相同的交易账号。请先进行树关系搜索，然后在树中选择您的目标账号需要生成报告。',
        not_is_IB: '该账户不是IB账号',
        TA_not_available_to_check: '该账号不属于可查看范围',
        apply_ta_failed: '您提交的子帐户申请失败了，请再试一次。如果依然失败，请与您的客户经理联系。',
        request_trading_account_failed: '很抱歉，该交易服务器当前无法选择，请联系相应的管理人员。',
        demo_config_error: '模拟账户配置缺失或者格式错误',
        not_create_demo: '配置限制无法创建模拟账户',
        demo_not_acount: '注册模拟账户数量超出限制',
        demo_deposit_error: '模拟账户请求入金失败 ',
        not_receive_reward: '该用户不能领取奖励',
        not_have_account: '该用户没有POINTS账户，请联系管理员',
        received_all_reward: '您已领取完所有奖励',
        captcha_verify_fail: '第三方人机验证服务端验证失败',
        miss_required_parameters: '缺少必填参数',
        password_is_empty: '用户密码为空',
        sale_code_error: '分配sale code 错误',
        upload_error: '短协议上传错误',
        referral_code_exist: '推荐码重复',
        referral_link_no_create_permission: '无权限创建推荐码',
        referral_link_no_edit_permission: '无权限编辑推荐码',
        no_point_account: '此用户没有POINTS账号，请联系管理员',
        the_account_is_archived: '该账号已经被归档',
        your_account_cannot_be_used: `您的账户不可用，请联系我们的客服support{'@'}threetrader.com，我们将进一步协助您`,
        the_email_in_black_list_unable_to_retrieve_password: '邮箱在黑名单，无法找回密码',
        user_has_no_operation_permissions: '用户没有操作权限',
        network_access_is_restricted: '很抱歉，您的网络访问受限，请联系客服',
        is_mam_template_exists: '该模板名称已存在',
        get_code_message: '验证码已发送，请 {minutes} 分钟后重试。',
        send_error: '抱歉，系统错误，请稍后再试。',
        invalid_code: '验证码无效',
        used_code: '该验证码已被使用。',
        email_contact_admin: '验证码发送失败，请联系管理员。',
        complete_required_questions: '请完成所有题目。',
        operation_too_frequent_please_try_again_later: '操作过于频繁，请稍后再试。',
        bank_does_not_exist: '银行不存在',
        notTransferToIb: '当前所选账号不支持向IB TA转账。',
        product_category_not_display: '系统错误，请联系管理员。',
        coupon_is_not_valid: '此优惠券无效，请联系管理员。',
        request_deposit_fund_failed: '交易失败，请稍后重试。',
        exchange_rate_invalid: '汇率失效。',
    },
    error: {
        format: {
            password_error: '输入的密码不符合要求，请重新输入',
            Select_Error: '请选择{field}',
            number_limited: '输入内容只能为数字，并且不能为空',
            empty_limited: '输入内容不能为空',
            money_limited: '只能输入数字，并且小数点不能超过两位',
            email_limited: '请输入正确的邮箱地址',
            password_limited: '输入的密码不符合要求，请重新输入',
            incorrect_limited: '输入内容有误',
            is_required: '%{field}是必填项',
            select_valid_date: '请选择有效日期',
        },
        password_not_equal: '密码不一致，请确认您的密码。',
        hub_pin_not_equal: 'PIN不一致，请确认您的PIN。',
    },
    agreements: {
        proof_file_management: '身份验证文件',
        upload_tutorial: '%{companyName}文档上传教程',
        click_add: '点击上传',
        select_file: '选择文件',
        agreements_management: '协议管理',
        type: '类型',
        agreement_name: '协议名称',
        agree: '同意',
        type_select: '类型选择',
        identification_proof: '身份证明',
        address_proof: '地址证明',
        bank_statement: '银行对账单',
        selfie: '自拍照',
        others: '其他证明',
        upload_new: '上传新文件',
        browse_existing_file: '浏览现有文件',
        file_name: '文件名称 >>',
        file_preview: '文件预览 >>',
        upload_success: '上传成功！',
        delete_file: '删除文件 - %{name}?',
        agree_file: '同意这个协议 - %{name}?',
        note_start: '已同意勾选文件',
        note_end: '：同意，客户同意',
        trading_account: '交易账户',
        poi: '身份证明(护照、身份证或驾驶证)',
        poa: '地址证明(水电费账单、银行/信用卡账单、固定电话/宽带账单)',
        other: '其他(银行对账单、自拍等)',
    },
    Sidebar: {
        TA_detail_popup_window: '交易账户明细弹窗',
        orders_report_recent_order: '近期订单',
        orders_report_historical_orders: '历史订单',
        funding_management: {
            transaction_history: '所有交易记录',
        },
        subMenu: {
            inbox: '收件箱',
            archive: '回收箱',
        },
    },
    global: {
        popup_menu: {
            account_detail: '账户详情',
            quick_Funding: '操作资金', // 这个是问过 rabi 的。确认这么翻译
            all_transaction: '所有交易',
            order_report: '近期订单',
            rebate_settings: '佣金设置',
        },
        order_report: {
            select: '选择',
            all: '所有',
            open_orders: '已开',
            close_orders: '已平',
            pending_orders: '挂单',
            top_label: '*仅显示最近6个月的数据。如有需要，请访问详细信息部分。',
            order_status: '交易状态',
            order_time: '交易时间',
            open_time: '开仓时间',
            order_type: '多单/空单',
            symbol: '交易产品',
            open_price: '开仓价位',
            close_time: '平仓时间',
            commission: '手续费',
            last_price: '最新报价/平仓报价',
            comment: '备注',
            to: '到',
            order_id: '订单号',
            volume: '手数',
            sl: '止损',
            tp: '止盈',
            swap: '隔夜利息',
            profit: '盈利',
            total_volume: '总手数',
            total_comm: '总手续费',
            total_swap: '总隔夜利息',
            total_profit: '真实盈利',
            total_total_profit: '真实盈利总额',
        },
    },
    report_management: {
        enter_trading_account: '输入交易账号',
        search_type: '搜索类型',
        search_tree: '搜索树',
        apply_report: '申请报告',
        report_time_select: '选择报告时间',
    },
    ReportManagement: {
        client_list_tree: '客户树关系',
        request_history: '申请历史',
        order_time_type: '订单时间类型',
    },
    dashboard: {
        show_the_latest: '显示最新',
        recent_orders_thirty_mins_show: '*近期订单可能在30分钟后展示',
        new_accounts: '新增账户',
        transactions: '交易',
        orders: '近期订单',
        margin_call_account: '追加保证金账户',
        data_updated: '数据更新于 ',
        update_now: '立即刷新',
        identity_status: '身份证明状态',
        address_status: '地址证明状态',
        bank_status: '银行对账单证明状态',
        verification_status: '身份验证状态',
        new_account: {
            register_time: '注册时间',
            member_name: '会员名字',
            verification: '验证状态',
            trading_server: '交易服务器',
            ta: '交易账户',
            ta_mini: '交易账户',
            parent: '代理',
            group: '组别',
            status: '交易账户状态',
            ta_status: '交易账户状态',
            check_detail: '查看详情',
            view: '查看',
            en: '启用',
            dis: '禁用',
        },
        transaction_table: {
            time_submitted: '提交时间',
            time_completed: '完成时间',
            member_name: '会员名字',
            server_type: '交易服务器类型',
            ta: '交易账户',
            ta_mini: '交易账户',
            parent: '代理',
            ta_status: '账户状态',
            ta_status_mini: '账户状态',
            fund: '资金 入金/出金',
            txn_no: '交易单号',
            transaction_status: '交易状态',
            transaction_types: '交易类型',
            all: '所有交易类型',
            all_tt: '所有交易类型',
            all_ts: '所有交易状态',
            deposit: '入金',
            withdrawal: '出金',
        },
        margin_table: {
            member_name: '会员名字',
            trading_server: '交易服务器',
            ta: '交易账户',
            ta_mini: '交易账户',
            parent: '代理',
            balance: '余额',
            equity: '净值',
            credit: '赠金',
            margin_level: '保证金比例',
            check_detail: '查看详情',
            view: '查看',
        },
        orders_table: {
            order_id: '订单号',
            ta: '交易账户',
            order_type: '多单/空单',
            symbol: '交易产品',
            volume: '手数',
            close_time: '平仓时间',
        },
    },
    date: {
        month_1: '一月',
        month_2: '二月',
        month_3: '三月',
        month_4: '四月',
        month_5: '五月',
        month_6: '六月',
        month_7: '七月',
        month_8: '八月',
        month_9: '九月',
        month_10: '十月',
        month_11: '十一月',
        month_12: '十二月',
    },
    aliyun: {
        _startTEXT: '请按住滑块，拖动到最右边',
        _yesTEXT: '验证通过',
        _error300: '哎呀，出错了，单击<a href="javascript:__nc.reset()">刷新</a>再来一次',
        _errorNetwork: '网络不给力，请单机<a href="javascript:__nc.reset()">刷新</a>',
    },
    notification: {
        popup: {
            next: '下一条',
            back: '上一条',
            close: '关闭',
            confirm: '确认',
            do_not_show_again: '不再展示',
        },
    },
    referral_link: {
        pop: {
            add_title: '创建新的推荐链接',
            edit_title: '编辑',
            view_title: '预览',
            ta: 'IB交易账号',
            redirect: '推荐链接直接重定向到注册。',
            do_you_want_to_cancel_create: '是否取消创建？',
            do_you_want_to_cancel_edit: '是否取消编辑？',
            do_you_want_to_update: '是否更新此代理推荐链接？',
            do_you_want_to_create: '是否创建此代理推荐链接？',
            tips: {
                name: '请输入推荐链接名字',
                ta: '请输入IB交易账号',
                node: '请输入组别',
                referral_code: '请输入推荐码',
                landing_page: '请输入登陆页面',
                referral_link: '请输入推荐链接',
                Maximum64: '*最多输入64个字符',
                referral_link_tip: '*因为选择了组别，此代理推荐链接是一个注册链接。',
            },
        },
    },
    new_login_version: {
        login_title: 'ThreeTrader Portal',
        login_sub_title: 'It is a long established fact that a reader will be distracted by the It is a long established fact that',
        knowledge_question: 'Knowledge questionnaire',
        set_a_password: '请输入您的个人门户密码',
        register: {
            step_two: {
                date_time_tip: '日期时间值是必需的并且是正确的',
                year_less: '有效期年份不能小于今年',
            },
        },
        register_tips_email: `no-reply{'@'}email.threetrader.com`,
        register_tip1: '为了确保您能收到我们的邮件通知，在您的邮箱设置中，请勿将',
        register_tip2: '标记为屏蔽/广告邮箱。我们建议您将',
        register_tip3: '添加到您的邮箱联系人中，以确保不会被屏蔽。',
    },
    upgrade_desc: {
        current: '正在使用',
        upgrade_now: '马上升级',
        count_down: '请于 %{time} 后重试',
        overall_progress: '申请进度',
    },
    MessageCenter: {
        inbox_title: '收件箱',
        archive_title: '回收箱',
        inbox: {
            title: '收件箱列表',
            date: '收件时间',
            search_placeholder: '搜索发件人，标题或内容',
        },
        archive: {
            title: '归档列表',
            search_placeholder: '搜索发件人，标题或内容',
        },
        common: {
            original: '原文内容:',
            reply: '回复内容:',
            total_message: '共%{count}条消息',
            unread: '未读',
            pin: '标星',
            subject: '标题',
            sender: '发件人',
            sender_type: '发件人类型',
            sender_typeOpts: {
                admin: '管理员',
                sales: '销售',
                member: '会员',
            },
            recipient_type: '收件人类型',
            type: '消息类型',
            typeOpts: {
                trigger: '自动触发',
                manual: '手动触发',
            },
            date: '时间',
            recipient: '收件人',
            time_sent: '发送时间',
            save_time: '储存时间',
            source: '来源',
            sourceOpts: {
                sent: '发件箱',
                inbox: '收件箱',
            },
            checkbox_btn_inverse: '反选',
            checkbox_btn_mark_read: '标为已读',
            checkbox_btn_mark_unread: '标为未读',
            checkbox_btn_archive: '归档',
            checkbox_btn_reopen: '恢复',
            sender_options: {
                is_delete_group: '是否删除组别%{name}？',
                select_groups: '选择组别',
                send_successfully: '发送消息成功',
                save_successfully: '保存草稿成功',
                portals: '门户',
                recipient: '收件人',
                add_group: '添加组别',
                group: '组别',
                subject: '标题',
                body: '内容',
                save_draft: '保存草稿',
                allow_reply: '允许回复',
                send_identical_email: '发送相同的电子邮件',
            },
        },
        tips: {
            mark_as_read: '信息已被标记为已读',
            mark_as_read_fail: '标记已读失败',
            mark_as_unread: '信息已被标记为未读',
            mark_as_unread_fail: '标记未读失败',
            archived: '信息已归档',
            archived_fail: '归档失败',
            reopened: '信息已恢复',
            reopened_fail: '恢复失败',
        },
        detail: {
            see_more: '查看更多信息',
            see_less: '查看较少信息',
            reply: '回复',
            reply_to: '回复给：',
            do_you_cancel_this_reply: '是否确认取消该回复？',
            do_you_want_to_send_this_message: '确认发送该信息？',
            reopen: '恢复',
            reopen_successfully: '恢复成功',
            send_success: '发送成功!',
        },
    },
    transactionStatus: {
        all: '所有',
        suspended: '暂停',
        complete: '完成',
        created: '创建',
        error: '错误',
        pendingTF: '等待内转',
        canceled: '取消',
        paid: '支付',
        confirmed: '确认',
        processing: '处理中',
        submitted: '提交',
        frozen: '进行中',
        exporting: '正在导出',
        exported: '已导出',
        attempted: '尝试中',
        inProcess: '处理中',
        // pending 的处理中，是一个泛指的翻译。后面可能会有其他的交易情况会用到
        pending: '处理中',
        refunded: '已退款',
    },
    trading_tools: {
        trading_not_available_mobile: 'WEB Trader当前不适用于移动设备。 您可以在下载页面上下载适合您设备的交易应用程序。',
        go_to_downloads: '前往下载',
    },
    rewards: {
        voucher_code: '兑换券代码',
        filters: '过滤',
        back: '上一条',
        next: '下一条',
        confirm1: '确认',
        search: '搜索',
        cancel: '取消',
        confirm: '确定',
        default: '默认',
        edit: '编辑',
        delete: '删除',
        action: '操作',
        view: '查看',
        reset: '重置',
        submit: '提交',
        save: '保存',
        create_success: '创建成功！',
        update_success: '更新成功！',
        delete_success: '删除成功！',
        delete_confirm: '确认要删除收货地址?',
        to: '至',
        no_data: '没有数据',
        point_currency_name: '积分',
        failed_to_load: '加载失败',
        no_more: '没有更多了',
        /******************系统通用 end************* */
        /* **************error code start*************/
        code_system_error: '系统异常',
        code_point_internal_transfer_switch_is_closed: 'POINTS内转开关已关闭',
        code_point_withdraw_switch_is_closed: 'POINTS出金开关已关闭',
        code_point_deposit_switch_is_closed: 'POINTS入金开关已关闭',
        code_please_try_again_later: '系统原因，请稍后再试',
        code_network_error: '请求服务器失败,请再次尝试连接。',
        code_delivery_address_not_exists: '收货地址不存在',
        code_given_data_was_invalid: '请求参数无效',
        code_given_product_not_exist: '商品不存在或已被删除',
        code_product_not_on_sale: '商品已被下架',
        code_product_categories_not_display: '此商品所属分类都被关闭',
        code_no_permission_user_country: '用户国家不符合商品条件',
        code_no_permission_user_jurisdiction: '用户牌照不符合商品条件',
        code_this_user_does_not_have_a_POINTS_account_please_contact_the_administrator: '您现在还没有钱包账户，请联系客服',
        /* **************error code end*************/
        /****************积分商城 start*************/
        not_for_sale: '已下架',
        detail: '详情',
        sold_out: '已售罄',
        stock: '库存: ',
        items: '件',
        name: '姓名',
        address_one: '地址1',
        address_two: '地址2',
        points: '积分',
        redeem_now: '立即兑换',
        search_category: '按类别搜索',
        more_products: '更多商品',
        homepage: '首页',
        category: '分类',
        custom_amount: '自定义金额',
        orders: '订单',
        address: '地址管理',
        userMenu: '个人中心',
        set_default_addr: '设为默认地址',
        model: '规格',
        quantity: '数量',
        default_delivery_address: '默认地址',
        province: '省份',
        city: '城市',
        suburb: '地区',
        detail_address: '详细地址',
        state: '州/区',
        country_region: '国家地区',
        phone: '联系电话',
        confirm_order_items: '确认商品信息',
        payment: '支付',
        payment_time: '支付时间',
        buy_tip_success: '您的订单已支付成功。',
        buy_tip_submit_fail: '提交失败，请重新兑换。',
        buy_tip_payment_fail: '支付失败，因为您没有足够的积分。',
        buy_tip_insufficient_inventory: '很抱歉，该商品目前暂时没有库存。',
        continue_shopping: '继续兑换',
        check_orders: '查看订单',
        return_to_item_detail: '返回商品详情',
        return_to_item_home: '返回商品首页',
        add_new_delivery_address: '新增收货信息',
        cancel_order: '是否确认取消订单',
        confirm_order: '是否确认购买',
        confirm_order_page: '确认订单',
        sort_by: '排序',
        time_added_most_recent: '创建时间从新到旧',
        time_added_least_recent: '创建时间从旧到新',
        points_highest: '积分从高到低',
        points_lowest: '积分从低到高',
        under_10000: '10000以下',
        please_select_model: '请选择您要的商品规格',
        product_detail: '商品详情',
        variation: '选择类别',
        your_points: '您的',
        order_details: '订单详情',
        order_status: '订单状态',
        recipient: '收货人',
        phone_number: '联系电话',
        phone_number_tip: '输入国际区号+电话号码，如：061 1234567890',
        postcode: '邮编',
        delivery_address: '收货地址',
        create_time: '创建时间',
        order_number: '订单编号',
        transaction_number: '交易单号',
        tracking_number: '物流单号',
        postage_information: '物流信息',
        item: '商品',
        price: '实付积分',
        total: '商品总额',
        paid: '已支付',
        completed: '已完成',
        canceled: '已取消',
        processing: '处理中',
        product_brand: '商品 / 品牌',
        enter_item_name_or_order_number: '输入商品名称或订单编号进行搜索',
        add_new: '添加收货地址',
        order_or_product: '订单/产品',
        selected: '已选择',
        edit_delivery_address: '编辑收货地址',
        discount: '折扣',
        hot: '热门',
        new: '上新',
        trading_account: '请选择一个接受返还现金的交易账号：',
        purchase_number: '采购单号',
        purchase_information: '采购信息',
        points_return_time: '积分退回时间',
        cash_back_account: '返现交易账号',
        cash_back_time: '返现时间',
        cash_back_reverse_time: '返现退回时间',
        /***************tips start**********************/
        tips_select_address: '请选择收货地址。',
        tips_add_address: '请添加一个新的收货地址。',
        user_has_no_permission: '该用户无操作权限',
        tips_no_account_available: `抱歉，您没有可用于接收返还现金的交易账号，请联系"support{'@'}threetrader.com"获取帮助。`,
        /***************tips end**********************/
        /****************积分商城 end*************/
        coupons: {
            name: '优惠券',
            coupon_nots: '优惠券',
            your_coupons: '您的优惠券',
            get_coupons: '获得更多优惠券',
            redemption: '兑换券',
            discount: '优惠券',
            all: '所有',
            soon: '即将到期',
            used: '已使用/已过期',
            participate_coupons: '参与活动，可以获得更多优惠券！',
            coming: '即将推出',
            learn_more: '查看更多',
            view_more: '查看更多',
            free: '免费',
            redemption_text: '兑换',
            valid_date: '有效期',
            copy_use: '复制并使用',
            received_from: '从{accessType}获得',
            used_text: '已使用',
            expired_text: '已过期',
            not_yet_available: '未到使用时间',
            not_available: '不可使用',
            received_time: '获得时间',
            available_products: '可用商品',
            off: '折扣',
            and_more: '以及{length}+件商品',
            stacked: '，可叠加商品折扣使用',
            available_only: '部分商品可用',
            available_all: '全部商品可用',
            coupon_code: '优惠券代码',
            got_it: '明白',
            day_validity: '还有{dayNum}天到期',
            more_info: '您可以在积分商城中使用积分与优惠券兑换商品。此优惠券{available}。',
            free_redemption: '礼品兑换券',
            discount_off: '{discount}% 折扣优惠券',
            discount_price: '折扣价: ',
            original_price: '原价：',
            after_coupon_price: '券后价',
            discount_price2: '折扣价',
            returned: '（已退回）',
            promotion: '活动',
            registration: '注册',
            trading: '交易',
            deposit: '入金',
            search_placeholder: '输入商品名称或优惠券代码进行搜索',
        },
    },
    otp: {
        authenticator: '验证器',
        add: '添加',
        name: '名称',
        two_factor: '双因素验证器',
        add_time: '添加时间',
        action: '操作',
        add_authenticator: '添加验证器',
        edit_authenticator: '编辑验证器',
        edit_sub_title: '登录场景可自行开启/关闭OTP功能。',
        login: '登录',
        forget_pwd: '忘记密码',
        change_pwd: '修改密码',
        change_email: '修改邮箱',
        withdrawal: '出金',
        withdrawal_tip: '仅应用于已开启OTP验证的出金方式',
        transfer_to_sub: '转账至下级',
        note: '备注:',
        tip_apply: '可作用于以下功能：登录，忘记密码，修改密码，修改邮箱，出金，转账至下级。',
        tip_method: '开启验证器后，点击编辑图标，可选择开启/关闭于登录功能。',
        please_install: '请先在您的手机中安装验证器',
        please_ensure: '请确保您已在手机中安装任意验证器，然后点击“下一步”。',
        recommend: '推荐使用：',
        microsoft: '微软验证器',
        google: '谷歌验证器',
        scan_qr: '请使用您手机中的验证器扫描此二维码。',
        cant_scan: '无法扫描二维码？',
        scan_next: '扫描后点击“下一步“',
        input_manually: '请手动输入以下信息。',
        account: '账号：',
        key: '密钥：',
        bind_authenticator: '绑定验证器',
        please_input: '请输入验证器中的一次性密码。',
        bind_successful: '验证器绑定成功！',
        delete_authenticator: '删除验证器',
        verification: '一次性密码校验',
        sure_delete: '你确定要删除此绑定的验证器吗？',
        authenticator_deleted: '验证器已删除！',
        please_input_complete: '请输入您验证器上的一次性密码来完成校验。',
        one_time_password: '一次性密码',
        lost_authenticator: '丢失验证器？',
        lost_authenticator_contact: '如果您丢失了验证器，请联系我们的客户团队。',
        reset_authenticator: '重置验证器',
        please_fulfill: '请完成以下验证，您将会收到一封含有验证码的邮件来完成本次重置验证器。',
        code_expire: '我们已将包含有验证码的邮件发送至 {email} 邮箱，验证码将会在 {time} 失效，验证通过后成功登陆将自动删除您的验证器。',
        verification_code: '验证码',
        not_get_email: '没有收到邮件？',
        been_sent: '验证码已发送至您的注册邮箱，有效时长为{time}分钟，请输入验证码以完成校验。',
        try_again: '验证码已经发送，请{time}分钟后再试。',
        been_reset: '您的验证器已重置，请重新登陆。',
        verify: '验证',
        reset_password: '重置密码',
        apply: '申请',
        or_use_email: '或使用邮箱验证',
        have_trouble_use_otp: '无法使用验证器？',
        use_email: '通过邮箱完成验证。',
    },

    vxe: {
        loading: {
            text: '加载中...',
        },
        table: {
            emptyText: '没有数据',
        },
    },
};
module.exports = zh_local;
