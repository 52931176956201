/**
 * 全部用小写，加下划线区分内容。因为这样的长文字可读性比驼峰高
 * 命名不可随意命名，必须是能代表有相关意思
 * 字段写入的位置必须跟 zh.js 的位置。这是为了后续的外部扩展语言文件的功能准备的
 */
const en_local = {
    inverse: 'INVERSE',
    home_page: 'Portal Homepage',
    isno: 'NO',
    isyes: 'YES',
    apply_now: 'Apply Now',
    one_click_close: 'CLOSE ALL TABS',
    loading: 'LOADING',
    login_name: 'Login Name',
    login_email: 'Email',
    login: 'LOGIN',
    welcome_to: 'WELCOME',
    login_to_access_your_account: 'Login to access your account',
    remember_me: 'Keep me logged in for the next 7 days',
    forgot_password: 'Forgot Password?',
    login_tip: 'If you do not have an account,',
    sign_up: 'sign up',
    copyright: 'Copyright',
    account: 'Account',
    password: 'Password',
    sign_in_cap: 'LOGIN',
    register_in_cap: 'REGISTER',
    enter_the_correct_date_format: 'Please enter the correct date format',
    date_format_correct: 'The date format is correct',
    sign_in: 'Sign In',
    register_in: 'Register',
    edit: 'Edit',
    add: 'Add',
    close: 'Close',
    filter: 'Filter',
    submit: 'SUBMIT',
    submit_lc: 'Submit',
    export: 'EXPORT',
    email: 'EMAIL',
    reset: 'RESET',
    reset_lc: 'Reset',
    search: 'Search',
    cancel: 'CANCEL',
    cancel_lc: 'Cancel',
    choose_lc: 'Choose',
    confirm: 'Confirm',
    view: 'View',
    go: 'GO',
    send: 'SEND',
    send_lc: 'Send',
    yes: 'YES',
    yes_lc: 'Yes',
    save: 'Save',
    update: 'UPDATE',
    delete: 'Delete',
    failed: 'Failed',
    back: 'BACK',
    back_lc: 'Back',
    next: 'NEXT',
    next_lc: 'Next',
    From: 'From',
    To: 'To',
    to: 'to',
    upload: 'UPLOAD',
    upload_file: 'UPLOAD FILE',
    sign_out: 'Sign Out',
    apply: 'APPLY',
    apply_lc: 'Apply',
    trade_account: 'Trading Account',
    select: 'Select',
    export_csv: 'Export CSV',
    export_all: 'Export All',
    export_search: 'Export Search Result',
    back_login: 'Back to Login',
    question: 'Question',
    empty_data_export: 'Empty value, can not export!',
    switch_to: 'Switch to',
    ib_portal: 'IB Portal',
    member_portal: 'Member Portal',
    mt4_time_tip: '* Please note all time in the page is MT4/5 local time based.',
    choose: 'CHOOSE',
    delete_agreement: 'DELETE AGREEMENT',
    select_file: 'SELECT FILE',
    no_lang: 'No translation for this language is currently available',
    the_password: 'Incorrect input format',
    the_password_lowercase: 'The password lacks lowercase',
    the_password_uppercase: 'The password lacks uppercase',
    the_password_digits: 'The password lacks digits',
    tree_customer: 'Customer',
    tree_client: 'Client',
    enter_password: 'Please enter your password',
    confirm_password_error: 'Password does not match',
    error_message: 'Sorry, we could not find the content you were looking for, please contact ThreeTrader Support for further assistance',
    optional: '(Optional)',
    en: 'EN',
    dis: 'DIS',
    ro: 'RO',
    ac: 'AC',
    disabled: 'Disabled',
    enabled: 'Enabled',
    readonly: 'Readonly',
    archive: 'Archive',
    id_vf: 'Verify ID Card',
    add_vf: 'Verify Address',
    bank_vf: 'Verify Bank Card',
    Verified: 'Verified',
    Unverified: 'Unverified',
    rejected: 'Rejected',
    pending: 'Pending',
    ambiguous: 'Ambiguous',
    incorrect: 'Incorrect',
    qr_code: 'QR Code',
    copy: 'COPY',
    open_link: 'OPEN LINK',
    desktop: 'Desktop',
    mobile: 'Mobile',
    domain: 'Domain',
    display_name: 'Display Name',
    node_link_title: '%{sever} -%{account} IB Link >>',
    got_it: 'Got it',
    country: 'Country and Region',
    page: {
        next: 'Next',
        prev: 'Prev',
        get_total: 'Get Total',
    },
    agree: 'AGREE',
    collapse_all_TA: 'COLLAPSE ALL TA',
    expand_all_TA: 'EXPAND ALL TA',
    generate_flat_report: 'Generate Flat Report',
    download_btn: 'DOWNLOAD',
    disclaimer_foot: 'Disclaimer',
    generate_all_historical_orders: 'Generate All Historical Orders',
    recent_order_tip: '* Recent Orders will only display the last {days} days, please use Historical Orders to see orders older than {days} days. Please note all time in the page is MT4/5 local time based.',
    history_order_tip: '* Historical Orders will only display orders from {days} days ago, please use Recent Orders to see the {days} days orders. Please note all time in the page is MT4/5 local time based.',
    an_email_has_been_sent_to_your_email_address: 'An email has been sent to your email address',
    trading_account_type: 'TA Type',
    ta: 'TA',
    continue: 'CONTINUE',
    certified: 'Authentication successful',
    auth_fail: 'Authentication failed',
    jump_fail: 'Fail',
    jump_fail_tip: 'Please return to the member portal, refresh the page and reauthenticate',
    certified_tip: 'Please return to the member portal',
    auth_fail_tip: 'Please return to the member portal and reauthenticate',
    auth_hardware_fail_tip: 'Please return to the member portal and contact the administrator',
    menu: {
        email_verify: 'Email Verify',
        dashboard: 'Dashboard',
        clients_report: 'Clients Report',
        funding: 'Funding',
        rebate_report_management: 'Rebate Report Management',
        orders_report_management: 'Orders Report Management',
        ib_profile: 'IB Profile',
        trading_tools: 'Trading Tools',
        downloads: 'Downloads',
        reports: 'Reports',
        documents: 'Documents',
        rewards: 'Rewards',
        account_settings: 'Account Settings',
        message_center: 'Message Center',
        payment_info: 'Saved Payment Information',
        verification: 'Verification',
        trading_accounts: 'Trading Accounts',
        web_mt4: 'WEB MT4',
        web_trader: 'Web Trader',
        trading_central: 'Trading Central',
        member_profile: 'Member Profile',
        ib_management: 'IB Management',
        node_link: 'IB Links',
        referral_links: 'Custom Links',
        login: 'Login',
        register: 'Register',
        Retrieve_Password: 'Retrieve Password',
        applyDemoTa: 'Demo Account Application',
        applyLiveTa: 'Live Account Application',
        get_verified_now: 'Get Verified Now',
        promotions: 'Promotions',
    },
    promotions: {
        promotion_will_finished: 'Hi there, please choose the reward you would like to redeem! Once reward was redeemed, this promotion will be finished.',
        view_details: 'View details',
        you_have_won: 'You have won',
        you_can_win_points: 'YOU CAN WIN POINTS',
        you_can_win_coupons: 'YOU CAN WIN COUPONS',
        await_win: 'VARIETY OF PRIZES AWAIT YOUR WINNING',
        one_of_the_following_rewards: 'One of the prize sets',
        reward_description: 'Reward Description',
        claim_one_points: 'Meet the conditions to claim one of the rewards.',
        check_gift_list: 'Please check the promotion description page to acquire further rewards detail.',
        earned_coupons: 'Earned Coupons',
        redeem_your_reward: 'Redeem your reward',
        choose_the_reward: 'Hi there, please choose the reward you would like to redeem!',
        have_redeemed: 'Congratulations you have redeemed fellow rewards!',
        redeemed_successfully: 'Rewards redeemed successfully.',
        some_failure: 'Some rewards were failure to redeem. Please contact our customer support team for assistance.',
        failed_redeem: 'Failed to redeem rewards',
        apologize_failure: 'We apologize for the failure to receive following rewards. Please contact our customer support team for assistance.',
        redeem_now_better: 'Would you want to redeem the reward now? You can continue to trade for better rewards!',
        redeem_now_2: 'Redeem now?',
        continue_to_trade: 'Continue to Trade',

        coupon_claim_not_successful: 'Your coupon claim was not successful. Please contact our customer support team for assistance.',
        have_received_rewards: 'You have successfully received {rewards}!',
        apologize_for_failure: 'We apologize for the failure to receive {coupons}. Please contact our customer support team for assistance.',
        ended_detail: 'Ended-Detail',
        Timezone: 'Timezone',
        I_have_read_and_agreed: 'I have read and agreed	',
        Completed_Deposits: 'Completed Deposits',
        Completed_Volumes: 'Completed Volumes',
        Need_Agree: 'You need to agree to the agreements.',
        Activation_Tips: 'Before the promotion officially starts, you would need to meet the following activation requirements.',
        Please_Reload: 'It may take some time to calculate your latest promotion progress. Please reload the page to check the latest progress.',
        No_Promotion: 'We apologize for the inconvenience. There are currently no ongoing promotions. Stay tuned for future exciting offers and promotions.',
        Check_Rewards: 'You can check it in the {menu}.',
        Promotion_Name: 'Promotion Name',
        Total_Rewards: 'Total Rewards',
        CouponsNum: 'a coupon | {num} coupons',
        PointsNum_CouponsNum: '{pointsNum} {pointsDisplay} and a coupon | {pointsNum} {pointsDisplay} and {couponsNum} coupons',
        Points_Coupons: '{pointsDisplay} and COUPONS',
        Funding_Method: 'Funding Method',
        Activity_Rules: 'Activity Rules',
        Can_Use_It: 'You can use POINTS and Coupons to redeem items in the Rewards.',
        Coupons_And_Vouchers: 'COUPONS AND VOUCHERS YOU HAVE WON',
        MoreCoupons: 'and {num}+ coupons',
        Adjust_Progress: 'ADJUST THE ORDER OF PROGRESS SETTLEMENT',
        Change_Priority_Title: 'Hi there, would you want to change the priority?',
        Change_Priority_Message: 'Progress of deposit and trading will be settled in the promotion with the highest priority.',
        Change_Priority_Tip: '*Changing the priority of promotions may have an impact on the accuracy of progress calculations.',
        Send_Backward: 'Send Backward',
        Bring_Forward: 'Bring Forward',
        Participating_trading_account: 'PARTICIPATING TRADING ACCOUNT',
        Participated_trading_account: 'Participated Trading Account',
        Promotion_Period: 'Promotion Period',
        Application_Period: 'Application Period',
        Shared_Progress: 'Shared Progress',
        TIME: 'TIME',
        points: '{num} {pointsDisplay}',
        Earned_Coupons: 'Earned Coupons',
        Completed_Promotion_Tip: 'Completed Your First Promotion to Receive Coupon Now!',
        deposit: 'Deposit',
        volume: 'Volume',
        ACTIVATING: 'ACTIVATING',
        Accumulated_Balance: 'Accumulated Balance',
        CONGRATULATIONS: 'CONGRATULATIONS!',
        COUPONS_AWARDED: 'Coupons Awarded',
        Coming_Soon: 'Coming Soon',
        Coming_soon_day_left: 'Coming soon: {dayNum}-day{time} left',
        Completed_Entries: 'Completed Entries',
        Congratulations: 'Congratulations!',
        Reward_Requirements: 'You have met the requirements to redeem your reward.',
        Congratulations_rewards: 'You have won {rewards}.',
        DETAIL: 'DETAIL',
        Deposit_Now: 'Deposit Now',
        ENDING_TIME: 'ENDING TIME',
        Ended: 'Ended',
        Finish: 'Finish',
        join_again: 'Hi there, would you want to join again?',
        I_got_it: 'I got it',
        Join_Again_Now: 'Join Again Now',
        Join_again_now: 'Join again now!',
        Learn_More: 'Learn More',
        OFF: 'OFF',
        On_going: 'On-going',
        One_time_Reward: 'One-time Reward',
        PARTICIPATE_NOW: 'PARTICIPATE NOW',
        PAUSED: 'PAUSED',
        PROGRESS_PAUSED: 'PROGRESS - PAUSED',
        PROGRESS_READY: 'PROGRESS - READY',
        PROGRESS_FINISHED: 'PROGRESS - FINISHED',
        PROGRESS_PROMOTION_ACTIVATING: 'PROGRESS - PROMOTION ACTIVATING',
        PROGRESS_PROMOTION_STARTED: 'PROGRESS - PROMOTION STARTED',
        PROGRESS_REDEEMABLE: 'PROGRESS - REDEEMABLE',
        Participable: 'Participable',
        View_all_coupons: 'View all coupons >',
        Participate_Now: 'Participate Now',
        Participated_Period: 'Participated Period',
        Previously_participated: 'Previously participated',
        Promotion_Description: 'Promotion Description',
        Promotion_Progress: 'Promotion Progress',
        READY: 'READY',
        REDEEMABLE: 'REDEEMABLE',
        RELATED_TRANSACTIONS_ORDERS: 'RELATED TRANSACTIONS & ORDERS',
        Record_participation: 'Record of participation',
        Redeem_Now: 'Redeem Now',
        STAGE: 'Stage {stage}',
        STARTED: 'STARTED',
        Sign_up_Now: 'Sign up Now',
        TOTAL_WIN: 'TOTAL WIN {rewards}!',
        You_can_win: 'You can win',
        win_more_rewards: 'You can win more rewards.',
        win_in_each_round: 'You can win {rewards} in each round!',
        participated_promotion: 'You have participated in the promotion!',
        day_left: '{dayNum}-day{time} left',
        account_type: 'TYPE',
        transaction_no: 'TRANSACTION NO.',
        trading_account: 'TRADING ACCOUNT',
        fund_in_amount: 'FUND IN/OUT AMOUNT',
        time_submitted: 'TIME SUBMITTED',
        time_completed: 'TIME COMPLETED',
    },
    member_upgrade: {
        drawer_close_tip: 'Content will not be saved, do you want to proceed?',
        component_back_tip: 'Content will not be saved, do you want to proceed?',
        submit_tip: 'Do you want to submit?',
        question_error: 'You failed to achieve the required number of correct answers, please try again after: {time}',
        document_apply_tip: 'Your documents are under review. You will be notified when the process has been completed. ',
        submit_success: 'Upgrade submitted successfully',
        upgrade_account_title: 'UPGRADE MEMBER ACCOUNT',
        member_upgrade_title: 'Member Upgrade',
        agreement_desc_title: 'Agreement',
        agreement_desc_sub_title: 'Member Account Upgrade Service Agreements',
        questionnaire_desc_title: 'Questionnaire',
        questionnaire_desc_sub_title: 'Complete Questionnaire to proceed',
        verification_desc_title: 'Verification',
        verification_desc_sub_title: 'Please upload the required documents',
        template_name: '*Click to download %{name} template',
        become_a: 'BECOME A',
        pro_trader: 'ThreeTrader PRO TRADER',
    },
    register: {
        send_reminder: 'A verification email with a verification link has been sent to {email}, please click the link to complete the verification.',
        expired_reminder: 'This link has expired, a new verification link has been sent to {email}.',
        invalid_reminder: 'Verification Link is invalid.',
        pass_reminder: 'Email has been verified. Please close this page.',

        you_have_uploaded_maximum_number_of_documents: 'You have uploaded the maximum number of documents',
        member_title_live: 'Live Account Application',
        member_title_demo: 'Demo Account Application',
        personal_info: 'Personal Info',
        additional_info: 'Additional Info',
        questionnaire: 'Questionnaire',
        continue_questionnaire: 'Continue Questionnaire',
        account_configuration: 'Account Configuration',
        demo_completed: 'Demo Trading Account registration is completed',
        live_completed: 'Live Trading Account registration is completed',
        crm_password: 'CRM Password',
        set_invest: 'Set an Investor Password',
        set_trading_password: 'Set a trading password',
        set_trading: 'Set Password',
        edit_notes: 'Notes',
        invest_password: 'Investor Password',
        trading_password: 'Password',
        trading_server: 'Trading Server',
        live_title: 'Live Account Application',
        upload_tips1: 'Your ID verification did not pass, please upload your proof document, or you can edit your information and resubmit it.',
        upload_tips: '(Provide at least two proof files)',
        identificationProofLabel: 'Photo ID',
        addressProofLabel: 'Proof of Address',
        bankStatementLabel: 'Bank statement',
        upload_file: 'Drop files here or click to upload',
        accept_file_label: 'Only accept .jpg .png .bmp type file under 10M',
        fill: 'Fill in your personal information',
        demo_title: 'Demo Account Application',
        deposit: 'Initial Deposit',
        upload_proof_document: 'Get Verified Now',
        title: 'Title',
        id_number: 'ID Number',
        country_of_origin: 'Country of Origin',
        is_black_tips: 'Unfortunately, the service is not available on the selected country/region',
        please_select_birth_date: 'Please select date of birth',
        year: 'Year',
        month: 'Month',
        day: 'Day',
        please_select_the_field: 'Please select the {field}',
        please_enter_the_field: 'Please enter the {field}',
        do_not_input_char: 'Do not input character {char}',
        the_fields_in_red_are_required: 'The fields in red are required',
        please_upload_all_documents_as_required: 'Please upload all documents as required',
        mobile_number: 'Mobile Number',
        chinese_name: 'Chinese Name',
        please_enter_chinese: 'Can only enter Chinese',
        info_title_tips: 'Please note the information you provide needs to be verifiable with proof of identity (eg identity card or passport)',
        verification: {
            tips_1: 'Before you make a deposit and start trading with us, we need to verify your identity.',
            tips_2: 'We can verify you electronically, please fill out the details below.',
            confirm:
                'I consent to ThreeTrader Markets electronically verifying my identity by providing my personal information, including identity documents, to an identity verification service to obtain an assessment of whether it matches information held by them or other identity verification sources.',
            choose_method: 'Choose your verification method :',
            document_details: 'Document Details',
            method: {
                au_driver_licence_residential: 'Australia Driver’s License',
                au_medicare_residential: 'Australia Medicare Card',
            },
            upload_at_least_doc_tip_for_photo_id: 'Please upload at least {x} {document_wording} for {proof_type}.',
            upload_at_least_doc_tip_for_proof_of_addr: ' Please upload at least {x} {document_wording} for {proof_type}.',
            good_job: 'Well done!',
            your_documents_were_sent_for_verification: 'Your documents were sent for verification.',
            you_will_receive_an_email_once_they_are_verified: 'You will receive an email once they are verified.',
            GO_TO_PORTAL: 'GO TO PORTAL',
            electronic_description_1:
                'In order to speed up the verification process, enter your ID details into our online portal for instant verification. Our interface with the verification allows identifying information to be compared against the record of the agency that issued the document.',
            electronic_description_2: "If we are unable to verify you electronically, we will need you to upload your documents below. We'll inform you as soon as your account is approved.",
            photo_description_1: 'Please upload one of the following documents',
            photo_description_2: '* All photo ID documents must be valid and clearly show your full name, date of birth and Issue/Expiry date.',
            proof_description_2: '* The proof of address document should confirm your full residential address and be dated within the last three months.',
            ocr: {
                title: 'Choose your verification method',
                uploadChineseId: 'UPLOAD CHINESE ID',
                typeInInformation: 'TYPE IN INFORMATION',
                tip: 'If you do not have a Chinese ID please Type in Information manually (option on the right).',
                id_front: 'ID FRONT',
                id_back: 'ID BACK',
                mobile_tip: 'Please make sure to provide YOUR OWN mobile number otherwise the verification will fail.',
                input_manually: 'INPUT MANUALLY',
                continue_button: 'CONTINUE',
                error_tip1_title: 'OOPS - ERROR!',
                error_tip1_desc: 'Unfortunately, we were unable to complete automatic verification. Please upload your documents manually and our team will process your verification within an hour.',
                error_tip2_desc: 'Sorry, we were unable to detect your ID correctly. Please ensure your ID(s) are clean and tidy. You can choose to upload them again or input your details manually.',
                error_tip3_desc: 'Sorry, We were unable to detect your ID correctly. Please input your details manually, thanks! ',
                error_tip4_desc: 'The ID you uploaded has expired, Please upload a valid ID, thanks!',
                recommendation: 'Recommendation',
                single_bg_tip: 'For optimal results, capture coloured photos against a well-lit, single-colour background.',
                uncropped_tip: 'Ensure the photos remain uncropped, displaying complete ID information clearly.',
                overexposure_tip: 'Pay attention to clarity, avoiding overexposure and glare.',
                guiding_example: 'Guiding example',
                cropping: 'Cropping',
                excessive_blank_space: 'Excessive blank space',
                blurriness: 'Blurriness',
                overexposure: 'Overexposure',
                glare: 'Glare',
            },
            group: {
                no_ev: "I don't have any above document",
                cn_id_2d_and_3d: 'China ID card',
                au_driver_licence_residential: 'Australia Driver’s License',
                au_passport_residential: 'Australia Passport',
                au_medicare_residential: 'Australia Medicare Card',
                au_international_passport_visa: 'International Passport',
                hk_id_residential: 'Hong Kong National ID',
                indonesia_id_tax: 'Indonesia Resident Identity Card',
                my_id_credit_bureau: 'Malaysia National ID',
                sg_credit_bureau_utility: 'Singapore National ID',
                ph_id_credit_bureau: 'Philippines National ID',
                vn_id_social_security: 'Vietnam ID card',
                th_national_id: 'Thailand National ID',
                sa_national_id: 'South Africa National ID',
                kh_national_id: 'Cambodia National ID',
                id3_passport: 'Passport',
            },
            field: {
                driversLicenceNo: 'Driver Licence Number',
                driversLicenceCardNo: 'Driver Licence Card Number',
                driversLicenceStateOfIssue: 'State Of Issue',
                driversLicenceExpiryDate: 'Expiry Date',
                passportNo: 'Passport Number',
                passportExpiry: 'Passport Expiry',
                medicareCardType: 'Medicare Card Type',
                medicareCardNo: 'Medicare Card Number',
                medicareExpiry: 'Medicare Expiry',
                medicareReferenceNo: 'Medicare Reference Number',
                passportIssuerCountry: 'Passport Issuer Country and Region',
                passportCountry: 'Passport Country and Region',
                nationalIDNo: 'National ID Number',
                nik: 'NIK',
                npwp: 'NPWP',
                phoneNo: 'Phone Number',
                nationalIDType: 'National ID Type',
                idCardNo: 'ID Card Number',
                laserNo: 'Laser Number',
                thaiFirstName: 'First Name(Thai)',
                thaiLastName: 'Last Name(Thai)',
                Number: 'Passport Number',
                CountryOfOrigin: 'Country of Origin',
                PassportExpiry: 'Expiry',
                gender: 'Gender',
                voterNo: 'Voter Number',
                khmerScriptFirstName: 'First Name(Khmer Script)',
                khmerScriptLastName: 'Last Name(Khmer Script)',
                id_number: 'ID Number',
                name: 'Full Name',
                phone_number: 'Mobile Number',
                cn_name: 'Chinese Name',
            },
            tip: {
                driversLicenceCardNo_tip: '*Card no. is not required for VIC and QLD issued driver licenses.',
            },
            dialog: {
                dialog_1_title: 'CONGRATULATIONS!',
                dialog_1_message: 'Your identity was successfully verified, and you can now start trading with us.',
                goto_dashboard: 'GO TO DASHBOARD',
                mark_a_deposit: 'MAKE A DEPOSIT',
                dialog_2_title: 'CONGRATULATIONS!',
                dialog_3_title: 'REGISTRATION NOTICE',
                dialog_3_message: 'Unfortunately, we were not able to verify you automatically. Please upload your documents to get verified, it takes a few seconds to upload, and we’ll get back to you within 1 hour.',
                try_again: 'TRY AGAIN',
                double_check_details: 'DOUBLE CHECK YOUR DETAILS',
                upload_documents: 'UPLOAD DOCUMENTS',
                dialog_4_title: 'ELECTRONIC VERIFICATION FAILED',
                dialog_4_message: 'Unfortunately, we were not able to verify you automatically. As the 3rd attempt has failed, we need you to upload your documents manually, and we’ll get back to you within 1 hour.',
                dialog_5_message: 'Your documents were submitted for verification! You will receive an email once verification is complete.',
                id_used_tip: `We've found that this ID number has already been registered with us. Please note that each ID can only be registered once in our system. If you need to update your email address or reactivate an existing account, please reach out to our customer service team. We're here to help you with any inquiries.Thank you for your cooperation!`,
            },
        },
        upload_documents: {
            photo_id: 'Photo ID*',
            poi_notes: 'Please upload one of the following documents',
            passport: 'Passport',
            national_id: 'National ID',
            drivers_license: 'Drivers License',
            poi_hints: '*All photo ID documents must be valid and clearly show your full name, date of birth and Issue/Expiry date.',
            selfie: 'Selfie*',
            selfie_notes: 'Please upload a picture of yourself holding the document uploaded in item 1 (Photo ID)',
            selfie_hints: '*All photo ID documents must be valid and clearly show your full name, date of birth and Issue/Expiry date.',
            poa: 'Proof of Address*',
            notes: 'Please upload the following document',
            utility_bill: 'Utility Bill',
            gas_water_electricity: 'Gas/Water/Electricity',
            Bank_Credit: 'Bank/Credit Card Statement',
            utility_bill_or: 'Landline Phone/Broadband Bill',
            poa_hints:
                'Your Bank Statement should confirm your full residential address and be dated within the last three months or you will need to upload an additional document (options above) confirming your address within the last 3 months.',
            bank: 'Bank Statement*',
            bank_hints: 'Your Bank Statement should confirm your full residential address and be dated within the last three months.',
            thank_you: 'THANK YOU',
            upload_success_notes: 'Your documents were sent for verification. You will receive an email once they are verified.',
            go_to_dashboard: 'GO TO DASHBOARD',
            take_a_picture: 'Take a picture from your computer or mobile device',
            select_a_file: 'Click to select a file or drag and drop a file from your computer',
            mobile_upload_document: 'Upload your document',
            mobile_new_document: 'Upload new document',
        },
        isRegisterTips: "It looks like you have started your registration with us but didn't complete it, would you like to receive an email to continue from where you left off?",
        yes: 'YES',
        use_a_different_email: 'No ,I’ll use a different email',
        send_me_the_link: 'Send me the link',
        no_tips: "NO (I'll use a different email)",
        ok: 'OK',
        The_link_has_been_sent_please_kindly_check_your_inbox: 'The link has been sent, please check your inbox.',
        stepOne: {
            first_name: 'First Name',
            middle_name: 'Middle Name',
            last_name: 'Last Name',
            first_name_cn: 'First Name',
            middle_name_cn: 'Middle Name',
            last_name_cn: 'Last Name',
            first_name_cn_ev: 'First Name',
            last_name_cn_ev: 'Last Name',
            country: 'Country & Region',
            email: 'Email',
            mobile: 'Mobile Number',
            code: 'Country Code',
            referral_code: 'Referral Code',
            client_portal_password: 'Client Portal Password',
            apply_member_account: 'Member Account Application',
            upload_tip: 'We need more information to verify your account.',
            country_region_of_residence: `Country/Region of Residence`,
        },
        additionalInfo: {
            date_title_tips: '(*Please enter the date in the correct time format)',
            date_format_tips: 'Born YYYY-MM-DD',
            pick_a_date: 'Pick a date',
            photo_id: 'Photo ID',
            photo_id_number: 'Photo ID Number',
            photo_id_expiry_date: 'Photo ID Expiry Date',
            id_number: 'ID Number',
            id_selection: 'Electronic Verification',
            china_national_id: 'China National ID',
            phone: 'phone',
            title: 'Title',
            gender: 'Gender',
            birth: 'Date of Birth',
            birth_format: 'Date of Birth (YYYY-MM-DD)',
            postcode: 'Postcode',
            address: 'Address',
            address_one: 'Detailed Address',
            address_two: 'Detailed Address',
            town: 'Town',
            province: 'Province',
            city: 'City',
            suburb: 'Suburb',
            state: 'State',
            district: 'District',
            prefecture: 'Prefecture',
            city_town_village: 'City/Town/Village',
            state_province: 'State/Province',
            province_city: 'Province/City',
            city_post_town: 'City/Post Town',
            village_road: 'Village/Road',
            flat_house: 'Flat/House',
            street_region: 'Street/Region',
            ward_town: 'Ward/Town',
            region: 'Region',
            building_street: 'Building/Street',
            building_unit: 'Building/Unit',
            village_subdistrict: 'Village/Subdistrict',
            district_town: 'District/Town',
            street_name_and_number: 'Street Name and Number',
            barangay_suburb_or_village: 'Barangay(Suburb or Village)',
            unit_or_flat_number: 'Unit or Flat Number',
            street_level_information: 'Street Level Information',
            street_address_of_building: 'Street Address of Building',
            unit_number: 'Unit Number',
            passport: 'Global Passport',
            reference_number: 'Reference Number',
            expiry_time: 'Expiry Date',
            country_of_origin: 'Country of Origin',
            drive_state: 'Drive State',
            male: 'Male',
            female: 'Female',
            other: 'Others',
            referral_code: 'Referral Code',
            mr: 'Mr.',
            mrs: 'Mrs.',
            mx: 'Mx.',
            ms: 'Ms.',
            miss: 'Miss.',
            dr: 'Dr.',
            delete_proof_tips: 'Delete this proof?',
            other_information: 'Other Information',
            verification: 'Verification',
            residential_address: 'Residential Address',
            residential_address_tips: '(*Please do not enter PO box or Postal Address and ensure your residential address is correct and complete)',
            process_to_next_step: 'The residential address entered is correct and complete',
            close_attention_popup: 'I will check and revise my address details',
            address_confirm_tips: 'Please ensure your residential address information entered is correct and complete. Incomplete or incorrect address information may cause delay in your account opening process.',
        },
        stepThree: {
            answer: 'Answer Quiz',
            multiple: 'Multiple Answers',
            option: 'Optional',
            textarea: 'e.g. Answer',
            q_title: 'Questionnaire %{index}',
            country_list: 'Country and Region List',
            answer2: 'Answer',
            warning: 'Warning',
            test_failed: 'Test Failed',
            did_not_pass: 'Unfortunately, you did not pass the test.',
            review_or_try: 'You can choose to review the result or go for another try.',
            review_and_retry: 'Please Review and Correct your answer before we can process you to next step.',
            close: `It's important to trade with capital you can afford to lose. Unfortunately, your income and/or savings do not meet our internal criteria so we're unable to open an account for you. Please check you've provided the correct information. If you have any questions, please get in touch with us.`,
            result: 'Result',
            retry: 'Retry',
            please_try_after: 'Please try after {time}',
            questionnaire_count_down: 'You have reached the limit for taking questionnaire, please try after {time}.',
            popup_tip_title: 'Popup Tip',
        },
        stepFour: {
            login_name: 'Login Name',
            login_rules: '*6 to 16 characters, can be numbers, lowercase, capitals, underline',
            account: 'Account Type',
            leverage: 'Account Leverage',
            currency: 'Account Currency',
            password: 'Password',
            captcha: 'Captcha',
            change_one: 'Change one',
            not_changed_account_name: "*Can't be changed after account was created.",
            trading_platform: 'Trading Platform',
        },
        complete: {
            title: 'Member Account registration is completed',
            text_one: 'Thank you for choosing %{companyName} as your trading and investment partner.Our team member will be in contact with you as soon as we can. And here comes emails for you as below:',
            text_two: 'In the meantime, should you have any questions, please feel free to contact us via email ',
            login_to_portal: 'Got it',
        },
        id: {
            malaysia_national_id: 'Malaysia National ID',
            vietnam_national_id: 'Vietnam National ID',
            philippines_credit_bureau: 'Philippines Residential Card',
            cambodia_national_id: 'Cambodia National ID',
            indonesia_resident_identity_card: 'Indonesia Resident Identity Card',
            south_africa_national_id: 'South Africa National ID',

            australia_driving_licence: 'Australian Driving Licence',
            australia_medicare: 'Australian Medicare',
            australia_short_passport: 'Australian Passport',
            // international_passport: 'International Passport',
            international_passport: 'Global Passport',
            identification_card: 'China National ID',
            // 中国居民身份证（Deep Finch Aliyun） 不是相同翻译，一个是使用阿里云，一个不是
            deep_finch_aliyun: 'China National ID',
            hk_id_card: 'Hong Kong ID Card',
            no_certificate: 'I do not have these documents',
        },
        error: {
            mobile_error: 'Please enter a valid mobile number',
            null_error: 'Input can not be empty',
            email_error: 'Please enter the correct email address',
            num_error: 'Requires to enter  numbers',
            id_name_error: 'Requires to enter numbers and letters',
            name_error: 'Please use English letters only',
            no_year: 'Please note that in order to open an account with us you need to be at least %{year} years old',
            number_count: 'Can only enter one number',
            id_length: 'Must be 18 characters',
            passport_min_length: 'Enter at least 7 characters',
            passport_max_length: 'Enter up to 44 characters',
            chinese: 'Can not input Chinese',
            china_count: 'Can only enter Chinese',
            id_card: 'Please enter a valid ID number',
            en_error: 'Please enter english capitalization',
            postcode_error: 'Please enter the correct post code',
            tip: 'Please enter the mobile number',
            mobile_num_error: 'Enter at least 8 digits phone number',
            enter_number: 'Please enter numbers only',
            please_enter_your_field: 'Please enter your %{field}',
            please_enter_voter_id: 'Please enter a valid Voter ID',
            please_finish_all_required_questionnaires: 'Please finish all required questionnaires',
        },
        processing: 'We’re processing your request of create account, please don’t leave this page',
        error_wrong: 'There is something wrong',
        error_account: 'with the request of create account',
        error_content: 'We apologize for the inconvenience. You can contact your account manager or contact us by email.',
        go_back: 'GO BACK >',
        btn_back: 'BACK',
        btn_next: 'NEXT',
        btn_submit: 'SUBMIT',
    },
    leftBar: {
        member_info_title: 'SIGN UP FOR A LIVE ACCOUNT',
        member_info_content: 'Your personal details',
        step_one_content: 'A bit more about you',
        step_two_content: 'Complete Questionnaire to proceed',
        step_three_content: 'Account Configuration',
        demo_title: 'SIGN UP FOR A DEMO ACCOUNT',
        demo_content: 'Your personal details',
        demo_ta_content: 'Account Configuration',
    },
    transfer: {
        popup: {
            title: 'Transfer for',
            from: 'From',
            to: 'To',
            trading_account: 'Trading Account',
            fund_out_amount: 'Fund Out Amount',
            exchange_rate: 'Exchange Rate',
            payment_amount: 'Payment Amount',
            surcharge: 'Surcharge',
            fund_in_amount: 'Fund In Amount',
            to_symbol: '(To)',
            from_symbol: '(From)',
            balance: 'Balance',
            credit: 'Credit',
            equity: 'Equity',
            margin: 'Margin',
            free_margin: 'Free Margin',
            margin_level: 'Margin Level',
            transfer_successfully: 'The Transfer transaction was submitted successfully!',
            comment:
                "*Before completing your transaction, please carefully confirm that both the payment amount and the recipient's details are correct. We are not responsible for any errors in the information you provide and cannot be held liable for any resulting financial or other losses. Ensure accuracy in your submission to prevent any complications.",
        },
    },
    transfer_sub: {
        safety_tips: 'Safety Tips',
        add_now: 'Add Now',
        safety_text: 'For the safety of your funds. Please go to account settings to add an Authenticator!',
        email: 'Client Email',
        same_account_error: 'The transfer out account and the transfer in account cannot be the same.',
        trading_account: 'Client Trading Account',
        not_config_rate: 'The current exchange rate is not configured, please contact customer service for processing.',
        transfer_sub_title: "You are transferring to {display}'s Trading Account {account}.",
        insufficient_amount: 'Insufficient amount available.',
    },
    withdrawal: {
        popup: {
            title: 'Withdrawal for {serve} - {account}',
            fund_out_amount: 'Fund Out Amount',
            payment_currency: 'Payment Currency',
            bank_info: 'Saved Withdrawal Information',
            bank_name: 'Bank Name',
            bank_branch_name: 'Bank Branch Name',
            bank_province: 'Bank Province',
            bank_city: 'Bank City',
            bank_account_number: 'Bank Account Number',
            bank_account_name: 'Bank Account Name',
            bsb: 'BSB',
            bank_address: 'Bank Address',
            international_bank_code: 'International Bank Code(Swift Code)',
            international_bank_code_au: 'Swift Code',
            account_address: 'Account Holder’s Address',
            account_name: 'Account Holder’s Name',
            abn: 'ABN',
            saved_fund_info: 'Save Withdrawal Information',
            get_code: 'Get Code',
            send_code: 'The verification code has been sent to your email. It will be valid for {minutes} minutes. Please enter the code to complete the verification.',
            address_is_duplicate: 'The submitted address is already registered by another user.',
            usdt_format: 'Only Numbers and Alphabets are allowed in USDT Address.',
            iban: 'Bank Account Number / IBAN',
            swift_code: '(Swift Code)',
            sort_code: 'Sort Code',
            optional_uk: 'Recommended for UK customers',
            set_name: 'Withdrawal Information Name',
            withdrawable_successful: 'The withdrawal transaction was submitted successfully!',
            not_allowed_0: 'Amount Not Allowed as 0',
            can_not_be_empty: 'Can not be empty',
            number_only: 'Only allow positive numbers with no more than two decimal places',
            AU: 'Australia',
            Global: 'Global',
            can_number_only: 'Can only enter numbers',
            account_number: 'Account Number',
            fund_info: 'Fund Info',
            neteller_account: 'Neteller Account',
            fasapay_account_number: 'Fasapay Account Number',
            skrill_account: 'Skrill Account',
            sticPay_account: 'SticPay Account',
            id_upload_message:
                'As this is your first time submitting a withdrawal request, we need you to upload an identification document for verification below. Once the verification process is successful, you will be able to submit your withdrawal request. This process only needs to be done once.',
            id_upload_title: 'Please provide the following information to proceed.',
            id_upload_pending_title: 'Your document are under verification',
            id_upload_pending_message: 'You will receive an email once verification is complete.',
            bank_account_number_4_characters: 'The bank account number must be at least 4 characters',
        },
        email_verification: {
            email_verification_title: 'Email Verification',
            email_verification_text: 'Please input the code on your email to complete verification.',
            use_authenticator_to_verify: 'Use the authenticator to verify.',
        },
    },
    funding: {
        cant_deposit_ib: 'IB Trading Account is not allowed to make deposit.',
        cant_deposit_ib_transfer: 'IB Trading Account is not allowed to accept transfers from Non-IB Trading Account.',
        error_message: 'Please enter amount',
        title: 'Funding Management',
        trading_account_list: 'TRADING ACCOUNT LIST',
        demo_account_list: 'DEMO ACCOUNT LIST',
        mam_account_list: 'MAM ACCOUNT LIST',
        side_wallet_currency: 'Currency',
        side_wallet_balance: 'Balance',
        wallet_list: 'WALLET LIST',
        ib_list: 'IB LIST',
        type: 'TYPE',
        trading_server: 'TRADING SERVER',
        trading_account: 'TRADING ACCOUNT',
        account: 'Account',
        status: 'Status',
        currency: 'Currency',
        balance: 'Balance',
        equity: 'Equity',
        credit: 'Credit',
        notes: 'Notes',
        withdrawable_amount: 'Withdrawable Amount',
        deposit: 'DEPOSIT',
        withdrawal: 'WITHDRAWAL',
        transfer: 'TRANSFER',
        transfer_1: 'TRANSFER',
        saved_info: 'Saved Funding Information',
        choose: 'Choose',
        action: 'Action',
        method: 'METHOD',
        processing_time: 'Processing Time',
        wallet_deposit: 'WALLET DEPOSIT',
        wallet_withdrawal: 'WALLET WITHDRAWAL',
        wallet_transfer: 'WALLET TRANSFER',
        wallet_dialog_title: 'Choose currency',
        fees: 'Fees',
        ib_popup_title: 'Choose IB Account',
        add_new_payment_info: 'Add A New Payment Information',
        select_payment_info: 'Select a verified payment info',
        verify_payment_info_tips: 'We need to verify your payment information first.',
        thank_you: 'THANK YOU!',
        success_text: 'Thank you. Your withdrawal request has been received, and it will be processed in the shortest time possible. You will receive a confirmation email once completed.',
        check_history: 'Check History',
        transaction_processing: 'Transaction Processing',
        dear_client: 'Dear Client,',
        duplicate_reminder: 'We are processing your withdrawal application. Please wait for 2 minutes before submit a new withdrawal application.',
        confirm_usdt_title: 'Dear valued client,',
        confirm_usdt_address_content: 'Please kindly verify the accuracy of the entered USDT address ({USDTAddress}). Inaccurate address information may result in financial losses.',
        ta_placeholder: 'Please select a trading account',
        dialog_box: {
            deposit: 'Deposit for',
            deposit_via: 'Deposit via {method}',
            withdrawal: 'Withdrawal for',
            withdrawal_via: 'Withdrawal via {method}',
            saved_fund_info: 'Saved Fund Info for',
        },
        funding_ev_dialog: {
            face_qr_tip: 'Please scan the QR code with your cell phone for facial recognition',
            face_qr_mobile_tip: 'Please click next for facial recognition',
            face_qr_scanning_tip: 'Scanning successful, face recognition in progress ...',
            face_qr_scanning_mobile_tip: 'Face recognition in progress ...',
            face_qr_success_tip: 'Facial recognition verified',
            face_qr_fail_tip: 'Facial recognition verification failed',
            face_qr_timeout_tip: 'Facial recognition has timed out',
            new_win_confirm_tip: 'Please perform facial recognition in a new window',
            recognition: 'Re-recognition',
            complete_face_tip: 'Please complete facial recognition',
            no_need_face_tip: 'No need for facial recognition',
            title: 'DEPOSIT FOR',
            message1: 'To deposit via this channel, please select one of following verification methods.',
            auto_verify: 'Auto Verify',
            auto_verify_tip: 'You can gain instant deposit access once you pass auto verification.',
            show_upload_selfie: 'Upload a selfie with your ID',
            upload_selfie_tip: 'You will gain deposit access once our staff complete your identity verification.',
            self_taken_photo: 'Self-taken photo',
            message21: 'Please upload an image of yourself holding your ID or passport.',
            message22: '*All photo ID documents must be valid, clearly displaying your full name, date of birth and issuance/expiry date to be verified.',
            upload_selfie: 'Upload Selfie',
            submit: 'Submit',
            upload: 'Upload',
            message31: 'Please provide the following information to proceed.',
            message32: 'It appears the information provided is invalid. Please review and correct inputs before submission.',
            message4: 'Unfortunately, you have exceeded the maximum number of attempts. Please kindly upload a selfie of you holding your ID Card in order to access the deposit channel.',
            ev_succeed: 'Electronic Verification Succeed',
            ev_failed: 'Electronic Verification Failed',
            upload_success: 'We have received your handheld ID selfie. The verification result will take approximately 10-30 minutes. You can also check the result in the Documents section.',
        },
        deposit_popup: {
            country_code: 'Country Code',
            mobile_number: 'Mobile Number',
            deposit_for: 'Deposit for',
            register_this_card: 'Save Deposit Information',
            set_name: 'Deposit Information Name',
            upload_photo: 'Upload Photo',
            bank_receipt_required: 'Bank receipt is required',
            bank_receipt_upload: 'Bank Receipt Upload',
            fund_in_amount: 'Fund in Amount',
            exchange_rate: 'Exchange Rate',
            surcharge: 'Surcharge',
            payment_amount: 'Payment Amount',
            transfer_amount: 'Transfer Amount',
            payment_card: 'Payment Card',
            transfer_currency: 'Payment Currency',
            bank: 'Bank',
            voucher_no: 'Voucher No.',
            voucher_key: 'Voucher Key',
            voucher_currency: 'Voucher Currency',
            voucher_value: 'Voucher Value',
            card_number: 'Card Number',
            card_holder_name: 'Cardholder Name',
            expiry_date: 'Expiry Date',
            cvv_description: 'CVV  is the three-digit number that is printed on the signature panel on the back of the card.',
            usdt_tip_1: '1. Do not save or transfer to an old address that has already been used.',
            usdt_tip_2: '2. Please use the correct agreement ({usdtType}) to pay, otherwise it will not be credited.',
            usdt_tip_3:
                "3. Kindly ensure the actual amount of USDT transferred from your end, excluding transfer fees and other fees, is precisely matched with the 'order amount' displayed on the payment page, down to every decimal point.",
            usdt_tip_4: '4. After the transfer is completed, please submit the transfer receipt to complete the transaction.',
            usdt_time_tip: 'Please complete the transfer according to the following order within {minutes} minutes and {seconds} seconds',
            usdt_address: 'Address',
            embed_disclaimer:
                'By using the provided QR code or payment link, you agree to use a third-party payment service at your own discretion and risk, acknowledging that it may contain technical inaccuracies, errors, or delays. We have no liability to you for any loss, damage or cost which you may suffer as a result of transmission errors, technical faults, malfunctions, illegal intervention in network equipment, network overloads, malicious blocking of access by third parties, internet malfunctions, interruptions or other deficiencies on the part of internet service providers or other system errors.',
            payto: {
                create_payment: 'Create a New Payment Agreement',
                canceled_payment: 'Canceled payment agreement',
                payto_notice_one: 'Due to AML policy, third party deposits are not permitted. Please use bank accounts under your own name to create the payment agreement.',
                payto_notice_two: 'Only active payment agreements authorized through your online or mobile banking can be used for payments.',
                payto_notice_three: 'Every new deposit must be requested from the client portal. Funds will not be automatically deducted from your bank account without you initiating a deposit request.',
                payto_notice_four:
                    'Deposit Limit per transaction and payment frequency restrictions are specified in the PayTo agreement. Please note that the amount you can pay via PayTo is also limited by your bank account.',
                success_message: 'Please authorize this payment agreement in your online or mobile banking.',
                limit_message: 'You have reached the limit. Please use existing agreements for payment. ',
                exist_message: 'A payment agreement with the provided bank account already exists. Please use a different one.',
                fail_message: 'The provided bank account is invalid or not eligible for PayTo payments. Please double-check your account details and try again.',
                pending_tip: 'After authorizing this payment agreement through your online or mobile banking,',
                paused_tip: 'After resuming this payment agreement through your online or mobile banking,',
                tip_2: 'please refresh this card for the latest status!',
                refresh_success: 'Agreement is now active for payment!',
                refresh_pending: 'Please authorize this payment agreement in your online or mobile banking and try refreshing again!',
                refresh_paused: 'Please resume this payment agreement in your online or mobile banking and try refreshing again!',
                status_canceled: 'CANCELED',
                status_active: 'ACTIVE',
                status_pending: 'PENDING',
                status_paused: 'PAUSED',
                payment_agreement: 'Payment Agreement',
                got_it: 'Got it',
                deposit_success: "Your payment is currently being processed by the PayTo network.<br />Please check the 'Transaction Report' for the latest transaction status shortly.",
                deposit_failed: 'Payment failed.<br />Please check your bank account or try again later.',
                deposit_processing: "Your payment is currently being processed by the PayTo network.<br />Please check the 'Transaction Report' for the latest transaction status shortly.",
            },
            double_check: 'Please double-check and transfer the amount according to this amount, otherwise there is a possibility that the crediting will fail.',
            speed_up: 'Uploading a transfer receipt will speed up the arrival of the USDT deposit and we will prioritize orders with a receipt.',
            submit_credentials: 'Submit transfer credentials',
            please_click_tip: '*After the transfer is completed, please click the button below to submit the transfer credentials',
            requirements_receipt: 'requirements of the transfer receipt:',
            requirements_receipt_app: '{number} screenshot of coin withdrawal records from crypto exchanges, personal wallets and other apps, with {transferAmount} and {hashId} clearly visible.',
            requirements_receipt_app_content: 'the transfer amount',
            requirements_receipt_cold: 'If you use a cold wallet to transfer, please upload {number} screenshots, which are the {address} and {hashId}.',
            requirements_receipt_cold_address: 'transaction records of your personal wallet address,',
            requirements_receipt_cold_hash: 'the screenshot of the public chain with hash ID',
            upload_limit: 'Upload up to 2 files, please try again',
            hash_id: 'hash ID',
            confirm_proceed: 'Confirm and Proceed',
            exchange_rate_changed: 'Please note! The exchange rate changed.',
            updated_amount_note: 'The updated amount as below:',
            exchange_rate_info_0: 'Payment amount {currency}: {number}',
            exchange_rate_info_1: 'Exchange rate: {number}',
            exchange_rate_info_2: 'Fund in amount {currency}: {number}',
        },
        recently_used: 'Recently Used',
        add_as_my_favourite: 'Add as My Favourite',
        remove_as_my_favourite: 'Remove from My Favourite',
        more_deposit_method: 'More Deposit Methods',
        more_withdrawal_method: 'More Withdrawal Methods',
        current_form: 'Current (From)',
        after_transfer_out_from: 'After Transfer Out (From)',
        current_to: 'Current (To)',
        after_transfer_out_to: 'After Transfer Out (To)',
        indicative_rate: 'Indicative Exchange Rate',
    },
    trading_account: {
        wallet: 'Wallet',
        IB: 'IB',
        wallet_account: 'Wallet Account',
        apply_new_account: 'Apply New Account',
        additional: 'Additional Info',
        not_account: 'Your trading account has not yet been created.',
        please_create_account: 'Please ensure you have submitted the required documents so that we can fully verify your account.',
        message_center: 'Message Center',
        todo: 'To Do List',
        live_title: 'Live Account Application',
        demo_title: 'Demo Account Application',
        live_table_title: 'Live Trading Accounts',
        demo_table_title: 'Demo Trading Accounts',
        mam_table_title: 'MAM Accounts',
        live_content: 'Your Live Trading Account will be created as soon as your account is verified.',
        live_content_second: "Please make sure you have submitted your documents in the 'Documents' section.",
        demo_content: 'One step to finish your application for demo trading account with properties that you want.',
        file_title: 'Get Verified Now',
        file_content: 'To activate your live trading account, we are required to verify your identity.',
        personal: 'Personal Info',
        questionnaire: 'Questionnaire',
        detail: 'Account Configuration',
        continued: 'CONTINUE',
        welcome: 'Welcome, {userName}',
        apply: 'APPLY HUBx',
        upgrade: 'MEMBER UPGRADE',
        apply_new_live_ta: 'Live Account Application',
        apply_new_demo_ta: 'Demo Account Application',
        type: 'Type',
        trading_server: 'Trading Server',
        trading_account: 'Trading Account',
        name: 'Name',
        TA_status: 'Account Status',
        leverage: 'Leverage',
        currency: 'Currency',
        credit: 'Credit',
        balance: 'Balance',
        equity: 'Equity',
        floating_profit: 'Floating Profit',
        stop_out: 'Stop Out',
        notes: 'Notes',
        settings: 'Settings',
        settings_wallet: 'SETTINGS',
        dis: 'Disabled',
        en: 'Enabled',
        ro: 'Readonly',
        ac: 'Archived',
        archived_trading_account: 'Archived trading account',
        apply_success: 'Apply HUBx success',
        HUBX_tip: 'Your HUBX Customer Agreement can be checked in your',
        wallet_points: 'Wallet - Points',
        filter_type: 'Filter Type',
        filters: 'Filters',
        more: 'More',
        new_account: 'New account',
        account_type: 'Account Type',
        mam: {
            master: 'Master',
            sub: 'Sub',
            mam_type: 'MAM Type',
            member_name: 'Member Name',
            mam_status: 'MAM Status',
            invitation: 'MAM Invitation',
            invitation_notification: 'MAM Invitation',
            invitation_sub: 'Invite Sub',
            active: 'Activated',
            deactivated: 'Deactivated',
            change_password: 'Change Password',
            action: 'Action',
            submit_success: 'Your application have successfully submitted.',
            detach_from_mam: 'Detach from MAM',
            detach_confirm_title: 'Detach from MAM application',
            detach_confirm_subTitle_1: 'MAM Sub Account: ',
            detach_confirm_subTitle_2: 'MAM Master Account: ',
            detach_confirm_content_1: 'After detachment, your MAM Sub account will be deactivated and disconnected from the MAM Master account.',
            detach_confirm_subTitle_3: 'Regarding any remaining open positions',
            detach_confirm_content_2: `Should any open positions still exist in the MAM Sub Account during detachment, those positions will be automatically closed as the corresponding positions are closed in the MAM Master account. Throughout this period, any new positions initiated in the MAM Master Account will not affect your MAM Sub Account. For prompt closure of these positions, please reach out to us using the registered email address at support{'@'}threetrader.com.`,
            mam_invitation: 'MAM Invitation',
            invitation_content: 'You have a new MAM invitation from {name}',
            invitation_from: 'Invitation from',
            master_account: 'Master Account',
            agree_text: 'I have read and agreed to the above',
            confirm_reject: 'Are you sure to reject the invitation?',
            reject: 'Reject',
            accept: 'Accept',
        },
        apply_mam: 'Apply MAM',
        popup: {
            deposit_title: 'Deposit',
            apply_title: 'Apply for a New Live Trading Account',
            apply_wallet_title: 'Apply for a New Wallet Account',
            node: 'Node',
            choose_parent_title: 'Choose Agent >>',
            apply_new_parent: 'Agent',
            apply_new_server: 'Apply new account on',
            leverage: 'Leverage',
            currency: 'Currency',
            trading_platform: 'Trading Platform',
            account_type: 'Account Type',
            set_pwd_title: 'Set Password >>',
            set_hub_pin: 'Set a HUBx PIN >>',
            confirm_pin: 'Confirm PIN',
            new_pwd: 'New Password',
            new_pin: 'New PIN',
            pin_require: '*4-6 characters, must contain digits or alphabets.',
            confirm_pwd: 'Confirm Password',
            set_invest_pwd_title: 'Set an Invest Password',
            set_trading_pwd_title: 'Set Password >>',
            edit_notes_title: 'Edit Notes >>',
            edit_notes: 'Edit Notes',
            note_require: '*Maximum 20 chars',
            invest_password_not_equal: 'Invest Password not equal',
            setting_title: '{trading_server} {trading_account} Settings',
            setting_wallet_title: '{name} {account} Settings',
            choose_leverage_title: 'Choose Leverage',
            choose_account_details: 'Trading Details',
            choose_leverage: 'Choose from 1:{leverage} to',
            change_password_title: 'Change Password',
            pwd_type: 'Password Type',
            trading_pwd: 'Trading Account',
            invest_pwd: 'Invest',
            hubx_pwd: 'HUBx PIN',
            cancel_confirm: 'Are you sure to cancel ?',
            create_successfully: 'Apply New Account Successfully!',
            optional: '(Optional)',
            update_success: 'Update Successfully!',
            trading_password_not_equal: 'Trading Password not equal, Please confirm your password.',
            confirm_update_leverage: 'Leverage update may change your Margin of Trading Account. Are you sure to make the change?',
            confirm_update_password: 'Are you sure to reset password?',
            confirm_update_pin: 'Are you sure to reset PIN?',
            update_password_success: 'Change Password Successful！',
            update_pin_success: 'Change PIN Successful！',
            update_leverage_success: 'Your leverage application has been submitted. Please be patient.',
            cancel_leverage_success: 'Cancel Successful！',
            apply_demo_title: 'Apply for a New Demo Trading Account',
            demo_trading_server: 'Trading Server',
            deposit: 'Deposit',
            hub_pin_not_equal: 'Hub PIN not equal',
            inherit_agent: 'Inherit agent',
            application_password: 'Application Password',
        },
        mam_popup: {
            apply_title: 'MAM Master Account Application,',
            apply_title_name: 'Manage Your Trades More Efficiently & Professionally',
            apply_sub_title: 'MAM (Multi-Account Manager)',
            apply_sub_title_content_main:
                'MAM (Multi-Account Manager) is a tool designed to simplify and optimize trade management for professional investors, fund managers, and investment managers dealing with multiple accounts. With MAM, the master account manager only needs to execute trades on the main account, and these trades are automatically copied and executed across all connected sub-accounts according to the predetermined proportions.',
            apply_sub_title_bold: 'Suitable for: ',
            apply_sub_title_content: ' Professional traders, fund managers, investment managers and more',
            apply_sub_title_benefits: 'MAM Advantages',
            apply_sub_title_benefits_bold_1: 'Simplified Trade Management: ',
            apply_sub_title_benefits_content_1: 'By using MAM, you can manage multiple accounts simultaneously without the need to execute trade instructions for each account separately, great time and energy saving.',
            apply_sub_title_benefits_bold_2: 'Efficient Trade Execution: ',
            apply_sub_title_benefits_content_2: 'MAM automatically replicates and executes the trading strategy of the main account to all sub-accounts, ensuring all accounts can maintain a relatively fixed ratio.',
            apply_sub_title_benefits_bold_3: 'Flexible Funds Allocation: ',
            apply_sub_title_benefits_content_3: 'You can freely allocate funds to various sub-accounts as needed, meeting the needs and risk preferences of different customers.',
            apply_sub_title_for: 'How to Apply',
            apply_sub_title_for_content:
                'If you wish to take advantage of the powerful features of the MAM, simply click Apply, and we will provide you with detailed information on the requirements. Once these requirements are met, you will enjoy all the functions and advantages brought by MAM.',
            apply_sub_title_qualification: 'Activation Qualification',
            apply_sub_title_qualification_content: 'Once the total deposits across all sub-accounts reach $20,000 USD, we will activate the master account and provide the license for the MAM manager.',
            apply_mam_third_party_disclaimer: 'Third-Party_Software_(Multi_Account_Manager)_Disclaimer',
            apply_mam_forbidden: 'You can only have four MAM Master Account applications at the same time, please allow us more time to process them before new applications.',
        },
        mam_leverage: {
            leverage_title: 'Setup Trading Platform and Leverage For',
            leverage_title_sub: 'Multiple Account Manager',
            apply_mam_confirm_title: 'Application has been sent',
            apply_mam_confirm_content: 'We have received your multiple accounts manager (MAM) application and will notify you by message once your application is approved. Thank you for your patience.',
            create_mam_successfully: 'Your new MAM account has been created successfully.',
            create_mam_failure: 'Sorry, there is an error while creating your new MAM account, please contact us for assistance.',
        },
        no_available_ta: `No Available Trading Accounts. Please contact support{'@'}threetrader.com for more info.`,
    },
    mam_template: {
        title: 'MAM Invitation Template',
        add_new_template: 'Add New Invitation Template',
        template_name: 'Template Name',
        mam_commission_account: 'MAM Commission Account',
        management_fee: 'Management Fee',
        fixed: 'Flat',
        fix_note: 'USD',
        flat: 'Volume',
        flat_note: 'USD per lot times Trading Volume',
        percentage: 'Percentage',
        percentage_note: 'of the Balance',
        fixed_management_fee: 'Flat Fee',
        flat_management_fee: 'Volume Fee',
        percentage_management_fee: 'Percentage Fee',
        management_fee_payment_frequency: 'Payment Frequency',
        weekly: 'Weekly',
        fortnightly: 'Fortnightly',
        monthly: 'Monthly',
        profit_sharing_methods: 'Profit Sharing Method',
        high_water_mark: 'High Water Mark',
        not_sharing: 'Not Sharing',
        performance_fee: 'Performance Fee',
        percentage_rate_over: 'Maximum 1%',
        performance_fee_over: 'Maximum 50%',
        performance_fee_payment_frequency: 'Payment Frequency',
        mam_account_create_success: 'MAM invitation template create successfully',
        mam_account_edit_success: 'MAM invitation template save successfully',
        mam_account_delete_success: 'MAM invitation template delete successfully',
        management_fee_introduction:
            'In the event that the annualized management fee exceeds 12%, you acknowledge and agree that it shall be deemed to be equal to 12%, regardless of any other provision or circumstance.',
        mam_template_tutorial: 'MAM Account Invitation Template Creation Tutorial',
        tips: {
            mam_commission_account: 'MAM Commission Account Required',
            required: 'Required',
            performance_fee_must_greater_than_zero: 'Performance Fee must be greater than 0. If profit sharing is not required, please select "Not Sharing" in the Profit Sharing Method.',
        },
        list: {
            fixed_management_fee: 'USD {fee}',
            flat_management_fee: 'USD {fee} per lot times Trading Volume',
            percentage_management_fee: '{fee}% of the Balance',
            performance_fee: '{fee}% of the performance',
            performance_fee_sub: '{fee}%',
        },
        send_invitation: {
            title: 'Create your invitation',
            mam_master_ta: 'MAM Master TA',
            based_currency: 'Base Currency',
            based_currency_detail: 'USD',
            client_email: 'Client Email',
            client_last_name: 'Client Last Name',
            client_full_name: 'Client Full Name',
            invitation_template: 'Invitation Template',
            user_not_found: 'No record found',
            user_no_authorize: 'Ineligible User',
            user_pending_invitation: 'Invitation pending on accept',
            contract_header: 'LPOA & ATP',
            contract_checkbox: 'I have read and agreed to the above',
            confirm_dialog_title: 'Invitation Has Been Sent',
            confirm_dialog_content: 'MAM invitation has been sent, the sub-account will be displayed below your master account after the receiving party accepts the invitation. Thank you for your patience.',
            send_invitation_failure: 'Oops, something went wrong, please contact us for assistance. ',
            last_name_required: 'Client last name is required',
        },
    },
    Tips: {
        email_verify: 'Email verified',
        email_not_verify_ta: 'Email verification failed, please complete verification to continue registration.',
        email_not_verify: 'Email verification failed.',
        email_already_verify: 'Email has already been verified.',
        email_verify_not_access: 'Please login to continue email verification.',
        email_verify_repeat: 'Email has already been verified.',
        email_resend_second_tip: 'If you have not received a verification email, please check your spam folder or after {second}s request a new verification email.',
        email_resend_tip: 'If you have not received a verification email, please check your spam folder or',
        resend_email: 'request a new verification email',
        minimum_deposit_tips: 'Minimum deposit of {currency} {amount}. Created trading accounts of this type will be read-only until the minimum deposit is met.',

        code_should_characters: 'Verification Code should be {num} characters',
        demo_deposit_limit_1: 'The maximum can be credited {currency} {amount}',
        demo_deposit_limit_2: 'The account balance limit is {currency} {amount}',
        demo_deposit_limit_3: 'Maximum single deposit limit is {currency} {amount}',
        demo_deposit_limit_4: 'Minimum deposit {currency} {amount}',

        querying_tips: 'Querying, please try again later.',
        export_all_used: 'Processing report for "{typeName}" in "{sectionName}" section, please try again later!',
        csv_content_error: 'File download error',
        exporting_close_tip: 'Exporting all users, please do not refresh or close this browser window',
        export_list_timeout: 'The operation has timed out, export csv failed',
        csv_generate_tip: 'The file is being generated, please wait!',
        no_data_export: 'No data(s) to export',

        cn_3d_phone_number: 'Please fill your mobile number WITHOUT Country Code',
        contact_admin_tips: `Please contact support{'@'}threetrader.com for more info`,
        view_csv_limit: 'Maximum display {count} orders, please download csv to view all orders',
        please_select_a_jurisdiction: 'Please select a jurisdiction',
        use_number_birth_date: 'Please use number for {birthDate}',
        agree_trading_tools_tips: 'You will need to agree to Terms and Conditions before you can use this section.',
        enter_date_of_birth: 'Please enter the Date of Birth',
        enter_one_time_password: 'Please enter one-time password',
        enter_verification_code: 'Please enter Verification Code',
        use_number_only: 'Please use numbers only',
        enter_valid_date: 'Please enter a valid date',
        age_limit: 'Please note that in order to open an account with us you need to be at least {age} years old',
        can_not_find_deposit_template: 'Sorry, the deposit template could not be found.',
        config_error: 'Incorrect configuration',
        question_try_again: 'You have failed to pass. Please try again',
        select_jurisdiction: 'Please select a jurisdiction',
        view_csv_search: 'Enter TA, Name or Node to search',
        loading_not_submit: 'Loading a new page, please do not submit repeatedly',
        load_lang_error: 'Failed to load language file.',
        password_tip: 'Password must be 8-15 characters in length with at least 1 digit, 1 special character, 1 uppercase and 1 lowercase letter, special characters only include {chars}.',
        file_upload_number_limit: 'The maximum upload file is not more than %{num}',
        length_min_tips: 'Length cannot be less than %{num}',
        upload_type_tips2: 'Only accept .jpg .png file',
        select_account_to_view: 'Please select the account you want to view',
        one_close_confirm: 'Are you sure to close all tabs?',
        deposit_payment_text: 'You will be redirected to our partners for processing the payment.',
        enter_trading_account_to_view: 'Please enter a trading account to view',
        trading_account_not_exit: '*Trading account does not exist.',
        rebate_report_status_refresh_hint: "It may take 30-60 seconds for reports to be generated, please click on 'refresh' button to see status change.",
        csv_data_error: 'Unable to get data',
        file_format_only: 'Only accept %{files} file under %{size}M',
        file_type_only: 'Only accept %{files} file',
        expiration_time_tips: 'Expiry date cannot be less than current time',
        copy_link: 'Copied Successfully!',
        please_enter_your_account: 'Please enter your email',
        please_enter_your_password: 'Please enter the Password',
        please_enter_your_verification: 'Please enter the verification code',
        two_password_errors: 'Two password errors',
        number_length_tips: 'Number <= 8 bits',
        number_positive_tips: 'Please enter a positive number',
        number_point_tips: 'No more than %{num} decimal places',
        can_not_empty: 'Input can not be empty',
        number_tips: 'Please enter a number',
        card_num_length_tips: 'Please enter a valid card number',
        number_num_tips: 'Please enter %{num} digits',
        upload_type_tips: 'Only accept .pdf .jpg .png .bmp file',
        file_size_limit: 'File size cannot exceed %{size}MB!',
        total_file_size_limit: 'The total file size cannot exceed %{size}MB!',
        upload_an_invalid_file: 'You uploaded an invalid file, please re-upload!',
        submit_success: 'Submitted successfully',
        submit_doc_success: 'Your documents were successfully submitted',
        save_success: 'Saved Successfully',
        global_trading_server: 'Please select a trading server',
        only_english_letters: 'Only English letters',
        only_enter_number_english: 'Can only enter numbers, or letters',
        letter_number_tips: 'Please enter the correct format',
        cannot_enter_numbers: 'Cannot enter numbers',
        BSB: 'Please enter 6 digits',
        enter_email: 'Please enter your email',
        enter_correct_email: 'Please enter the correct email address',
        no_data: 'No Data',
        records: '*<u>%{record}</u> of %{allRecords} Records',
        records_other: '*There are %{allRecords} Records',
        deposit_submit_success: 'Your deposit application was submitted successfully',
        deposit_submit_fail: 'Your deposit application failed to submitted',
        deposit_success: 'Deposit successfully',
        deposit_fail: 'Deposit failed',
        update_pwd: 'Whether to update the password?',
        add_new_ta: 'Whether to add new trading account?',
        add_new_wallet: 'Whether to add new wallet account?',
        update_success: 'Update Successfully!',
        create_success: 'Created successfully!',
        add_successfully: 'Added successfully',
        delete_success: 'Delete Success!',
        update_success_password: 'Your account password has been changed, please login again.',
        change_email_success: 'Your email address has been changed to be: {email}, please re-login.',
        not_enter_space: 'Cannot enter a space',
        only_enter_lowercase: 'Can only enter lowercase letters',
        only_enter_capital: 'Can only enter uppercase letters',
        only_enter_letter: 'Can only enter letters',
        number_error: 'Can only enter numbers',
        number_negative_zero_tips: 'Please enter a number <= 0',
        number_positive_zero_tips: 'Please enter a number >= 0',
        agreement_file_not_exist: 'File does not exist',
        ta_change_success: 'Trading account change successful',
        can_not_export: 'Empty value, can not export!',
        different_trading_servers_in_crm:
            'There are same number Trading Accounts on different Trading Servers in CRM. Please search the tree at first, and choose your target account on the tree to generate the order report.',
        reports_cannot_generated_demo_accounts: 'Reports cannot be generated for demo accounts',
        flat_report_sent_success: 'Your requested report is being prepared, we will notify you via email once it is ready for download.',
        you_cannot_apply_new_live_trading_account_now: `Additional Live Account creation has been disabled. Please contact support{'@'}threetrader.com for more info`,
        you_cannot_apply_new_demo_trading_account_now: `Additional Demo Account creation has been disabled. Please contact support{'@'}threetrader.com for more info`,
        you_cannot_apply_new_wallet_account_now: `Additional Wallet creation has been disabled. Please contact support{'@'}threetrader.com for more info`,
        you_cannot_apply_for_new_deposit_now: `Deposits have been disabled. Please contact support{'@'}threetrader.com for more info`,
        transfer_amount_error: 'Transfer amount error.',
        same_user_transfer_sub: "It is not possible to transfer funds to the same user's trading account.",
        email_account_not_match: 'Email and trading account do not match.',
        account_not_transfer: 'It is not possible to transfer funds to the current trading account.',
        transfer_failed: 'Transfer failed.',
        outstanding_order: 'There are currently outstanding orders, please try again later.',
        you_cannot_apply_for_new_withdrawal_now: `Withdrawals have been disabled. Please contact support{'@'}threetrader.com for more info`,
        you_cannot_apply_for_new_internal_transfer_now: `Internal transfers have been disabled. Please contact support{'@'}threetrader.com for more info`,
        you_cannot_apply_for_new_internal_transfer_sub: `Transfers to sub have been disabled. Please contact support{'@'}threetrader.com for more info.`,
        please_complete_the_captcha: 'Please complete the captcha',
        please_enter_your_password_again: 'Please enter your password again',
        please_enter_a_valid_email_address: 'Please enter a valid email address',
        please_include_at_least_1_uppercase_letter: 'Please include at least 1 uppercase letter',
        please_include_at_least_1_lowercase_letter: 'Please include at least 1 lowercase letter',
        please_include_at_least_1_number: 'Please include at least 1 number',
        please_include_at_least_1_special_character: 'Please include at least 1 special character',
        password_character_limit: 'Special characters only include {chars}',
        Password_must_be_8_15_characters_in_length: 'Password must be 8-15 characters in length',
        the_email_you_entered_has_already_been_registered: 'The email you entered has already been registered',
        The_link_expired_after_1_hour_or_has_already_been_used: 'The link expired after 1 hour or has already been used.',
        not_found_group_tips: `We're in the process of creating your account. For more info, please contact support{'@'}threetrader.com`,
        please_upload_verification_file: 'Please ensure all requested documents are uploaded.',
        point_use: 'Points can be used to redeem gifts in Rewards.',
        attention: 'Attention',
        report_confirm_text_paraOne: 'Dear Client,',
        report_confirm_text_paraTwo:
            'Since the most recent data may take up to 1.5 hours to complete synchronization, if you select the current time as the cut-off point for the report, some of the most recent data may not be included.',
        please_fill_english: 'Please fill in the bank details in English. Other Language Characters are not accepted (e.g. ç, ã{noSpaceText})',
        please_fill_simplified_chinese: 'Please fill in Simplified Chinese only. Other Language Characters are not accepted (e.g. a, ã{noSpaceText})',
        please_fill_number: 'Please enter numbers.',
        space: ', spaces',
        deposit_disabled: 'Deposits have been disabled.',
        withdrawal_disabled: 'Withdrawals have been disabled.',
        transfer_disabled: 'Transfers have been disabled.',
        contact_support: `Please contact support{'@'}threetrader.com for more info.`,
        exchange_error: 'Exchange rate error. Please contact support.',
    },
    MemberManagement: {
        incorrect_file_type: 'The file must be a file of type: txt, csv, xls, xlsx.',
        member_name: 'MemberName: %{name}',
        ActionMenu: {
            view_account: 'View Account',
            txn_report: 'TXN Report',
            recent_orders: 'Recent Orders',
            rebase_report: 'Rebate Report',
            login_as: 'Login As',
        },
        search_title: 'Search Trading Account',
    },
    FundingManagement: {
        type: {
            deposit: 'Deposit',
            credit: 'Credit',
            transfer: 'Transfer',
            internalTF: 'Internal Transfer',
            internalTF_sub: 'Transfer to Sub',
            points: 'Points',
            Rebate: 'Rebate',
            withdrawal: 'Withdrawal',
            CFD: 'CFD',
            Rollover: 'Rollover',
            Misc: 'Misc',
            internal_TF: 'Internal TF',
        },
        SolveLaterList: {
            transaction_no: 'Transaction No.',
            time_submitted: 'Time Submitted',
            time_processed: 'Time Processed',
            time_completed: 'Time Completed',
            transaction_status: 'Transaction Status',
            transaction_type: 'Transaction Type',
            transaction_notes: 'Transaction Notes',
            member_name: 'Member Name',
            country: 'Country and Region',
            member_verification_status: 'Member Verification Status',
            payment_amount_in: 'Payment Amount in/out',
            to: 'To',
            exchange_rate: 'Exchange Rate',
            surcharge_amount: 'Surcharge Amount',
            fund_in: 'Fund in/out',
            tradingAccount: 'Trading Account',
            trading_account_status: ' Trading Account Status',
            parent: 'Agent',
            trading_group: 'Trading Account Group',
            internal_notes: 'Internal Notes',
            updated_by: 'Last Edit By ',
            trading_server_comment: 'Trading Server Comment',
            transaction_time: 'Transaction Time',
        },
        Dashboard: {
            Popup: {
                balance: 'Balance',
                equity: 'Equity',
                margin: 'Margin',
                margin_free: 'Margin Free',
                margin_level: 'Margin Level',
                credit: 'Credit',
                avaliable_amount: 'Available Amount',
                account_BSB: 'BSB',
                currency: 'Currency',
            },
        },
        History: {
            transactionTimeLabel: 'Transaction Time',
            show: 'Show',
            each_page: '(each page)',
            notVerified: 'Not Verified',
            verified: 'Verified',
            end_time: 'End time cannot be empty',
            start_time: 'Start time cannot be empty',
            title: 'All Transaction List',
        },
    },
    ClientsReport: {
        member_detail: 'Member Details',
        pending_customers: 'Pending Customers',
        member_list: 'Member List',
        clients: 'Clients',
        ta: 'TA',
        client_tip: 'No. of Member Matching the Filter',
        ta_tip: 'No. of TA Matching the Filter',
        member_type: 'Member Type',
    },
    IB: {
        profile: {
            rebate_node: 'Rebate Node',
            referral_links: 'Referral Links',
        },
        status_pending: 'Pending',
        rejected: 'Rejected',
        referred_clients: 'Referred Clients',
        retail_clients: 'Retail Clients',
        Live: 'Live',
        Demo: 'Demo',
        sales_link: 'Sales Link',
        ib_link: 'IB LINK',
        ib_referral_code: 'IB Referral Code',
        language: 'Language',
        register_node: 'Register Node',
        register_ib: 'Register IB',
        register_account_type: 'Register Account Type',
        register_link_full: 'Register Link Full',
        country: 'Country and Region',
        jurisdiction: 'Jurisdiction',
        // label for error message,
        orders_type: 'Orders Type',
        generate_all_orders: 'GENERATE ALL ORDERS',
        generate_all_orders_tab: 'Generate All Orders',
        parent_title: 'Agent',
        sales_code_title: 'Sales Code',
        ta_server_title: 'TA Server',
        status_title: 'Status',
        jurisdictionTitle: 'Jurisdiction',
        member_detail_information: 'Member Detail Information',
        need_refresh_data: 'The data has changed, please refresh the page.',
        token_has_expired: 'Token has expired',
        the_token_has_been_blacklisted: 'The token has been blacklisted',
        admin_user_not_exist: 'Admin user not exist',
        incorrect_username_or_password: 'The email or password you’ve entered is incorrect',
        admin_Group_id_already_exist: 'Admin Group id already exist',
        special_User_is_already_exist: 'Special User is already exist',
        admin_group_not_exist: 'Admin group not exist',
        code_is_already_exist: 'Code is already exist',
        no_mt4_account: 'No mt4 account',
        user_password_email_incorrect: 'Incorrect email or password',
        ib_user_not_exist: 'This IB user not exist',
        user_ID_is_required: 'User ID is required',
        trading_account_ID_is_required: 'Trading account ID is required',
        file_does_not_exist: 'File does not exist',
        failed_to_get_oss_url: 'Failed to get temporary oss url',
        rebate_report_already_exists: 'Request already exists',
        user_has_no_corresponding_children: 'The user has no corresponding children and cannot generate the corresponding data',
        trading_account_does_not_exist: 'Trading account does not exist in CRM',
        feature_not_open: 'This feature is not yet open!',
        select_user_tips: 'Please select a user to view',
        ib_detail_information: 'IB Detail Information',
        account_not_exist_portal: 'The account does not exist in PORTAL',
        parent_account_not_exist: 'Agent IB trading account does not exist in CRM',
        trading_server_error: 'Trading server error',
        the_currency_does_not_exist: 'The currency does not exist',
        the_value_goes_wrong: 'The value goes wrong',
        the_leverage_does_not_exist: 'The leverage does not exist',
        the_set_name_does_not_exist: 'The set name does not exist',
        the_country_does_not_exist: 'The country does not exist',
        the_trading_server_group_does_not_exist: 'The trading server group does not exist',
        the_trading_server_does_not_exist: 'The trading server does not exist',
        insufficient_credit: 'Insufficient credit',
        group_saved_failed: 'the trading server group saved failed',
        merged_all_report: 'Merged All Report',
        merged_self_report: 'Merged Self Report',
        merged_direct_report: 'Merged Direct Report',
        merged_full_report: 'Merged Full Report',
        trade_not_exist: 'Trade not exist',
        the_nodes_created_are_duplicated: 'The nodes created are duplicated',
        the_password_format_is_incorrect: 'The password format is incorrect',
        password_setting_failed: 'Password setting failed',
        external_id_does_not_exist: 'External id does not exist',
        some_children_cannot_be_set: 'For have some children, cannot be set to non-ib',
        customer_must_have_no_parent: 'Customer must have no agent',
        the_account_must_be_a_leaf_node: 'This trading account has some sub account, cannot remove agent',
        permission_already_exists: 'Permission already exists',
        role_not_exist: "Role don't exist",
        platform_not_exist: "Platform don't exist",
        given_data_was_invalid: 'The given data was invalid',
        file_type_error: 'Incorrect file type',
        system_customization_error: 'System customization error',
        operation_failure: 'Operation failure',
        user_disables_deletion: 'This user disables deletion',
        token_is_incorrect: 'Login information has expired',
        token_unauth: 'Unauthenticated',
        token_grant_type: 'Unsupported grant type',
        token_is_invalid: 'The refresh token is invalid',
        user_password_incorrect: 'User password incorrect',
        file_upload_failed: 'File upload failed',
        file_deleted_failed: 'File deleted failed',
        agreement_file_name_cannot_be_repeated: 'The agreement file name cannot be repeated',
        group_cannot_be_reused: 'Group cannot be reused',
        mt4_id_cannot_be_reused: 'MT4 ID cannot be reused',
        special_user_disabled: 'Special  user disabled!',
        user_not_have_right_permissions: 'User does not have the right permissions',
        permission_not_right: 'The permissions not right',
        system_error: 'System Error',
        network_error: 'The request server failed. Please try connecting again.',
        system_Verification_error: 'Verification code error',
        system_Verification_failure: 'Verification code failure',
        user_rest_password_incorrect: 'Incorrect user name or password reset token.',
        user_rest_password_email_expired: 'The retrieve password email has expired',
        protocol_has_been_applied: 'The HUB protocol has been applied',
        the_pin_must_4: 'The PIN must be at least 4-6 characters',
        please_enter_PIN: 'Please enter the PIN',
        two_password_errors: 'Inconsistent password entered twice.',
        search: 'Search',
        data_allowed_to_displayed: 'Only 20 data is allowed to be displayed!',
        choose_a_user: 'Please select a user to view',
        tradingAccount: 'TA',
        memberName: 'Member Name',
        emailTitle: 'Email',
        registeredTime: 'Reg. Time',
        tradingAccountStatus: 'Trading Acct. Status',
        verificationStatus: 'Verification',
        viewAccount: 'View Account',
        rebateReport: 'Rebate Report',
        flat_report: 'Flat Rebate Report',
        flat_ledger_report: 'Flat Ledger Report',
        flat_performance_report: 'Flat Performance Report',
        TXNReport: 'TXN Report',
        MemberTXNReport: 'Member TXN Report',
        rebate_list: {
            name: 'Category',
            IB_rebate: 'From IB',
            customer_rebate: 'From Customer',
            rebate_rate: 'Rebate Rate',
        },
        included_symbols: 'Included Symbols',
        orderReport: 'Order Report',
        ib_orderReport: 'IB Order Report',
        phoneColumn: 'Phone',
        registeredTimeColumn: 'Register Time',
        verificationColumn: 'Account Verification',
        QuickLinksColumn: 'QuickLink to Report',
        sameOptionWarn: 'The same option does not operate',
        changeAccountStatusText: 'Are you sure to change the status of this account?',
        changeStatusSuccess: 'Success',
        noMoreDataWarning: 'No more data',
        statusChangeFaild: 'Status setting failed',
        statusNoChange: 'No need to change state',
        viewMore: 'VIEW MORE',
        generateMergedReport: 'Generate Merged Report',
        // 0-粉色：未提供 1-绿色：已验证 2-黑色：未验证 case2183
        verified: 'Verified',
        not_verified: 'Not verified',
        unverified: 'Unverified',
        pending: 'Not-provided',
        selfReport: 'Self Report',
        directReport: 'DIRECT REPORT',
        fullReport: 'Full report',
        fullOrdersReport: 'FULL ORDERS REPORT',
        directBalanceReport: 'DIRECT BALANCE REPORT',
        fullBalanceReport: 'FULL BALANCE REPORT',
        generateReport: 'Generate Report',
        switch_direct_report: 'SWITCH TO DIRECT REPORT',
        switch_self_report: 'SWITCH TO SELF REPORT',
        // switch_full_report: 'SWITCH TO FULL REPORT',
        enabled: 'enabled',
        readonly: 'readonly',
        disabled: 'disabled',
        enabled_short: 'EN',
        readonly_short: 'RO',
        disabled_short: 'DIS',
        export_name_ib: 'IB_users',
        export_name_member: 'Member_users',
        export_name_pending_customers: 'pending_customers',
        // label for RebateReportList
        rebateReportTimeLabel: 'Request History Time Filter',
        requestID: 'Request ID',
        requestTime: 'Request Time',
        report_type: 'Report Type',
        rebate_summary_report: 'Rebate Summary Report',
        rebate_ledger_report: 'IB Ledger Report',
        rebate_performance_report: 'IB Performance Report',
        reportTime: 'Report Time',
        requestHistory: 'Request History',
        requestStatus: 'Request Status',
        downloadCSV: 'Download CSV',
        download_report: 'Download Report',
        viewCSV: 'View CSV',
        view_report: 'View Report',
        sendEMail: 'Send Email',
        select_report_time: 'Report Time Select',
        complete: 'Complete',
        process: 'Processing',
        delete: 'Delete',
        deleted: 'Deleted',
        failed: 'Failed',
        recentWeek: 'Recent Week',
        history: 'History',
        before: 'Before %{time}',
        quiz_result: 'Quiz Result',
        all: 'All',
        ib: 'IB',
        clients_ib: 'Clients with IB',
        registration_time: 'REGISTRATION TIME',
        time: 'Time',
        ta_create_time: 'TA CREATED TIME',
        retail: 'Retail Client',
        view_summary: 'View Summary',
        rebateReportType: {
            user_full: 'Merged Rebate Report',
            user_self: 'Merged Self',
            account_self: 'Trading Account Self',
            account_full: 'IB TA Rebate Report',
            merged: 'Merged',
            full: 'Full',
            self: 'Self',
            ib_rebate: 'IB Rebate',
        },
        // label for ViewCSV
        view_csv: {
            total: 'Total',
            sub_ib: 'Sub Ibs',
            direct_clients: 'Direct Clients',
            trading_account: 'Trading Account',
            member_name: 'Member Name',
            name: 'Name',
            account_type: 'Account Type',
            agent: 'Agent',
            sales_code: 'Sales Code',
            total_rebate: 'Total Rebate',
            group: 'Group',
            position: 'Position',
            node_name: 'Node Name',
            deposit: 'Deposit',
            withdraw: 'Withdraw',
            net_deposit: 'Net Deposit',
            internal_transfer: 'Internal Transfer',
            rollover_and_dividend: 'Rollover & Dividend',
            others: 'Others',
            credit: 'Credit',
            swap: 'Swap',
            commission: 'Comm.',
            profit: 'Profit',
            total_profit: 'Total Profit',
            from_true_equity: 'True EQ From',
            to_true_equity: 'True EQ To',
            eq_change: 'EQ Change',
            rebate: 'Rebate',
            forex_vol: 'FOREX',
            bullion_vol: 'BULLION',
            oil_vol: 'OIL',
            cfd_vol: 'CFD',
            crypto_vol: 'CRYPTO',
            shares_vol: 'SHARES',
            ib_trading_account: 'IB Trading Account',
            ib_name: 'IB Name',
            client_trading_account: 'Client Trading Account',
            client_name: 'Client Name',
            ib_agent_number: 'IB Agent Number',
            platform: 'Platform',
            rebate_method: 'Rebate Method',
            ib_currency: 'IB Currency',
            client_platform: 'Client Platform',
            symbol: 'Symbol',
            ticket: 'Ticket',
            open_time: 'Open Time',
            close_time: 'Close Time',
            vol_traded: 'Volume Traded',
            contractsize: 'Contract Size',
            category_name: 'Category Name',
            rebate_reval_rate: 'Rebate Reval Rate',
            rebate_reval_symbol: 'Rebate Reval Symbol',
            rebate_rate: 'Rebate Rate',
            actual_rate: 'Actual Rate',
            true_equity: 'True Equity',
            adjusted_trading_vol: 'Adjusted Trading Volume',
        },
        mergedFullReport: 'Merged',
        parent: 'Agent',
        deposit: 'Deposit',
        withdr: 'Withdr.',
        netDepo: 'Net Depo.',
        swap: 'Swap',
        credit: 'Credit',
        commissions: 'Commissions',
        profit: 'Profit',
        totalProfit: 'True Profit',
        trueEQF: 'True EQ',
        trueEQT: 'True EQ',
        EQChange: 'EQ Change',
        tradedLots: 'Traded Lots',
        forexVol: 'Forex Vol.',
        bullionVol: 'Bullion Vol.',
        spotOilVol: 'Spot Oil Vol.',
        CFDVol: 'CFD Vol.',
        cryptoVol: 'Crypto Vol.',
        totalContributedRebates: 'Total Contributed Rebates',
        totalSelfRebates: 'Total Self Rebates',
        nodeA_CateI: 'NodeA_CateI',
        noteB_CateII: 'NodeA_CateII',
        eMailaddr: 'Email Address',
        sendReport: 'SEND REPORT',
        eMailSuccess: 'Sent successfully',
        downloadSuccess: 'Downloaded',
        downloadFailed: 'Download failed',
        startTimeOver: 'Start time cannot be greater than end time',
        end_time_need: 'End time cannot be empty',
        start_time_need: 'Start time cannot be empty',
        noneTimeWarning: 'Please select report time',
        noneTradingAccount: 'Please enter trading account',
        closeTabInfo: 'The request you just opened cannot be closed',
        // label for RebateSetting_detail
        rebateSetting: 'RebateSetting',
        input_placeholder: 'Please Input',
        node_setting: 'NODE SETTING',
        no_data: 'No data in current account',
        connect_error: 'Connect error with server. Please try later!',
        max_rate: 'Max Rate',
        fixed_rate: 'Fixed Rate',
        max_r: 'Max',
        fixed_r: 'Fixed',
        check_add_node: 'Check & Add Node',
        node_name: 'Node Name',
        group_select: 'Group select',
        maximum_characters: '*Maximum 16 characters',
        rebate_rate_type: 'Rebate Rate Type',
        default_trading_platform: 'Default Trading Platform',
        rebate_currency: 'Rebate Currency',
        rebate_rates_setting: 'REBATE RATES SETTING',
        commission_rebates: 'Rebate Type',
        notional: 'NOTIONAL',
        allow_show_rates_setting: 'Allow to show rebate rates setting on IB Portal',
        add_new_category: 'ADD NEW CATEGORY',
        new_category_name: 'New Category Name',
        available_symbols: 'AVAILABLE SYMBOLS',
        selected_symbols: 'SELECTED SYMBOLS',
        from_IB: 'From IB',
        from_customer: 'From Customer',
        check_current_nodes: 'CHECK CURRENT NODES',
        manual_add_new_node: 'Manual add new node',
        check_result: 'Check result',
        empty_node: 'Empty Node',
        group_not_in_current_node: 'Group Not in Current Node',
        go_to_node: 'GO TO NODE',
        add_node: 'ADD NODE',
        edit_node: 'EDIT NODE',
        bind_with: 'Bind with',
        group_a_select: 'Group A select',
        group_b_select: 'Group B select',
        group_a: 'Group A',
        group_b: 'Group B',
        default_group: 'Select IB link default group',
        rebate_setting_detail_save_success: 'Rebate setting detail update successful',
        node_name_cannot_be_empty: 'Node name cannot be empty!',
        // label for OrdersReport
        account: 'Trading Account',
        close_order_history: 'Close order history',
        order_time: 'Order Time',
        account_name: 'Trading Account Name',
        open_time: 'Open Time',
        order_type: 'Order Type',
        symbol: 'Symbol',
        open_price: 'Open Price',
        close_time: 'Close Time',
        last_price: 'Last Price / Close Price',
        commission: 'Commission',
        comment: 'Comment',
        to: 'To',
        order_id: 'Order ID',
        volume: 'Volume',
        sl: 'S/L',
        tp: 'T/P',
        total_volume: 'Ajusted Volume',
        total_comm: 'Total Comm',
        total_swap: 'Total Swap',
        total_profit: 'Gross Profit',
        total_total_profit: 'Total True Profit',
        open_orders: 'Open Orders',
        close_orders: 'Close Orders',
        pending_orders: 'Pending Orders',
        buy: 'Buy',
        sell: 'Sell',
        buy_limit: 'Buy Limit',
        sell_limit: 'Sell Limit',
        buy_stop: 'Buy Stop',
        sell_stop: 'Sell Stop',
        buy_stop_limit: 'Buy Stop Limit',
        sell_stop_limit: 'Sell Stop Limit',
        no_orders: 'No Orders',
        symbol_type: 'Symbol Type',
        name: 'Symbol Type',
        comm: 'Comm.',
        fx: 'FX',
        bullion: 'Bullion',
        oil: 'Oil',
        cfd: 'CFD',
        crypto: 'Crypto',
        total: 'Total',
        adjusted_volume: 'Adjusted Volume',
        // label for TXN Report
        submitted_time: 'Time Submitted',
        processed_time: 'Time Processed',
        submitted: 'Submitted',
        processed: 'Processed',
        completed: 'Completed',
        transaction_status: 'Transaction Status',
        application_status: 'Application Status',
        processed_by: 'Processed By',
        transaction_type: 'Transaction Type',
        transaction_notes: 'Transaction Notes',
        customer_notes: 'Notes to Customer',
        transaction_no: 'Transaction No.',
        transaction_time: 'Transaction Time',
        member_name: 'Member Name',
        original_amount: 'Original Amount',
        exchange_rate: 'Exchange Rate',
        surcharge_amount: 'Surcharge Amount',
        amount: 'Amount',
        trading_account: 'Trading Account',
        trading_account_status: 'Trading Account Status',
        member_verfication_status: 'Member Verification Status',
        trading_acct_status: 'Trading Acct. Status',
        trading_group: 'Trading Account Group',
        trading_server_comments: 'Trading Server Comments',
        enable: 'Enable',
        readOnly: 'Read Only',
        disable: 'Disable',
        multi: '- Multi -',
        noData: 'NO DATA',
        ib_ta_data: 'Fill in IB TA',
        // label for Transaction Detail
        td_pending: 'Pending',
        td_complete: 'Complete',
        td_confirmed: 'Confirmed',
        td_canceled: 'Canceled',
        td_deposit: 'Deposit',
        td_transfer: 'Internal Transfer',
        td_leverage: 'Leverage',
        td_credit: 'Credit',
        td_withdrawal: 'Withdrawal',
        td_rebate: 'Rebate',
        td_rollover: 'Rollover',
        td_cfd: 'CFD',
        td_misc: 'Misc',
        transaction_detail: 'Transaction Detail - ',
        trading_server: 'Trading Server',
        trading_server_type: 'Server Type',
        currency: 'Currency',
        balance: 'Balance',
        sales_code: 'Sales Code',
        equity: 'Equity',
        margin: 'Margin',
        margin_free: 'Margin Free',
        margin_level: 'Margin Level',
        available_amount: 'Available Amount',
        transaction_number: 'Transaction Number',
        record_number: 'Record Number',
        ticket: 'Ticket',
        ticket_from: 'Ticket(From)',
        ticket_to: 'Ticket(To)',
        trading_server_comment: 'Trading Server Comment',
        trading_server_comment_from: 'Trading Server Comment(From)',
        trading_server_comment_to: 'Trading Server Comment(To)',
        internal_notes: 'Internal Notes',
        notes_to_customer: 'Notes to Customer',
        deposit_information: 'Deposit Information >>',
        payment_gateway: 'Payment Gateway',
        external_notes: 'External Notes',
        payment_amount: 'Payment Amount',
        surcharge: 'Surcharge',
        deposit_amount: 'Deposit Amount',
        withdrawal_information: 'Withdrawal Information >>',
        with_amount: 'Withd. Amount',
        payment_detail: 'Payment Detail >>',
        bank_name: 'Bank Name',
        account_Name: 'Account Name',
        account_number: 'Account Number',
        account_BSB: 'Account BSB',
        bank_swift: 'Bank Swift',
        bank_branch: 'Bank Branch',
        bank_province: 'Bank Province',
        transfer_information: 'Transfer Information >>',
        transfer_from: 'Transfer From',
        transfer_out_amount: 'Transfer Out Amount',
        transfer_to: 'Transfer To',
        transfer_in_amount: 'Transfer In Amount',
        funding_information: 'Funding Information >>',
        credit_fund: 'Credit Fund',
        fund_amount: 'Fund Amount',
        with_credit: 'Withd. Credit',
        deposit_credit: 'Deposit Credit',
        original_leverage: 'Original Leverage',
        change_leverage_to: 'Change Leverage to',
        // report title
        reportTitle: {
            RequestList: 'Request List',
            RebateReportList: 'Rebate Report',
            TXNReport: 'TXN Report',
            RecentOrders: 'Recent Orders',
            MergedReport: 'Merged Report',
        },
        settingTitle: {
            AccountDetail: 'Account Detail',
            MemberVerified: 'Member Verified',
            RebateSetting: 'Rebate Setting',
            Agreement: 'Agree-ment',
        },
        // TXN summary
        summaryTitle: {
            type: 'Type',
            transaction_report: 'Transaction  Report',
            view_summary: 'View Summary',
            transaction_status: 'Transaction Status',
            transaction_type: 'Transaction Type',
            Submitted: 'Submitted',
            Attempted: 'Attempted',
            Complete: 'Complete',
            Paid: 'Paid',
            InProcess: 'In Process',
            Canceled: 'Canceled',
            Remove: 'Removed',
        },
        /*
       |--------------------------------------------------------------------------
       | Account Detail
       |--------------------------------------------------------------------------
       */
        AccountDetail: {
            input_not_null: 'Input can not be empty',
            name_limited: 'Input can not be more than 15 characters',
            state_limited: 'Input can not be more than 24 characters',
            country_limited: 'Input can not be more than 32 characters',
            phone_limited: 'Number only and not empty',
            number_only_not0: 'Number only and not start with 0',
            number_only: 'Number only',

            button: {
                edit: 'EDIT',
                cancel: 'CANCEL',
                save: 'SAVE',
                reset: 'RESET',
                generatePassword: 'GENERATE PASSWORD',
                sendEmail: 'Send email to customer',
            },
            memberProfile: {
                title: 'Member Profile Edit',
                firstName: 'First Name',
                email: 'Email',
                address: 'Address',
                country: 'Country and Region',
                lastName: 'Last Name',
                DOB: 'Date of Birth',
                city: 'City',
                state: 'State',
                postCode: 'Postcode',
                mobile: 'Mobile',
                hubMerchant: 'HUB Merchant',
                hubCustomer: 'HUB Customer',
                is_ib: 'IB user',
                resetPasswordTitle: 'Reset Member Password to',
                passwordLimit: '*Requires numbers and letters, capitals, 8-12 characters',
                placeHolder: 'Enter a phone number',
                hub_money_manager: 'HUB Money Manager',
                button_banned_text: 'BANNED FUNDING METHOD LIST',
            },
            tradingProfile: {
                // Up side
                title: 'Trading Account Details Edit',
                tradingServer: 'Trading Sever',
                parentServer: 'Agent Server',
                tradingAccount: 'Trading Account',
                balance: 'Balance',
                equity: 'Equity',
                margin: 'Margin',
                marginFree: 'Margin Free',
                marginLevel: 'Margin Level',
                credit: 'Credit',
                parent: 'Agent',
                type: 'Type',
                customer: 'Customer',
                sales_code: 'Sales Code',

                // left-down side
                enable: 'Enable',
                regDate: 'Reg. Date',
                metaID: 'MetaQuotes ID',
                name: 'Name',
                phonePassword: 'Phone Password',
                city: 'City',
                state: 'State',
                country: 'Country and Region',
                address: 'Address',
                zipCode: 'Zip-code',
                phone: 'Phone',
                email: 'Email',
                idNUmber: 'ID Number',
                status: 'Status',
                color: 'Color',
                group: 'Group',
                comment: 'Comment',
                leverage: 'Leverage',
                forexOnly: 'Forex Only',
                taxRate: 'Tax Rate',
                agentAccount: 'Agent Account',
                leadSource: 'Lead Source',
                allowChangePassword: 'Allow to change password',
                readOnly: 'Read only (without trading)',
                sendReport: 'Send reports',
                oneTimePassword: 'Enable one-time password',

                // right-down side
                resetPasswordTitle: 'Reset Trading Account',
                password: 'Password',
                passwordType: 'Password Type',
                resetPasswordTo: 'Reset Password to',
                IBNodeTitle: 'IB Link',
                qrCode: 'QR Code',
                trading: 'Trading',
                investor: 'Investor',
                refresh: 'Refresh',
                download: 'Download',
            },
        },
        referral_links: {
            reset: 'Reset',
            search: 'Search',
            referral_name: 'Referral Name',
            target_country: 'Target Country and Region',
            referral_code: 'Referral Code',
            landing_page: 'Landing Page',
            website_link: 'Official Website Link',
            referral_link: 'Referral Link',
            ib_ta: 'IB TA',
            ta: 'Trading Account',
            node_name: 'Node Name',
            creation_time: 'Creation Time',
            action: 'Action',
            default: 'Default',
            global: 'Global',
            add_new_link: 'Add new link',
            filters: 'Filters',
            edit: 'Edit',
            export_flat_report: 'Export Search Result',
            export_all_report: 'Export All',
            referral_list: 'IB Referral Link',
            view: 'View',
            page_view: 'Page View',
        },
        tips: {
            select_user: 'Choose a member account in the above search block and more details will be show in this block.',
            export_report: 'Export report that including all trading accounts which belongs to this customer.',
            rebate_setting_rate_type: 'Once node rebate rate type was set to Fixed, the whole tree that this customer is belonged will be set to Fixed.',
            confirmReset: 'Are you sure to reset password?',
            no_tree_view: 'Can not get the trading account tree, please confirm whether you added trading sub-account.',
            select_type: 'Please choose the type!',
            change_ib: 'After changing IB status, this detail window will be closed',
            rebate_report_trade_account_select:
                'Selection of this column will be changed with TA (Short in Trading Account) column selection."IB TA Rebate Report" in this column aren’t available to select if you choose "Merged" in TA column.',
        },
    },
    reporting: {
        leverage_change: {
            leverage_change_list: 'Leverage Change Report List',
            export: 'EXPORT CSV',
            application_no: 'Application No.',
            member_name: 'Member Name',
            time_submitted: 'Time Submitted',
            time_processed: 'Time Processed',
            application_status: 'Application Status',
            original_leverage: 'Original Leverage',
            change_to: 'New Leverage',
            trading_account: 'Trading Account',
            trading_account_status: 'Trading Account Status',
            enabled_short: 'EN',
            readonly_short: 'RO',
            disabled_short: 'DIS',
            all: 'All',
            pending: 'Pending',
            complete: 'Complete',
            canceled: 'Canceled',
            view_more: 'VIEW MORE',
        },
        transaction_report_list: {
            view_more: 'VIEW MORE',
            main_title: 'Transaction Report',
            title: 'Transaction Report List',
            To: 'To',
            From: 'From',
            delete_info: 'Cancel Transaction',
            cancel_successfully: 'Cancel successfully',
            cancel_withdrawal: 'Cancel Withdrawal',
            cancel_internalTF: 'Cancel Internal TF',
            type: {
                deposit: 'Deposit',
                credit: 'Credit',
                internalTF: 'Internal TF',
                Rebate: 'Rebate',
                withdrawal: 'Withdrawal',
                CFD: 'CFD',
                Rollover: 'Rollover',
                Misc: 'Misc',
            },
            solve_Later_List: {
                server_type: 'Server Type',
                transaction_no: 'Transaction No.',
                time_submitted: 'Time Submitted',
                time_processed: 'Time Processed',
                time_completed: 'Time Completed',
                transaction_status: 'Transaction Status',
                transaction_type: 'Transaction Type',
                transaction_notes: 'Transaction Notes',
                member_name: 'Member Name',
                payment_amount_in: 'Payment Amount in/out',
                to: 'To',
                exchange_rate: 'Exchange Rate',
                surcharge_amount: 'Surcharge Amount',
                fund_in: 'Fund in/out',
                tradingAccount: 'Trading Account',
                trading_account_status: ' Trading Account Status',
                parent: 'Agent',
                trading_group: 'Trading Account Group',
                internal_notes: 'Internal Notes',
                updated_by: 'Last Edit By ',
                trading_server_comment: 'Trading Server Comment',
            },
            withdrawal_popup: {
                title: 'Payment Details',
                entry_tips: 'Please click Transaction No. for withdrawal details.',
                empty_reminder: 'No payment details available at this time. For assistance, kindly reach out to our customer support.',
            },
        },
        order_report_list: {
            end_time_need: 'End time cannot be empty',
            start_time_need: 'Start time cannot be empty',
            startTimeOver: 'Start time cannot be greater than end time',
            order_id_error: 'Order ID must be number',
            To: 'To',
            From: 'From',
            export: 'Export CSV',
            export_valid_orders: 'Export Valid Orders',
            view_more: 'VIEW MORE',
            main_title: 'Orders Report',
            title: 'Recent Orders',
            records: '*<u>%{record}</u> of %{allRecords} Records',
            account: 'Trading Account',
            close_order_history: 'Close order history',
            order_time: 'Order Time',
            account_name: 'Trading Account Name',
            open_time: 'Open Time',
            order_type: 'Order Type',
            symbol: 'Symbol',
            open_price: 'Open Price',
            close_time: 'Close Time',
            last_price: 'Last Price / Close Price',
            commission: 'Commission',
            comment: 'Comment',
            to: 'To',
            order_id: 'Order ID',
            volume: 'Volume',
            sl: 'S/L',
            tp: 'T/P',
            total_volume: 'Adjusted Volume',
            total_comm: 'Total Comm.',
            total_swap: 'Total Swap',
            total_profit: 'True Profit',
            total_total_profit: 'Total True Profit',
            swap: 'Swap',
            profit: 'Profit',
            open_orders: 'Open Orders',
            close_orders: 'Close Orders',
            pending_orders: 'Pending Orders',
            buy: 'Buy',
            all: 'All',
            sell: 'Sell',
            buy_limit: 'Buy Limit',
            sell_limit: 'Sell Limit',
            buy_stop: 'Buy Stop',
            sell_stop: 'Sell Stop',
            buy_stop_limit: 'Buy Stop Limit',
            sell_stop_limit: 'Sell Stop Limit',
            no_orders: 'No Orders',
            symbol_type: 'Symbol Type',
            name: 'Symbol Type',
            comm: 'Comm.',
            fx: 'FX',
            bullion: 'Bullion',
            oil: 'Oil',
            cfd: 'CFD',
            crypto: 'Crypto',
            total: 'Total',
            trading_server: 'Trading Server',
            view_summary: 'View Summary',
            adjusted_volume: 'Adjusted Volume',
            server_tip: 'Maximum show %{limit} records from one trading server in this table when customer request merged records from multi trading servers.',
            history_order_tip: '* The Historical Orders Report may not include orders from the last 30 minutes. Please note all time in the page is MT4/5 local time based.',
            adjusted_volume_tip: 'The result is based on: FX lots + Gold lots + (Oil lots + CFD lots + Crypto lots)*0.1）',
        },
        leverage_change_report_list: {
            title: 'Leverage Change Report List',
            main_title: 'Leverage Change Report',
        },
    },
    download: {
        qr_code_tip: 'Scan QR code to download',
        p_1: 'The most immediate market quotes',
        p_2: 'The most intuitive technical chart',
        p_3: 'The most complete analytical tool',
        p_4: 'The most timely financial news',
    },
    save_funding_info: {
        create_success_wait_for_verification: 'Create fund info successfully! Please wait for verification.',
        create_successfully: 'Create fund info successfully!',
        delete_successfully: 'Delete fund info successfully!',
        save_successfully: 'Save fund info successfully!',
        add_tip: 'Apply New Fund Information',
        number_type: 'Input must be numeric or English',
        delete_confirm: 'Are you sure to delete {name}?',
        deposit: {
            title: 'Deposit',
            add_title: 'Deposit Method',
            second_title: 'Credit Card',
            second_title_1: 'Poli Pay',
            second_title_2: 'Customized',
            card_name: 'Deposit Information Name',
            Card_Status: 'Verification Status',
            Poli_Pay_Status: 'Poli Pay Payment Status',
            not_verified: 'Not Verified',
            Verified: 'Verified',
            Reject: 'Rejected',
            card_num: 'Card Number',
            card_holder: 'Cardholder Name',
            cvv: 'CVV',
            expiry_date: 'Expiry Date',
            select_year_month: 'select Year-Month',
            upload_photo: 'Upload Photo',
            imageLabel: 'Image',
            areaLabel: 'Display Area',
            add_type_credit_card: 'Credit Card',
            add_type_poli_pay: 'Poli Pay',
            add_type_customized: 'Customized',
            click_upload: 'CLICK UPLOAD',
            delete_customized_info: 'Delete customized info %{name} ?',
            tips: {
                cvv_tips: 'CVV is the three-digit number that is printed on the signature panel on the back of the card.',
                card_name: 'Please set the name',
                card_num: 'Please enter a valid card number',
                card_holder: "Please enter card holder's name",
                cvv_empty: 'Please enter cvv',
                cvv_limit: 'cvv is a number and it must be 3 digits',
                expiry_date: 'Please select Expiry Date',
                image: 'Please upload photo file',
            },
            poli_pay: {
                account_name: 'Account Name',
                account_number: 'Account Number',
                bsb: 'BSB',
                tips: {
                    account_name: 'Please enter account name',
                },
            },
        },
        withdrawal: {
            title: 'Withdrawal',
            bank_account: 'Bank Account Number',
            bank_name: 'Bank Name',
            add_placeholder: 'Select type',
            add_title: 'Withdrawal Method',
            au: {
                self_bank_name: 'Withdrawal Information Name',
                bank_name: 'Bank Name',
                bank_account_number: 'Bank Account Number',
                bsb: 'BSB',
                account_holder_name: 'Account Holder’s Name',
                international_bank_code: 'Swift Code',
                tips: {
                    self_bank_name: 'Please enter account name',
                    bank_name: 'Please enter bank name',
                    bank_account_number: 'Please enter bank account number',
                    bsb: 'Please enter BSB',
                    account_holder_name: 'Please enter account holder’s name',
                    international_bank_code: 'Please enter swift code',
                },
            },
            global: {
                self_bank_name: 'Withdrawal Information Name',
                bank_name: 'Bank Name',
                bank_address: 'Bank Address',
                bank_account_number: 'Bank Account Number / IBAN',
                account_holder_address: 'Account Holder’s Address',
                account_holder_name: 'Account Holder’s Name',
                international_bank_code: 'International Bank Code(Swift Code)',
                sort_code: 'Sort Code',
                tips: {
                    self_bank_name: 'Please enter account name',
                    bank_name: 'Please enter bank name',
                    bank_address: 'Please enter bank address',
                    bank_account_number: 'Please enter bank account number',
                    account_holder_address: "Please enter account holder's address",
                    account_holder_name: 'Please enter account holder’s name',
                    international_bank_code: 'Please enter international bank code(Swift Code)',
                    sort_code: 'Please enter Sort Code',
                },
            },
            rmb: {
                self_bank_name: 'Withdrawal Information Name',
                bank_name: 'Bank Name',
                bank_account_number: 'Bank Account Number',
                account_holder_address: 'Account Holder’s Address',
                account_holder_name: 'Account Holder’s Name',
                bank_branch_name: 'Bank Branch Name',
                bank_province: 'Bank Province',
                bank_city: 'Bank City',
                tips: {
                    self_bank_name: 'Please enter account name',
                    bank_name: 'Please enter bank name',
                    bank_account_number: 'Please enter bank account number',
                    account_holder_address: "Please enter account holder's address",
                    account_holder_name: 'Please enter account holder’s name',
                    bank_province: 'Please enter bank province',
                    bank_city: 'Please enter bank city',
                    bank_branch_name: 'International bank branch name',
                },
            },
            other: {
                account_number: '%{title} Account Number',
                account: '%{title} Account',
                tips: {
                    account_number: 'Please enter account number',
                },
            },
            customization: {
                other_bank: 'Other Bank',
                tips: {
                    other_bank: `Please enter other bank's name here`,
                },
            },
        },
    },
    retrievePassword: {
        retrieve_password: 'Retrieve password',
        retrieve_password_title1: 'Please enter your Email address.',
        retrieve_password_title2: 'You will receive a link to create a new password via email.',
        accountTip: 'Email Address',
        verificationTip: 'Captcha',
        captcha_change: 'Change one',
        get_new_password: 'Get New Password',
        email_title1: 'A Link to reset your password has been sent to your email:',
        email_title2: 'Please check your inbox for our email. We have sent it from {email}',
        reset_password: 'Reset password',
        new_password: 'New Password',
        confirm_password: 'Confirm Password',
        submit: 'SUBMIT',
    },
    memberProfile: {
        default_setting: 'SETTINGS',
        preferred_language: 'Preferred Language:',
        subscription_news: 'Subscribe to News Emails:',
        receiving_email: 'Receiving Margin Call Email:',
        password_setting: 'RESET PASSWORD',
        login_email: 'Login Email',
        new_email: 'New Email',
        change_email: 'Change Email',
        change_password: 'Change Password',
        account_info: 'ACCOUNT INFORMATION',
        crm_password: 'Client Portal Password:',
        hub_pin_password: 'HUB PIN Password:',
        yes: 'YES',
        no: 'NO',
        pin_verification: '*4-6 characters, must contain digits or alphabets.',
        HUBX_tip: 'HUBX Customer Agreement was agreed successfully, please set a PIN for your HUBx',
        black_tip: 'Sorry, it is not supported to change email currently.',
        please_enter_new_email: 'Please enter your new email address.',
        enter_email_code_tip: 'Please input the code on {email} to complete verification.',
    },
    errorCode: {
        email_verify_limit: 'The maximum number of request attempts has been reached, please try again after 24 hours.',
        preview_key_expire: 'preview key expire',
        redeem_failed: 'Rewards redeem failed, please contact our support team.',
        participating_failed: 'Participating promotion failed, you do not meet the activation requirement, and activation period has passed.',
        authenticator_existed: 'Authenticator already existed',
        authenticator_not_exist: 'Authenticator is not existing.',
        reset_fail: 'Reset authenticator failed, password or date of birth was incorrect.',
        incorrect_password: 'One-time password was incorrect',
        new_email_not_supported: 'This new email address is not supported now.',
        promotion_end_cant_participate: 'The application period has ended, can not participate in the promotion.',
        promotion_not_have_authorized: 'Sorry, you are not authorized to participate in the promotion, please contact administrator.',
        promotion_have_withdrawn: 'You have withdrawn from this promotion, please refresh the page.',
        promotion_double_register: 'Do not double-register for the promotion, please refresh the page.',
        promotion_cant_adjust_order: 'Sorry, this promotion cannot adjust the order of progress settlement.',
        promotion_redeem_repeatedly: 'Do not redeem rewards repeatedly, please refresh the page',
        promotion_not_exist: 'promotion not exists.',
        promotion_cannot_delete: 'Promotion status is enable, can not delete.',
        promotion_ended: 'The promotion has ended, please refresh the page.',
        promotion_exception: 'Promotion exception, please contact administrator.',
        coupons_claim_not_successful: 'Your coupon claim was not successful. Please contact our customer support team for assistance.',
        system_busy: 'The system is busy, please refresh the page and try again.',
        redemption_failed: 'Redemption failed, please refresh the page and try again.',
        product_not_exist: 'The product does not exist.',
        product_not_sale: 'The product does not on sale.',
        product_not_redeem: 'Sorry, this product cannot be redeemed in your country.',
        product_not_available: 'Sorry, the item is not available anymore, please choose others instead.',
        invalid_product: 'Invalid product for cash back.',
        valid_trading: 'Please provide a valid trading account id.',
        amount_change: 'The payment amount for the product has changed. Please refresh the page and try again.',
        coupon_not_available: 'Payment failed, coupon not available.',
        please_open_wallet: 'Please activate your wallet account first.',
        no_permission_order: 'Sorry, you are not authorized to create the order.',

        not_allowed_transfer_in_IB_TA: 'IB Trading Account is not allowed to make internal transfer in.',
        not_allowed_deposit_to_IB_TA: 'IB Trading Account is not allowed to make deposit.',
        failed_please_contact_the_admin: 'Failed, Please contact the admin.',
        you_have_submitted_the_request_please_wait: 'You have submitted the request, Please wait.',
        apply_ta_limit: 'Trading Account limit reached. Please wait while Customer Service reviews your application.',

        single_demo_deposit_limit: 'Exceed demo trading account deposit limit',
        balance_demo_deposit_limit: 'Exceed demo trading account balance limit',
        disable_create_demo_ta: 'Additional Demo Account creation has been disabled. Please contact the admin for more info',

        link_error: 'The link has been deleted, please check with Admin.',
        reports_cannot_generated_manual_accounts: 'Reports cannot be generated for manual accounts',
        hub_code_201: 'This record already exists',
        hub_code_202: 'Record is not existing',
        hub_code_205: 'HUBx TA has reached the maximum limit and cannot be created',
        hub_code_301: 'Insufficient balance of the account',
        hub_code_302: 'Can not change group as the TA has balance',
        hub_code_303: 'Can not update group currency',
        hub_code_305: 'Can not update TA Group as the trading account is used in MAM',
        hub_code_306: 'Can not withdraw as the trading account is active in MAM',
        hub_code_308: 'Can not change the status of TA as the TA is activated in MAM',
        hub_code_999: 'Core error',
        link_invalid: 'The link is invalid',
        missing_required_field: 'Missing required field.',
        channel_name_exist: 'channel name is already exist.',
        user_register_error: 'User registration failed',
        email_format_error: 'The email format is incorrect',
        card_number_wrong: 'Card number wrong',
        card_info_wrong: 'The verified card information is incorrect',
        source_error: 'Source-parameter error.',
        registration_link_error: 'The registration link is incorrect, please contact the administrator',
        mam_slave_account_only_deposit: 'MAM slave account can only deposit',
        mam_account_cannot_deposit_withdraw: 'MAM master account cannot deposit or withdraw',
        user_not_participate_promotion: 'The current user is not participating in this promotion',
        exchange_rate_does_not_exit: 'Exchange rate does not exist',
        activity_not_started: 'Activity not started',
        more_than_activation_time: 'Cannot participate in the activity for more than activation time',
        promotion_can_not_withdrawal: 'The account is currently participating in the promotion can not be withdrawal',
        client_can_not_withdrawal: 'By participating in "Reward First" promotion, the client will not be able to submit any withdrawal transaction.',
        unable_to_add_wallet_account: `Additional Wallet creation has been disabled. Please contact support{'@'}threetrader.com for more info`,
        account_has_exceeded: 'Wallet account has exceeded the maximum limit and cannot be added',
        points_internal_is_close: 'Points account is not allowed to internal transfer.',
        points_withdraw_switch_is_closed: 'Points account is not allowed to withdraw.',
        points_deposit_switch_is_closed: 'Points account is not allowed to deposit.',
        trading_server_type_is_unique: 'The trading server type is unique, can not be create or edit',
        points_currency_cannot_be_added: 'POINTS currency cannot be added',
        unable_participate_promotion: 'unable to participate promotion again',
        no_account_participate_promotion: 'no account to participate in the promotion',
        cannot_add_wallet_account: 'Cannot add wallet account',
        lang_file_not_exist: 'Language file does not exist',
        register_config_changed: ' The registration configuration has been modified, please refresh the page.',
        exceeded_the_maximum_number_of_files: 'Exceeded the maximum number of files',
        less_than_the_minimum_number_of_files: 'Less than the minimum number of files',
        card_verified_can_not_edit_delete: 'This card has been verified and cannot be deleted or edited. If you have any questions, please contact the administrator.',
        rebate_reques_time_limit: 'Request time should not exceed three months, if you need to request additional time, please contact the administrator.',
        payment_info_error: 'Incorrect payment information',
        deposit_channel_disable: 'The payment method is under maintenance',
        missing_registration_configuration_information: 'Missing required parameters.',
        please_contact_the_administrator_to_register_account: 'Please contact the administrator to register account',
        address_is_invalid: 'The login in address is invalid',
        country_has_added_configuration_information: 'The country has added configuration information',
        configuration_information_cant_be_deleted: "The configuration information can't be deleted",
        please_upload_file: 'Please upload file',
        incorrect_user_name_or_email_authentication_code: 'Incorrect user name or email authentication code',
        email_authentication_code_has_expired: 'The email authentication code has expired',
        admin_cannot_add_demo_account: 'Admin user cannot add demo account',
        the_configure_not_exists: 'The configure not exists',
        demo_trading_account_through_the_registration_process: 'Cannot register demo trading account through the registration process',
        account_no_rebate_report: 'This Trading Account can not generate rebate report',
        leverage_no_display_now: 'The actual value of the leverage is not displayed due to data transmission, please try to confirm the change later.',
        csv_sent_email: '%{tableName} will be sent to your email cause data of this account is over 10,000',
        max_sent_email: '%{number} of %{totalCount} records displayed. File will be sent to you via email. If you can’t find the data you want, please change filter condition to narrow down.',
        take_a_little_while: 'It may take a little while for data download, please wait a moment',
        exceed_rate_limit: 'Request rate limit has been exceeded, please try again later',
        not_auth_review_account: 'Sorry, You are not authorized to view this account',
        no_ib: 'The current user is not an IB user',
        miss_system_config: 'Missing system configuration',
        channel_config_file_error: 'Channel configure file create failed',
        payment_method_has_same_name: 'This payment method already has a channel of the same name.',
        channel_config_delete_error: 'Channel configure file delete failed.',
        channel_config_edit_error: 'Channel configure file edit failed.',
        bank_code_same_name: 'This payment method already has a bank code of the same name.',
        default_channel_not_exist: 'The default channel does not exist.',
        amount_not_meet_the_requirements: 'The revised amount does not meet the requirements.',
        method_has_exist: 'Method has existed',
        insufficient_balance: 'Insufficient balance',
        insufficient_withdrawal_amount: 'Insufficient Withdrawal amount',
        transactio_blocked: 'This transaction has been blocked',
        csv: 'There is no exportable content.',
        trans_edit_failed: 'Transaction edit failed',
        trans_approve_failed: 'Transaction approve failed',
        changes_this_state_not_allowed: 'Changes to this state are not allowed.',
        transactionTypeExist: '	The transaction type is already exist',
        TransactionTypeNoEdit: 'Update this transaction type are not allowed',
        TransactionTypeNoDelete: 'Delete this transaction type are not allowed',
        data_change: 'The data has changed, please refresh the page',
        BULK_FUNDING_UPLOAD: 'The column of this file in incorrect',
        user_black_list: 'Sorry, we are not able to open an account for you now.',
        language_invaild: 'The language was invalid',
        agreement_not_exist: 'The agreement does not exist',
        trading_server_error: 'No transaction server is available',
        exists_error_tips: 'The set name has already been taken.',
        exist_name: 'The login name has already been taken',
        exist_email: 'The email you entered has already been registered',
        no_default: 'no default problem set',
        not_jurisdiction: 'jurisdiction not have trading server',
        unable_select_the_server: 'Sorry, this trading server is not available to select currently. Please contact your account manager or email us (%{email}).',
        not_trading_server_question: 'Sorry, there is no questionnaire set',
        not_exist_default_exchange_rate: 'Not exist default exchange rate',
        internal_transfer_failed: 'Internal transfer failed',
        not_exist_exchange_rate: 'The exchange rate does not exist',
        fund_not_allow: 'The fund amount is not allowed by the channel',
        payment_not_allow: 'The payment amount is not allowed by the channel',
        channel_not_exist: 'Sorry, the channel is under maintenance. Please try later.',
        amount_not_enough_withdrawal: 'Sorry, the Fund out Amount is not enough to pay the surcharge, please modify the Fund out Amount.',
        unable_withdrawal: 'Sorry, the Funding Method is under maintenance. Please try later.',
        debounce_transaction: "We're processing your transaction, please make your new transaction after 2 minutes!",
        no_permission: 'No permission to cancel this Transaction',
        fund_name_exist: 'Funding info name already exist',
        user_has_no_permission: 'This user has no operation permissions.',
        withdrawal_bank_info_name_exist: 'Bank info name %{name} has already been taken',
        this_currency_disabled: 'This currency is disabled',
        chose_trading_server: 'The chosen trading server is incompatible with the trading account.',
        ib_users_no_trading_account: 'Sorry, You are not authorized to view this account.',
        invalid_request_address: 'Sorry, your account is not available at this site. If there’s any problem please contact your account manager.',
        file_lock_cannot_deleted: 'Locked files cannot be deleted',
        file_lose_config: 'Configuration file lose config',
        missing_order_type: 'Missing order type',
        beyond_the_limitation_of_ta: 'You have reached the maximum number of trading accounts. Please contact our support team for more information.',
        missing_deposit_parameters: 'Missing deposit parameters',
        third_party_failed: 'Dear customer, the system is currently busy. Please try again or contact your account manager.',
        channel_does_not_exist: 'Channel does not exist',
        user_not_exist: 'User not exist.',
        error_deposit_request: 'Sorry,Cancel transaction failed,Request deposit fund error',
        not_ib_portal: 'IB Portal not open',
        email_invalid: 'invalid email address',
        crm_under_maintenance_tip: 'Our Portal is currently under maintenance. Please try again later',
        function_disabled_tip: 'The function has been disabled temporarily',
        duplicate_trading_account_exist:
            'There are same number Trading Accounts on different Trading Servers in CRM. Please search the tree at first, and choose your target account on the tree to generate the rebate report.',
        not_is_IB: 'This account is not IB',
        TA_not_available_to_check: 'This Trading Account isn’t available to check.',
        apply_ta_failed: 'Your submission of sub account application failed. Please try again. If it failed again, please contact your account manager.',
        request_trading_account_failed: 'Sorry, this trading server is not available to select currently. Please contact the corresponding management personnel.',
        demo_config_error: 'Demo trading account config error.',
        not_create_demo: 'Unable to create demo trading account due to configuration restrictions.',
        demo_not_acount: 'Exceed demo trading account quantity limit',
        demo_deposit_error: 'Demo Trading Account Request creditIn fund failed.',
        not_receive_reward: 'The user does not receive a reward',
        not_have_account: 'This user does not have a POINTS account. Please contact the administrator',
        received_all_reward: 'You have received all the reward',
        captcha_verify_fail: 'Captcha verify fail.',
        miss_required_parameters: 'Missing required parameters.',
        password_is_empty: 'The user password is empty, please contact the administrator.',
        sale_code_error: 'Assign sale code error.',
        upload_error: 'Short agreement upload error.',
        referral_code_exist: 'The referral code already exists.',
        referral_link_no_create_permission: 'You do not have the right to create referral code.',
        referral_link_no_edit_permission: 'You do not have the right to edit referral code.',
        no_point_account: 'This user does not have a POINTS account. Please contact the administrator.',
        the_account_is_archived: 'The account is archived',
        your_account_cannot_be_used: `Your account has been disabled. Please contact support{'@'}threetrader.com for more info.`,
        the_email_in_black_list_unable_to_retrieve_password: 'The email in black list，Unable to retrieve password.',
        user_has_no_operation_permissions: 'This user has no operation permissions.',
        network_access_is_restricted: 'Sorry, your network access is restricted. Please contact our support team.',
        is_mam_template_exists: 'The template name already exists',
        get_code_message: 'Verification code has been sent already, please try again in {minutes} minutes.',
        send_error: 'Oops, something went wrong, please try again later.',
        invalid_code: 'Invalid Verification Code',
        used_code: 'The code has been used.',
        email_contact_admin: 'Failed to send the verification code, please contact the administrator.',
        complete_required_questions: 'Please Complete All Question(s).',
        operation_too_frequent_please_try_again_later: 'Operation too frequent, please try again later.',
        bank_does_not_exist: 'Bank does not exist',
        notTransferToIb: 'The currently selected account does not support transferring funds to IB TA.',
        product_category_not_display: 'System error. Please contact admin for assistance.',
        coupon_is_not_valid: 'This coupon is not valid. Please contact admin for assistance.',
        request_deposit_fund_failed: 'Transaction failed. Please try again later.',
        exchange_rate_invalid: 'Exchange rate not valid.',
    },
    error: {
        format: {
            password_error: 'Please input right format password.',
            number_limited: 'Number only and not empty.',
            empty_limited: 'Input can not be empty',
            money_limited: 'Only number with no more than two decimal.',
            email_limited: 'Please input the right format email.',
            password_limited: 'Password format error, Please try again.',
            incorrect_limited: 'Incorrect input information',
            is_required: '%{field} is required',
            select_valid_date: 'please select a valid date',
            Select_Error: 'Please select {field}',
        },
        password_not_equal: 'Password not equal, Please confirm your password.',
        hub_pin_not_equal: 'PIN not equal, Please confirm your PIN.',
    },
    agreements: {
        proof_file_management: 'Verification Documents',
        upload_tutorial: '%{companyName} Document Upload Tutorial',
        click_add: 'Click on Add',
        select_file: 'SELECT FILE',
        agreements_management: 'Agreements Management',
        type: 'Type',
        agreement_name: 'Agreement Name',
        agree: 'Agree',
        type_select: 'Type Select',
        identification_proof: 'Identification Proof',
        address_proof: 'Address Proof',
        bank_statement: 'Bank Statement',
        selfie: 'Selfie',
        others: 'Others',
        upload_new: 'Upload New',
        browse_existing_file: 'Browse Existing Files',
        file_name: 'File Name >>',
        file_preview: 'File Preview >>',
        upload_success: 'Upload Success!',
        delete_file: 'Delete File - %{name}?',
        agree_file: 'Agree to this agreement - %{name}?',
        note_start: 'Tick File to agree: ',
        note_end: ': Agreed,customer agree',
        trading_account: 'Trading Account',
        poi: 'Proof of Identity (Passport, National ID, Drivers License)',
        poa: 'Proof of address (Utility Bill, Bank/Credit Card Statement, Landline Phone/Internet Bill)',
        other: 'Others (Bank Statement, Selfie, etc)',
    },
    Sidebar: {
        TA_detail_popup_window: 'TA Detail Popup Window',
        orders_report_recent_order: 'Recent Orders',
        orders_report_historical_orders: 'Historical Orders',
        funding_management: {
            transaction_history: 'All Transactions',
        },
        subMenu: {
            inbox: 'Inbox',
            archive: 'Archive',
        },
    },
    global: {
        popup_menu: {
            account_detail: 'Account Detail',
            quick_Funding: 'Quick Funding',
            all_transaction: 'All Transactions',
            order_report: 'Recent Orders',
            rebate_settings: 'Rebate Settings',
        },
        order_report: {
            select: 'Select',
            all: 'All',
            open_orders: 'Open',
            close_orders: 'Close',
            pending_orders: 'Pending',
            top_label: '*Only show data in recent 6 months. If required further please visit detail section.',
            order_time: 'Order Time',
            open_time: 'Open Time',
            order_type: 'Order Type',
            order_status: 'Order Status',
            symbol: 'Symbol',
            open_price: 'Open Price',
            close_time: 'Close Time',
            last_price: 'Last Price / Close Price',
            commission: 'Commission',
            comment: 'Comment',
            to: 'To',
            order_id: 'Order ID',
            volume: 'Volume',
            sl: 'S/L',
            tp: 'T/P',
            swap: 'Swap',
            profit: 'Profit',
            total_volume: 'Total Volume',
            total_comm: 'Total Comm.',
            total_swap: 'Total Swap',
            total_profit: 'True Profit',
            total_total_profit: 'Total True Profit',
        },
    },
    report_management: {
        enter_trading_account: 'Enter Trading Account',
        search_type: 'Search Type',
        search_tree: 'Search Tree',
        apply_report: 'Apply Report',
        report_time_select: 'Report Time Select',
    },
    ReportManagement: {
        client_list_tree: 'Client List Tree',
        request_history: 'Request History',
        order_time_type: 'Order Time Type',
    },
    dashboard: {
        show_the_latest: 'Show the latest',
        recent_orders_thirty_mins_show: '*Recent orders may take up to 30 minutes to show',
        new_accounts: 'New Accounts',
        transactions: 'Transactions',
        orders: 'Recent Orders',
        margin_call_account: 'Margin Call Account',
        data_updated: 'Data was updated at ',
        update_now: 'UPDATE NOW',
        identity_status: 'Proof of Identity Status',
        address_status: 'Proof of Address Status',
        bank_status: 'Proof of Bank Status',
        verification_status: 'Proof of Verification Status',
        new_account: {
            register_time: 'Register Time',
            member_name: 'Member Name',
            verification: 'Verification Status',
            trading_server: 'Trading Server',
            ta: 'Trading Account',
            ta_mini: 'TA',
            parent: 'Agent',
            group: 'Group',
            status: 'Trading Account Status',
            ta_status: 'TA Status',
            check_detail: 'Check Detail',
            view: 'VIEW',
            en: 'EN',
            dis: 'DIS',
        },
        transaction_table: {
            time_submitted: 'Time Submitted',
            time_completed: 'Time Completed',
            member_name: 'Member Name',
            server_type: 'Server Type',
            ta: 'Trading Account',
            ta_mini: 'TA',
            parent: 'Agent',
            ta_status: 'Trading Account Status',
            ta_status_mini: 'TA Status',
            fund: 'Fund in/out',
            txn_no: 'Transaction No.',
            transaction_status: 'Transaction Status',
            transaction_types: 'Transaction Types',
            all: 'All Transaction Types',
            all_tt: 'All TXN Types',
            all_ts: 'All TXN Statuses',
            deposit: 'Deposit',
            withdrawal: 'Withdrawal',
        },
        margin_table: {
            member_name: 'Member Name',
            trading_server: 'Trading Server',
            ta: 'Trading Account',
            ta_mini: 'TA',
            parent: 'Agent',
            balance: 'Balance',
            equity: 'Equity',
            credit: 'Credit',
            margin_level: 'Margin Level',
            check_detail: 'Check Detail',
            view: 'VIEW',
        },
        orders_table: {
            order_id: 'Order ID',
            ta: 'Trading Account',
            order_type: 'Order Type',
            symbol: 'Symbol',
            volume: 'Volume',
            close_time: 'Close Time',
        },
    },
    date: {
        month_1: 'Jan',
        month_2: 'Feb',
        month_3: 'Mar',
        month_4: 'Apr',
        month_5: 'May',
        month_6: 'Jun',
        month_7: 'Jul',
        month_8: 'Aug',
        month_9: 'Sep',
        month_10: 'Oct',
        month_11: 'Nov',
        month_12: 'Dec',
    },
    aliyun: {
        _startTEXT: 'Please slide to verify',
        _yesTEXT: 'Verified',
        _error300: 'Oops, something went wrong, click <a href="javascript:__nc.reset()">refresh</a> to do it again',
        _errorNetwork: 'Network error, please click <a href="javascript:__nc.reset()"> refresh</a>',
    },
    notification: {
        popup: {
            next: 'NEXT',
            back: 'BACK',
            close: 'CLOSE',
            confirm: 'CONFIRM',
            do_not_show_again: 'Do not show again',
        },
    },
    referral_link: {
        pop: {
            add_title: 'CREATE A NEW REFERRAL LINK',
            edit_title: 'EDIT ',
            view_title: 'View',
            ta: 'IB Trading Account',
            redirect: 'Referral Link redirects to registration directly.',
            do_you_want_to_cancel_create: 'Do you want to cancel the creating?',
            do_you_want_to_cancel_edit: 'Do you want to cancel the editing?',
            do_you_want_to_update: 'Do you want to update this IB Referral Link?',
            do_you_want_to_create: 'Do you want to create this IB Referral Link?',
            tips: {
                name: 'Referral Name is required',
                ta: 'IB Trading Account is required',
                node: 'Node is required',
                referral_code: 'Referral Code is required',
                landing_page: 'Landing Page is required',
                referral_link: 'Referral Link is required',
                Maximum64: '*Maximum 64 characters',
                referral_link_tip: '*Node has been selected, this IB Referral Link is a registration link.',
            },
        },
    },
    new_login_version: {
        login_title: 'ThreeTrader Portal',
        login_sub_title: 'It is a long established fact that a reader will be distracted by the It is a long established fact that',
        knowledge_question: 'Knowledge questionnaire',
        set_a_password: 'Client Portal Password',
        register: {
            step_two: {
                date_time_tip: 'Date time value is required and correct',
                year_less: 'The year of validity cannot be less than this year',
            },
        },
        register_tips_email: `no-reply{'@'}email.threetrader.com`,
        register_tip1: 'Please make sure that emails from ',
        register_tip2: ' do not get marked as spam so that you can receive all communication emails seamlessly. We suggest adding ',
        register_tip3: 'to your list of contacts to avoid emails landing in your spam folder. ',
    },
    upgrade_desc: {
        current: 'Current',
        upgrade_now: 'Upgrade Now',
        count_down: 'Next  possible attempt after: %{time}',
        overall_progress: 'Overall Progress',
    },
    MessageCenter: {
        inbox_title: 'INBOX',
        archive_title: 'ARCHIVE',
        inbox: {
            title: 'Inbox',
            date: 'Date',
            search_placeholder: 'Search Sender, Title or Content.',
        },
        archive: {
            title: 'Archive',
            search_placeholder: 'Search Sender, Title or Content.',
        },
        common: {
            original: 'Original:',
            reply: 'Reply:',
            total_message: 'Total: %{count} Messages',
            unread: 'Unread',
            pin: 'Flagged',
            subject: 'Subject',
            sender: 'Sender',
            sender_type: 'Sender Type',
            sender_typeOpts: {
                admin: 'Admin',
                sales: 'Sales',
                member: 'Member',
            },
            recipient_type: 'Recipient Type',
            type: 'Type',
            typeOpts: {
                trigger: 'Trigger',
                manual: 'Manual',
            },
            date: 'Date',
            recipient: 'Recipient',
            time_sent: 'Time Sent',
            save_time: 'Save Time',
            source: 'Source',
            sourceOpts: {
                sent: 'Sent',
                inbox: 'Inbox',
            },
            checkbox_btn_inverse: 'INVERSE',
            checkbox_btn_mark_read: 'MARK READ',
            checkbox_btn_mark_unread: 'MARK UNREAD',
            checkbox_btn_archive: 'ARCHIVE',
            checkbox_btn_reopen: 'REOPEN',
            sender_options: {
                is_delete_group: 'Do you want to delete group %{name}?',
                select_groups: 'Select Group',
                send_successfully: 'Send Message Successfully',
                save_successfully: 'Save Draft Successfully',
                portals: 'Portals',
                recipient: 'Recipient',
                add_group: 'Add Group',
                group: 'Group',
                subject: 'Subject',
                body: 'Body',
                save_draft: 'SAVE DRAFT',
                allow_reply: 'Allow Reply',
                send_identical_email: 'Send Identical Email',
            },
        },
        tips: {
            mark_as_read: 'Message marked as read.',
            mark_as_read_fail: 'Action Failed.',
            mark_as_unread: 'Message marked as unread.',
            mark_as_unread_fail: 'Action Failed.',
            archived: 'Message archived',
            archived_fail: 'Action Failed.',
            reopened: 'Message reopened',
            reopened_fail: 'Action Failed',
        },
        detail: {
            see_more: 'Show More',
            see_less: 'Hide',
            reply: 'REPLY',
            reply_to: 'Reply To:',
            do_you_cancel_this_reply: 'Do you cancel this reply?',
            do_you_want_to_send_this_message: 'Do you want to send this message?',
            reopen: 'REOPEN',
            reopen_successfully: 'Reopen Successfully',
            send_success: 'Sent successfully!',
        },
    },
    transactionStatus: {
        all: 'All',
        suspended: 'Suspended',
        complete: 'Complete',
        created: 'Created',
        error: 'Error',
        pendingTF: 'Pending TF',
        canceled: 'Canceled',
        paid: 'Paid',
        confirmed: 'Confirmed',
        processing: 'Processing',
        submitted: 'Submitted',
        frozen: 'Working',
        exporting: 'Exporting',
        exported: 'Exported',
        attempted: 'Attempted',
        inProcess: 'In Process',
        pending: 'Pending',
        refunded: 'Refunded',
    },
    trading_tools: {
        trading_not_available_mobile: 'WEB Trader is currently not available for mobile device. You can download the trading app that is suitable for your device on Download page.',
        go_to_downloads: 'Go to Downloads',
    },
    rewards: {
        voucher_code: 'Voucher Code',
        filters: 'FILTERS',
        back: 'Back',
        next: 'Next',
        confirm1: 'Confirm',
        search: 'Search',
        cancel: 'Cancel',
        confirm: 'Confirm',
        default: 'Default',
        edit: 'Edit',
        delete: 'Delete',
        action: 'Action',
        view: 'VIEW',
        reset: 'RESET',
        submit: 'SUBMIT',
        save: 'SAVE',
        create_success: 'Created successfully!',
        update_success: 'Update Successfully!',
        delete_success: 'Delete Successfully!',
        delete_confirm: 'Do you want to delete delivery address?',
        to: 'to',
        no_data: 'NO DATA',
        point_currency_name: 'Points',
        failed_to_load: 'Failed to load',
        no_more: 'No More',
        /******************系统通用 end************ */
        /* **************error code start*************/
        code_system_error: 'System Error',
        code_point_internal_transfer_switch_is_closed: 'POINTS internal transfer switch is closed',
        code_point_withdraw_switch_is_closed: 'POINTS withdraw switch is closed',
        code_point_deposit_switch_is_closed: 'POINTS deposit switch is closed',
        code_please_try_again_later: 'Please try again later',
        code_network_error: 'The request server failed. Please try connecting again.',
        code_delivery_address_not_exists: 'delivery address not exists.',
        code_given_data_was_invalid: 'The given data was invalid',
        code_given_product_not_exist: 'Product not exist.',
        code_product_not_on_sale: 'Product not on sale.',
        code_product_categories_not_display: 'Product categories not display.',
        code_no_permission_user_country: 'No permission(user country).',
        code_no_permission_user_jurisdiction: 'No permission(user jurisdiction)',
        code_this_user_does_not_have_a_POINTS_account_please_contact_the_administrator: 'You currently do not have a Points Wallet. Please contact support for more information.',
        /* **************error code end*************/
        /****************积分商城 start*************/
        not_for_sale: 'Off The Shelf',
        detail: 'Detail',
        sold_out: 'Sold Out',
        stock: 'Stock:',
        items: 'items',
        name: 'Name',
        address_one: 'Address 1',
        address_two: 'Address 2',
        points: 'Points',
        redeem_now: 'Redeem Now',
        search_category: 'Search By Category',
        more_products: 'More Products',
        homepage: 'Home',
        category: 'Category',
        custom_amount: 'Custom Amount',
        orders: 'Orders',
        address: 'Address Management',
        userMenu: 'User Menu',
        set_default_addr: 'Make Default',
        model: 'Model',
        quantity: 'Quantity',
        default_delivery_address: 'Default',
        province: 'Province',
        city: 'City',
        suburb: 'Suburb',
        detail_address: 'Detailed Address',
        state: 'State',
        country_region: 'Country and Region',
        phone: 'Phone Number',
        confirm_order_items: 'Confirm Order Items',
        payment: 'Payment',
        payment_time: 'Payment Time',
        buy_tip_success: 'Your order has been completed',
        buy_tip_submit_fail: 'Submission Failed. Please create a new order.',
        buy_tip_payment_fail: 'Payment failed due to insufficient points.',
        buy_tip_insufficient_inventory: 'Sorry, this item is currently out of stock.',
        continue_shopping: 'Continue Shopping',
        check_orders: 'Check Orders',
        return_to_item_detail: 'Return to item details',
        return_to_item_home: 'Return to item Home',
        add_new_delivery_address: 'Add New Delivery Address',
        cancel_order: 'Confirm to cancel the order?',
        confirm_order: 'Confirm to buy the',
        confirm_order_page: 'Confirm Order',
        sort_by: 'Sort by:',
        time_added_most_recent: 'Time Added: Most Recent',
        time_added_least_recent: 'Time Added: Least Recent',
        points_highest: 'Points: Highest',
        points_lowest: 'Points: Lowest',
        under_10000: 'Under 10000',
        please_select_model: 'Please select model',
        product_detail: 'Product Details',
        variation: 'Variation',
        your_points: 'Your',
        order_details: 'Order Details',
        order_status: 'Order Status',
        recipient: 'Recipient',
        phone_number: 'Phone Number',
        phone_number_tip: 'Input the country code followed by the phone number, e.g. 061 1234567890',
        postcode: 'Post Code',
        delivery_address: 'Delivery Address',
        create_time: 'Creation Time',
        order_number: 'Order Number',
        transaction_number: 'Transaction Number',
        tracking_number: 'Tracking Number',
        postage_information: 'Postage Information',
        item: 'Item',
        price: 'Price',
        total: 'Total',
        paid: 'Paid',
        completed: 'Completed',
        canceled: 'Canceled',
        processing: 'Processing',
        product_brand: 'Product / Brand',
        enter_item_name_or_order_number: 'Enter Item Name or Order Number',
        add_new: 'Add New',
        order_or_product: 'Order/Product',
        selected: 'Selected',
        edit_delivery_address: 'Edit Delivery Address',
        discount: 'Discount',
        hot: 'Hot',
        new: 'New',
        trading_account: 'Please select a trading account to receive your cash back:',
        purchase_number: 'Purchase Number',
        purchase_information: 'Purchase Information',
        points_return_time: 'Points Return Time',
        cash_back_account: 'Cash Back TA',
        cash_back_time: 'Cash Back Deposit Time',
        cash_back_reverse_time: 'Cash Back Reversed Time',
        /***************tips start**********************/
        tips_select_address: 'Please select a delivery address.',
        tips_add_address: 'Please add a new delivery address.',
        user_has_no_permission: 'This user has no operation permissions.',
        tips_no_account_available: `There is no eligible trading account available for receiving cash back, please contact "support{'@'}threetrader.com" for assistance.`,
        /***************tips end**********************/
        /****************积分商城 end*************/
        coupons: {
            name: 'COUPONS',
            coupon_nots: 'COUPON',
            your_coupons: 'Your Coupons',
            get_coupons: 'Get Coupons',
            redemption: 'Redemption Vouchers',
            discount: 'Discount Coupons',
            all: 'ALL',
            soon: 'Expire soon',
            used: 'Used / Expired',
            participate_coupons: 'Participate in promotions and get more coupons!',
            coming: 'Coming Soon',
            learn_more: 'Learn More',
            view_more: 'View More',
            free: 'FREE',
            redemption_text: 'Redemption',
            valid_date: 'Valid date',
            copy_use: 'Copy and Use',
            received_from: 'Received from {accessType}',
            used_text: 'USED',
            expired_text: 'EXPIRED',
            not_yet_available: 'NOT YET AVAILABLE',
            not_available: 'NOT AVAILABLE',
            received_time: 'Received time',
            available_products: 'Availabel products',
            off: 'OFF',
            and_more: 'and {length}+ products',
            stacked: '; can be stacked with product discount',
            available_only: 'available for some products only',
            available_all: 'available for all products',
            coupon_code: 'Coupon Code',
            got_it: 'I got it',
            day_validity: '{dayNum}-DAY VALIDITY',
            more_info: 'You can use POINTS and Coupons to redeem items in the Rewards. This coupon is {available}.',
            free_redemption: 'gift redemption voucher',
            discount_off: '{discount}% OFF COUPON',
            discount_price: 'DISCOUNT PRICE: ',
            original_price: 'Original price: ',
            after_coupon_price: 'AFTER COUPON',
            discount_price2: 'Discount price',
            returned: '(Returned)',
            promotion: 'promotion',
            registration: 'registration',
            trading: 'trading',
            deposit: 'deposit',
            search_placeholder: 'Enter item name or coupon code to search',
        },
    },
    otp: {
        authenticator: 'Authenticator',
        add: 'Add',
        name: 'NAME',
        two_factor: 'Two-factor Authenticator',
        add_time: 'ADDED TIME',
        action: 'ACTION',
        add_authenticator: 'Add Authenticator',
        edit_authenticator: 'Edit Authenticator',
        edit_sub_title: 'You can turn it on/off to Login function.',
        login: 'Login',
        forget_pwd: 'Forget Password',
        change_pwd: 'Change Password',
        change_email: 'Change Email',
        withdrawal: 'Withdrawal',
        withdrawal_tip: 'Only apply to withdrawal methods that enable OTP',
        transfer_to_sub: 'Transfer To Sub',
        note: 'Note:',
        tip_apply: 'It can apply to Login, Forget Password, Edit Password, Change Email, Withdrawal, Transfer to Sub.',
        tip_method: 'You can turn it on/off to Login function thru Edit icon button after adding authenticator.',
        please_install: 'Please Install Authenticator on your phone',
        please_ensure: 'Please ensure that you have installed any authenticator on your phone, then click "Next".',
        recommend: 'Recommend: ',
        microsoft: 'Microsoft Authenticator',
        google: 'Google Authenticator',
        scan_qr: 'Please scan the QR code with your authenticator on your phone.',
        cant_scan: "Can't scan QR code?",
        scan_next: 'Click "Next" after scanning.',
        input_manually: 'Please input below info manually.',
        account: 'Account: ',
        key: 'Key: ',
        bind_authenticator: 'Binding your authenticator',
        please_input: 'Please input the one-time password on your authenticator. ',
        bind_successful: 'Binding authenticator successfully!',
        delete_authenticator: 'Delete Authenticator',
        verification: 'OTP Verification',
        sure_delete: 'Are you sure to delete this binded authenticator?',
        authenticator_deleted: 'Authenticator deleted!',
        please_input_complete: 'Please input the OTP on your authenticator to complete verification.',
        one_time_password: 'One-time password',
        lost_authenticator_contact: 'If you lost your authenticator, please contact our support department',
        lost_authenticator: 'Lost your authenticator?',
        reset_authenticator: 'Reset Authenticator',
        please_fulfill: 'Please fulfill below verification, you will receive an email contains a Verification Code to complete reset authenticator.',
        code_expire: ' We just sent your Verification Code via email to {email}, This code will expire at {time}, Once login succeed your authenticator will be deleted.',
        verification_code: 'Verification Code',
        not_get_email: "Didn't get the email?",
        been_sent: 'The verification code has been sent to your registered email. It will be valid for {time} minutes. Please enter the code to complete the verification.',
        try_again: 'Verification code has been sent already, please try again in {time} minutes.',
        been_reset: 'Your authenticator has been reset, please login again',
        verify: 'Verify',
        reset_password: 'Reset Password',
        apply: 'Apply',
        or_use_email: 'or use email to verify',
        have_trouble_use_otp: 'Have trouble using your authenticator?',
        use_email: 'Use your email instead to verify.',
    },

    vxe: {
        loading: {
            text: 'loading...',
        },
        table: {
            emptyText: 'No Data',
        },
    },
};

module.exports = en_local;
