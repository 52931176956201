import * as _ from 'lodash-es';
import { defineStore } from 'pinia';
import * as actionTypes from './actionTypes';

interface View {
    type: 'order-request' | 'csv';
    id: string;
    payload: Record<string, any>;
}
interface State {
    activeViewID: string;
    viewList: View[];
}

export const useReportsStore = defineStore('reports', {
    state: (): State => ({
        activeViewID: 'order-request-default',
        viewList: [
            {
                type: 'order-request',
                id: 'order-request-default',
                payload: {},
            },
        ],
    }),

    getters: {
        activeView(state): any {
            return (
                _.find(state.viewList, {
                    id: state.activeViewID,
                }) || {}
            );
        },
    },

    actions: {
        [actionTypes.ADD_VIEW](payload) {
            const exists = _.find(this.viewList, {
                type: payload.type,
                id: payload.id,
            });

            if (!exists) {
                this.viewList.push(payload);
            }

            this.activeViewID = payload.id;
        },
        [actionTypes.TOGGLE_VIEW](id) {
            this.activeViewID = id;
        },
        [actionTypes.REMOVE_VIEW](id) {
            this.viewList = _.filter(this.viewList, (item) => item.id !== id);

            if (this.activeViewID === id) {
                this.activeViewID = 'order-request-default';
            }
        },
        [actionTypes.RESET_VIEW]() {
            this.activeViewID = 'order-request-default';
            this.viewList = [
                {
                    type: 'order-request',
                    id: 'order-request-default',
                    payload: {},
                },
            ];
        },
    },
});
