import {
    ElAlert,
    ElAside,
    ElAvatar,
    ElBadge,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElButton,
    ElCard,
    ElCarousel,
    ElCarouselItem,
    ElCascader,
    ElCheckbox,
    ElCheckboxGroup,
    ElCol,
    ElCollapse,
    ElCollapseItem,
    ElConfigProvider,
    ElContainer,
    ElDatePicker,
    ElDialog,
    ElDivider,
    ElDrawer,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElFooter,
    ElForm,
    ElFormItem,
    ElHeader,
    ElIcon,
    ElImage,
    ElInput,
    ElInputNumber,
    ElMain,
    ElMenu,
    ElMenuItem,
    ElMessage,
    ElNotification,
    ElOption,
    ElPagination,
    ElPopover,
    ElProgress,
    ElRadio,
    ElRadioButton,
    ElRadioGroup,
    ElRow,
    ElScrollbar,
    ElSelect,
    ElStep,
    ElSteps,
    ElSwitch,
    ElTabs,
    ElTimePicker,
    ElTimeSelect,
    ElTimeline,
    ElTimelineItem,
    ElTooltip,
    ElTree,
    ElUpload,
} from 'element-plus';
import { App } from 'vue';
export const ElFormType = ElForm;
export const $message = ElMessage;

/**
 * @param app {App}
 */
export default function loadComponent(app: App) {
    app.use(ElAvatar);
    app.use(ElInput);
    app.use(ElRow);
    app.use(ElCol);
    app.use(ElContainer);
    app.use(ElHeader);
    app.use(ElMain);
    app.use(ElBadge);
    app.use(ElCheckbox);
    app.use(ElCheckboxGroup);
    app.use(ElIcon);
    app.use(ElCollapse);
    app.use(ElCollapseItem);
    app.use(ElTimePicker);
    app.use(ElTimeSelect);
    app.use(ElButton);
    app.use(ElSelect);
    app.use(ElOption);
    app.use(ElForm);
    app.use(ElFormItem);
    app.use(ElFooter);
    app.use(ElPopover);
    app.use(ElRadio);
    app.use(ElRadioGroup);
    app.use(ElRadioButton);
    app.use(ElTooltip);
    app.use(ElPagination);
    app.use(ElSteps);
    app.use(ElStep);
    app.use(ElAside);
    app.use(ElDropdown);
    app.use(ElDropdownItem);
    app.use(ElDropdownMenu);
    app.use(ElUpload);
    app.use(ElProgress);
    app.use(ElMessage);
    app.use(ElMenu);
    app.use(ElMenuItem);
    app.use(ElSwitch);
    app.use(ElCascader);
    app.use(ElTimeline);
    app.use(ElTimelineItem);
    app.use(ElAlert);
    app.use(ElDrawer);
    app.use(ElNotification);
    app.use(ElDialog);
    app.use(ElDatePicker);
    app.use(ElCard);
    app.use(ElConfigProvider);
    app.use(ElScrollbar);
    app.use(ElDivider);
    app.use(ElInputNumber);
    app.use(ElCarousel);
    app.use(ElCarouselItem);
    app.use(ElBreadcrumb);
    app.use(ElBreadcrumbItem);
    app.use(ElImage);
    app.use(ElTree);
    app.use(ElTabs);

    app.config.globalProperties.$message = ElMessage;
    app.config.globalProperties.$notify = ElNotification;
}
