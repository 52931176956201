import $NP from '@/utils/NP';
import ResizeObserver from 'resize-observer-polyfill';
import { DirectiveBinding } from 'vue';

const calculateWidth = () => {
    const horizontalSteps = document.querySelector('.el-steps--horizontal');
    if (!horizontalSteps) return;
    const width = horizontalSteps?.clientWidth || 0;
    const stepList = document.getElementsByClassName('el-step') as HTMLCollectionOf<HTMLElement>;
    const activeDiv = document.querySelector('.el-step__active')?.getElementsByClassName('el-step__main')[0];
    const activeDivWidth = $NP.plus(activeDiv?.clientWidth || 0, 20);
    const count = stepList.length;
    const eachWidth = $NP.divide($NP.minus(width, activeDivWidth), count - 1);

    let i = 1;
    for (const c of stepList) {
        if (i !== count) {
            const w = c.classList.contains('el-step__active') ? $NP.plus(activeDivWidth, eachWidth) : eachWidth;
            c.style.setProperty('flex-basis', `${w}px`, 'important');
        }
        i++;
    }
};

const resizeObserver = new ResizeObserver(calculateWidth);

export const stepsCalcWidth = {
    mounted() {
        resizeObserver.observe(document.body);
    },
    updated(el, binding: DirectiveBinding) {
        const value = binding.value;
        const oldValue = binding.oldValue;
        let isChange = false;
        // 如果有除了 resize 之外其他情况需要计算宽度时通过传值进行判断是否需要重新进行计算
        if (Array.isArray(value)) {
            value.forEach((v, i) => {
                if (v !== oldValue[i]) {
                    isChange = true;
                }
            });
        } else if (typeof value === 'number') {
            if (value !== oldValue) {
                isChange = true;
            }
        }

        if (isChange) calculateWidth();
    },
    unmounted() {
        resizeObserver.unobserve(document.body);
    },
};
