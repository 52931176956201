import { Directive } from 'vue';

const removeNode = (parent: Node | null, child: Node): void => {
    if (parent?.contains(child)) {
        parent.removeChild(child);
    }
};

export const overInput: Directive = {
    mounted: (el) => {
        const div = document.createElement('div');
        const body = document.querySelector('body');
        const target = el.querySelector('.el-input__inner');
        target.addEventListener('mouseover', () => {
            if (target.scrollWidth <= target.offsetWidth) {
                return;
            }
            const popperArrow = document.createElement('div');
            const { top, left } = target.parentElement.getBoundingClientRect();
            div.innerText = target.value;
            div.className = 'tooltip_popper';
            popperArrow.className = 'popper_arrow';
            div.appendChild(popperArrow);
            body?.appendChild(div);
            div.setAttribute('style', `top:${top - 40}px;left:${left + target.parentElement.offsetWidth / 2 - div.offsetWidth / 2}px`);
        });
        el.addEventListener('mouseout', () => removeNode(body, div));
    },
};

// offsetWidth: https://developer.mozilla.org/zh-CN/docs/Web/API/HTMLElement/offsetWidth
// 是否超出宽度，应该减去父元素的padding。因为考虑到显示title的元素都不会加上border，因为这里直接忽略掉。 --leo 2019-9-5
function notOverflowElementWidth(el: any, parent: any, word_warp?: any): boolean {
    // 当word_warp为true，即是多行 换行时，如果目标元素超出容器的高度，则肯定显示不完整。所以判断超出内容范围。
    if (word_warp) {
        return parent.scrollWidth <= parent.offsetWidth && el.offsetHeight < parent.offsetHeight;
    }
    return parent.scrollWidth <= parent.offsetWidth;
}

function tooltipFun(el: any, word_warp: any): void {
    el.style.overflow = 'hidden';
    el.style.whiteSpace = 'nowrap';
    el.style.textOverflow = 'ellipsis';
    const div = document.createElement('div');
    const body = document.querySelector('body');
    el.addEventListener('mouseover', () => {
        const popper_arrow = document.createElement('div');
        const { top, left } = el.parentElement.getBoundingClientRect();
        div.innerText = el.innerText;
        div.className = 'tooltip_popper';
        popper_arrow.className = 'popper_arrow';
        div.appendChild(popper_arrow);
        body?.appendChild(div);
        word_warp
            ? div.setAttribute('style', `top:${top - div.offsetHeight - 10}px;left:${left}px;width:${210}px`)
            : div.setAttribute('style', `top:${top - 40}px;left:${left + el.parentElement.offsetWidth / 2 - div.offsetWidth / 2}px`);
    });
    el.addEventListener('mouseout', () => removeNode(body, div));
    el.addEventListener('mousedown', () => removeNode(body, div));
}

/**
 * v-tooltip 指令用来兼容字体移除显示 tip 但无法使用 el-tooltip 组件的情况，
 * 具体使用：
 *  <div>
        <span v-tooltip>{{column.group}}</span>
    </div>
 */
export const tooltip: Directive = {
    mounted: (el, binding) => {
        const word_warp = binding.value || false;
        if (notOverflowElementWidth(el, el.parentElement, word_warp)) {
            return;
        }
        tooltipFun(el, word_warp);
    },
    updated: (el, binding) => {
        const word_warp = binding.value || false;
        if (notOverflowElementWidth(el, el.parentElement)) {
            return;
        }
        tooltipFun(el, word_warp);
    },
};

function bindTooltipFun(el: any): void {
    if (notOverflowElementWidth(el, el.parentElement)) {
        return;
    }
    el.style.overflow = 'hidden';
    el.style.whiteSpace = 'nowrap';
    el.style.textOverflow = 'ellipsis';
    const div = document.createElement('div');
    const body = document.querySelector('body');
    el.addEventListener('mouseover', () => {
        const popper_arrow = document.createElement('div');
        const { top, left } = el.parentElement.getBoundingClientRect();
        div.innerText = el.innerText;
        div.className = 'tooltip_popper';
        popper_arrow.className = 'popper_arrow';
        div.appendChild(popper_arrow);
        body?.appendChild(div);
        div.setAttribute('style', `top:${top - 40}px;left:${left + el.parentElement.offsetWidth / 2 - div.offsetWidth / 2}px`);
    });
    el.addEventListener('mouseout', () => removeNode(body, div));
}

/* 动态改变value */
/**
 * 具体使用：
 *  <div>
        <span v-bind-tooltip>{{column.group}}</span>
    </div>
 */
export const bindTooltip: Directive = {
    mounted: (el) => {
        bindTooltipFun(el);
    },
    updated: (el) => {
        bindTooltipFun(el);
    },
};
